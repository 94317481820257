import React, {Fragment, Component} from 'react';
import ajax from 'superagent';
import jwtDecode from 'jwt-decode';
import UnlockMapButton from './UnlockMapButton';
import SiteDisplay from './SiteDisplay';
import config from '../../../constants/config';

export default class Sidebar extends Component {

    constructor(props){
    	//Accepted props include a list of sites

    	const userJWT = localStorage.getItem('token');
        const userDecoded = jwtDecode(userJWT);
        let username = userDecoded['cognito:username'];
        let email = userDecoded['email'];

    	super(props)
    	this.state={
    		sites: [],
    		username,
        	userJWT,
    		email,
    	}
	}

	render(){
		return(<Fragment>
			<div className="col-12 px-0 clearfix">
				<h4 className="mt-2 mb-2 ml-4 float-left">My Sites</h4>
				<a className="h6 pt-2 mt-2 mr-4 float-right" href={"/my-partners"}>My Partners<i className="fa fa-angle-right mx-2" aria-hidden="true"></i></a>
			</div>
			<hr className="mt-0 mb-4" />

			{this.renderSidebar()}
		</Fragment>)
	}

	renderEmptyState = () => {
		let {mapLocked, setMapLocked} = this.props;
		return (<Fragment>

			<UnlockMapButton 
				className="btn btn-block btn-primary mt-4"
				mapLocked={mapLocked}
				setMapLocked={setMapLocked}>
				+ Add Your First Site
			</UnlockMapButton>

			<hr className="mt-6" />

		</Fragment>)

	}

	renderSitesList = () => {
		let {mapLocked, setMapLocked, getSiteList, sites, showSharedSite, switchSidePanel} = this.props;

		return(<Fragment>

			{sites.map((site, key)=>{
				return <SiteDisplay site={site} key={site.idsite} refreshSites={getSiteList} showSharedSite={showSharedSite} switchSidePanel={switchSidePanel} />
			})}

			<UnlockMapButton 
				className="btn btn-sm btn-outline-primary mt-4 pb-3"
				mapLocked={mapLocked}
				setMapLocked={setMapLocked}
				callback={() => switchSidePanel("sandbox")}>
				+ Add a Site
			</UnlockMapButton>

		</Fragment>)
	}

	renderSidebar = () => {
		//alert("Calling renderSitesList")
		let {sites} = this.props;

		if (!sites || sites.length == 0){
			return this.renderEmptyState();
		} else {
			return this.renderSitesList();
		}
	}

}
