import React, {Component} from 'react';

export default class ModalDownloadTicket extends Component {
    constructor(props){
        super(props);
    }

    render(){
        return (
            <div className="modal fade" id="modal-filter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-long" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title" id="exampleModalLabel">Filter Tickets</h3>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body mx-4">
                            <div className="row">
                                <div className="form-group mr-4">
                                    <label>Start Date</label>
                                    <input className="form-control form-control-sm" type="text" placeholder="mm/dd/yyyy" />
                                </div>
                                <div className="form-group mr-4">
                                    <label>End Date</label>
                                    <input className="form-control form-control-sm" type="text"  placeholder="mm/dd/yyyy" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group">
                                    <label>Soil Profiles</label>
                                    <div className="custom-controls-stacked">
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" />
                                            <label className="custom-control-label">Soil Profile 1</label>
                                        </div>
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" />
                                            <label className="custom-control-label">Soil Profile 2</label>
                                        </div>
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" />
                                            <label className="custom-control-label">Soil Profile ...</label>
                                        </div>
                                    </div>
                                </div>
                            </div>                  
                        </div>
                        <div className="modal-footer flexbox">
                            <div>
                                <button type="button" className="btn btn-outline-secondary" data-dismiss="modal">Reset</button>
                            </div>
                            <div>
                                <button type="button" className="btn btn-primary" data-dismiss="modal">Filter</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}