import React, {Component, Fragment}  from 'react';
import MenuAppBar from '../NavBar';
import Footer from '../Footer';
import config from '../../constants/config';
import SandboxMap from '../SandboxMap/SandboxWrapper'
import Sidebar from './Sidebar'
import AddSoilProfileModal from './Sidebar/AddSoilProfileModal';
import Event from '../Event'
import ActiveTicketsList from '../Manifest/ActiveTickets/ActiveTicketList';
import CreateTicket from '../Manifest/ActiveTickets/CreateTicket';
import HauledToday from '../Manifest/HauledToday';
import Archive from '../Manifest/Archive';
import ajax from 'superagent'

import {
  isMobile,
  isBrowser
} from 'react-device-detect'

import {CSSTransition} from 'react-transition-group'

import './animation.css'
import './styles.css'

export default class MySites extends Component {

   constructor(props){
      let user = localStorage.getItem('user');
      let username = (JSON.parse(user).username);
      let iduser = JSON.parse(user).id;
      let email = JSON.parse(user).email;
      //username = username.split(':')[0];

      super(props)
      this.state = {
        mapLocked: true,
        siteRefresh: false,
        activeSite: null,
        sidebarSitesList: [],
        sandboxSitesList: [],
        username,
        email,
        sharedSite: null,

        displayState: "sandbox",
        displayStateHistory: [],

        panelProps: {
          site: {idsite: 0, sitename: "default"},
          idprojectsoil: null
        },

      }
  }

  componentWillMount = () => {
    this.getSiteList(false);

    let loadParams = localStorage.getItem('loadParams');
    if (loadParams != null && loadParams != undefined){
      loadParams = JSON.parse(loadParams);
      if (loadParams.displayState && loadParams.panelProps){
        let displayState = loadParams.displayState;
        let panelProps = loadParams.panelProps;
        this.setState({displayState, panelProps})
      }
      localStorage.removeItem('loadParams')
    }
  }


  getSiteList = (openNewest) => {
    let {email, username, panelProps} = this.state;
    let oldSite = panelProps.site;
    panelProps.site = {idsite: 0, sitename: "default"};
    this.setState({panelProps}, () => {


      ajax.get(`${config.apiUrl}/sites/readSiteAndProjectDataByUsername/${username}`)
        .end((error, response) => {
          if (!error && response) {
            let sidebarSitesList = response.body.sitesList;
            console.log("Sidebar", "Sites response", response);

            panelProps.site = oldSite;

            this.setState({sidebarSitesList, panelProps});



            //If needbe, open a specific sidebar accordion
            if (openNewest){
              this.setActiveSite(sidebarSitesList[sidebarSitesList.length-1], true);
            }
          } else {
            panelProps.site = oldSite;
            this.setState({panelProps})
            console.log('There was an error fetching', error);
          }
        }
      );

    });
  }

  showSharedSite = (sharedSite) => {
    this.setState({sharedSite});
  }

  setActiveSite = (val, openAccordion) => {
    this.setState({activeSite: val});

    setTimeout(()=> {
      let idsite = val.idsite;
      document.getElementById('collapse-trigger-'+idsite).click();
    }, 500);
  }

  setMapLocked = (val) => {
    this.setState({mapLocked: val});
  }

  setSiteRefresh = (val) => {
    this.setState({siteRefresh: val});
  }

  switchSidePanel = (displayState, params /*Wildcard obj that can have all manner of different parameters depending on the state being switched to*/) => {

    let {displayStateHistory, panelProps} = this.state;
    let openCreateTicket = false;

    if (displayState == "previous"){
      displayState = displayStateHistory.pop();
    } else if (displayState == "createticket"){ 
      openCreateTicket = true;
      displayState = this.state.displayState;
    } else if (displayState != "previous" && displayState != "same") {
      displayStateHistory.push(this.state.displayState);
    } else if (displayState == "same"){
      displayState = this.state.displayState;
    }

    console.log("displayState: ", displayState);

    //if (displayState == "archivedTickets" || displayState == "activeTickets" || displayState == "hauledToday"){
      if (params && params !== {}){
        panelProps = params;
        if (params.site) localStorage.setItem('site', JSON.stringify(params.site));
      }
    //}

    this.setState({displayState, displayStateHistory, panelProps}, () => {
      if (openCreateTicket){
        document.getElementById('open-new-ticket-modal').click();
      }
    });
  }


  render(){

    let {mapLocked, siteRefresh, sandboxSitesList, sidebarSitesList, sharedSite, displayState, panelProps} = this.state;

    return (

      <Fragment>
        <MenuAppBar />
        {displayState != "sandbox" ? 
          <nav className="nav navbar-dark border-bottom">
            <a className="nav-link h6 ml-0 py-0" href="#" onClick={() => this.switchSidePanel("sandbox")}>
              <i className="fa fa-angle-left mx-2" aria-hidden="true"></i>My Sites
            </a>
          </nav>
        : null}
        <main className="main-content">
          <section className="section py-0 shadow-2">
            <div className="row no-gutters">

             {!((displayState == "archivedTickets" || displayState == "activeTickets" || displayState=="hauledToday") && isMobile) ? 
              <div className="col-12 col-md-6 col-lg-4 p-0 pl-4 p-md-3 px-lg-5 pt-lg-4" >
                <Sidebar 
                  mapLocked={mapLocked} 
                  setMapLocked={this.setMapLocked} 
                  sites={sidebarSitesList}
                  showSharedSite={this.showSharedSite}
                  switchSidePanel={this.switchSidePanel}
                />
              </div> : null}

              {this.renderSandbox()}
              {this.renderAnimatedSidePanel("activeTickets", this.renderActiveTickets)}
              {this.renderAnimatedSidePanel("hauledToday", this.renderHauledToday)}
              {this.renderAnimatedSidePanel("archivedTickets", this.renderArchivedTickets)}

            </div>
          </section>
        </main>

        <CreateTicket site={panelProps.site} refreshSidebarTickets={this.getSiteList} />
        <AddSoilProfileModal idsite={panelProps.site.idsite} callback={this.getSiteList} />
        <Event eventtype={"setupGuideModal"} />
        <Event eventtype="phoneNumberCompanyName" />

        <Footer />
      </Fragment>
    )
  }

  renderActiveTickets = () => {
    let {panelProps} = this.state;
    return (<Fragment>
        <ActiveTicketsList 
          site={panelProps.site}
          switchSidePanel={this.switchSidePanel}
          refreshSidebarTickets={this.getSiteList}
          defaultFilter={panelProps.idproject}
        />
      <Event eventtype="ticketGuideModal" />
    </Fragment>)
  }

  renderHauledToday = () => {
    let {panelProps} = this.state;
    return (<HauledToday
      site={panelProps.site}
      switchSidePanel={this.switchSidePanel}
      defaultFilter={panelProps.idproject}
    />)
  }

  renderArchivedTickets = () => {
    let {panelProps} = this.state;
    return (<Archive
      site={panelProps.site}
      idprojectsoil={panelProps.idprojectsoil}
      switchSidePanel={this.switchSidePanel}
    />)
  }

  renderAnimatedSidePanel = (mode, renderFunc) => {
    let showPanel = (mode == this.state.displayState);
    //let isMobile = true; //For debugging
    //let isBrowser = false; //For debugging


    return(<Fragment>

      {showPanel && isBrowser ?
        <div className="col-md-6 col-lg-8 bg-light my-sites-scroll">
          <div className="col-12 pt-4 px-lg-6 mx-auto">
            {renderFunc()}
          </div>
        </div> : null }

      <CSSTransition 
        in={showPanel && isMobile}
        unmountOnExit
        timeout={400}
        classNames="slide-right"
        className="slide-right-base col-md-6 col-lg-8 bg-light"
      >

        <div id="matches-inbox">
          <div className="col-md-6 col-lg-8">
            {renderFunc()}
          </div>
        </div>

      </CSSTransition>

    </Fragment>)

  }

  renderSandbox = () => {
    let {mapLocked, sidebarSitesList, sharedSite, displayState} = this.state;

    if (displayState !== "sandbox") return null;
    else return(<div className="col-md-6 col-lg-8 my-sites-scroll sandbox">
      <SandboxMap 
        locked={mapLocked} 
        setMapLocked={this.setMapLocked}
        sitesList={sidebarSitesList}
        getSiteList={this.getSiteList} 
        sharedSite={sharedSite}
      />
    </div>)
  }


}
