import React, {Component} from 'react';
import ajax from 'superagent';
import config from '../../constants/config';
import MenuAppBar from '../NavBar';
import Footer from '../Footer';
import pageUrls from '../../constants/pageUrls';
import SuccessPopup from '../Popups';
import OptButton from '../StripeComponents/OptInToPayment/OptButton'
import ModalEditTeam from '../DumpProject/ModalEditTeam';
import AddSoilProfileModal from '../MySites/Sidebar/AddSoilProfileModal'

//This is the one we're actually using as the site-agreements page

export default class AgreementsList extends Component {

	constructor(props){
		super(props);

		this.state = {
			site: JSON.parse(localStorage.getItem('site')),
			user: JSON.parse(localStorage.getItem('user')),
			canAddAgreements: false,
			agreements: [],
			showDeletionConfirmPopup: false,
			showDeletionSuccessPopup: false,
			activeAgreement: null,
			siteCanPayForFeatures: true,
		}

		this.getAgreements();
	}

	getAgreements = () => {
		let idsite = JSON.parse(localStorage.getItem('site')).idsite;
		let iduser = JSON.parse(localStorage.getItem('user')).id;

		console.log("getAgreements idsite:",idsite);
		
		ajax.get(`${config.apiUrl}/projects/readSoilsAndProjectsBySite/` + idsite + "/" + iduser)
            .end((error, response) => {
                if (!error && response) {
                    console.log("Success");
                    console.log(response.body);
                    this.setState({agreements: response.body.projectList})
                } else {
                    console.log('There was an error fetching', error);
                }
            }
        );

        console.log("Debug BUG-863", "URL for the permissions endpoing:", config.apiUrl + '/sites/permissions/' + iduser + '/' + idsite);

        ajax.get(config.apiUrl + '/sites/permissions/' + iduser + '/' + idsite)
            .end((error, response) => {
                if (!error && response) {
                	console.log("Debug BUG-863", "permissions returned with response:", response);
                    console.log(response.body);
                    this.setState({canAddAgreements: response.body.permissions.canAddAgreements, 
                    	canDeleteAgreements: response.body.permissions.canDeleteAgreements})
                } else {
                	console.log("Debug BUG-863", "permissions returned with error:", error);
                    console.log('There was an error fetching', error);
                }
            }
        );
	}

	renderNoAgreementCard = () => {
		return(
			<div class="col-12 col-sm-8 col-md-6 col-lg-5 mx-auto ml-md-1">
				<div class="card d-block shadow-1 hover-shadow-3">
					<div class="card-body ">
						<h5 class="card-title empty-card-heading">No agreements yet</h5>
						<p class="mb-0">Start a new agreement and invite your disposal partner.</p>
					</div>
					<div class="card-footer  text-left">
						<a class="fs-12 fw-600" href={pageUrls.newDisposalAgreement}>+ New Agreement</a>
					</div>
				</div>
			</div>);
	}

	renderAgreementTable = () => {

		if (this.state.agreements.length === 0) return this.renderNoAgreementCard();

		return(<React.Fragment>
			{/*<h6>Length: {this.state.agreements.length}</h6>*/}
			<table className="table table-striped">
				<thead>
					<tr>
						<th>Disposal Partner</th>
						<th>Soil Profile</th>
						<th>Haul Direction</th>
						<th>Contact</th>
						<th>Premium Features</th>
					</tr>
				</thead>
				<tbody>
					{this.renderAgreementTableBody()}
				</tbody>
			</table>
		</React.Fragment>);
	}


	renderAgreementTableRow = (agreementData, key) => {

		let siteToUse = "dumpsite";
		let verb = "Importing From You";

		let showBadge = false;
        let badgeClear = false;

        if (agreementData.notification !== undefined){
            showBadge = true;
            badgeClear = agreementData.notification.dateread !== null;
        }

		/*console.log("Comparison data:");
		console.log(agreementData);
		console.log(agreementData.idsource);
		console.log(this.state.site.idsite);
		console.log(agreementData.idsource != this.state.site.idsite);*/
		if (agreementData.idsource != this.state.site.idsite){
			siteToUse = "sourcesite";
			verb = "Exporting To You";
		}

		let siteName = agreementData[siteToUse];
		if (siteName === null){
			if (agreementData.customername !== ""){
				siteName = agreementData.customername;
			} else {
				siteName = "Pending";
			}
		}

		let soil = agreementData.profile;
		if (agreementData.soildescription){
			soil += " - " + agreementData.soildescription;
		}

		let id = agreementData.idproject;

		return (
			<tr key={agreementData.idprojectsoil || id}>
				<th scope="row">
					<div className={"badge-primary mr-3" + (badgeClear ? " badge-ring" : " badge-dot")} 
                        style={{display: (showBadge ? 'inline-block' : 'none')}}></div>
					{siteName}
				</th>
				<td>{soil ? soil : "No soil profile set"}</td>
				<td>{verb}</td>
				<td>{agreementData.email}</td>
				<td><OptButton 
					agreement={agreementData} 
					canChangePaymentOptions={this.state.canAddAgreements && this.state.siteCanPayForFeatures} 
					label={false}
					idsite={this.state.site.idsite}
					site={this.state.site}
				 /></td>
				{this.state.canDeleteAgreements ? 
					<td>
						<div class="dropdown">
							<span class="dropdown-toggle no-caret" data-toggle="dropdown"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></span>
							<div class="dropdown-menu dropdown-menu-right">
								<a class="dropdown-item" onClick={() => this.setState({activeAgreement: id})} data-toggle="modal" data-target="#add-material">
									Add Soil Profile
								</a>
							</div>
							<div class="dropdown-menu dropdown-menu-right">
								<a class="dropdown-item" onClick={() => this.initiateDeleteAgreement(id)}>
									Delete Agreement
								</a>
							</div>
						</div>
	                </td>
                : null}
			</tr>
		)

	}

	renderAgreementTableBody = () => {

        return this.state.agreements.map((agreementData, index) => {
            return this.renderAgreementTableRow(agreementData, index)
        })
									
	}

	initiateDeleteAgreement = (idproject) => {
		this.setState({activeAgreement: idproject, showDeletionConfirmPopup: true});
	}

	callDeleteAgreement = () => {
		this.setState({showDeletionConfirmPopup: false});

		ajax.post(`${config.apiUrl}/projects/hide`)
            .send({idproject: this.state.activeAgreement, idsite: this.state.site.idsite})
            .then(res => {
                this.getAgreements();
            }).catch(e =>{
                alert(e);
            }); 
	}

	renderSupportLink = () => {
		return(
			<a class="lead cursor float-right mr-2 mr-lg-10 mt-0" 
			   data-toggle="offcanvas" data-target="#offcanvas-setup-1" href="#">
				<span data-toggle="tooltip" data-placement="left" title="Getting Set Up">
					<i class="fa fa-question-circle text-light fs-18 pt-1 pr-2" aria-hidden="true"></i>
				</span>
			</a>
		)
	}

	render(){
		return(<React.Fragment>
			<MenuAppBar showSandbox={true} />
			
			<main class="main-content">
				<header class="section-header mt-5 mb-1 mx-lg-12">
		            <h2><strong>{this.state.site.name} Agreements</strong></h2>
		            <a class="btn col-md-4 btn-primary mt-6" href={config.webUrl + pageUrls.activeTickets}>View Tickets</a>
		         </header>

				<div class="section pt-6">
					<div class="container">


						<div class="row mb-0">
					      	<div class="col-lg-10 mx-auto flexbox">
						        <p class="lead fw-400">PARTNER SITES</p>
						        <a class="link small strong text-primary cursor" data-toggle="modal" data-target="#edit-team"><u>Manage Site Team</u></a>
					        </div>
				      	</div>


						<div class="row mb-0">
							<div class="table-responsive col-lg-10 mx-auto">  

								{this.renderAgreementTable()}
								{this.state.canAddAgreements ? <a class="btn btn-xs btn-outline-primary ml-0 ml-md-8" href={pageUrls.newDisposalAgreement}>+ Add an Agreement</a> : null}
							</div> 

						</div>
						<label><em class="small-5 lh-1 my-0 py-0">If your agreement does not appear at first, please refresh the page.</em></label>

					</div>
				</div>
			</main>


			<SuccessPopup
                popupId="confirmAgreementDeletion"
                title="Are you sure you want to delete this?"
                message="Once an agreement has been deleted, you will lose all ticket records for that agreement."
                trigger={this.state.showDeletionConfirmPopup}
                callback={this.callDeleteAgreement}
                useButton = {true}
                style="danger"
                buttonLabel = "Yes I'm Sure"
                />

            <ModalEditTeam site={this.state.site.idsite} />
            <AddSoilProfileModal idproject={this.state.activeAgreement} callback={this.getAgreements} />

      		<Footer />
		</React.Fragment>)
	}
 

}