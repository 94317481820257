import React, {Fragment, Component} from 'react';
import jwtDecode from 'jwt-decode';

export default function SharedSiteButton ({site, showSharedSite}) {


	return(
		<a href="#" onClick={() => {showSharedSite(site)}}>
			<i className="fa fa-location-arrow text-primary pl-2 fs-16"></i>
		</a>
	)

}