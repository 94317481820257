/****
*
*  This is a "core" file for the EI Platform.
*  Please keep this file identical accross all platforms on which it appears.
*  If you must implement app-specific functionality, implement that in a wrapper component.
*
*/

import React, { Component } from 'react';
import { Auth } from "aws-amplify";

export default class Logout extends Component {
  constructor(props) {
    super(props);
    this.handleSignOut();
  }

  handleSignOut = () => {
    localStorage.setItem('token', null);
    localStorage.setItem('user', null);
    Auth.signOut()
      .then(() => {
        console.log("Signed-Out");
        //this.props.screenProps.onStateChange('signedOut', null);
        window.location.href = this.props.home;
      })
      .catch(err => console.log(err));
  }

  render = () => {
    return(<h1>Logging out...</h1>);
  }

}
