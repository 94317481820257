import React, {Fragment, Component} from 'react';
import ajax from 'superagent';
import jwtDecode from 'jwt-decode';
import SetupGuideModal from './SetupGuideModal';
import TicketGuideModal from './TicketGuideModal';
import PhoneNumberCompanyName from './PhoneNumberCompanyName';
import config from '../../constants/config';

export default class Event extends Component {

    constructor(props){

    	const userJWT = localStorage.getItem('token');
      const userDecoded = jwtDecode(userJWT);
      let username = userDecoded['cognito:username'];
      let email = userDecoded['email'];

    	super(props)

    	this.state={
    		username,
    		email,
        seen: true
    	}
	}


	componentWillMount = async () => {
		let {email} = this.state;
    let {eventtype} = this.props;

		ajax.get(`${config.eiBackend}/events/getByEventTypeAndUsername/${eventtype}/${email}`)
      .end((error, response) => {
        if (!error && response) {
            this.setState({seen: response.body.eventSeen})
        } else {
            console.log('There was an error fetching', error);
        }
      }
    );
	}

  markAsSeen = async () => {

    let {email} = this.state;
    let {eventtype} = this.props;

    ajax.post(`${config.eiBackend}/events/markAsSeen`)
      .send({
        eventtype,
        email
      })
      .end((error, response) => {
        if (!error && response) {
            //this.setState({seen: true})
        } else {
            console.log('There was an error fetching', error);
        }
      }
    );

  }

	render(){
    let {eventtype} = this.props; 
    let {seen} = this.state;

    if (seen == true) return null;
    else {
  		/*if (eventtype == "testPopup"){
        return <TestPopup markAsSeen={this.markAsSeen} />
      }*/
      if (eventtype == "ticketGuideModal"){
        return <TicketGuideModal markAsSeen={this.markAsSeen} />
      }
       if (eventtype == "setupGuideModal"){
        return <SetupGuideModal markAsSeen={this.markAsSeen} />
      }
      else if (eventtype == "phoneNumberCompanyName"){
        return <PhoneNumberCompanyName markAsSeen={this.markAsSeen} eiBackend={config.eiBackend} />
      }
      else return null;
      return null;
    }
	}

}