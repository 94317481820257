/****
*
*  This is a "core" file for the EI Platform.
*  Please keep this file identical accross all platforms on which it appears.
*  If you must implement app-specific functionality, implement that in a wrapper component.
*
*/

import React from 'react';
import '../Login/styles.css';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { SignUp } from "aws-amplify-react";
import { Auth } from 'aws-amplify';
import { withRouter } from 'react-router-dom';

class VerifyRegister extends React.Component {
  constructor(props) {
    super(props);

    let username = localStorage.getItem('tempUsername');
    this.state = {
      username,
      code: '',
      isOpen: false,
      message: '',
      displaySpinner: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this._validAuthStates = ["signIn", "signedIn", "signedOut", "signedUp"];
    Auth.currentSession()
    //.then(data => this.props.history.push("/haul-checks"))
    .then(data => window.location.href = `${this.props.home}/index`)
    .catch(err => console.log(err));
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  handleClose(event) {
    this.setState({message: '', isOpen: false});
  }

  handleStateChanged(authState) {
    console.log("On State changed");
    const {redirect} = this.state;
    if (authState==='signedIn' && !redirect) {
      //this.props._authState = 'signedIn';
      this.setState({redirect: true});
      console.log(redirect);
      //window.location.href = `${this.props.home}/haul-checks`
    } else if (authState==='signedOut') {
      //this.props._authState = 'signedIn';
      this.setState({redirect: false});
      console.log("From SignedOut || SignIn" + redirect);
    }
  }

  keyPress = (e) =>{
      if(e.keyCode == 13){
         this.handleVerify()
      }
   }

   resendCode = async () => {
    try {
      await Auth.resendSignUp(this.state.username);
      this.setState({message: 'Code was re-sent!', isOpen: true});
    } catch(error) {
      this.setState({message: "Error resending code: " + error.message, isOpen: true});
    }
    
   }

  async handleVerify() {
    let {username, code, displaySpinner} = this.state
    this.setState({displaySpinner: true});
    try {
      if (!code) {
        this.setState({message: 'Please enter confirmation code', isOpen: true, displaySpinner: false});
        return;
      }
      const user = await Auth.confirmSignUp(username, code);
      console.log(user);
      this.setState({displaySpinner: false});
      this.setState({message: 'User verified successfully', isOpen: true});
      window.lintrk('track', { conversion_id: 6174154 });
      //this.props.history.push("/");
      window.location.href = `${this.props.home}/login`;
    } catch (error) {
        this.setState({message: 'Error verifying code: ' + error.message, isOpen: true, displaySpinner: false});
    }
    //super.signIn().then(() => {
        // this.props.history.push("/haul-checks");
        // window.location.href = `${this.props.home}/haul-checks`;
    // });
  }

    render() {
        return (
            <div className="Login" style={{backgroundImage: `url(/img/bg/4.jpg)` }}>
            <Snackbar open={this.state.isOpen}
              autoHideDuration={2500}
              onClose={this.handleClose}
              message = {this.state.message}
            >
            </Snackbar>
                <div className="Login-content">
                    <div className="background">
                        <Container component="main" maxWidth="xs">
                            <CssBaseline />
                            <div>
                                <img src={this.props.logo} alt="logo" width="45%" className="mb-6"/>
                                <h5 className="login-title">Confirm Sign Up</h5>

                                <form name="form">
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="username"
                                        label="Username"
                                        name="username"
                                        autoComplete="username"
                                        value={this.state.username} onChange={this.handleChange}
                                        autoFocus
                                        disabled
                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="code"
                                        label="Confirmation Code"
                                        name="code"
                                        value={this.state.code} onChange={this.handleChange}
                                        onKeyUp={this.keyPress}
                                        autoFocus
                                    />
                                    <p>Lost your code? <Link variant="body2" tabIndex="0" onClick={this.resendCode}>Resend Code</Link></p>
                  
                                    <Button
                                        type="button"
                                        onClick={() => {this.handleVerify()} }
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                    >
                                        {this.state.displaySpinner ? <img src="../img/app/photoswipe/preloader.gif" /> : null}
                                        Confirm
                                    </Button>
                                    <p style={{textAlign: 'center', marginTop: '1.5em'}}><Link variant="body2" tabIndex="0" onClick={() => window.location.href = `${this.props.home}/login`}>Back to Login</Link>
                                    </p>
                                    
                                </form>
                            </div>
                        </Container>
                    </div>
                </div>

            </div>
        );
    }
}

export default withRouter(VerifyRegister);
