import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import { Button, Modal } from 'react-bootstrap';
import ajax, {Request} from 'superagent';
import { connect } from 'react-redux';
import config from '../../../constants/config';
import ModalConfirmDisposedPhoto from '../ModalConfirmDisposedPhoto';
import ModalConfirmDisposedTicket from '../ModalConfirmDisposedTicket';

import {
    getActiveTickets,
    createTicket,
    updateTicketSourceGeo,
    updateTicketSourcePicture,
    updateTicketArrivalGeo,
    updateTicketArrivalPicture,
    getNextTicketNumber,
} from '../../../actions/tickets';

import { getPartnerSites } from '../../../actions/sites';

import { getUser } from '../../../actions/user';

class CreateTicketForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            generator: props.users.user.data,
            customer: null,
            licenseToSend: null,
            soilProfileToSend: null,
        }
        if(!props.users.user.inProgress && !props.users.user.success){
            const id = JSON.parse(localStorage.getItem('user')).id;
            if(id){
                props.getUser(id);
            }
        }
    }

    componentWillReceiveProps(newProps){
        if(this.props.users.user.inProgress !== newProps.users.user.inProgress && this.state.generator !== newProps.users.user.data){
            this.setState({generator: newProps.users.user.data})
        }
    }

    renderDisposalPartnerOptions(){
        return this.state.sites.map(site => {
            return (
                <option value={site.idproject} key={site.idsite}>{site.name}</option>
            )
        })
    }

    disposalPartnerChanged(e){
        const project = this.state.projects.find(p => {
            return p.idproject == e.target.value;
        })
        this.setState({
            disposalPartnerSelected: e.target.value,
            isNewTicketDisabled: false,
            generator: project.generator,
            customer: project.customername,
            idsitetype: project.idsitetype,
        });
        this.props.getNextTicketNumber(e.target.value);
        ajax.get(`${config.apiUrl}/soilprofiles/projectid/${e.target.value}`)
            .end((error, response) => {
                if (!error && response) {
                    this.setState({soilProfilesList: response.body.soilProfiles})
                } else {
                    console.log('There was an error fetching', error);
                }
            }
        );
        ajax.get(`${config.apiUrl}/transporters/byprojectid/${e.target.value}`)
            .end((error, response) => {
                if (!error && response) {
                    this.setState({transportersList: response.body.transportersList})
                } else {
                    console.log('There was an error fetching', error);
                }
            }
        );
    }

    render() {
        const {
            dumpSite,
            sourceSite,
            generator,
            transporter,
            customer,
            licenseToSend,
            soilProfileToSend,
        } = this.state;
        const ticketNumber = this.state.nextTicketNumber;

        return (

           <h1>CreateTicketForm.js</h1>

        )


        /*return (
            <div className="fullwidth col-lg-9 accordion accordion-arrow-right accordion-light mb-4 mx-auto" id="accordion-1">
                <div className="card noborder">
                    <label className="card-title lead fw-500 bg-pale-primary mb-3">
                        <a className="bg-pale-primary collapsed" data-toggle="collapse" href="#collapse-1">CREATE NEW TICKET</a>
                    </label>
                    <div id="collapse-1" className="collapse" data-parent="#accordion-1">
                        <div className="card noborder">
                            <div className="card-body">
                                <div className="row mb-4">
                                    <div className="col-md-4 col-lg-4">
                                        <select className="form-control form-control-lg" value={this.state.disposalPartnerSelected} onChange={this.disposalPartnerChanged.bind(this)}>
                                            <option value='-1' disabled={true}>Disposal Partner</option>
                                            {this.renderDisposalPartnerOptions()}
                                        </select>
                                    </div> 
                                </div>
                                <div className="row mb-2"> 
                                    <div className="col-md-6 col-lg-7">
                                        <label className="col-4 col-sm-6 col-md-5 col-lg-4 fw-100">Ticket #</label>
                                        <label className="fw-600 fs1m">{ticketNumber}</label>
                                    </div>
                                    <div className="col-md-6 col-lg-7">
                                        <label className="col-4 col-sm-6 col-md-5 col-lg-4 fw-100">Generator</label>
                                        <label className="fw-600 fs1m">{generator}</label>
                                    </div>
                                    <div className="col-md-6 col-lg-7">
                                        <label className="col-4 col-sm-6 col-md-5 col-lg-4 fw-100">Customer</label>
                                        <label className="fw-600 fs1m">{customer}</label>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-5 col-lg-6 mt-2 mb-3">
                                        <label>Soil Profile</label>
                                        <select className="form-control form-control-lg" disabled={this.state.isNewTicketDisabled} value={soilProfileToSend} name="soilProfileToSend" onChange={this.handleChange.bind(this)}>
                                            <option value="" disabled>Select one</option>
                                            {this.renderSoilProfilesOptions()}
                                        </select>
                                    </div>
                                    <div className="col-md-5 col-lg-6 mt-2 mb-3">
                                        <label>Transporter</label>
                                        <select className="form-control form-control-lg" disabled={this.state.isNewTicketDisabled} value={this.state.transporter} onChange={e => this.setState({transporter: e.target.value})}>
                                            <option value='-1' disabled={true}>Select one</option>
                                            {this.renderTransportersOptions()}
                                        </select>
                                    </div> 
                                    <div className="col-md-5 col-lg-6 mt-2 mb-3">
                                        <label>Truck's State License #</label>
                                        <div className="input-group">
                                            <input className="form-control form-control-lg" disabled={this.state.isNewTicketDisabled} type="text"  placeholder="License Plate (AB 12345)" value={licenseToSend} name="licenseToSend" onChange={this.handleChange.bind(this)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8 col-lg-8 mt-5">
                                    <button type="button" className="btn btn-label btn-lg btn-outline-secondary" disabled={this.state.isNewTicketDisabled} onClick={() => this.setState({showModal: true})}>
                                        <label><i className="fa fa-camera"></i></label> Photo of Load
                                    </button>
                                    {false /*fotoUploaded*//* ? <label className="small-1 my-2">Image Uploaded: 20191210-173389.jpg</label> : null}
                                    <div className="custom-control custom-checkbox pb-3" data-toggle="collapse" href="#open-nophoto">
                                        <input type="checkbox" className="custom-control-input mt-2 mb-4" />
                                        <label className="custom-control-label mt-4 mb-2">No Photo Available</label>
                                    </div>
                                
                                    <div className="accordion accordion-light" id="nophoto1">
                                        <div className="card noborder"  style={{overflow: 'visible'}}>
                                            <div id="open-nophoto" className="collapse" data-parent="#nophoto1">
                                                <div className="card-body border-primary p-4 mb-4">
                                                    <label className="lead fw-600">Digital Signature required:</label>

                                                    <div className="col-9 input-line pl-2">
                                                        <div className="input-group mb-4">
                                                            <DatePicker
                                                                selected={this.state.offlineTimestamp}
                                                                onChange={date => {this.setState({offlineTimestamp: date})}}
                                                                showTimeSelect
                                                                timeFormat="HH:mm"
                                                                timeIntervals={10}
                                                                timeCaption="time"
                                                                dateFormat="mm/dd/yyyy h:mm aa"
                                                                placeholderText="Timestamp"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-9 input-line pl-2">
                                                        <div className="input-group mb-4">
                                                        <input className="form-control form-control-sm" type="text" placeholder="Your Initials" name="offlineSignature" value={this.state.offlineSignature} onChange={this.handleChange.bind(this)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8 col-lg-8 mt-2">
                                    <button type="button" className="btn btn-label btn-lg btn-outline-secondary mb-4" data-toggle="collapse" href="#open-calculator1">
                                        <label><i className="fa fa-calculator"></i></label> Enter Tonnage
                                    </button>
                                    <label className="lead ml-4">{this.state.tonnage}</label>
                                    <div className="accordion accordion-light" id="calc1">
                                        <div className="card noborder">
                                            <div id="open-calculator1" className="collapse" data-parent="#calc1">
                                                <div className="card-body border p-4 mb-4">
                                                    <div className="col-md-9 col-lg-9">
                                                        <label>Tare Weight (lbs)</label>
                                                        <div className="input-group">
                                                            <input className="form-control form-control-lg" disabled={this.state.isNewTicketDisabled} type="number" step="0.01" placeholder="0" value={Number(this.state.tareWeight)} onChange={this.handleChange.bind(this)} name="tareWeight" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-9 col-lg-9">
                                                        <label>Gross Weight (lbs)</label>
                                                        <div className="input-group">
                                                            <input className="form-control form-control-lg" disabled={this.state.isNewTicketDisabled} type="number" step="0.01" placeholder="0" value={Number(this.state.grossWeight)} onChange={this.handleChange.bind(this)} name="grossWeight" onBlur={this.grossWeightBlur.bind(this)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-9 col-lg-9 mb-2">
                                                        <label>Calculated Tonnage</label>
                                                        <div className="input-group">
                                                        <input className="form-control form-control-sm" disabled={this.state.isNewTicketDisabled} type="number" step="0.01" placeholder="20.00" value={this.state.calculatedTonnage} onChange={this.handleChange.bind(this)} name="calculatedTonnage" />
                                                            <div className="input-group-append ml-4">
                                                                <button className="btn btn-outline-primary" type="button" onClick={() => {this.setState({tonnage: this.state.calculatedTonnage})}}  data-toggle="collapse" href="#open-calculator1" >Save</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.Modal.bind(this)()}
                                <div style={{display: this.state.showCamera ? 'block' : 'none'}}>
                                    <button id="takePicture">Take snapshot</button>
                                    <button id="changeCamera">Change camera</button>
                                    <br />
                                    <video playsInline autoPlay id="cameraInput" style={{maxWidth: '50%'}}></video>
                                    <canvas id="takenPicture" style={{maxWidth: '50%'}}></canvas>
                                </div>
                                {this.state.imageToSend && this.state.imageToSend.name ? <span>(File: {this.state.imageToSend.name})</span> : null}
                                <hr className="mt-6 mb-3" />
                                <div className="mt-5 flexbox">
                                    <a></a>
                                    <button className="btn btn-primary" data-toggle="modal" data-target="#modal-start" style={{color: "white"}} onClick={this.startTicket.bind(this)}
                                        disabled={
                                            this.state.isNewTicketDisabled
                                            || !this.state.soilProfileToSend 
                                            || !this.state.licenseToSend 
                                            || this.state.transporter == -1
                                            //|| !this.state.imageToSend 
                                        }>Start Ticket</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )*/
    }
}

function mapStateToProps(state) {
    return {
        tickets: state.tickets,
        sites: state.sites,
        users: state.users,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getPartnerSites: (idsite, idgenerator) => dispatch(getPartnerSites(idsite, idgenerator)),
        getUser: (id) => dispatch(getUser(id)),
        getTickets: (idsite, idgenerator) => dispatch(getActiveTickets(idsite, idgenerator)),
        createTicket: (data) => dispatch(createTicket(data)),
        updateTicketSourceGeo: (data) => dispatch(updateTicketSourceGeo(data)),
        updateTicketSourcePicture: (idticket, picture) => dispatch(updateTicketSourcePicture(idticket, picture)),
        updateTicketArrivalGeo: (data) => dispatch(updateTicketArrivalGeo(data)),
        updateTicketArrivalPicture: (idticket, picture, signature, tons) => dispatch(updateTicketArrivalPicture(idticket, picture, signature, tons)),
        getNextTicketNumber: (id) => dispatch(getNextTicketNumber(id)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateTicketForm)