import {
    GET_ACTIVE_TICKETS_REQUESTED,
    GET_ACTIVE_TICKETS_REJECTED,
    GET_ACTIVE_TICKETS_FULFILLED,

    GET_HAULED_TODAY_TICKETS_REQUESTED,
    GET_HAULED_TODAY_TICKETS_REJECTED,
    GET_HAULED_TODAY_TICKETS_FULFILLED,
    
    GET_CLOSED_TICKETS_REQUESTED,
    GET_CLOSED_TICKETS_REJECTED,
    GET_CLOSED_TICKETS_FULFILLED,

    CREATE_NEW_TICKET_REQUESTED,
    CREATE_NEW_TICKET_REJECTED,
    CREATE_NEW_TICKET_FULFILLED,

    UPDATE_TICKET_SOURCE_PICTURE_REQUESTED,
    UPDATE_TICKET_SOURCE_PICTURE_REJECTED,
    UPDATE_TICKET_SOURCE_PICTURE_FULFILLED,
    
    UPDATE_TICKET_SOURCE_GEO_REQUESTED,
    UPDATE_TICKET_SOURCE_GEO_REJECTED,
    UPDATE_TICKET_SOURCE_GEO_FULFILLED,

    UPDATE_TICKET_ARRIVAL_PICTURE_REQUESTED,
    UPDATE_TICKET_ARRIVAL_PICTURE_REJECTED,
    UPDATE_TICKET_ARRIVAL_PICTURE_FULFILLED,
    
    UPDATE_TICKET_ARRIVAL_GEO_REQUESTED,
    UPDATE_TICKET_ARRIVAL_GEO_REJECTED,
    UPDATE_TICKET_ARRIVAL_GEO_FULFILLED,

    GET_NEXT_TICKET_NUMBER_REQUESTED,
    GET_NEXT_TICKET_NUMBER_REJECTED,
    GET_NEXT_TICKET_NUMBER_FULFILLED,

    MARK_TICKET_AS_IN_PROGRESS,

    SET_TICKET_FOR_MODAL,
} from './types';

import request from 'superagent';
import config from '../constants/config';

export function getActiveTickets(idsite, idgenerator){
    //console.log("Calling getActiveTickets");
    return dispatch => {
        dispatch(getActiveTicketsRequestedAction());
        request
        .get(`${config.apiUrl}/tickets/bysiteandgenerator/${idsite}/${idgenerator}/active`)
        .set('Accept', 'application/json')
        .then(response => {
            dispatch(getActiveTicketsFulfilledAction(response.body))
        })
        .catch((error) => {
            dispatch(getActiveTicketsRejectedAction(error));
        });
    }
}

function getActiveTicketsRequestedAction() {
    return {
        type: GET_ACTIVE_TICKETS_REQUESTED,
        inProgress: true
    };
}
  
function getActiveTicketsRejectedAction(error) {
    return {
        type: GET_ACTIVE_TICKETS_REJECTED,
        inProgress: false,
        success: false,
        error
    }
}
  
function getActiveTicketsFulfilledAction(data) {
    return {
        type: GET_ACTIVE_TICKETS_FULFILLED,
        data,
        success: true,
        inProgress: false,
        error: false
    };
}

export function getHauledTodayTickets(idsite, idgenerator){
    return dispatch => {
        dispatch(getHauledTodayTicketsRequestedAction());
        request
        .get(`${config.apiUrl}/tickets/bysiteandgenerator/${idsite}/${idgenerator}/closedtoday`)
        .set('Accept', 'application/json')
        .then(response => {
            dispatch(getHauledTodayTicketsFulfilledAction(response.body))
        })
        .catch((error) => {
            dispatch(getHauledTodayTicketsRejectedAction(error));
        });
    }
}

function getHauledTodayTicketsRequestedAction() {
    return {
        type: GET_HAULED_TODAY_TICKETS_REQUESTED,
        inProgress: true
    };
}

function getHauledTodayTicketsRejectedAction(error) {
    return {
        type: GET_HAULED_TODAY_TICKETS_REJECTED,
        inProgress: false,
        success: false,
        error
    }
}

function getHauledTodayTicketsFulfilledAction(data) {
    return {
        type: GET_HAULED_TODAY_TICKETS_FULFILLED,
        data,
        success: true,
        inProgress: false,
        error: false
    };
}

export function getClosedTickets(idsite, idgenerator){
    return dispatch => {
        dispatch(getClosedTicketsRequestedAction());
        request
        .get(`${config.apiUrl}/tickets/bysiteandgenerator/${idsite}/${idgenerator}/closed`)
        .set('Accept', 'application/json')
        .then(response => {
            dispatch(getClosedTicketsFulfilledAction(response.body))
        })
        .catch((error) => {
            dispatch(getClosedTicketsRejectedAction(error));
        });
    }
}

function getClosedTicketsRequestedAction() {
    return {
        type: GET_CLOSED_TICKETS_REQUESTED,
        inProgress: true
    };
}

function getClosedTicketsRejectedAction(error) {
    return {
        type: GET_CLOSED_TICKETS_REJECTED,
        inProgress: false,
        success: false,
        error
    }
}

function getClosedTicketsFulfilledAction(data) {
    return {
        type: GET_CLOSED_TICKETS_FULFILLED,
        data: data.ticketsList,
        success: true,
        inProgress: false,
        error: false
    };
}

export function createTicket(data){
    return dispatch => {
        dispatch(createTicketRequestedAction());
        request
        .post(`${config.apiUrl}/tickets`)
        .send(data)
        .set('Accept', 'application/json')
        .then(response => {
            dispatch(createTicketFulfilledAction(response.body))
        })
        .catch((error) => {
            dispatch(createTicketRejectedAction(error));
        });
    }
}

function createTicketRequestedAction() {
    return {
        type: CREATE_NEW_TICKET_REQUESTED,
        inProgress: true
    };
}

function createTicketRejectedAction(error) {
    return {
        type: CREATE_NEW_TICKET_REJECTED,
        inProgress: false,
        success: false,
        error
    }
}

function createTicketFulfilledAction(data) {
    return {
        type: CREATE_NEW_TICKET_FULFILLED,
        data,
        success: true,
        inProgress: false,
        error: false
    };
}

export function updateTicketSourcePicture(idticket, picture){
    return dispatch => {
        dispatch(updateTicketSourcePictureRequestedAction());
        /*request
        .post(`${config.apiUrl}/tickets/trackdeparting/picture`)
        .attach('picture', picture)
        .field('id', idticket)
        .set('Accept', 'application/json')
        .then(response => {
            dispatch(updateTicketSourcePictureFulfilledAction(response.body))
        })
        .catch((error) => {
            dispatch(updateTicketSourcePictureRejectedAction(error));
        });*/
    }
}

function updateTicketSourcePictureRequestedAction() {
    return {
        type: UPDATE_TICKET_SOURCE_PICTURE_REQUESTED,
        inProgress: true
    };
}

function updateTicketSourcePictureRejectedAction(error) {
    return {
        type: UPDATE_TICKET_SOURCE_PICTURE_REJECTED,
        inProgress: false,
        success: false,
        error
    }
}

function updateTicketSourcePictureFulfilledAction(data) {
    return {
        type: UPDATE_TICKET_SOURCE_PICTURE_FULFILLED,
        data,
        success: true,
        inProgress: false,
        error: false
    };
}

export function updateTicketSourceGeo(data){
    return dispatch => {
        dispatch(updateTicketSourceGeoRequestedAction());
        request
        .post(`${config.apiUrl}/tickets/trackdeparting/geo`)
        .send(data)
        .set('Accept', 'application/json')
        .then(response => {
            dispatch(updateTicketSourceGeoFulfilledAction(response.body))
        })
        .catch((error) => {
            dispatch(updateTicketSourceGeoRejectedAction(error));
        });
    }
}

function updateTicketSourceGeoRequestedAction() {
    return {
        type: UPDATE_TICKET_SOURCE_GEO_REQUESTED,
        inProgress: true
    };
}

function updateTicketSourceGeoRejectedAction(error) {
    return {
        type: UPDATE_TICKET_SOURCE_GEO_REJECTED,
        inProgress: false,
        success: false,
        error
    }
}

function updateTicketSourceGeoFulfilledAction(data) {
    return {
        type: UPDATE_TICKET_SOURCE_GEO_FULFILLED,
        data,
        success: true,
        inProgress: false,
        error: false
    };
}

export function updateTicketArrivalPicture(idticket, signature, tons, timestamp, datedisposed, callback){


    return dispatch => {
        dispatch(updateTicketArrivalPictureRequestedAction());
        request
        .post(`${config.apiUrl}/tickets/trackarriving/picture`)
        .field('id', idticket)
        .field('signature', signature)
        .field('tons', tons)
        .field('timestamp', timestamp)
        .field('datedisposed', datedisposed)
        .field('idcloser', JSON.parse(localStorage.getItem('user')).id)
        .set('Accept', 'application/json')
        .then(response => {
            if (callback) callback();
            dispatch(updateTicketArrivalPictureFulfilledAction(response.body))
        })
        .catch((error) => {
            dispatch(updateTicketArrivalPictureRejectedAction(error));
        });
    }
}

function updateTicketArrivalPictureRequestedAction() {
    return {
        type: UPDATE_TICKET_ARRIVAL_PICTURE_REQUESTED,
        inProgress: true
    };
}

function updateTicketArrivalPictureRejectedAction(error) {
    return {
        type: UPDATE_TICKET_ARRIVAL_PICTURE_REJECTED,
        inProgress: false,
        success: false,
        error
    }
}

function updateTicketArrivalPictureFulfilledAction(data) {
    return {
        type: UPDATE_TICKET_ARRIVAL_PICTURE_FULFILLED,
        data,
        success: true,
        inProgress: false,
        error: false
    };
}

export function updateTicketArrivalGeo(data){
    return dispatch => {
        dispatch(updateTicketArrivalGeoRequestedAction());
        request
        .post(`${config.apiUrl}/tickets/trackarriving/geo`)
        .send(data)
        .set('Accept', 'application/json')
        .then(response => {
            dispatch(updateTicketArrivalGeoFulfilledAction(response.body))
        })
        .catch((error) => {
            dispatch(updateTicketArrivalGeoRejectedAction(error));
        });
    }
}

function updateTicketArrivalGeoRequestedAction() {
    return {
        type: UPDATE_TICKET_ARRIVAL_GEO_REQUESTED,
        inProgress: true
    };
}

function updateTicketArrivalGeoRejectedAction(error) {
    return {
        type: UPDATE_TICKET_ARRIVAL_GEO_REJECTED,
        inProgress: false,
        success: false,
        error
    }
}

function updateTicketArrivalGeoFulfilledAction(data) {
    return {
        type: UPDATE_TICKET_ARRIVAL_GEO_FULFILLED,
        data,
        success: true,
        inProgress: false,
        error: false
    };
}

export function getNextTicketNumber(idproject){
    return dispatch => {
        dispatch(getNextTicketNumberRequestedAction());
        request
        .get(`${config.apiUrl}/tickets/nextticket/${idproject}`)
        .set('Accept', 'application/json')
        .then(response => {
            dispatch(getNextTicketNumberFulfilledAction(response.body))
        })
        .catch((error) => {
            dispatch(getNextTicketNumberRejectedAction(error));
        });
    }
}

function getNextTicketNumberRequestedAction() {
    return {
        type: GET_NEXT_TICKET_NUMBER_REQUESTED,
        inProgress: true
    };
}
  
function getNextTicketNumberRejectedAction(error) {
    return {
        type: GET_NEXT_TICKET_NUMBER_REJECTED,
        inProgress: false,
        success: false,
        error
    }
}
  
function getNextTicketNumberFulfilledAction(data) {
    return {
        type: GET_NEXT_TICKET_NUMBER_FULFILLED,
        data,
        success: true,
        inProgress: false,
        error: false
    };
}
export function markTicketAsInProgress(idticket){
    return dispatch => {
        dispatch(markTicketAsInProgressAction(idticket));
    }
}

function markTicketAsInProgressAction(idticket) {
    return {
        type: MARK_TICKET_AS_IN_PROGRESS,
        idticket
    };
}

export function setTicketForModal(ticket) {
    return {
        type: SET_TICKET_FOR_MODAL,
        ticket
    };
}