import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter, Route, Redirect, Switch} from 'react-router-dom';
import './index.css';

import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import jwtDecode from 'jwt-decode'
import { userService } from './services';


import App from './App';
import Login from './components/Login';
import LogoutCore from './components/Logout/LogoutCore';
//import Auth from './components/Auth';
import RegisterCore from './components/Register/RegisterCore';
import VerifyRegisterCore from './components/Register/VerifyRegisterCore';
import * as serviceWorker from './serviceWorker';
//import Manifest from './components/Manifest';
import ActiveTickets from './components/Manifest/ActiveTickets';
//import HauledToday from './components/Manifest/HauledToday';
//import Archive from './components/Manifest/Archive';
import DumpProject from './components/DumpProject';
import AgreementsList from './components/AgreementsList';
import NewProject from './components/NewProject';
import ProjectDash from './components/ProjectDash';
import Recover from './components/Recover';
import InviteeRegister from './components/InviteeRegister';
import UserSettigns from './components/UserSettings';
import Stripe from './components/Stripe';
//import Transporters from './components/CRUDs/Transporters';
//import Sites from './components/CRUDs/Sites';
import MySites from './components/MySites'
import store from './store';
import pageUrls from './constants/pageUrls';
import config from './constants/config';
import MyPartners from './components/MyPartners';


const LoggedIn = (
    <Provider store={store}>
        <BrowserRouter>
             <Switch>
                {/*<Route path={pageUrls.login} component={Login} />
                <Route path={pageUrls.loginFromInvite + ":projectdata"} component={Login} />
                <Route path={pageUrls.registerFromInvite + ":email/:projectdata"} component={InviteeRegister} />
                <Route path={pageUrls.login} component={Login} />
                <Route path={pageUrls.register} component={RegisterWrapper} />*/}
                <Route path="/logout" render={(props) => <LogoutCore home={config.webUrl} /> } />
                <Route path="/recover" component={Recover} />
                <Route path="/my-sites" render={(props) => <MySites history={props.history} />} />
                {/*<Route path="/auth" component={Auth} />*/}
                <Route path={pageUrls.mySites} component={DumpProject} />
                <Route path={pageUrls.agreementsList} component={AgreementsList} />
                <Route path={pageUrls.newDisposalAgreement} render={(props) => <NewProject recipient={false} history={props.history} match={props.match} /> } />
                <Route path={pageUrls.completeAgreement+"/:projectdata"} render={(props) => <NewProject recipient={true} history={props.history} match={props.match}/>} />
                <Route path="/my-partners" component={MyPartners} />
                <Route path="/project-dash" component={ProjectDash} />
                {/* <Route path="/invitee-register/:email" component={InviteeRegister} /> */}
                <Route path="/user-settings" component={UserSettigns} />
                <Route path="/stripe" component={Stripe} />
                {/*<Route path="/cruds/transporters" component={Transporters} />
                <Route path="/cruds/sources" component={Sites} />
                <Route path="/cruds/dumps" component={Sites} />*/}
                {/*{!JSON.parse(localStorage.getItem('site')) ? <Redirect from="/manifest" to="/dump-project" /> : <Route path="/manifest" render={(props) => <Manifest history={props.history}/> } /> }*/}

                {!localStorage.getItem('site') ? 
                    <Redirect from={pageUrls.activeTickets} to="/my-sites" /> : 
                    <Route path={pageUrls.activeTickets} render={(props) => <ActiveTickets history={props.history}/> } />
                }

                {/*!JSON.parse(localStorage.getItem('site')) ? 
                    <Redirect from={pageUrls.closedTickets} to="/my-sites" /> : 
                    <Route path={pageUrls.closedTickets} render={(props) => <HauledToday history={props.history}/> } />
                */}

                {/*!JSON.parse(localStorage.getItem('site')) ? 
                    <Redirect from={pageUrls.archivedTickets} to="/my-sites" /> : 
                    <Route path={pageUrls.archivedTickets} render={(props) => <Archive history={props.history} location={props.location}/> } />
                */}

                <Redirect from="/" to="/my-sites" />
                <Redirect from="/login" to="/my-sites" />
            </Switch>
        </BrowserRouter>
    </Provider>
)

const LoggedOut = (
    <Provider store={store}>
        <BrowserRouter>
            <Switch>
                <Route path={pageUrls.login} component={Login} />
                <Route path={pageUrls.register} render={(props) => <RegisterCore home={config.webUrl} logo="/img/logo/ad-logo.png" /> } />
                <Route path="/account-confirmation" render={(props) => <VerifyRegisterCore home={config.webUrl} logo="/img/logo/ad-logo.png" /> } />
                {/*<Route path={pageUrls.loginFromInvite + ":projectdata"} component={Login} />
                <Route path={pageUrls.registerFromInvite + ":email/:projectdata"} component={InviteeRegister} />*/}
                <Route path={pageUrls.recoverPassword} component={Recover} />
                <Route path="/stripe" component={Stripe} />
                <Route path={pageUrls.inviteeRegister + "/:email"} component={InviteeRegister} />
                {/*<Route path="/auth*" component={Auth} />
                <Redirect to="/auth" />*/}
                <Redirect to={pageUrls.login} />
            </Switch>}
        </BrowserRouter>
    </Provider>
)

Amplify.configure(awsconfig);

Auth.currentAuthenticatedUser().then(async user => {
    console.log("user:", user);
    //alert("User aunthenticated")
    const userJWT = user.signInUserSession.idToken.jwtToken;
    

    if(userJWT!==null){
      const userDecoded = jwtDecode(userJWT);
      let storedUser = JSON.parse(localStorage.getItem('user'));

      if (storedUser == null || (storedUser.username !== userDecoded['cognito:username'])){
        await userService.auth(userDecoded);
      }

      localStorage.setItem('token', userJWT);
    }

    ReactDOM.render(LoggedIn, document.getElementById('root'));

}).catch(err => {
    console.log("err:", err);
    if (err.toString().indexOf("authenti") !== -1 || err.toString().indexOf("log") !== -1 || err.toString().indexOf("signed") !== -1){ //for authentication errors, logged out errors, logged in errors
        ReactDOM.render(LoggedOut, document.getElementById('root'));
    }
});


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
