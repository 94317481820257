import React, { Component } from 'react';
import eiLogo from '../../assets/img/logo/ei-logo.png'
import { stripeService } from '../../services';
import { getStripeToken, getStripeId } from "../../services/userService";
import '../../assets/css/page.min.css';
import '../../assets/css/style.min.css';

class Stripe extends Component {
    constructor () {
        super();

        let user = localStorage.getItem('user');
        let userId = user ? JSON.parse(user).id : null;

        this.state = {
            charge:     {},
            chargeInfo: {
                amount:         0,
                currency:       'usd',
                payment_method: '',
                customer:       '',
                receipt_email:  '',
                confirm:        true,
            },
            submitted:  false,
        };

        getStripeToken(userId).then(token => {
            getStripeId(userId).then(res => {
                this.setState({
                    chargeInfo: {
                        customer: res.user[0].stripe_cus_id,
                        payment_method: token.user[0].stripe_token,
                        amount:         0,
                        currency:       'usd',
                        receipt_email:  '',
                        confirm:        true,
                    }
                })
            })
        });

        this.handleCharge = this.handleCharge.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        const { name, value } = event.target;
        const { chargeInfo } = this.state;
        this.setState({
            submitted:  false,
            chargeInfo: {
                ...chargeInfo,
                [name]: value
            }
        });
    }

    handleCharge(event) {
        event.preventDefault();
        const { chargeInfo } = this.state;
        stripeService.charge(chargeInfo);
        console.log(this.state);
    }

    render() {
        const { submitted, chargeInfo } = this.state;
        return (
            <div className="layout-centered bg-img bg-4 register-component vcenter">
                <main className="main-content">
                    <div className="bg-white rounded shadow-7 w-400 mw-100 p-6">
                        <img src={eiLogo} width="45%" className="mb-6" alt=""></img>
                        <h5 className="mb-4">Make a charge</h5>
                        
                        <form name="form" onSubmit={this.handleCharge}>
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                name="amount"
                                value={chargeInfo.amount}
                                onChange={this.handleChange}
                                placeholder="Amount" />
                            {submitted && !chargeInfo.amount &&
                                <div className="help-block">Amount is required</div>
                            }
                        </div>
                
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                name="receipt_email"
                                value={chargeInfo.receipt_email}
                                onChange={this.handleChange}
                                placeholder="Email" />
                            {submitted && !chargeInfo.receipt_email &&
                                <div className="help-block">Email is required</div>
                            }
                        </div>

                        <div className="form-group">
                            <button className="btn btn-block btn-primary">Charge</button>
                        </div>
                        </form>

                    </div>
                </main>
            </div>
        );
    }
}

export default Stripe;
