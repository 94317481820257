import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import config from '../../../constants/config';
import LetterAvatar from '../../LetterAvatar';
import { convertDateToShortString } from '../../../helpers/dateHelpers';
import TruckProfile from '../TruckProfile';
import ajax from 'superagent';
import SuccessPopup from '../../Popups'
import Contact from '../Contact';

import { getClosedTickets, updateTicketArrivalPicture } from '../../../actions/tickets';

class ModalClosedTickets extends Component {
    constructor(props){
        super(props);

        let userId = JSON.parse(localStorage.getItem('user')).id;
        let idsite = JSON.parse(localStorage.getItem('site')).idsite;

        this.state = {
            idticket: null,
            dumpSite: "",
            ticketNumber: "",
            sourceSite: "",
            generator: "",
            transporter: "",
            customer: "",
            idtruck: "",
            truckphoto: "",
            soilProfile: "",
            loadedDate: "",
            dumpDate: "",
            picture_load: "",
            picture_dump: "",
            loadCoords: null,
            dumpCoords: null,
            sourcesignature: "",
            sourcetimestamp: "",
            dumpsignature: "",
            dumptimestamp: "",
            idcreator: null,
            idcloser: null,

            invalid: false,
            recovered: false,

            invalidtimestamp: "",
            invalidCoords: null,
            invalidmarkername: null,
            invalidreason: "",
            idinvalidmarker: null,
            recovertimestamp: "",
            recoverCoords: null,
            recovermarkername: null,
            recoverreason: null,
            idrecovermarker: null,

            recoverPopup: false,

            showRecoverInput: false,
            showInvalid: false,

            userId,
            idsite

        }
    }

    componentWillMount = () => {
        ajax.get(config.apiUrl + '/sites/permissions/' + this.state.userId + '/' + this.state.idsite)
            .end((error, response) => {
                if (!error && response) {
                    this.setState({canEditTickets: response.body.permissions.canEditTickets})
                } else {
                    console.log('There was an error fetching', error);
                }
            }
        );
    }
    
    componentWillReceiveProps(props){
        function convertDate(date){
            if(typeof date == "string"){
                let parameters = date.split(' ');
                parameters[0] = parameters[0].split('-');
                parameters[1] = parameters[1].split(':');
                parameters = parameters[0].concat(parameters[1]);
                parameters[1] -= 1;
                date = new Date(...parameters)
            }else{
                date = new Date(date);
            }
            return "" + ((date.getHours() % 12) || "12") + ":" + ('0' + date.getMinutes()).substr(-2) + ((date.getHours() >= 12) ? "PM" : "AM") + " - " + date.toISOString().substr(5,5).replace('-', '/') + "/" + date.getFullYear()
        }
        if(props.ticket && props.ticket.idticket !== this.state.idticket ){
            this.setState({
                ticketNumber: props.ticket.internalnumber,
                tonnage: props.ticket.dumped,
                idtruck: props.ticket.truck,
                truckphoto: props.ticket.truckphoto,
                soilProfile: props.ticket.profile,
                loadedDate: convertDate(props.ticket.datecreated),
                dumpDate: convertDate(props.ticket.datedisposed),
                picture_load: props.ticket.picture_load,
                picture_dump: props.ticket.picture_dump,
                transporter: props.ticket.transporter,
                creatorName: props.ticket && props.ticket.creatorname,
                idticket: props.ticket && props.ticket.idticket,

                //creatorName: props.ticket.creatorName,
                closerName: props.ticket.closername,
                dumpSite: props.ticket.dump_site,
                sourceSite: props.ticket.source_site,
                generator: props.ticket.generatorname,
                customer: props.ticket.customername,
                sourcesignature: props.ticket.sourcesignature,
                sourcetimestamp: props.ticket.sourcetimestamp,
                dumpsignature: props.ticket.dumpsignature,
                dumptimestamp: props.ticket.dumptimestamp,
                idcreator: props.ticket.idcreator,
                idcloser: props.ticket.idcloser,

                invalid: (props.ticket.invalidreason != null),
                recovered: (props.ticket.recoverreason != null),

                idinvalidmarker: props.ticket && props.ticket.idinvalidmarker,
                invalidtimestamp: props.ticket && props.ticket.invalidtimestamp,
                invalidmarkername: props.ticket && props.ticket.invalidmarkername,
                invalidreason: props.ticket && props.ticket.invalidreason,
                idrecovermarker: props.ticket && props.ticket.idrecovermarker,
                recovertimestamp: props.ticket && props.ticket.recovertimestamp,
                recovermarkername: props.ticket && props.ticket.recovermarkername,
                recoverreason: props.ticket && props.ticket.recoverreason,

                showRecoverInput: false

            });
            if(props.ticket.geo_load){
                let coords = props.ticket.geo_load.replace('POINT(', '').replace(')', '').split(' ');
                this.setState({
                    loadCoords: {lat: coords[1] && Number(coords[1]).toFixed(7), long: coords[0] && Number(coords[0]).toFixed(7)}
                })
            }
            if(props.ticket.geo_dump){
                let coords = props.ticket.geo_dump.replace('POINT(', '').replace(')', '').split(' ');
                this.setState({
                    dumpCoords: {lat: coords[1] && Number(coords[1]).toFixed(7), long: coords[0] && Number(coords[0]).toFixed(7)}
                })
            }
            if(props.ticket.geo_invalid){
                let coords = props.ticket.geo_invalid.replace('POINT(', '').replace(')', '').split(' ');
                this.setState({
                    invalidCoords: {lat: coords[1] && Number(coords[1]).toFixed(7), long: coords[0] && Number(coords[0]).toFixed(7)}
                })
            }
            if(props.ticket.geo_recover){
                let coords = props.ticket.geo_recover.replace('POINT(', '').replace(')', '').split(' ');
                this.setState({
                    recoverCoords: {lat: coords[1] && Number(coords[1]).toFixed(7), long: coords[0] && Number(coords[0]).toFixed(7)}
                })
            }
        }
    }

    sendRecoverData = () => {
        this.getGeoLocation("recoverCoords", (error, coords) => {
            let geo_recover = null;
            if(!error){
                geo_recover = `POINT(${coords.longitude} ${coords.latitude})`;
            }

            let user = localStorage.getItem('user');
            let userId = user ? JSON.parse(user).id : null;

            const data = {
               idrecovermarker: userId,
               geo_recover,
               recoverreason: this.state.recoverreason,
               idticket: this.props.ticket.idticket
            }

            ajax.post(`${config.apiUrl}/tickets/trackinvalid/recover`)
            .send(data)
            .then(res => {
                this.setState({recoverPopup: true});
                console.log("ticket successfully marked as recovered");
                console.log("Res:");
                console.log(res.body);
                this.setState({
                    recovermarkername: res.body.data[0].recovermarkername,
                    recovertimestamp: res.body.data[0].recovertimestamp,
                    recoverCoords: res.body.data[0].geo_recover,
                    recoverreason: res.body.data[0].recoverreason,
                    recovered: true,
                    showRecoverInput: false,
                });

                this.props.setTicketAsRecovered();
            }).catch(res =>{
                alert('Error creating project!');
            });    
            
        });
    }

    sendInvalidData = () => {
        const today = new Date();
        function get24hours(date){
            date = new Date(date);
            let hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
            let minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
            let seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
            return "" + hours + ":" + minutes + ":" + seconds
        }

        this.getGeoLocation("dumpSite", (error, coords) => {
            let geo_invalid = null;
            if(!error){
                geo_invalid = `POINT(${coords.longitude} ${coords.latitude})`;
            }

           const data = {
                geo_invalid,
                id: this.props.ticket.idticket,
                timestamp: today.toISOString().split('T')[0]+ ' ' + get24hours(today),
                idcloser: JSON.parse(localStorage.getItem('user')).id,
                invalidreason: this.state.invalidreason,
                closedTicket: true,
            }

            ajax.post(`${config.apiUrl}/tickets/trackarriving/geo`)
            .send(data)
            .then(res => {

                const offlineData = {
                    tons: this.state.tonnage,
                    workingOffline: this.state.workingOffline,
                    offlineConfirmation: this.state.offlineConfirmation,
                    offlineTimestamp: new Date(this.state.offlineTimestamp),
                    offlineSignature: this.state.offlineSignature,
                }
                this.setState({invalid: true});
                this.setState({
                    invalidmarkername: res.body.data[0].invalidmarkername,
                    invalidtimestamp: res.body.data[0].invalidtimestamp,
                    invalidCoords: res.body.data[0].geo_invalid,
                    invalidreason: res.body.data[0].invalidreason,
                    invalid: true,
                    showInvalidInput: false,
                });

                this.props.setTicketAsInvalid();
            }).catch(e =>{
                alert('Error creating project!');
                throw e;
            });    
            
        });
    }

    getGeoLocation = (event, cb) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                cb(null, position.coords)
            }, error => {
                cb(error);
            });
        } else {
            let currentPosition = {
                status: 'error',
                position: null,
            }
            this.setState({[event]: currentPosition.position});
        }
    }

    render(){


        function convertTimestamp(timestamp){
            let date = new Date(timestamp);
            return ('0' + (date.getMonth() + 1)).substr(-2)
                + '-' + ('0' + date.getDate()).substr(-2)
                + '-' + date.getFullYear().toString().substr(2,2)
                + " " + ((date.getHours() % 12) || "12") + ":" + ('0' + date.getMinutes()).substr(-2) + ((date.getHours() >= 12) ? "PM" : "AM")
        }
        function dateFromTimestamp(timestamp){
            let date = new Date(timestamp);
             return ((''+(date.getMonth() + 1)).substr(-2)
                + '/' + (''+ date.getDate()).substr(-2)
                + '/' + date.getFullYear().toString().substr(2,2));
        }
        let daysOfWeek=["SUN","MON","TUES","WED","THU","FRI","SAT"];
        function dayAndTimeFromTimestamp(timestamp){
            let date = new Date(timestamp);
             return (daysOfWeek[date.getDay()]
                + " " + ((date.getHours() % 12) || "12") + ":" + ('0' + date.getMinutes()).substr(-2) + ((date.getHours() >= 12) ? "PM" : "AM"));
        }

        let creatorName = this.state.creatorName;
        if (this.state.sourcesignature == "Automated") creatorName = "Automated";

        return(<React.Fragment>
            <div className="modal" id="modal-closed" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-long" role="document">
                    <div className="modal-content">

                        {!this.state.invalid && !this.state.recovered ? 
                            <div className="alert alert-primary" role="alert">
                                <strong>Closed Ticket</strong>
                                <button type="button" id="close-closed-ticket-modal" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        : null }

                        {this.state.invalid && !this.state.recovered ?
                            <div class="alert alert-dark" role="alert">
                                <strong>Invalid Ticket</strong>
                                <button type="button" id="close-closed-ticket-modal" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        : null }

                        {this.state.recovered ? 
                            <div class="alert alert-info" role="alert">
                                <strong>Recovered Ticket</strong>
                                <button type="button" id="close-closed-ticket-modal" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        : null}

                        <div className="modal-body pt-0">
                            <div className="row m-0 mb-5">
                                <div className="col-12 p-0 mt-4 mb-1 border-bottom">
                                    <label className="badge badge-pale badge-info fw-600 fs1m p-1 mb-2 float-right text-right">#{this.state.ticketNumber}</label>
                                    <label className="col-9 m-0 p-0 lead-2 fw-600 lh-1">{this.state.soilProfile}</label>

                                </div>
                                <div className="col-12 p-0 px-0 flexbox">
                                    <label className="lead-1 fw-400 mb-0 text-left">{this.state.sourceSite}</label>
                                    <label className="lead-1 fw-100 mb-0 text-center"><i className="fa fa-long-arrow-right" aria-hidden="true"></i></label>
                                    <label className="lead-1 fw-400 mb-0 text-right">{this.state.dumpSite}</label>
                                </div>
                            </div>

                            <div className="row p-1 mt-4 mx-0 bg-pale-primary">
                                <div className="col-12 p-2">
                                      <label className="col-6 m-0 p-0 fw-700 float-left">TONNAGE</label>
                                      <label className="m-0 p-0 fw-700 float-right"><i className="ti ti-check text-primary mr-1" aria-hidden="true"></i>{this.state.tonnage}</label>
                                  </div>
                            </div>   

                            <TruckProfile idtruck={this.state.idtruck} photo={this.state.truckphoto} />

                            <div className="row p-1 mt-4 mx-0 bg-pale-success">
                                <div className="col-12 p-2">

                                <label className="col-6 m-0 p-0 fw-700 float-left"><i className="ti ti-check text-success mr-1" aria-hidden="true"></i>TRUCK LOADED</label>
                                <label className="col-5 m-0 p-0 fw-600 float-right text-right">
                                    <img className="round m-1 mb-1 mr-1" width="20" height="20" src={LetterAvatar(creatorName || "Automated")} />
                                    {creatorName != "Automated" && this.state.sourcesignature != "Automated" ? <Contact iduser={this.state.idcreator} idticket={this.state.idticket} mode={"creator"}> 
                                        {creatorName}
                                    </Contact> : <span>Automated</span> }
                                    {creatorName != "Automated" && this.state.sourcesignature != "Automated" ? <a className="col-4 m-0 p-0" href={this.state.loadCoords && `https://www.google.com/maps/dir/${this.state.loadCoords.lat},${this.state.loadCoords.long}//@${this.state.loadCoords.lat},${this.state.loadCoords.long},15z`} target="window">
                                        <label className="m-0 mr-1">&nbsp;<i className="fa fa-location-arrow" aria-hidden="true"></i></label>
                                    </a> : null }
                                </label>
                                <label className="m-0 ml-4 p-0 fw-400 float-left">{dayAndTimeFromTimestamp(this.state.sourcetimestamp)}</label>
                                <label className="col-5 m-0 mr-2 p-0 fw-400 float-right text-right">{dateFromTimestamp(this.state.sourcetimestamp)}</label>
                                </div>
                            </div> 

                            { !this.state.invalid && !this.state.recovered ?    
                            <div className="row p-1 mt-4 mx-0 bg-pale-success">
                                <div className="col-12 p-2">

                                    <label className="col-6 m-0 p-0 fw-700 float-left"><i className="ti ti-check text-success mr-1" aria-hidden="true"></i>TRUCK DUMPED</label>
                                    <label className="col-5 m-0 p-0 fw-600 float-right text-right">

                                        {this.state.dumpsignature != 'Automated' && !this.state.closerName ? <Fragment>
                                        <img className="round m-1 mb-1 mr-1" width="20" height="20" src={LetterAvatar(this.state.creatorName)} />
                                         <Contact iduser={this.state.idcreator} idticket={this.state.idticket} mode={"creator"}>
                                            {this.state.creatorName}
                                        </Contact> </Fragment>: (!this.state.dumpsignature && this.state.closerName) ? <Fragment>

                                        <img className="round m-1 mb-1 mr-1" width="20" height="20" src={LetterAvatar(this.state.closerName)} />
                                        <Contact iduser={this.state.idcloser} idticket={this.state.idticket} mode={"closer"}>
                                            {this.state.closerName}
                                        </Contact></Fragment> 
                                        :<Fragment>
                                         <img className="round m-1 mb-1 mr-1" width="20" height="20" src={LetterAvatar("Automated")} />
                                          <span>Automated</span> 
                                          </Fragment>}
                                        {this.state.closerName || this.state.dumpsignature != "Automated" ? <a className="col-4 m-0 p-0" href={this.state.dumpCoords && `https://www.google.com/maps/dir/${this.state.dumpCoords.lat},${this.state.dumpCoords.long}//@${this.state.dumpCoords.lat},${this.state.dumpCoords.long},15z`} target="window">
                                            <label className="m-0 mr-1">&nbsp;<i className="fa fa-location-arrow" aria-hidden="true"></i></label>
                                        </a> : null }
                                    </label>
                                    <label className="m-0 ml-4 p-0 fw-400 float-left">{dayAndTimeFromTimestamp(this.state.dumptimestamp)}</label>
                                    <label className="col-5 m-0 mr-2 p-0 fw-400 float-right text-right">{dateFromTimestamp(this.state.dumptimestamp)}</label>
                                </div>

                                {this.state.canEditTickets ? <React.Fragment>
                                    <div className="custom-control custom-checkbox pb-3" onClick={() => this.setState({showInvalid: !this.state.showInvalid, invalidreason: null})}>
                                        <input type="checkbox" className="custom-control-input mt-2 mb-2"
                                        checked={this.state.showInvalid} />
                                        <label className="custom-control-label mb-0"><em>This is an invalid ticket.</em></label>
                                    </div>

                                    <div className="accordion" id="invalid-ticket">
                                        <div className="card noborder">
                                            <div id="open-invalid" className={"collapse bg-gray" + (this.state.showInvalid ? " show" : "") } data-parent="#invalid-ticket">
                                                <div className="card-body border-primary p-2 mb-2">
                                                    <label className="col-6 m-0 p-0 fw-700 float-left">
                                                        <i className="ti ti-close text-danger mr-1" aria-hidden="true"></i>
                                                        INVALID TICKET
                                                    </label>
                                                    <div className="contrast">
                                                        <textarea className="form-control"
                                                        id="invalid-reason-textarea"
                                                        rows="4" 
                                                        onChange={(e) => this.setState({[e.target.name]: e.target.value})}
                                                        value={this.state.invalidreason}
                                                        name="invalidreason"
                                                        placeholder="Why is this ticket invalid?">
                                                        </textarea>
                                                        <button class="btn btn-xs btn-outline-dark text-dark float-left px-2 py-0 mt-3 ml-4 mb-2" disabled={this.state.invalidreason === null} onClick={this.sendInvalidData}>Mark as Invalid</button> 
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment> : null }
                            </div> : null }

                            {this.state.invalid ? <React.Fragment>
                                <div class="row p-1 mt-4 mx-0 bg-light">
                                    <div class="col-12 p-2">
                                        <label class="col-6 m-0 p-0 fw-700 float-left">
                                            <i class="ti ti-close text-danger mr-1" aria-hidden="true"></i>INVALID TICKET
                                        </label>
                                        <label class="col-5 m-0 p-0 fw-600 float-right text-right">
                                            <img class="round m-1 mb-1 mr-1" width="20" height="20" src={LetterAvatar(this.state.invalidmarkername)} />
                                            <Contact iduser={this.state.idinvalidmarker} idticket={this.state.idticket} mode={"closer"}>
                                                {this.state.invalidmarkername}
                                            </Contact>
                                            <a class="col-4 m-0 p-0" href={this.state.invalidCoords && `https://www.google.com/maps/dir/${this.state.invalidCoords.lat},${this.state.invalidCoords.long}//@${this.state.invalidCoords.lat},${this.state.invalidCoords.long},15z`} target="window">
                                                <label class="m-0 mr-1">&nbsp;<i class="fa fa-location-arrow" aria-hidden="true"></i></label>
                                            </a>
                                        </label>
                                        <label class="m-0 ml-4 p-0 fw-400 float-left">{dayAndTimeFromTimestamp(this.state.invalidtimestamp)}</label>
                                        <label class="col-5 m-0 mr-2 p-0 fw-400 float-right text-right">{dateFromTimestamp(this.state.invalidtimestamp)}</label>
                                        <p class="float-left clearfix mx-4 mt-2 lh-2">
                                            <i class="fa fa-quote-left" aria-hidden="true"></i>
                                            <em>{this.state.invalidreason}</em>
                                            <i class="fa fa-quote-right float-right" aria-hidden="true"></i>
                                        </p>
                                        {! this.state.recovered ? 
                                        <a class="btn btn-xs btn-outline-info float-left px-2 py-0 ml-4 mb-2"
                                            onClick={() => this.setState({showRecoverInput: !this.state.showRecoverInput})}>Recover Ticket</a>
                                        : null }
                                    </div>
                                </div> 

                                {! this.state.recovered && this.state.canEditTickets ? 
                                    <div class="accordion mt-3" id="recover-invalid">
                                        <div class="card noborder">
                                            <div id="open-recover" className={"collapse bg-gray" + (this.state.showRecoverInput ? " show" : "" )}>
                                                <div class="card-body border-primary p-2 mb-2">
                                                    <label class="col-6 m-0 p-0 fw-700 float-left">
                                                        <i class="ti ti-pulse text-info mr-1" aria-hidden="true"></i>RECOVER TICKET
                                                    </label>                                                            
                                                    <div class="contrast">
                                                        <textarea class="form-control" 
                                                            rows="4" 
                                                            id="recover-reason-textarea"
                                                            onChange={(e) => this.setState({recoverreason: e.target.value})}
                                                            value={this.state.recoverreason}
                                                            name="recoverreason"
                                                            placeholder="Why is this ticket being recovered?">
                                                        </textarea>
                                                    </div>
                                                    <a class="btn btn-xs btn-outline-info text-info float-left px-2 py-0 mt-3 ml-4 mb-2" onClick={this.sendRecoverData}>Save & Recover</a> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                : null }
                            </React.Fragment> : null}

                            {this.state.recovered ? 
                                <div class="row p-1 mt-4 mx-0 bg-pale-info">
                                    <div class="col-12 p-2">
                                    <label class="col-6 m-0 p-0 fw-700 float-left">
                                        <i class="ti ti-pulse text-info mr-1" aria-hidden="true"></i>RECOVERED TICKET
                                    </label>
                                    <label class="col-5 m-0 p-0 fw-600 float-right text-right">
                                        <img class="round m-1 mb-1 mr-1" width="20" height="20" src={LetterAvatar(this.state.recovermarkername)} />
                                        <Contact iduser={this.state.idrecovermarker} idticket={this.state.idticket} mode={"closer"}>
                                            {this.state.recovermarkername}
                                        </Contact>
                                        <a class="col-4 m-0 p-0" href={this.state.recoverCoords && `https://www.google.com/maps/dir/${this.state.recoverCoords.lat},${this.state.recoverCoords.long}//@${this.state.recoverCoords.lat},${this.state.recoverCoords.long},15z`} target="window">
                                            <label class="m-0 mr-1">&nbsp;<i class="fa fa-location-arrow" aria-hidden="true"></i></label>
                                        </a>
                                    </label>
                                    <label class="m-0 ml-4 p-0 fw-400 float-left">{dayAndTimeFromTimestamp(this.state.recovertimestamp)}</label>
                                    <label class="col-5 m-0 mr-2 p-0 fw-400 float-right text-right">{dateFromTimestamp(this.state.recovertimestamp)}</label>
                                    <p class="float-left clearfix mx-4 mt-2 lh-2">
                                        <i class="fa fa-quote-left" aria-hidden="true"></i>
                                        <em>{this.state.recoverreason}</em>
                                        <i class="fa fa-quote-right float-right" aria-hidden="true"></i>
                                    </p>
                                    </div>
                                </div>           
                            : null }

                        </div>

                        <button type="button" className="btn btn-lg btn-primary" data-toggle="modal" data-dismiss="modal" >DONE</button>
                    </div>
                </div>
            </div>

            <SuccessPopup
                popupId="recoverPopupInvalidTicket"
                title="Recovered"
                message="Ticket successfully recovered!"
                trigger={this.state.recoverPopup}
                style="info"
                callback={() => {
                    this.setState({recoverPopup: false}); 
                }}
                />
        </React.Fragment>);

    }
  
}


function mapStateToProps(state) {
    return {
      tickets: state.tickets,
    };
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      getTickets: (id) => dispatch(getClosedTickets(id)),
      updateTicketArrivalPicture: (idticket, picture, signature, tons, timestamp, datedisposed) => dispatch(updateTicketArrivalPicture(idticket, picture, signature, tons, timestamp, datedisposed)),
    };
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(ModalClosedTickets)