import React, {Component, Fragment} from 'react';
import StripeForm from '../../StripeForm'
import {Elements, StripeProvider} from 'react-stripe-elements';
import config from '../../../../constants/config';
import ajax, {Request} from 'superagent';
import {apiService} from '../../../../services'

export default class CCForm extends Component {

  constructor(props){
      super(props);

      let user = JSON.parse(localStorage.getItem('user'));

      let optingIn = false;
      if (props.recipient || !props.showSwitch) optingIn = true;

      this.state = {
        paymentMethod: null,
        optingIn,
        optInLock: false,
        userIsLeader: false,
        leaderHasPaymentInfo: false,
        loading: true,
        iduser: user.id,
        price: ""
      };
    }

    async componentWillMount(){
      if (this.props.idsite) this.getSiteLeader(this.props.idsite);
      let price = await apiService.getADPrice();
      console.log("AD Price:", price);
      this.setState({price});
    }

    componentWillReceiveProps(newProps){

      if (this.props.newSite == false && newProps.newSite == true){
        this.getCurrentUser(this.state.iduser)
      }

      else if (this.props.idsite !== newProps.idsite){
        this.getSiteLeader(newProps.idsite);
      }
    }

    getSiteLeader(idsite){
      //console.log("Calling getSiteLeader with idsite " + idsite);
      ajax.get(config.apiUrl + '/sites/leader/' + idsite)
        .then((res) => {
          let leader = res.body.leader[0];
          this.setState({
            userIsLeader: leader.iduser == this.state.iduser,
            leaderHasPaymentInfo: !!(leader.stripe_token && leader.stripe_cus_id),
            loading: false,
          })
          this.props.returnValidPaymentInfo({optingInToPayment: this.state.optingIn, validPaymentInfo: !!(leader.stripe_token && leader.stripe_cus_id)});
          //let leader = res.body.leader[0]
          //console.log("getSiteLeader Site Leader: ", leader);
          //console.log("getSiteLeader Am I the site leader?", leader.iduser == this.state.iduser);
        })
    }


    

    getCurrentUser(iduser){
      this.setState({loading: true});
      //console.log("Calling getSiteLeader with idsite " + idsite);
      ajax.get(config.apiUrl + '/users/id/' + iduser)
        .then((res) => {
          let leader = res.body.user[0];
          this.setState({
            userIsLeader: true,
            leaderHasPaymentInfo: !!(leader.stripe_token && leader.stripe_cus_id),
            loading: false,
          })
          this.props.returnValidPaymentInfo({optingInToPayment: this.state.optingIn, validPaymentInfo: !!(leader.stripe_token && leader.stripe_cus_id)});
          //let leader = res.body.leader[0]
          //console.log("getSiteLeader Site Leader: ", leader);
          //console.log("getSiteLeader Am I the site leader?", leader.iduser == this.state.iduser);
        })
    }


    getStripePaymentMethod = (paymentMethod) => {
      this.setState({paymentMethod});
      this.props.returnValidPaymentInfo({optingInToPayment: this.state.optingIn, validPaymentInfo: (paymentMethod || this.state.leaderHasPaymentInfo)});
    }

    handleOptInToggle = (optingIn) => {
      if (!this.state.paymentMethod && !this.state.leaderHasPaymentInfo) return;
      else {
        console.log("Calling handleOptInToggle with val " + optingIn);
        this.setState({optingIn}, () =>
        this.props.returnValidPaymentInfo({optingInToPayment: optingIn, validPaymentInfo: (this.state.paymentMethod || this.state.leaderHasPaymentInfo)})
        );
      }
    }

    renderLoading = () => {
      return(
        (this.props.recipient ?
          <p>Payment method info will appear here when you select the site you want to use to connect.</p>
        : 
          <p>Getting payment data...</p>)
      )
    }

    renderLeaderInfo = () => {
      return(
        (this.state.leaderHasPaymentInfo ? 
          <p>Your Site Leader has payment info on file</p>
        :
          <p>Oops! Your Site Leader does not have payment info on file.</p>
        )
      )
    }

    renderStripeForm = () => {
      return(
        <StripeProvider apiKey={config.stripe_key}>
          <Elements>
            <StripeForm getStripePaymentMethod={this.getStripePaymentMethod} />
          </Elements>
        </StripeProvider>
      )
    }

    render(){
      let {price} = this.state;
    	return (<Fragment>

        {this.props.showHeading !== false ?
          (this.props.showOptIn ?
            (this.props.recipient ? <Fragment>
              <p className="lead mb-2 border-bottom">Get our Premium Features</p>
              {/*<p className="small mt-2 mb-4">As an invited partner, you will have access to basic features for free.<br/>If you would like to view the ticket archive and generate tonnage reports for this agreement, opt in for these premium features and you will be charged {price} per valid ticket that is closed on this agreement.</p>*/}

              {this.props.showSwitch ? <div className="switch" onClick={() => this.handleOptInToggle(!this.state.optingIn)}>
                <input type="checkbox" 
                  name="optingIn" 
                  id="optingIn" 
                  className="switch-input" 
                  checked={this.state.optingIn}
                  disabled={!this.state.userIsLeader && !this.state.leaderHasPaymentInfo}
                />
                <label className="switch-label">Opt-In to Premium Features</label>
              </div> : null }
            </Fragment>:<Fragment> 
              <p className="lead mb-2 border-bottom">Enter your credit card to get started.</p>
              <p className="small mt-2 mb-0">You will be charged {price} per valid ticket that is closed on this agreement.<br />You will receive our premium features which allow you to view archived tickets and generate tonnage reports for this agreement.</p> 
            </Fragment>)
          : <Fragment> 
              <p className="lead mb-2 border-bottom">Enter your credit card to opt into premium features.</p>
              {/*<p className="small mt-2 mb-0">Premium features include access to archived tickets for the agreement, with the ability to generate tonnage reports.<br/>If you initiate an agreement, you will automatically receive premium features.</p>*/} 
            </Fragment> )
          : null}

          <div className="row mt-4 mb-0 ml-0">
            <div className="col-lg-12 mr-auto alert-secondary px-1">
              {this.state.loading ?
                this.renderLoading() :
                !this.state.userIsLeader ?
                  this.renderLeaderInfo()
                  : this.renderStripeForm() 
              }
            </div>
          </div>

    	</Fragment>)
    }

}
