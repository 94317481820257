import React, {Component} from 'react';
import ajax, {Request} from 'superagent';
import { connect } from 'react-redux';
import config from '../../../constants/config';
import ModalClosedTickets from '../ModalClosedTickets';
import TempTruckID from '../TempTruckID';
import AgreementFilter from '../AgreementFilter'

import {
    getActiveTickets,
    createTicket,
    updateTicketSourceGeo,
    updateTicketSourcePicture,
    updateTicketArrivalGeo,
    updateTicketArrivalPicture,
    getNextTicketNumber,
    getHauledTodayTickets
} from '../../../actions/tickets';
/*import {
   getHauledTodayTickets
} from '../../../actions/tickets';*/

class HauledToday extends Component {
    constructor(props){
        super(props)
        console.log("HauledToday site:", props.site)
        let user = localStorage.getItem('user');
        let idgenerator = JSON.parse(localStorage.getItem('user')).id;
        let idsite = props.site.idsite;
        let userId = user ? JSON.parse(user).id : null;
        let site = props.site;

        let filterList = [];
        if (props.defaultFilter) filterList.push(props.defaultFilter)

        this.state = {
            ticketList: [],
            ticketSelected: null,
            idsite,
            site,
            idgenerator,
            tonsToday: 0,
            loadsToday: 0,
            needsToggle: false,
            filterList,
            filteredTicketList: [],
            userId,
            canViewArchiveTab: false,

        }

        this.renderTickets = this.renderTickets.bind(this);
    }

    componentWillReceiveProps(newProps){

        if (newProps.defaultFilter){
            this.updateFilter([newProps.defaultFilter]);
        }

        if ((JSON.stringify(newProps.site) != JSON.stringify(this.state.site)) && newProps.site.idsite != 0){
            clearInterval(this.intervalId);
            this.setState({site: newProps.site, sitename: newProps.site.name, idsite: newProps.site.idsite}, () => {
                this.intervalId = setInterval(() => { 
                    this.props.getHauledTodayTickets(newProps.site.idsite, this.state.idgenerator);
                }, 60000);
            });
        }

        if(newProps.tickets.updateTicketArrivalGeo.inProgress !== this.props.tickets.updateTicketArrivalGeo.inProgress){
            if(newProps.tickets.updateTicketArrivalGeo.success){
                this.props.getHauledTodayTickets(this.state.idsite, this.state.idgenerator);
            }
        }
        if(newProps.tickets.updateTicketArrivalPicture.inProgress !== this.props.tickets.updateTicketArrivalPicture.inProgress){
            if(newProps.tickets.updateTicketArrivalPicture.success){
                this.props.getHauledTodayTickets(this.state.idsite, this.state.idgenerator);
            }
        }
        if(newProps.tickets.hauledTodayTickets.inProgress !== this.props.tickets.hauledTodayTickets.inProgress){
            if(newProps.tickets.hauledTodayTickets.success){
                console.log('ticket list will change here', newProps.tickets.hauledTodayTickets)
                let temp = newProps.tickets.hauledTodayTickets.data.slice();
                for(let i = 0; i < temp.length; ++i){
                    for(let j = temp.length - 1; j > i; --j){
                        if(temp[i].idticket == temp[j].idticket){
                            temp[i].idprofile = temp[i].idprofile <= temp[j].idprofile ? temp[i].idprofile : temp[j].idprofile
                            temp.splice(j, 1)
                        }
                    }
                }
                this.setState({ticketList: temp}, () => {
                    if (newProps.defaultFilter){
                        this.updateFilter([newProps.defaultFilter]);
                    } else {
                        this.updateFilter([])
                    }
                });
            }
        }
    }

    calcTonsToday = () => {
        let tonsToday = this.state.filteredTicketList.reduce((total, item) => {
            if (item.invalidreason !== null && item.recoverreason === null ) 
                return total;
            else return total + item.dumped;
        }, 0);

        this.setState({tonsToday});
    }

    calcLoadsToday = () => {

         let loadsToday = this.state.filteredTicketList.reduce((total, item) => {
            if (item.invalidreason !== null && item.recoverreason === null ) 
                return total;
            else return total + 1;
        }, 0);

        this.setState({loadsToday});

    }

    componentWillMount() {
        this.props.getHauledTodayTickets(this.state.idsite, this.state.idgenerator);

        ajax.get(config.apiUrl + '/sites/permissions/' + this.state.userId + '/' + this.state.idsite)
            .end((error, response) => {
                if (!error && response) {
                    console.log("response.body:",response.body);
                    let {canViewArchiveTab} = response.body.permissions;
                    this.setState({canViewArchiveTab});
                } else {
                    console.log('There was an error fetching', error);
                }
            }
        );

        ajax.get(`${config.apiUrl}/projects/bysiteid/${this.state.idsite}`)
            .end((error, response) => {
                if (!error && response) {
                   
                    /*const project = response.body.projectsList.find(r => {
                        return r.idsource == site.idsite || r.iddump == site.idsite;
                    });*/
                    let project = response.body.projectsList;
                    if(project){
                        this.setState({
                            dumpSite: project.dumpsite,
                            sourceSite: project.sourcesite,
                            generator: project.generator,
                            transporter: project.transporter,
                            customer: project.customername,
                        })
                        ajax.get(`${config.apiUrl}/soilprofiles/projectid/${project.idproject}`)
                            .end((error, response) => {
                                if (!error && response) {
                                    this.setState({soilProfilesList: response.body.soilProfiles})
                                } else {
                                    console.log('There was an error fetching', error);
                                }
                            }
                        );
                    }
                    
                    
                } else {
                    console.log('There was an error', error);
                }
            }
        );

    }

    componentDidMount(){
        this.intervalId = setInterval(() => { 
             this.props.getHauledTodayTickets(this.state.idsite, this.state.idgenerator);
        }, 60000);
    }

    componentWillUnmount = () => {
        clearInterval(this.intervalId);
    }

    renderTickets(){
        function extractTime(date){
            if(typeof date == "string"){
                let parameters = date.split(' ');
                parameters[0] = parameters[0].split('-');
                parameters[1] = parameters[1].split(':');
                parameters = parameters[0].concat(parameters[1]);
                parameters[1] -= 1;
                date = new Date(...parameters)
            }else{
                date = new Date(date);
            }
            return "" + ((date.getHours() % 12) || "12") + ":" + ('0' + date.getMinutes()).substr(-2) + ((date.getHours() >= 12) ? "PM" : "AM")
        }
        return this.state.filteredTicketList.sort((a,b) => {
            return (new Date(b.datedisposed)).getTime() - (new Date(a.datedisposed)).getTime()
        }).map(r => {
            return (
                <tr key={r.idticket}>
                    <td>{r.internalnumber || ""}</td>
                    <td>{r.truckowner || "No owner set"}</td>
                    <td>{r.licenseplate || 
                        <TempTruckID
                        number={r.trucktempnum || 0}
                        idtruck={r.truck}
                        idElement="label"
                        idClasses = "small-4 fw-600"
                        showIcon = {true}
                        iconClasses = "small fa fa-exclamation-triangle text-warning mr-1 fw-100"
                        showLink = {false}
                        />}</td>
                    <td>{r.source_site || ""}</td>
                    <td>{extractTime(new Date(r.sourcetimestamp) || r.datecreated)}</td>
                    <td>{r.dump_site || ""}</td>
                    <td>{ r.recoverreason !== null ?
                            <label className="small-6 text-info fw-700">RECOVERED</label>
                        : (r.invalidreason !== null) ?
                            <label className="small-6 text-danger fw-700">INVALID</label>
                        :
                        extractTime(new Date(r.dumptimestamp) || r.datedisposed)
                    }</td>
                    <td>{r.truck}</td>
                    <td>{r.profile} : {r.soildescription}</td>
                    <td>{r.dumped}</td>
                    <td><a className={"btn btn-xs px-2 py-0 small-6 fw-700 btn-outline-" + (
                        r.recoverreason !== null ? "info" : 
                        r.invalidreason !== null ? "danger" : 
                        "secondary") }
                    data-toggle="modal" data-target="#modal-closed" 
                    onClick={() => this.setState({closedTicket: r})}>VIEW TICKET</a></td>
                    {/*<td>{(new Date(r.datedisposed)).toString()}</td>*/}
                </tr>
            )
        })
    }

    setTicketAsInvalid = (ticket) => {
        ticket.invalidreason = true;
        this.calcTonsToday(); this.calcLoadsToday();
        //this.setState({needsToggle: !this.state.needsToggle});
    }

    setTicketAsRecovered = (ticket) => {
        ticket.recoverreason = true;
        this.calcTonsToday(); this.calcLoadsToday();
        //this.setState({needsToggle: !this.state.needsToggle});
    }

    updateFilter = (filterList) => {
        console.log("Running updateFilter in HauledToday with filterList", filterList);
        this.setState({filterList});

        let {ticketList} = this.state;
        let filteredTicketList = [];

        if (filterList.length == 0){
            filteredTicketList = ticketList;
        } else {
            for (var i = 0; i < ticketList.length; i++){
                let {idproject} = ticketList[i];
                if (filterList.indexOf(idproject) !== -1){
                    filteredTicketList.push(ticketList[i]);
                }
            }
        }

        this.setState({filteredTicketList}, () => { this.calcTonsToday(); this.calcLoadsToday(); });

    }

    render(){
        return(<React.Fragment>
            <div className="row mb-2">
                <div className="fullwidth col-12 mx-auto">
                    <div className="clearfix">  
                        <h3 className="ml-2">Hauled Today</h3>

                        <p className="small-1 strong text-dark text-left float-left pt-4">
                            <a class="text-dark" href="#" onClick={() => this.props.switchSidePanel("activeTickets")}>
                                <i class="fa fa-caret-left mr-2 ml-2" aria-hidden="true"></i>Active Loads
                            </a>
                        </p>
                            
                        {this.state.canViewArchiveTab ? 
                            <p className="small-1 strong text-dark border-bottom text-right float-right pt-4">
                                <a className="text-dark" href="#" onClick={() => this.props.switchSidePanel("archivedTickets")}>
                                    Archive for {this.state.site.name}<i className="fa fa-caret-right ml-2" aria-hidden="true"></i>
                                </a>
                            </p>
                        : null}
                    </div>
                    <AgreementFilter idsite={this.state.idsite} callback={this.updateFilter} filterList={this.state.filterList} sitename={this.state.site.name} />
                </div>

                <div className="col-12 mb-4">
                    <p className="small-1 strong mb-0">Total Loads Today: {this.state.loadsToday}</p>
                    <p className="small-1 strong mb-0">Total Tons Today:  {this.state.tonsToday}</p>
                </div>

                <div className="fullwidth col-lg-12 pr-0 pl-0 mr-auto">
                    <div className="col-12 pr-0 pl-0 table-responsive">

                        {this.state.filteredTicketList.length > 0 ? <table className="table table-responsive table-hover">
                            <thead>
                                <tr>
                                    <th><strong>Ticket #</strong></th>
                                    <th><strong>Truck Owner</strong></th>
                                    <th><strong>Truck License</strong></th>
                                    <th><strong>Export Site</strong></th>
                                    <th><strong>Loaded</strong></th>
                                    <th><strong>Import Site</strong></th>
                                    <th><strong>Unloaded</strong></th>
                                    <th><strong>Truck #</strong></th>
                                    <th><strong>Soil Profile</strong></th>
                                    <th><strong>Tonnage</strong></th>
                                    {/*<th><strong>Date Closed</strong></th>*/}
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.renderTickets()}
                            </tbody>
                        </table> : 
                            this.renderNoTicketsCard()

                        }
                    </div>
                </div>
            </div>
            <ModalClosedTickets ticket={this.state.closedTicket} setTicketAsInvalid={() => this.setTicketAsInvalid(this.state.closedTicket)} setTicketAsRecovered={() => this.setTicketAsRecovered(this.state.closedTicket)} project={this.state.project}/>
        </React.Fragment>)
    }

    renderNoTicketsCard = () => {
        return(
            <div className="col-12 col-sm-8 col-md-6 col-lg-5 ml-md-9">
                <div className="card d-block border">
                    <div className="card-body">
                        <h5 className="card-title" data-font="Permanent Marker:400">Nothing hauled today yet</h5>
                        <p className="mb-0">No loads have been dumped today.</p>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
      tickets: state.tickets,
    };
  }
  
  /*function mapDispatchToProps(dispatch) {
    return {
      getHauledTodayTickets: (idsite, idgenerator) => dispatch(getHauledTodayTickets(idsite, idgenerator)),
    };
  }*/

  function mapDispatchToProps(dispatch) {
    return {
      getTickets: (idsite, idgenerator) => dispatch(getActiveTickets(idsite, idgenerator)),
      createTicket: (data) => dispatch(createTicket(data)),
      updateTicketSourceGeo: (data) => dispatch(updateTicketSourceGeo(data)),
      updateTicketSourcePicture: (idticket, picture) => dispatch(updateTicketSourcePicture(idticket, picture)),
      updateTicketArrivalGeo: (data) => dispatch(updateTicketArrivalGeo(data)),
      updateTicketArrivalPicture: (idticket, picture, signature, tons) => dispatch(updateTicketArrivalPicture(idticket, picture, signature, tons)),
      getNextTicketNumber: (id) => dispatch(getNextTicketNumber(id)),
      getHauledTodayTickets: (idsite, idgenerator) => dispatch(getHauledTodayTickets(idsite, idgenerator)),
    };
  }
  
  
  export default connect(mapStateToProps, mapDispatchToProps)(HauledToday)