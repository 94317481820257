import React, {Component} from 'react';
import "./style.css"
import ajax from 'superagent';

import config from '../../constants/config';


export default class TonnageCalculator extends Component{
    constructor(props){
        super(props);
        this.state = {
            tonnage: this.props.tonnage,
            tareweight: null,
            grossWeight: 0,
            calculatedTonnage: this.props.tonnage,
            idtruck: null
        }
    }
    componentWillReceiveProps(newProps){


        if(newProps.tonnage !== this.state.tonnage){
            this.setState({tonnage: newProps.tonnage, calculatedTonnage: newProps.tonnage})
        }

        if (newProps.idtruck !== this.state.idtruck && newProps.idtruck != null){
            ajax.get(`${config.apiUrl}/trucks/${newProps.idtruck}`)
            .end((error, response) => {
                if (!error && response) {
                    if (response.body.success && response.body.truckData){
                        this.setState({tareweight: response.body.truckData[0].tareweight, grossWeight: (newProps.tonnage * 2000) - response.body.truckData[0].tareweight})
                        console.log(this.state);
                    }
                } else {
                    console.log('There was an error fetching', error);
                }
            });
        }
    }
    
    handleChange(e) {
        /*if(e.target.name == "calculatedTonnage"){
            let vals = e.target.value.split('.');
            e.target.value = vals[1] && vals[1].length > 2 ? vals[0] + '.' + vals[1].substr(0,2) : e.target.value;
        }*/
        if(/*e.target.name === "tareweight" || */e.target.name === "grossWeight"){
            e.target.value = Number(e.target.value)
        }
        this.setState({[e.target.name]: e.target.value})
        if(/*e.target.name === "tareweight" || */e.target.name === "grossWeight"){
            const name = e.target.name;
            this.setState(state => {
                let grossWeight = Number(state.grossWeight);
                let tareweight = Number(state.tareweight);
                if(tareweight > grossWeight && name === "tareweight"){
                    grossWeight = tareweight;
                }
                const calculatedTonnage = ((grossWeight - tareweight) / 2000).toFixed(2);
                return {grossWeight, tareweight, calculatedTonnage}
            })
        }
    }

    grossWeightBlur(){
        this.setState(state => {
            let grossWeight = Number(state.grossWeight);
            let tareweight = Number(state.tareweight);
            if(tareweight > grossWeight){
                grossWeight = tareweight;
            }
            //console.log("grossWeight: " + grossWeight);
            //console.log("tareweight: " + tareweight);
            //console.log("Maths: " + ((grossWeight - tareweight) / 2000));
            const calculatedTonnage = ((grossWeight - tareweight) / 2000).toFixed(2);
            return {grossWeight, tareweight, calculatedTonnage}
        })
    }
    render(){
        return(
            <div class="col-12 mt-3 pr-0 pl-0">
                <div class="accordion accordion-light" id={'calc-'+this.props.id}>
                    <div class="card noborder">
                        <div id={'open-calculator-'+this.props.id} class="collapse" data-parent={'#calc-'+this.props.id}>
                            <div class="card-body bg-gray input-line p-3 mb-2">

                                <div class="col-12 mb-1 mt-3">
                                    <div class="input-group contrast">
                                        <label class="col-8 pt-3 pr-6 pl-0 float-left">Gross Weight (lbs)</label>
                                        <input className="form-control form-control-lg text-right bg-secondary p-3" disabled={this.props.disabled} type="number" step="0.01" placeholder="0" value={Number(this.state.grossWeight)} onChange={this.handleChange.bind(this)} name="grossWeight" />
                                    </div>
                                </div>

                                <div class="col-12 mb-1">
                                    <div class="input-group flexbox">
                                        <label class="pt-3 pr-5">Tare Weight</label>
                                        <label class="pt-3 text-right">{this.state.tareweight}</label>
                                    </div>
                                </div>

                                <div class="col-12 mb-2">
                                    <div class="input-group flexbox">
                                        <label class="pt-3 pr-5">Calculated Tonnage</label>
                                        <label class="mt-3 py-1 px-2 fs2m text-right alert-success">{this.state.calculatedTonnage}</label>
                                    </div>
                                    <div class="mt-4 text-right">
                                        <button class="btn btn-block btn-secondary btn-sm" type="button" data-toggle="collapse" onClick={() => {this.setState({tonnage: this.state.calculatedTonnage}); this.props.onChange(this.state.calculatedTonnage)}} href={"#open-calculator-"+this.props.id}>Update Tonnage</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
