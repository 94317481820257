import React, { Component, Fragment } from 'react'
import config from '../../../constants/config';
import ajax from 'superagent';


export default class AddAMaterial extends Component {

  constructor(props){
  super(props);

      this.state = {
        projectSoils: [{idsoilprofile: 0}],
        idproject: 0,
        soilProfilesList: [],
        agreementData: {"dumpsite": 'Loading...', "sourcesite": "Loading..."},
        projectsList: [],
      }
  }

  componentDidMount = async () => {

    ajax.get(`${config.apiUrl}/soilprofiles`)
      .end((error, response) => {
          if (!error && response) {
              this.setState({soilProfilesList: response.body.soilProfilesList})
          } else {
              console.log('There was an error fetching', error);
          }
      }
    );
  }

  componentWillReceiveProps = async (newProps) => {
    if (newProps.idproject != undefined && newProps.idproject != 0 && newProps.idproject != this.state.idproject){
      this.resetState(newProps.idproject);
      this.setState({idproject: newProps.idproject});
    }

    else if (newProps.idsite != undefined && newProps.idsite != 0 && newProps.idsite != this.state.idsite){
      this.getSite(newProps.idsite);
      this.setState({idsite: newProps.idsite})
    }
  }

  getSite = (idsite) => {
    ajax.get(`${config.apiUrl}/projects/siteTypeAndPartners/${idsite}`)
    .end((error, response) => {
        if (!error && response) {
            let {fullPartnersList} = response.body;
            this.resetState(fullPartnersList[0].idproject);
            this.setState({projectsList: fullPartnersList, idproject: fullPartnersList[0].idproject});
        } else {
            console.log('There was an error', error);
        }
    });
  }

  resetState = (idproject) => {

    this.setState({idproject});

    ajax.get(`${config.apiUrl}/projects/data/${idproject}`)
      .end((error, response) => {
          if (!error && response) {
            console.log("Project data response:", response);
              this.setState({
                projectSoils: [{idsoilprofile: 0}],
                agreementData: response.body.data
              })
          } else {
            console.log('There was an error fetching', error);
          }
      }
    );
      
  }

  updateSoilProfile(key, value) {
    console.log("Calling updateSoilProfile")
    this.setState(state => {
      const projectSoils = state.projectSoils.map((item, j) => {
        if(j == key){
          return Object.assign({}, item, {idsoilprofile: value});
        }else{
          return item;
        }
      });
      return {
        projectSoils
      }
    })
  }

  updateSoilDescription(key, value) {
    this.setState(state => {
      const projectSoils = state.projectSoils.map((item, j) => {
        if(j == key){
          return Object.assign({}, item, {soildescription: value});
        }else{
          return item;
        }
      });
      return {
        projectSoils
      }
    })
  }

  addNewProfileRow(){
    console.log("Calling addNewProfileRow");
    const profiles = this.state.projectSoils;
    const last = profiles.length - 1;
    if(profiles[last].soildescription != "" && profiles[last].idsoilprofile != 0){
        this.setState(state => {
            const projectSoils = [...state.projectSoils, {idsoilprofile: 0}];
            return {projectSoils}
        }, console.log(this.state.projectSoils))
    }
  }   

  handleSubmit = async () => {


    console.log("Calling handleSubmit,")
    ajax.post(`${config.apiUrl}/soilprofiles/newSoilProfileInExistingAgreement`)
    .send(this.state)
    .then(res => {
      console.log("handleSubmit returned")

      this.setState({})

      let {callback} = this.props;
      if (callback) callback();

      document.getElementById('close-add-material').click();
    }).catch(res =>{
      alert('Error creating project!');
    });
  }


    render = () => {
        return(<div className="modal" id="add-material" tabindex="-1" role="dialog" aria-labelledby="addMaterial" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">

                    <div className="alert alert-primary" role="alert">
                        <strong>Add Materials</strong>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" id="close-add-material">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                <div className="modal-body pt-0">
                    <div className="row mt-0 mb-2 ml-0">
                        <div className="col-lg-12 mr-auto pl-4 m-2">
                    

                            {this.state.idsite ? <Fragment>

                              <span>Agreement Partner:</span>
                              {this.renderSitesDropdown() }

                            </Fragment>: <Fragment>
                              <div className="clearfix">
                                <p className="small mb-0 pl-0 pr-2 mr-7">
                                  <span><b>Source:</b> {this.state.agreementData.sourcesite}</span>
                                  <span className="text-right float-right"><b>Dump:</b> {this.state.agreementData.dumpsite}</span>
                                </p>
                            </div>
                            </Fragment>}
                            
                            <div class="form-group mt-1 pb-0">

                              {this.renderAddProfiles()}

                              <div class="col-12 row gap-y my-4">
                                   <button type="button"
                                      class="btn btn-outline-dark px-4 mr-3"
                                      onClick={this.addNewProfileRow.bind(this)}>
                                          <i class="fa fa-plus"></i>
                                  </button>
                              </div>
                            </div>

                        </div>
                    </div>

                </div>

                <a className="btn btn-primary" href="#" onClick={this.handleSubmit}>Add to Agreement</a>
                </div>
            </div>
        </div>)
    }

     renderAddProfileRow = (key) => {
        return (<Fragment>
            <div class="col-12 row gap-y my-4">
                <select class="form-control form-control-sm col-lg-3 py-0 mr-5 mb-2 pl-0"
                    value={this.state.projectSoils[key].idsoilprofile || 0}
                    onChange={e => {this.updateSoilProfile(key, ~~e.target.value); }}>
                        <option value={0} disabled={true}>Material Type</option>
                        {this.renderSoilProfilesOptions()}
                </select>
                <input class="form-control form-control-sm col-lg-5 mr-5 mb-2 pl-0" type="text" placeholder="Description"
                    value={this.state.projectSoils[key].soildescription || ""}
                    onChange={(e) => {this.updateSoilDescription(key, e.target.value)}} />
            </div>

        </Fragment> );
    }

    renderAddProfiles = () => {
        return this.state.projectSoils.map((item, index) => {
            return this.renderAddProfileRow(index)
        })
    }

    renderSoilProfilesOptions = () => {
        return this.state.soilProfilesList.map(r => {
            return <option key={r.idsoilprofile} value={r.idsoilprofile}>{r.profile}</option>
        })
    }

    renderSitesDropdown = () => {
     return(
        <div className="col-12">
            <select className="form-control form-control-sm col-lg-3 py-0 mr-5 mb-2 pl-0" id="disposalPartnerSelected" name="disposalPartnerSelected" value={this.state.idproject} onChange={(e) => this.resetState(e.target.value)}>
                {this.renderSitesDropdownOptions()}
            </select>
        </div> )
    }

  renderSitesDropdownOptions = () => {

    return this.state.projectsList.map((partner, index) => {
      return (
        <option value={partner.idproject} key={index}>{partner.name}</option>
      )
    })
     
  }

                        
}