import {
    GET_ACTIVE_TICKETS_REQUESTED,
    GET_ACTIVE_TICKETS_REJECTED,
    GET_ACTIVE_TICKETS_FULFILLED,

    GET_HAULED_TODAY_TICKETS_REQUESTED,
    GET_HAULED_TODAY_TICKETS_REJECTED,
    GET_HAULED_TODAY_TICKETS_FULFILLED,
    
    GET_CLOSED_TICKETS_REQUESTED,
    GET_CLOSED_TICKETS_REJECTED,
    GET_CLOSED_TICKETS_FULFILLED,

    CREATE_NEW_TICKET_REQUESTED,
    CREATE_NEW_TICKET_REJECTED,
    CREATE_NEW_TICKET_FULFILLED,

    CLOSE_NEW_TICKET_REQUESTED,
    CLOSE_NEW_TICKET_REJECTED,
    CLOSE_NEW_TICKET_FULFILLED,

    UPDATE_TICKET_SOURCE_PICTURE_REQUESTED,
    UPDATE_TICKET_SOURCE_PICTURE_REJECTED,
    UPDATE_TICKET_SOURCE_PICTURE_FULFILLED,
    
    UPDATE_TICKET_SOURCE_GEO_REQUESTED,
    UPDATE_TICKET_SOURCE_GEO_REJECTED,
    UPDATE_TICKET_SOURCE_GEO_FULFILLED,

    UPDATE_TICKET_ARRIVAL_PICTURE_REQUESTED,
    UPDATE_TICKET_ARRIVAL_PICTURE_REJECTED,
    UPDATE_TICKET_ARRIVAL_PICTURE_FULFILLED,
    
    UPDATE_TICKET_ARRIVAL_GEO_REQUESTED,
    UPDATE_TICKET_ARRIVAL_GEO_REJECTED,
    UPDATE_TICKET_ARRIVAL_GEO_FULFILLED,

    GET_NEXT_TICKET_NUMBER_REQUESTED,
    GET_NEXT_TICKET_NUMBER_REJECTED,
    GET_NEXT_TICKET_NUMBER_FULFILLED,

    MARK_TICKET_AS_IN_PROGRESS,
    SET_TICKET_FOR_MODAL,
} from '../actions/types';

const initialState = {
    activeTickets: {
        inProgress: false,
        success: '',
        error: '',
        data: [],
    },
    hauledTodayTickets:{
        inProgress: false,
        success: '',
        error: '',
        data: [],
    },
    closedTickets:{
        inProgress: false,
        success: '',
        error: '',
        data: [],
    },
    createTicket:{
        inProgress: false,
        success: '',
        error: '',
        data: [],
    },
    updateTicketSourcePicture:{
        inProgress: false,
        success: '',
        error: '',
        data: [],
    },
    updateTicketSourceGeo:{
        inProgress: false,
        success: '',
        error: '',
        data: [],
    },
    updateTicketArrivalPicture:{
        inProgress: false,
        success: '',
        error: '',
        data: [],
    },
    updateTicketArrivalGeo:{
        inProgress: false,
        success: '',
        error: '',
        data: [],
    },
    closeTicket:{
        inProgress: false,
        success: '',
        error: '',
        data: [],
    },
    nextTicketNumber: {
        inProgress: false,
        success: '',
        error: '',
        data: null,
    },
    ticketForModal: null,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_ACTIVE_TICKETS_REQUESTED: {
            const { inProgress } = action;
            return Object.assign({}, state, {
                activeTickets: {
                    inProgress
                }
            });
        }
        case GET_ACTIVE_TICKETS_REJECTED: {
            const { inProgress, success, error } = action;
            return Object.assign({}, state, {
                activeTickets: {
                    inProgress,
                    success,
                    error
                }
            });
        }
        case GET_ACTIVE_TICKETS_FULFILLED: {
            const { inProgress, success, error, data } = action;
            if(data.success){
                return Object.assign({}, state, {
                    activeTickets: {
                        inProgress,
                        success,
                        error,
                        data: data.ticketsList
                    }
                });
            }else{
                return Object.assign({}, state, {
                    activeTickets: {
                        inProgress,
                        success: false,
                        error: true,
                        data: []
                    }
                });
            }
        }

        case GET_HAULED_TODAY_TICKETS_REQUESTED: {
            const { inProgress } = action;
            return Object.assign({}, state, {
                hauledTodayTickets: {
                    inProgress
                }
            });
        }
        case GET_HAULED_TODAY_TICKETS_REJECTED: {
            const { inProgress, success, error } = action;
            return Object.assign({}, state, {
                hauledTodayTickets: {
                    inProgress,
                    success,
                    error
                }
            });
        }
        case GET_HAULED_TODAY_TICKETS_FULFILLED: {
            const { inProgress, success, error, data } = action;
            return Object.assign({}, state, {
                hauledTodayTickets: {
                    inProgress,
                    success,
                    error,
                    data: data.ticketsList,
                }
            });
        }

        case GET_CLOSED_TICKETS_REQUESTED: {
            const { inProgress } = action;
            return Object.assign({}, state, {
                closedTickets: {
                    inProgress
                }
            });
        }
        case GET_CLOSED_TICKETS_REJECTED: {
            const { inProgress, success, error } = action;
            return Object.assign({}, state, {
                closedTickets: {
                    inProgress,
                    success,
                    error
                }
            });
        }
        case GET_CLOSED_TICKETS_FULFILLED: {
            const { inProgress, success, error, data } = action;
            return Object.assign({}, state, {
                closedTickets: {
                    inProgress,
                    success,
                    error,
                    data
                }
            });
        }

        case CREATE_NEW_TICKET_REQUESTED: {
            const { inProgress } = action;
            return Object.assign({}, state, {
                createTicket: {
                    inProgress
                }
            });
        }
        case CREATE_NEW_TICKET_REJECTED: {
            const { inProgress, success, error } = action;
            return Object.assign({}, state, {
                createTicket: {
                    inProgress,
                    success,
                    error
                }
            });
        }
        case CREATE_NEW_TICKET_FULFILLED: {
            const { inProgress, success, error, data } = action;
            return Object.assign({}, state, {
                createTicket: {
                    inProgress,
                    success,
                    error,
                    data
                }
            });
        }

        case UPDATE_TICKET_SOURCE_PICTURE_REQUESTED: {
            const { inProgress } = action;
            return Object.assign({}, state, {
                updateTicketSourcePicture: {
                    inProgress
                }
            });
        }
        case UPDATE_TICKET_SOURCE_PICTURE_REJECTED: {
            const { inProgress, success, error } = action;
            return Object.assign({}, state, {
                updateTicketSourcePicture: {
                    inProgress,
                    success,
                    error
                }
            });
        }
        case UPDATE_TICKET_SOURCE_PICTURE_FULFILLED: {
            const { inProgress, success, error, data } = action;
            return Object.assign({}, state, {
                updateTicketSourcePicture: {
                    inProgress,
                    success,
                    error,
                    data
                }
            });
        }

        case UPDATE_TICKET_SOURCE_GEO_REQUESTED: {
            const { inProgress } = action;
            return Object.assign({}, state, {
                updateTicketSourceGeo: {
                    inProgress
                }
            });
        }
        case UPDATE_TICKET_SOURCE_GEO_REJECTED: {
            const { inProgress, success, error } = action;
            return Object.assign({}, state, {
                updateTicketSourceGeo: {
                    inProgress,
                    success,
                    error
                }
            });
        }
        case UPDATE_TICKET_SOURCE_GEO_FULFILLED: {
            const { inProgress, success, error, data } = action;
            return Object.assign({}, state, {
                updateTicketSourceGeo: {
                    inProgress,
                    success,
                    error,
                    data
                }
            });
        }

        case UPDATE_TICKET_ARRIVAL_PICTURE_REQUESTED: {
            const { inProgress } = action;
            return Object.assign({}, state, {
                updateTicketArrivalPicture: {
                    inProgress
                }
            });
        }
        case UPDATE_TICKET_ARRIVAL_PICTURE_REJECTED: {
            const { inProgress, success, error } = action;
            return Object.assign({}, state, {
                updateTicketArrivalPicture: {
                    inProgress,
                    success,
                    error
                }
            });
        }
        case UPDATE_TICKET_ARRIVAL_PICTURE_FULFILLED: {
            const { inProgress, success, error, data } = action;
            return Object.assign({}, state, {
                updateTicketArrivalPicture: {
                    inProgress,
                    success,
                    error,
                    data
                }
            });
        }

        case UPDATE_TICKET_ARRIVAL_GEO_REQUESTED: {
            const { inProgress } = action;
            return Object.assign({}, state, {
                updateTicketArrivalGeo: {
                    inProgress
                }
            });
        }
        case UPDATE_TICKET_ARRIVAL_GEO_REJECTED: {
            const { inProgress, success, error } = action;
            return Object.assign({}, state, {
                updateTicketArrivalGeo: {
                    inProgress,
                    success,
                    error
                }
            });
        }
        case UPDATE_TICKET_ARRIVAL_GEO_FULFILLED: {
            const { inProgress, success, error, data } = action;
            return Object.assign({}, state, {
                updateTicketArrivalGeo: {
                    inProgress,
                    success,
                    error,
                    data
                }
            });
        }

        case CLOSE_NEW_TICKET_REQUESTED: {
            const { inProgress } = action;
            return Object.assign({}, state, {
                closeTicket: {
                    inProgress
                }
            });
        }
        case CLOSE_NEW_TICKET_REJECTED: {
            const { inProgress, success, error } = action;
            return Object.assign({}, state, {
                closeTicket: {
                    inProgress,
                    success,
                    error
                }
            });
        }
        case CLOSE_NEW_TICKET_FULFILLED: {
            const { inProgress, success, error, data } = action;
            return Object.assign({}, state, {
                closeTicket: {
                    inProgress,
                    success,
                    error,
                    data
                }
            });
        }

        case GET_NEXT_TICKET_NUMBER_REQUESTED: {
            const { inProgress } = action;
            return Object.assign({}, state, {
                nextTicketNumber: {
                    inProgress
                }
            });
        }
        case GET_NEXT_TICKET_NUMBER_REJECTED: {
            const { inProgress, success, error } = action;
            return Object.assign({}, state, {
                nextTicketNumber: {
                    inProgress,
                    success,
                    error
                }
            });
        }
        case GET_NEXT_TICKET_NUMBER_FULFILLED: {
            const { inProgress, success, error, data } = action;
            if(data.success){
                return Object.assign({}, state, {
                    nextTicketNumber: {
                        inProgress,
                        success,
                        error,
                        data: data.nextTicket
                    }
                });
            }else{
                return Object.assign({}, state, {
                    nextTicketNumber: {
                        inProgress,
                        success: false,
                        error: true,
                        data: null,
                    }
                });
            }
        }
        case MARK_TICKET_AS_IN_PROGRESS: {
            const { idticket } = action;
            const activeTickets = state.activeTickets;
            activeTickets.data.forEach(item => {
                if(item.idticket == idticket){
                    item.inProgress = true
                }
            })
            console.log(state, idticket)
            console.log(activeTickets)
            return Object.assign({}, state, {
                activeTickets,
            });
        }
        case SET_TICKET_FOR_MODAL: {
            const { ticket } = action;
            return Object.assign({}, state, {
                ticketForModal: ticket,
            });
        }
        default:
            return state;
    }
  };
  