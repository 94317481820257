import React, {Fragment, Component} from 'react';
import BaseGuideModal from './BaseGuideModal';

export default class TicketGuideModal extends Component {

  constructor(props){

    super(props)

    this.state={
      pages: [
        {
          img: "../../img/onboard/modal-4.png",
          title: <span><strong>Tickets</strong> appear in your Site's <strong>Ticket Feed</strong> for all Agreements connected to your Site.</span>,
          text: <span>Whether you are exporting, importing, or both, the Ticket Feed is <strong>sorted to give you the clearest view</strong>.</span>,
        },
        {
          img: "../../img/onboard/modal-5.png",
          title: <span><strong>Active Tickets</strong> are opened on the export end & closed on the import end.</span>,
          text: <span>This can be <strong>automated if there is no one available</strong> at one end or the other, and can be changed at any time.</span>,
        },
        {
          img: "../../img/onboard/modal-6.png",
          title: <span>Each Ticket starts with a <strong>Truck</strong>, and each Truck starts with a <strong>photo</strong> and <strong>license plate # or temporary ID</strong>.</span>,
          text: <span>Trucks will return in the <strong>Today's Trucks lineup</strong>, allowing for quick and easy ticket creation and haul tracking.</span>,
        },
        {
          img: "../../img/onboard/modal-7.png",
          title: <span>Every ticket has a <strong>Tonnage amount</strong> for the specific <strong>Material</strong> being hauled on that trip.</span>,
          text: <span><strong>Daily Totals</strong> can be viewed under <strong>Hauled Today</strong>, and <strong>Tonnage Reports</strong> can be generated from the <strong>Archive</strong>.</span>,
        },
      ]
    }
  }


  render(){

    return(<Fragment>

      <BaseGuideModal id="ticket" markAsSeen={this.props.markAsSeen} pages={this.state.pages} />

    </Fragment>)
  }

}