// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsconfig = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:22486eb0-5233-46ac-a8c5-2a0e2a6b59ba",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_lN3jMYcjz",
    "aws_user_pools_web_client_id": "6s4ocl4v6li6vck832bff8figp",
    "oauth": {}
};


export default awsconfig;
