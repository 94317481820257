import React, {Component} from 'react';
import config from '../../../constants/config';
import { connect } from 'react-redux';
import { getUserById } from '../../../services/userService';
import { stripeService } from '../../../services';
import LetterAvatar from '../../LetterAvatar';
import { convertDateToShortString } from '../../../helpers/dateHelpers';

import {
    getActiveTickets,
    createTicket,
    updateTicketSourceGeo,
    updateTicketSourcePicture,
    updateTicketArrivalGeo,
    updateTicketArrivalPicture,
    markTicketAsInProgress,
} from '../../../actions/tickets';

class ModalConfirmDisposedTicket extends Component {
    constructor(props){
        super(props);

        let userId = JSON.parse(localStorage.getItem('user')).id;

        this.state = {
            dumpsite: props.project && props.project.dumpsite,
            dumpCoords: {lat: 0, long: 0},
            internalnumber: props.ticket && props.ticket.internalnumber,
            sourcesite: props.project && props.project.sourcesite,
            generator: props.project && props.project.generator,
            transporter: props.project && props.project.transporter,
            customer: props.project && props.project.customername,
            idtruck: props.ticket && props.ticket.idtruck,
            soilprofile: props.ticket && props.ticket.profile,
            chargeinfo: {
                amount:         0,
                description:    '',
                currency:       '',
                customer:       '',
                receipt_email:  '',
            },
            userId,
            creatorName: props.ticket && props.ticket.creatorname,
            closerName: '',
            picture_load: props.ticket && props.ticket.picture_load,
            picture_dump: props.dumpImage,
            sourcetimestamp: props.ticket && props.ticket.sourcetimestamp,
            sourcesignature: props.ticket && props.ticket.sourcesignature,
        }
        
        let userResponse = getUserById(userId);
        userResponse.then((response) => {
            this.setState({
                chargeinfo: {
                    amount:         80,
                    description:    `Assured Disposal Charge to ${response.user[0].email}`,
                    currency:       'usd',
                    receipt_email:  response.user[0].email,
                    confirm:        true,
                    customer:       response.user[0].stripe_cus_id,
                    payment_method: response.user[0].stripe_token,
                },
                closerName: response.user[0].username,
            })
        });

        this.getGeoLocation = this.getGeoLocation.bind(this);

        this.getGeoLocation("dumpsite", (error, coords) => {
            if(!error){
                this.setState({dumpCoords : {lat: coords.latitude, long: coords.longitude}});
            }
        })
    }

    componentWillReceiveProps(newProps){
        this.setState({
            dumpsite: newProps.ticket && newProps.ticket.dump_site,
            internalnumber: newProps.ticket && newProps.ticket.internalnumber,
            sourcesite: newProps.ticket && newProps.ticket.source_site,
            generator: newProps.ticket && newProps.ticket.generatorname,
            transporter: newProps.ticket && newProps.ticket.transporter,
            customer: newProps.ticket && newProps.ticket.customername,
            idtruck: newProps.ticket && newProps.ticket.truck,
            loadedDate: newProps.ticket && newProps.ticket.datecreated,
            soilprofile: newProps.ticket && newProps.ticket.profile,
            creatorName: newProps.ticket && newProps.ticket.creatorname,
            picture_load: newProps.ticket && newProps.ticket.picture_load,
            picture_dump: newProps.dumpImage,
            sourcetimestamp: newProps.ticket && newProps.ticket.sourcetimestamp,
            sourcesignature: newProps.ticket && newProps.ticket.sourcesignature,
        });
        let userResponse = getUserById(this.state.userId);
        userResponse.then((response) => {
            this.setState({
                chargeinfo: {
                    amount:         80,
                    description:    `Assured Disposal Charge to ${response.user[0].email}`,
                    currency:       'usd',
                    receipt_email:  response.user[0].email,
                    confirm:        true,
                    customer:       response.user[0].stripe_cus_id,
                    payment_method: response.user[0].stripe_token,
                }
            })
        });
        if(newProps.tickets.updateTicketArrivalPicture.inProgress !== this.props.tickets.updateTicketArrivalPicture.inProgress){
            if(newProps.tickets.updateTicketArrivalPicture.success){
                this.setState({imageSelected: null, imageToSend: null});
                this.props.fetchTickets();
                const { chargeinfo } = this.state;
                stripeService.charge(chargeinfo);
            }
        }
    }

    getGeoLocation(event, cb){
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                let currentPosition = {
                    status: 'success',
                    position: position.coords,
                }
                cb(null, position.coords)
                //this.setState({[event]: currentPosition});
            }, error => {
                cb(error)
            });
        } else {
            let currentPosition = {
                status: 'error',
                position: null,
            }
            this.setState({[event]: currentPosition.position});
        }
    }

    handleUpdate(){
        this.props.markTicketAsInProgress(this.props.ticket.idticket)
        const today = new Date();
        function get24hours(date){
            date = new Date(date);
            let hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
            let minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
            let seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
            return "" + hours + ":" + minutes + ":" + seconds
        }
        this.getGeoLocation("dumpSite", (error, coords) => {
            if(!error){
                const data = {
                    geo_load: `POINT(${coords.longitude} ${coords.latitude})`,
                    id: this.props.ticket.idticket,
                    timestamp: today.toISOString().split('T')[0]+ ' ' + get24hours(today),
                    idcloser: this.state.userId,
                }
                this.props.updateTicketArrivalGeo(data);
            }
        });
        this.props.updateTicketArrivalPicture(
            this.props.ticket.idticket, 
            this.props.dumpImage, 
            this.props.offlineData.workingOffline ? this.props.offlineData.offlineSignature : '', 
            this.props.offlineData.tons,
            this.props.offlineData.workingOffline ? this.props.offlineData.offlineTimestamp.toISOString() : today.toISOString(),
            today.toISOString()
        );
    }

    render(){
        function convertTimestamp(timestamp){
            let date = new Date(timestamp);
            return ('0' + (date.getMonth() + 1)).substr(-2)
                + '-' + ('0' + date.getDate()).substr(-2)
                + '-' + date.getFullYear().toString().substr(2,2)
                + " " + ((date.getHours() % 12) || "12") + ":" + ('0' + date.getMinutes()).substr(-2) + ((date.getHours() >= 12) ? "PM" : "AM")
        }
        function convertDate(date){
            if(typeof date == "string"){
                let parameters = date.split(' ');
                parameters[0] = parameters[0].split('-');
                parameters[1] = parameters[1].split(':');
                parameters = parameters[0].concat(parameters[1]);
                parameters[1] -= 1;
                date = new Date(...parameters)
            }else{
                date = new Date(date);
            }
            return "" + ((date.getHours() % 12) || "12") + ":" + ('0' + date.getMinutes()).substr(-2) + ((date.getHours() >= 12) ? "PM" : "AM") + " - " + date.toISOString().substr(5,5).replace('-', '/') + "/" + date.getFullYear()
        }
        const offlineData = this.props.offlineData;
        const coords_load = this.props.ticket && this.props.ticket.geo_load ? this.props.ticket.geo_load.replace('POINT(', '').replace(')', '').split(' ') : [0, 0];
        const coords_dump = !offlineData.workingOffline ? [this.state.dumpCoords.long, this.state.dumpCoords.lat] : [0,0];
        return (
            <div className="modal" id="modal-close-ticket" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-long" role="document">
                    <div className="modal-content">
                        <div className="alert alert-primary" role="alert">
                            <strong>Are you ready to close this ticket?</strong>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body pt-0">
                            <div className="row m-0 mb-5">
                                <div className="col-12 p-0 mt-4 mb-1 border-bottom">
                                    <label className="col-9 m-0 p-0 lead-2 fw-600 lh-1">{this.state.soilprofile}</label>
                                    <label className="badge badge-pale badge-info fw-600 fs1m p-1 mb-2 float-right text-right">#{this.state.internalnumber}</label>
                                </div>
                                <label className="col-6 m-0 p-0 fw-500 float-left"><img className="round mt-1 mb-2 mr-1" width="20" height="20" src={LetterAvatar(this.state.transporter)} />{this.state.transporter}</label>
                                <label className="col-6 m-0 p-0 fw-500 float-right text-right">{this.state.idtruck}</label>
                                <div className="col-12 p-0 pt-5 flexbox px-0 border-bottom">
                                    <label className="lead-1 fw-400 mb-0 text-left">{this.state.sourcesite}</label>
                                    <label className="lead-1 fw-100 mb-0 text-center"><i className="fa fa-long-arrow-right" aria-hidden="true"></i></label>
                                    <label className="lead-1 fw-400 mb-0 text-right">{this.state.dumpsite}</label>
                                </div>
                                <label className="col-6 m-0 p-0 fw-100">{this.state.generator}</label>
                                {/* <label className="col-6 m-0 p-0 fw-100 float-right text-right">1300 E Rawson</label> */}
                            </div>
                            <div className="row p-1 mt-4 mx-0 bg-gray">
                                <div className="col-12 col-sm-6 p-1">
                                    <label className="col-6 m-0 p-0 fw-600 float-left">{convertDateToShortString(this.state.sourcetimestamp)}</label>
                                    <label className="col-6 m-0 p-0 fw-100 float-right text-right"></label>
                                    <div class="bg-pale-success mt-3 mb-3 p-3">
                                        <label><i class="ti ti-check text-success mr-2" aria-hidden="true"></i><em>Truck Loaded {convertTimestamp(this.state.sourcetimestamp)} <strong>{this.state.sourcesignature}</strong></em></label>
                                    </div>
                                    <label className="col-6 m-0 p-0 fw-100"><img className="round m-1 mb-2 mr-1" width="20" height="20" src={LetterAvatar(this.state.creatorName)} />{this.state.creatorName}</label>
                                    <a className="col-6 m-0 p-0 fw-100 float-right text-right" href={`https://www.google.com/maps/dir/${coords_load[1]},${coords_load[0]}//@${coords_load[1]},${coords_load[0]},15z`} target="window">
                                        <label><i className="fa fa-location-arrow" aria-hidden="true"></i></label>
                                    </a>
                                </div>
                                <div className="col-12 col-sm-6 p-1">
                                    <label className="col-6 m-0 p-0 fw-600 float-left">{convertDateToShortString(this.props.offlineData.workingOffline ? this.props.offlineData.offlineTimestamp : new Date())}</label>
                                    <label className="col-6 m-0 p-0 fw-100 float-right text-right"></label>
                                    <div class="bg-pale-success mt-3 mb-3 p-3">
                                        <label><i class="ti ti-check text-success mr-2" aria-hidden="true"></i><em>Truck Dumped {convertTimestamp(this.props.offlineData.offlineTimestamp)} <strong>{this.props.offlineData.offlineSignature}</strong></em></label>
                                    </div>
                                    <label className="col-6 m-0 p-0 fw-100"><img className="round m-1 mb-2 mr-1" width="20" height="20" src={LetterAvatar(this.state.closerName)} />{this.state.closerName}</label>
                                    <a className="col-6 m-0 p-0 fw-100 float-right text-right" href={`https://www.google.com/maps/dir/${coords_dump[1]},${coords_dump[0]}//@${coords_dump[1]},${coords_dump[0]},15z`} target="window">
                                        <label><i className="fa fa-location-arrow" aria-hidden="true"></i></label>
                                    </a>
                                </div>
                            </div>
                            <div className="col-12 mt-4 px-0">
                                <label className="col-12 alert alert-success">{this.props.offlineData.tons} tons</label>
                            </div>
                        </div>
                        <button type="button" className="btn btn-lg btn-primary" data-dismiss="modal" onClick={this.handleUpdate.bind(this)} >Close Ticket</button>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
      tickets: state.tickets,
    };
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      getTickets: (id) => dispatch(getActiveTickets(id)),
      createTicket: (data) => dispatch(createTicket(data)),
      updateTicketSourceGeo: (data) => dispatch(updateTicketSourceGeo(data)),
      updateTicketSourcePicture: (idticket, picture) => dispatch(updateTicketSourcePicture(idticket, picture)),
      updateTicketArrivalGeo: (data) => dispatch(updateTicketArrivalGeo(data)),
      updateTicketArrivalPicture: (idticket, picture, signature, tons, timestamp, datedisposed) => dispatch(updateTicketArrivalPicture(idticket, picture, signature, tons, timestamp, datedisposed)),
      markTicketAsInProgress: (id) => dispatch(markTicketAsInProgress(id)),
    };
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(ModalConfirmDisposedTicket)
