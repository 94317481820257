import React, {Fragment, Component} from 'react';
import SiteDisplayEmpty from './SiteDisplayEmpty';
import SiteDisplayAgreements from './SiteDisplayAgreements';

export default function SiteDisplay ({site, refreshSites, showSharedSite, switchSidePanel}) {

	let {idsite, name} = site;

	//console.log("SiteDisplay "+name+" projectsList:", site.projectsList, "site projects length:", site.projects);

	return(<Fragment>
		<div className="col-12 accordion accordion-arrow-right accordion-light collapsed" id={"accordion-"+idsite}>

			<div className="card noborder">
				<p className="card-title fs-17 text-dark strong border-bottom p-0">
					<a className="collapsed p-0 m-0 pt-1 px-2 bg-gray" data-toggle="collapse" href={"#collapse-trigger-"+idsite}>{name}</a>
				</p>

				<div id={"collapse-trigger-"+idsite}  className="collapse mb-4" data-parent={"#accordion-"+idsite}>
					<div className="card-body px-2 pt-5">
						{parseInt(site.projects) === 0 ? 
							<SiteDisplayEmpty site={site} refreshSites={refreshSites} />
						:
							<SiteDisplayAgreements site={site} refreshSites={refreshSites} showSharedSite={showSharedSite} switchSidePanel={switchSidePanel} />
						}
					</div>
				</div>
			</div>

		</div>
	</Fragment>)
}