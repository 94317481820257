const pageUrls = {

   auth: "/auth",

   mySites: "/assured-disposal-sites",
   
   agreementsList: "/site-agreements",
   newDisposalAgreement: "/disposal-agreement-setup-1",
   myAccountPage: "/user-settings",

   completeAgreement: "/disposal-agreement-setup-2",

   activeTickets: "/active-tickets",
   closedTickets: "/closed-tickets",
   archivedTickets: "/archived-tickets",

   login: "/login",
   register: "/register",
   inviteeRegister: "/invitee-register",
   recoverPassword: "/recover",  

   loginFromInvite: "/login-for-agreement/",
   registerFromInvite: "/register-for-agreement/"
}

export default pageUrls;
