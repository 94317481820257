import React, {Component, Fragment} from 'react';
import ajax, {Request} from 'superagent';
import request from 'superagent';
import { connect } from 'react-redux';
import Compress from 'compress.js';
import config from '../../../constants/config';
import CreateTicketForm from '../CreateTicketForm';
import ModalTruckID from './ModalTruckID';
import SuccessPopup from '../../Popups'
import LetterAvatar from '../../LetterAvatar';
import TempTruckID from '../TempTruckID';
import Manifest from '../../Manifest';
import pageUrls from '../../../constants/pageUrls';

import Event from '../../Event';

import "./style.css";

import {
    getActiveTickets,
    createTicket,
    updateTicketSourceGeo,
    updateTicketSourcePicture,
    updateTicketArrivalGeo,
    updateTicketArrivalPicture,
    getNextTicketNumber,
} from '../../../actions/tickets';

class ActiveTickets extends Component {
    constructor(props){
        super(props);
        let idgenerator = JSON.parse(localStorage.getItem('user')).id;
        //let idsite = JSON.parse(localStorage.getItem('site')).idsite;
        //let sitename = JSON.parse(localStorage.getItem('site')).name;
        let {idsite, sitename} = props.site;
        this.state = {
            activeTruck: undefined,
            calculatedTonnage: 20,
            canEditTickets: false,
                unconditionalTicketAccess: true,
                canCloseExportTickets: false,
                canOpenImportTickets: false,
                canModifyMixedTickets: false,
            customer: '',
            disposalPartnerSelected: -1,
                disposalPartnerOnDuty: false,
            dumpImage: null,
            dumps: [],
            dumpSite: null,
            generator: '',
            grossWeight: 0,
            idgenerator,
            idsitetype: null,
            idsite,
                sitename,
            imageSelected: null,
            imageToSend: null,
            idtruckToSend: '',
            nextTicketNumber: 0,
            newTicketTimestamp: null,
            newTicketInitials: null,
            newTicketNoPhotoAvailable: null,
            offlineData: {},
            //partnersLists:
                sourcePartnersList: [],
                dumpPartnersList: [],
            projects: [],
            showModal: false,
            sites: [],
            siteType: "export",
                mixedMode: false,
            //soilProfilesLists:
                dumpSoil: [],
                sourceSoil: [],
            soilProfileToSend: '',
            sourceSite: null,
            tareweight: null,
            tareSetBySelectedTruck: false,
            ticketSelected: null,
            todaysTrucks: [],
                emptyTrucks: [],
                fullTrucks: [],
            toggle: false,
            tonnage: '20.00',
            transporter: 1,
            transportersList: [],
            ticketsBeingUpdated: [],
            tareWeightPopupTrigger: false,
            ticketFeedPopupTrigger_import: false,
            ticketFeedPopupTrigger_export: false,
            imgLoading: false,
            hasInvalidImage: false,
            needsRefresh: false,
        }
    }

    initializeSite(site) {

        console.log("Calling initializeSite with site", site);
        if (this.intervalId) clearInterval(this.intervalId);

        this.setState({site, activeTruck: undefined, disposalPartnerSelected: -1, todaysTrucks: [], emptyTrucks: [], fullTrucks: [], tareweight: null, tonnage: '20.00', siteType: "export", mixedMode: false, soilProfileToSend: '', grossWeight: 0, disposalPartnerOnDuty: false})
        ajax.get(`${config.apiUrl}/sites/partners/${this.state.idgenerator}/${site.idsite}`)
            .end((error, response) => {
                if (!error && response) {
                    const sites = response.body.siteslist || [];
                    this.setState({sites})
                } else {
                    console.log('There was an error', error);
                }
            }
        );

        ajax.get(config.apiUrl + '/sites/permissions/' + this.state.idgenerator + '/' + site.idsite)
            .end((error, response) => {
                if (!error && response) {
                    let {canEditTickets, unconditionalTicketAccess, canOpenImportTickets, canModifyMixedTickets} = response.body.permissions;
                    this.setState({canEditTickets, unconditionalTicketAccess, canOpenImportTickets, canModifyMixedTickets})
                } else {
                    console.log('There was an error fetching', error);
                }
            }
        );

        ajax.get(`${config.apiUrl}/projects/getProjectsByUserId/${this.state.idgenerator}`)
        .end((error, response) => {
            if (!error && response) {
                const projects = response.body.projectsList;
                this.setState({projects})
            } else {
                console.log('There was an error', error);
            }
        });

        ajax.get(`${config.apiUrl}/projects/siteTypeAndPartners/${site.idsite}`)
        .end((error, response) => {
            if (!error && response) {
                let {siteType, sourcePartnersList, dumpPartnersList} = response.body;

                if (siteType === "mixed"){
                    this.setState({mixedMode: true, siteType: "export", sourcePartnersList, dumpPartnersList});
                } else {
                    this.setState({siteType, sourcePartnersList, dumpPartnersList})
                }
            } else {
                console.log('There was an error', error);
            }
        });

        ajax.get(`${config.apiUrl}/soilprofiles/siteid/sorted/${site.idsite}`)
        .end((error, response) => {
            if (!error && response) {
                this.setState({dumpSoil: response.body.dumpSoil, sourceSoil: response.body.sourceSoil})
            } else {
                console.log('There was an error fetching', error);
            }
        });

        this.getTodaysTrucks(site.idsite);
        this.intervalId = setInterval(() => { 
            this.getTodaysTrucks(site.idsite);
        }, 60000);

    }

    componentWillUnmount = () => {
        clearInterval(this.intervalId);
    }

    componentWillReceiveProps(newProps){

        console.log("CreateTicket with current idsite "+this.state.idsite+" recieving props ", newProps)
        if (newProps.site.idsite !== this.state.idsite && newProps.site.idsite != 0){
            this.setState({idsite: newProps.site.idsite, sitename: newProps.site.sitename}, () => {this.initializeSite(newProps.site)});
        } else if (newProps.site.idsite !== this.state.idsite && newProps.site.idsite == 0){
            this.setState({idsite: 0});
        }

        if(newProps.tickets.createTicket.inProgress !== this.props.tickets.createTicket.inProgress){
            if(newProps.tickets.createTicket.success){
                let idticket = newProps.tickets.createTicket.data.ticket.idticket;
                this.getGeoLocation("dumpSite", (coords) => {
                    const data = {
                        geo_load: `POINT(${coords.longitude} ${coords.latitude})`,
                        id: idticket,
                    }
                    this.props.updateTicketSourceGeo(data);
                });
                const picture = this.state.imageToSend;
                this.props.updateTicketSourcePicture(idticket, picture);
                this.props.getNextTicketNumber(this.state.disposalPartnerSelected);
            }
        }
        if(newProps.tickets.updateTicketSourcePicture.inProgress !== this.props.tickets.updateTicketSourcePicture.inProgress){
            if(newProps.tickets.updateTicketSourcePicture.success){
                this.setState({imageToSend: null})
            }
        }
        if(newProps.tickets.updateTicketSourceGeo.inProgress !== this.props.tickets.updateTicketSourceGeo.inProgress){
            if(newProps.tickets.updateTicketSourceGeo.success){
                this.props.getTickets(this.state.idsite, this.state.idgenerator);
                this.setState({idtruckToSend: '', /*soilProfileToSend: '',*/ transporter: '-1'});
            }
        }
        if(newProps.tickets.updateTicketSourcePicture.inProgress !== this.props.tickets.updateTicketSourcePicture.inProgress){
            if(newProps.tickets.updateTicketSourcePicture.success){
                this.props.getTickets(this.state.idsite, this.state.idgenerator);
                this.setState({imageSelected: null, imageToSend: null, idtruckToSend: '', /*soilProfileToSend: '',*/ transporter: '-1'});
            }
        }
        if(newProps.tickets.nextTicketNumber.inProgress !== this.props.tickets.nextTicketNumber.inProgress){
            if(newProps.tickets.nextTicketNumber.success){
                this.setState({nextTicketNumber: newProps.tickets.nextTicketNumber.data});
            }
        }
        if(newProps.tickets.updateTicketArrivalGeo.inProgress !== this.props.tickets.updateTicketArrivalGeo.inProgress){
            if(newProps.tickets.updateTicketArrivalGeo.success){
                this.props.getTickets(this.state.idsite, this.state.idgenerator);
            }
        }

    }

    handleChange = (e) => {
        if(e.target.name == "calculatedTonnage"){
            let vals = e.target.value.split('.');
            e.target.value = vals[1] && vals[1].length > 2 ? vals[0] + '.' + vals[1].substr(0,2) : e.target.value;
        }
        if(/*e.target.name === "tareweight" || */e.target.name === "grossWeight"){
            e.target.value = Number(e.target.value)
        }
        this.setState({[e.target.name]: e.target.value})
        if(e.target.name === "tareweight" || e.target.name === "grossWeight"){
            const name = e.target.name;
            this.setState(state => {
                let grossWeight = Number(state.grossWeight);
                let tareweight = Number(state.tareweight);
                if (isNaN(state.tareweight)){
                    tareweight = 0
                } else if(tareweight > grossWeight && name === "tareweight"){
                    grossWeight = tareweight;
                }
                const calculatedTonnage = ((grossWeight - tareweight) / 2000).toFixed(2);
                return {grossWeight, tareweight, calculatedTonnage}
            })
        }
    }

    grossWeightBlur = () => {
        this.setState(state => {
            let grossWeight = Number(state.grossWeight);
            let tareweight = Number(state.tareweight);
            if(tareweight > grossWeight){
                grossWeight = tareweight;
            }
            const calculatedTonnage = ((grossWeight - tareweight) / 2000).toFixed(2);
            return {grossWeight, tareweight, calculatedTonnage}
        })
    }

    startTicket = (event) => {
        this.handleNewTicket();
    }

    disposalPartnerChanged = (e) => {
        this.props.getNextTicketNumber(e.target.value);
        if (!e.sentBySoilProfile){
            ajax.get(`${config.apiUrl}/soilprofiles/projectid/${e.target.value}`)
                .end((error, response) => {
                    if (!error && response) {
                        if (response.body.soilProfiles){
                            let data = response.body;
                            let disposalPartnerOnDuty = !!data.on_duty_partner;
                            if (this.state.siteType == "import") disposalPartnerOnDuty = false;

                            this.setState({soilProfileToSend: data.soilProfiles[0].idprojectsoil, disposalPartnerOnDuty});
                        }
                    } else {
                        console.log('There was an error fetching', error);
                    }
                }
            );
        }

         this.setState({
            disposalPartnerSelected: e.target.value,
    
        });
    }

    soilProfileChanged = (e, value) => {

        this.setState({soilProfileToSend: e.target.value});

        ajax.get(`${config.apiUrl}/projects/getProjectByIdProjectSoil/${e.target.value}/${this.state.idsite}`)
            .end((error, response) => {
                if (!error && response) {
                    console.log("Partner Response:");
                    console.log(response.body);
                    if (response.body.project[0]){
                        let disposalPartnerOnDuty = !!response.body.project[0].on_duty_partner;
                        if (this.state.siteType == "import") disposalPartnerOnDuty = false;
                        this.setState({disposalPartnerOnDuty})

                        let e = {sentBySoilProfile: true, target: {value: ''+response.body.project[0].idproject}};
                        this.disposalPartnerChanged(e);
                        console.log("State after updating disposalPartnerSelected:");
                        console.log(this.state);
                    }
                } else {
                    console.log('There was an error fetching', error);
                }
            }
        );
    }

    getTodaysTrucks = (idsite) => {
        ajax.get(`${config.apiUrl}/trucks/getTodaysTrucks/${idsite}`)
            .end((error, response) => {
                if (!error && response) {
                    console.log("getTodaysTrucks returning with idsite " + idsite + " and response", response.body);
                    this.setState({todaysTrucks: response.body.todaysTrucks}, this.sortTodaysTrucks);
                } else {
                    console.log('There was an error fetching', error);
                }
            }
        );
    }

    sortTodaysTrucks = () => {

        console.log("calling sortTodaysTrucks with trucks ", this.state.todaysTrucks)

        let emptyTrucks = this.state.todaysTrucks.filter(truck => {
            if (this.state.siteType === 'export'){
                return (truck.loadtime !== null || truck.loadtime === null && truck.dumptime === null);
            } else {
                return (truck.loadtime !== null)
            }
        })

        let fullTrucks = this.state.todaysTrucks.filter(truck => {
            if (this.state.siteType === 'export'){
                return (truck.loadtime === null && truck.dumptime !== null);
            } else {
                return (truck.loadtime === null);
            }
            
        })

        this.setState({emptyTrucks, fullTrucks});

    }

    updateTodaysTrucksHandler = (truck) => {
        this.setState({tareweight: truck.tareweight, 
            tareSetBySelectedTruck: truck.tareweight !== null, 
            idtruckToSend: truck.idtruck, 
            activeTruck: truck}, () => {
                console.log("Adding ")
                document.body.classList.add("modal-open")
            })
    }

    updateTareInDatabase = (e) => {
        let data = {
            idtruck: this.state.idtruckToSend,
            tareweight: this.state.tareweight
        }

        ajax.post(`${config.apiUrl}/trucks/update/tareweight`)
            .send(data)
            .then(res => {
                this.setState({tareWeightPopupTrigger: true});
            }).catch(e =>{
                alert('Error setting tare weight!');
                console.log(e);
            });
    }

    renderActiveTruck = () => {
        if (!this.state.idtruckToSend || !this.state.activeTruck) { return null; }
        let truck = this.state.activeTruck;
        return(
            <div className="col-12 col-lg-7 mt-4">
                <div className="card border">
                    <div className="card-body bg-gray">
                        <div className="float-left mr-4"><img className="trkimg" src={config.apiUrl + "/images/trucks/" + truck.photo} /></div>
                        <div className="mt-2">
                            {truck.plate ? <div className="fw-700">{truck.shortcode} &ndash; {truck.plate}</div> :
                                <TempTruckID 
                                number={truck.number || 0}
                                idtruck={truck.idtruck}
                                idElement="label"
                                idClasses = "m-0 pl-0 pr-0 fw-600 float-left"
                                showIcon = {true}
                                iconClasses = "lead fa fa-exclamation-triangle text-warning mr-2 fw-100"
                                showLink = {true}
                                linkElement="label"
                                linkClasses="small-3 pt-1 px-0 text-primary text-right cursor float-right"
                           
                            /> }
                            {truck.owner ? <div className="mt-1"><img className="mr-2" width="24" height="24" src={LetterAvatar(truck.owner)} />#{truck.fleetnum}</div> : null }
                            {truck.driver ? <div className="mt-1">{truck.driver}</div> : null}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    scrollToTodaysTrucks = () => {

        //console.log("Calling scrollToTodaysTrucks");
        const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
       // console.log("VW: " + vw);

        //if (vw < 576){
           //window.location.hash = "todays-trucks-dropdown-menu"
           //document.getElementById("todays-trucks-dropdown-menu").scrollIntoView();
           //console.log("Should be scrolling!");
           window.scrollTo(0,this.findPos(document.getElementById("todays-trucks-dropdown-menu")));
        //}
    }

    handleTempFileUpload = (file) => {

        let compress = new Compress();

        compress.compress(new Array(file), {
            size: 4, // the max size in MB, defaults to 2MB
            quality: 1, // the quality of the image, max is 1,
            maxWidth: 720, // the max width of the output image, defaults to 1920px
            maxHeight: 720, // the max height of the output image, defaults to 1920px
            resize: true, // defaults to true, set false if you do not want to resize the image width and height
          }).then((data) => {
            //alert("handleTempFileUpload called with idsite " + this.state.idsite);

            let img1 = data[0]
            let base64str = img1.data
            let imgExt = img1.ext
            let file = Compress.convertBase64ToFile(base64str, imgExt)

            this.setState({imgLoading: true}, () => {
                 request
                    .post(`${config.apiUrl}/trucks/updateTruckPhoto`)
                    .attach('picture', file)
                    .set('Accept', 'application/json')
                    .then(response => {
                        //alert("Truck returned");
                       this.uploadTempTruck({idtruck: null, image: response.body.filename, idsite: this.state.idsite})
                    })
                    .catch((error) => {
                        //alert("Truck error")
                        console.log(error)
                        
                    });
                }
            );
          })  
    }

    uploadTempTruck = (data) => {

        //alert("uploadTempTruck is being called!!! ");
        ajax.post(`${config.apiUrl}/trucks/add/to/todaysTrucks`)
            .send(data)
            .then(res => {
                this.getTodaysTrucks(this.props.site.idsite);

                ajax.get(`${config.apiUrl}/trucks/${res.body.truck.idtruck}`)
                    .end((error, response) => {
                        if (!error && response) {
                            this.updateTodaysTrucksHandler(response.body.truckData[0]); 
                        } else {
                            console.log('There was an error fetching', error);
                        }
                    }
                );
            }).catch(e =>{
                console.log(e);

            });
    }

    //Finds y value of given object
    findPos = (obj) => {
        var curtop = 0;
        if (obj.offsetParent) {
            do {
                curtop += obj.offsetTop;
            } while (obj = obj.offsetParent);
        return [curtop];
        }
    }

    changePartnerOnDuty = (e) => {
        e.preventDefault();

        if (this.state.disposalPartnerSelected !== -1){
            console.log("Calling changePartnerOnDuty")
            ajax.post(`${config.apiUrl}/projects/setondutypartner`)
            .send({
                idproject: this.state.disposalPartnerSelected,
                on_duty_partner: this.state.disposalPartnerOnDuty
            })
            .then(res => {
                this.setState({disposalPartnerOnDuty: !!res.body.on_duty_partner});
            }).catch(e =>{
                alert('Error setting partner on duty!');
                console.log(e);
            });
        }
        
    }

    refreshWindow = () => {
       // window.location.reload(); 
    }

    getSubmitButtonDisabled = () => {

        if (this.state.disposalPartnerSelected === -1 ) return true;
        if (this.state.soilProfileToSend === '') return true;
        if (this.state.idtruckToSend ==='') return true;
        return false;
    }

    renderNoAgreementsCard = () => {
        return(
            <div class="col-12 col-sm-8 col-md-6 col-lg-5 mx-auto ml-md-9">
              <div class="card d-block shadow-1 hover-shadow-3">
                <div class="card-body">
                  <h5 class="card-title" data-font="Permanent Marker:400">No agreements yet</h5>
                  <p class="mb-0">Start or complete an agreement to be able to create tickets.</p>
                </div>
                <div class="card-footer  text-left">
                  <a class="fs-12 fw-600" href={pageUrls.agreementsList}>View Site Agreements</a>
                </div>
              </div>
            </div> );
    }


    getGeoLocation = (event, cb) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                let currentPosition = {
                    status: 'success',
                    position: position.coords,
                }
                cb(position.coords)
                //this.setState({[event]: currentPosition});
            });
        } else {
            let currentPosition = {
                status: 'error',
                position: null,
            }
            this.setState({[event]: currentPosition.position});
        }
    }

    /*renderGeoButton(){
        return (
            <div>
                <button onClick={() => this.getGeoLocation('dumpLocation')}>Geolocation</button>
                <label>{this.state.dumpLocation ? this.state.dumpLocation.position.coords.latitude + ',' + this.state.dumpLocation.position.coords.longitude : "No location yet!"}</label>
            </div>
        )
    }*/

    renderSoilProfilesOptions = () => {

        if (this.state.siteType === "export"){
            return this.state.sourceSoil && this.state.sourceSoil.map((r,i) => {
                return (
                    <option value={r.idprojectsoil} key={i}>{r.fullprofile || r.profile}</option>
                )
            })
         } else if (this.state.siteType === "import") {
            return this.state.dumpSoil && this.state.dumpSoil.map((r,i) => {
                return (
                    <option value={r.idprojectsoil} key={i}>{r.fullprofile || r.profile}</option>
                )
            })
        }
    }
    
    /*renderTransportersOptions(){
        return this.state.transportersList && this.state.transportersList.map(t => {
            return (
                <option value={t.idtransporter} key={t.idtransporter}>{t.name}</option>
            )
        })
    }*/

    handleNewTicket = () =>{
        let hoy = new Date();
        function get24hours(date){
            date = new Date(date);
            let hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
            let minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
            let seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
            return "" + hours + ":" + minutes + ":" + seconds
        }

        let idproject = this.state.disposalPartnerSelected;
        let idprojectsoil = this.state.soilProfileToSend;
        const data = {
            idsource: this.state.idsource,
            idtruck: this.state.idtruckToSend,
            idproject,
            idprojectsoil,
            datecreated: hoy.toISOString(),
            internalnumber: this.state.nextTicketNumber,
            tonnage: this.state.tonnage,
            idtransporter: 1,
            idcreator: this.state.idgenerator,
            noPhotoAvailable: true,
            timestamp: this.state.newTicketTimestamp ? this.state.newTicketTimestamp.toISOString() : hoy.toISOString(),
            initials: this.state.newTicketInitials,
            siteType: this.state.siteType,
            onDutyPartner: this.state.disposalPartnerOnDuty
        }
        this.props.createTicket(data);

        let popupType = this.state.siteType;

        this.setState({
            idtruckToSend: '',
            disposalPartnerSelected: idproject,
            soilProfileToSend: idprojectsoil,
            tareSetBySelectedTruck: false,
            tonnage: 20,
            calculatedTonnage: 20,
            transporter: null,
            newTicketInitials: null,
            newTicketTimestamp: null,
            ["ticketFeedPopupTrigger_" + popupType]: true,
        }, () => {
            console.log("This specific setstate was called");
            if(this.state.newTicketNoPhotoAvailable){
                document.getElementById('noPhotoAvailableBlancTicket').click()
            }
            
            this.props.refreshSidebarTickets();
            document.getElementById('close-modal-new-ticket').click();

            //this.disposalPartnerChanged({target: {value: idproject}});

            //this.setState({ticketFeedPopupTrigger: true});
        })

        /*setTimeout(function() {
            window.location.reload();
        }, 1250);*/
    }

    renderSiteTypeTabs = () => {
        return(
            <ul class="nav nav-tabs mx-auto flexbox pr-0">
                  <li class="nav-item">
                      <a class="nav-link active" data-toggle="tab" onClick={()=>this.switchSiteType("export")}>Exported Materials</a>
                    </li>
                  <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" onClick={()=>this.switchSiteType("import")}>Imported Materials</a>
                </li>
            </ul>
        )
    }

    switchSiteType = (siteType) => {
        this.setState({siteType});
    }

    render = () => {
        return (<Fragment>
            <div className="modal" id={"new-ticket"} tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-long" role="document">
                    <div class="modal-content" style={{overflowY: "auto", maxHeight: "calc(100vh - 3.5rem)"}}>
                        <div class="alert alert-primary" role="alert">
                            <strong>Create a Ticket</strong>
                            <button type="button" class="close" data-dismiss="modal" id="close-modal-new-ticket" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        {this.state.sourcePartnersList.length == 0 && this.state.dumpPartnersList.length == 0 ? 
                            this.renderNoAgreementsCard()
                        : <Fragment>



                            {this.state.mixedMode ? this.renderSiteTypeTabs() : null}
                            {this.renderBlankTicket({ticketNumber: 1})}
                            {/* <CreateTicketForm /> */}
                            
                        
                            <Event eventtype="ticketGuideModal" />
                        </Fragment>}
                    </div>
                </div>
            </div>

            <ModalTruckID clearState={true} updateTruckList={() => this.getTodaysTrucks(this.props.site.idsite)} setActiveTruck={this.updateTodaysTrucksHandler} site={this.props.site} />
            <SuccessPopup
                popupId="ticketAddedSuccessExport"
                title="Load Confirmed"
                message="Ticket has been added to the Ticket Feed"
                trigger={this.state.ticketFeedPopupTrigger_export}
                callback={() => this.setState({ticketFeedPopupTrigger_export: false})}
               // useButton = {true}

                />
            <SuccessPopup
                popupId="ticketAddedSuccessImport"
                title="Load Confirmed"
                message="Ticket has been moved to Haul Today"
                trigger={this.state.ticketFeedPopupTrigger_import}
                callback={() => this.setState({ticketFeedPopupTrigger_import: false})}
               // useButton = {true}
                />

            <button type="button" style={{display: 'none'}} data-toggle="modal" data-target={"#new-ticket"} id="open-new-ticket-modal"></button>

        </Fragment>)
    }

    renderBlankTicket(ticket) {

       // console.log("Status of submit button disabled:")
        //console.log(this.getSubmitButtonDisabled() );

        const {
            dumpSite,
            sourceSite,
            generator,
            transporter,
            customer,
            idtruckToSend,
            soilProfileToSend,
        } = this.state;
        const {
            title,
            license,
            soilProfile,
        } = ticket;
        const ticketNumber = this.state.nextTicketNumber;

        if (this.state.canEditTickets === false || (this.state.canOpenImportTickets === false && (this.state.siteType === "import" && this.state.mixedMode === false))){
            return null;
        } 

        else {
            return(

            <Fragment>

                <div className="fullwidth accordion accordion-arrow-right accordion-light mb-4 mx-auto" id="new-ticket">
                    <div className="card noborder">
                        <button type="button" className="btn btn-lg alert-primary collapsed mb-3">
                            <span className="float-left">New {this.state.siteType} Ticket</span>
                        </button>
                        <div id="new-ticket-form" className={"collapse show"} data-parent="#new-ticket">
                            <div className="card noborder">
                                <div className="card-body pt-0">

                                    <form className="input-line contrast px-1">

                                        <div className="row mb-2">
                                            <div className="col-12 mt-2 mb-3">
                                                <div className="dropdown trucks col-12">
                                                    <div className="dropdown-toggle no-caret text-left flexbox" 
                                                    data-toggle="dropdown" id="todays-trucks-dropdown-menu"
                                                    onClick={ () => this.scrollToTodaysTrucks()}>
                                                        Today's Trucks<i className="fa fa-caret-down" aria-hidden="true"></i>
                                                    </div>
                                                    <div className="dropdown-menu">

                                                        <a class="dropdown-item">
                                                            <span class="mb-0">Add a Truck</span>
                                                            <div class="mt-1 mr-4 flexbox">
                                                                <a class="col-6 btn btn-xs btn-outline-primary px-0" data-toggle="modal" href="#modal-addtruck"
                                                                onClick={() => this.setState({toggle: !this.state.toggle})} >License Plate<i class="fa fa-eye fs-12 ml-2" aria-hidden="true"></i></a>

                                                                <input type="file" name="image" className="custom-file-input ml-2" id="customFileTempTruck" accept=".png, .jpg, .jpeg" capture="environment"
                                                                onChange={(e) => {
                                                                    this.handleTempFileUpload(e.target.files[0]);
                                                                    /*this.setState({image: e.target.files[0]});*/
                                                                    }}
                                                                />
                                                                <label className={"col-6 btn btn-xs btn-outline-secondary px-0" + (this.state.hasInvalidImage ? " text-danger" : "")} 
                                                                for="customFileTempTruck">
                                                                    <i class="fa fa-eye-slash fs-12 mr-2" aria-hidden="true"></i>ID Not Visible
                                                                </label>
                                                            </div>
                                                        </a>
                                                        <div class="dropdown-divider mt-5 mb-0"></div>
                                                        {this.renderTodaysTrucks()}
                                                    </div>
                                                </div>
                                            </div>

                                            {this.renderActiveTruck()}

                                        </div>
                                        <div className="row mb-2">
                                            {this.renderExportSites()}
                                            <div className="col-12 mt-2 mb-3">
                                                <select id="soilProfileToSend" className="form-control form-control-lg" value={soilProfileToSend} name="soilProfileToSend" onChange={this.soilProfileChanged}>
                                                    <option value={''} id="soilProfileToSendDefault" disabled selected>Soil Profile</option>
                                                    {this.renderSoilProfilesOptions()}
                                                </select>
                                            </div> 
                                            {this.renderImportSites()}

                                            <div className="col-12 mt-2">
                                            {this.state.siteType == "export" ? <Fragment>
                                                <div className="switch" onClick={(e) => this.changePartnerOnDuty(e)}>
                                                    <input type="checkbox" 
                                                      name="optingIn" 
                                                      id="optingIn" 
                                                      className="switch-input" 
                                                      checked={this.state.disposalPartnerOnDuty}
                                                      disabled={this.state.disposalPartnerSelected === -1}
                                                    />
                                                    <label className="switch-label col-12 px-1">There is someone on duty at my partner's site.</label>
                                                </div>

                                                {this.state.disposalPartnerSelected !== -1 ?
                                                    <label className="px-1">
                                                    
                                                        {this.state.disposalPartnerOnDuty == true ? 
                                                            "Your disposal partner can close tickets as loads are dumped"
                                                        :
                                                            "Tickets will close automatically as loads are dumped"}
                                                
                                                    </label>
                                                : null }
                                            </Fragment>
                                            : 
                                            <label className="px-1">All tickets created at the import site will be closed automatically</label>
                                            }

                                            </div>
                                    
                                            <div className="col-12 mt-2">
                                                <label className="col-6 col-lg-8 fw-400 fs2m float-left px-1">Agreement Ticket</label>
                                                <label className="badge-pale badge-info fw-400 fs2m float-right text-right px-2 py-0">{ticketNumber === 0 ? 'N/A' : '#'+ticketNumber}</label>
                                            </div> 

                                            <div className="col-12 mt-2">
                                                <label className="col-5 float-left fw-400 fs2m px-1">Tonnage</label>
                                                <label className="btn-link fw-400 fs2m float-right pr-1" data-toggle="collapse" href="#open-calculator1"><u>{this.state.tonnage}</u></label>
                                            </div>

                                            <div className="col-md-12 mt-2">
                                                <div className="accordion accordion-light" id="calc1">
                                                    <div className="card noborder">
                                                        <div id="open-calculator1" className="collapse" data-parent="#calc1">
                                                            <div className="card-body bg-gray input-line p-3 mb-2">

                                                                <div className="col-12 mb-1 mt-3">
                                                                    <div className="input-group contrast">
                                                                        <label className="col-8 pt-3 pr-6 pl-0 float-left">Gross Weight (lbs)</label>
                                                                        <input className="form-control form-control-lg text-right bg-secondary p-3" name="grossWeight" onChange={this.handleChange} type="text" placeholder="0" value={this.state.grossWeight} />
                                                                    </div>
                                                                </div>

                                                                <div className="col-12 mb-1">
                                                                    <div className="input-group flexbox">
                                                                        <label className="pt-3 pr-5">Empty Weight</label>
                                                                        {this.renderTareInput()}
                                                                    </div>
                                                                </div>

                                                                <div className="col-12 mb-2">
                                                                    <div className="input-group flexbox">
                                                                        <label className="pt-3 pr-5">Calculated Tonnage</label>
                                                                        <label className="mt-3 py-1 px-2 fs2m text-right alert-success">{this.state.calculatedTonnage}</label>
                                                                    </div>
                                                                    <div className="mt-4 text-right">
                                                                       <button className="btn btn-block btn-secondary btn-sm" type="button" data-toggle="collapse" href="#open-calculator1" onClick={() => this.setState({tonnage: this.state.calculatedTonnage})}>Update Tonnage</button>
                                                            
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                        </div>

                                    </form>

                                    <div className="mt-4 text-center">
                                        <small><em>By clicking, you are confirming this load and signing this ticket.</em></small>
                                    </div>

                                    <div className="mt-3 mb-6">
                                        <button className="col-12 btn btn-lg mb-7 py-4 btn-primary" style={{color: 'white'}}
                                            onClick={this.startTicket}
                                            disabled={this.getSubmitButtonDisabled()}
                                                >Start Ticket <i className="fa fa-paper-plane pl-1" aria-hidden="true"></i>
                                            </button>
                                    </div>

                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </Fragment>
            )
        }   
    }

    renderNoTruckYetCard = () => {
        return(
            <a className="dropdown-item" href="#">
                <div className="mr-4 mb-4">
                    <h5 className="card-title ml-0 px-0 empty-card-heading">No Trucks yet</h5>
                    <p className="small fw-100 mb-0">Once you add a truck, it'll be here all day.</p>
                </div>
            </a>)
    }

    renderTodaysTrucks = () => {

        if (this.state.todaysTrucks.length === 0) return this.renderNoTruckYetCard();

        else if (this.state.siteType === "import") return this.renderImportTodaysTrucks();
        else if (this.state.siteType === "export") return this.renderExportTodaysTrucks(); 
    }

    renderImportTodaysTrucks = () => {
        return (<Fragment>

            <a class="dropdown-item mt-3 mb-1">Loaded Trucks ({this.state.fullTrucks.length})</a>
            {this.state.fullTrucks.map(truck => {
                return this.renderTodaysTrucksItem(truck);
            })}
            {this.state.fullTrucks.length == 0 ? <p class="small-3 fw-100 ml-3 mt-1"><em>All trucks are returning to the load site.</em></p> : null}
            <div class="dropdown-divider mt-0 mb-0"></div>
            <a class="dropdown-item mt-0 mb-0">Empty Trucks ({this.state.emptyTrucks.length})</a>
            <div class="dropdown-divider mt-0 mb-0"></div>
              
        </Fragment>)
    }

    renderExportTodaysTrucks = () => {
        return (<Fragment>

            <a class="dropdown-item mt-3 mb-1">Empty Trucks ({this.state.emptyTrucks.length})</a>
            {this.state.emptyTrucks.map(truck => {
                return this.renderTodaysTrucksItem(truck);
            })}
            {this.state.emptyTrucks.length == 0 ? <p class="small-3 fw-100 ml-3 mt-1"><em>All trucks are headed to the dump.</em></p> : null}
            <div class="dropdown-divider mt-0 mb-0"></div>
            <a class="dropdown-item mt-0 mb-0">Loaded Trucks ({this.state.fullTrucks.length})</a>
            <div class="dropdown-divider mt-0 mb-0"></div>
              
        </Fragment>)
    }

    renderTodaysTrucksItem = (truck) => {

        return(
            <a className="dropdown-item" href="#" onClick={() => this.updateTodaysTrucksHandler(truck) } data-toggle="dropdown">
                <div className="float-left mr-4 mb-4"><img className="trkimg" src={config.apiUrl + "/images/trucks/" + truck.photo} /></div>
                <div className="mt-2">
                    {truck.plate ? 
                        <div className="fw-700">{truck.shortcode} &ndash; {truck.plate}</div>
                        : <TempTruckID 
                            number={truck.number || 0}
                            idtruck={truck.idtruck}
                            idElement="label"
                            idClasses = "m-0 pl-0 pr-0 fw-600 float-left"
                            showIcon = {true}
                            iconClasses = "lead fa fa-exclamation-triangle text-warning mr-2 fw-100"
                            showLink = {false}
                        />}
                    {truck.owner ? <div className="mt-1"><img className="mr-2" style={{borderRadius: "0% !important"}} width="24" height="24" src={LetterAvatar(truck.owner)}/>#{truck.fleetnum}</div> : null }
                    {truck.driver ? <div className="mt-1">{truck.driver}</div> : null }
                    {this.renderETA(truck)}
                </div>
            </a>
        )
    }

    renderETA = (truck) => {
        let timeToUse = "loadtime";
        if (truck.loadtime === null && truck.dumptime === null) return null;
        else if (this.state.siteType === "import"){
            timeToUse = "dumptime";
        }

        let diffInMinutes = ((new Date(truck[timeToUse])).getTime() - (new Date().getTime()))/1000;
        diffInMinutes = Math.round(diffInMinutes /= 60);

        if (diffInMinutes > 0) return (<label class="small-3 text-danger float-right mt-1 pt-1 fw-700"><em>{diffInMinutes}-min ETA</em></label>);
        else if (diffInMinutes <= 0) return (<label class="small-3 text-success float-right mt-1 pt-1 fw-700"><em>Arriving...</em></label>);
    }

    renderExportSites = () => {
        if (this.state.siteType === "import"){
           return this.renderSitesDropdown("Export");
        } else {
            return null;
        }
    }

    renderImportSites = () => {
        if (this.state.siteType === "export"){
            return this.renderSitesDropdown("Import")
        } else {
            return null;
        }
    }

    renderSitesDropdown = (siteType) => {
       // console.log("Calling renderSitesDropdown with partner selected value of " + this.state.disposalPartnerSelected)
         return(
            <div className="col-12 mt-2">
                <select className="form-control form-control-lg" id="disposalPartnerSelected" name="disposalPartnerSelected" value={this.state.disposalPartnerSelected} onChange={this.disposalPartnerChanged}>
                    <option value={-1} id="disposalPartnerSelectedDefault" disabled selected>{siteType} Site</option>
                    {this.renderSitesDropdownOptions()}
                </select>
            </div> )
    }

    renderSitesDropdownOptions = () => {

        if (this.state.siteType === "export"){
            return this.state.sourcePartnersList.map((partner, index) => {
                return (
                    <option value={partner.idproject} key={index}>{partner.name}</option>
                )
            })
        } else if (this.state.siteType === "import") {
            return this.state.dumpPartnersList.map((partner, index) => {
                return (
                    <option value={partner.idproject} key={index}>{partner.name}</option>
                )
            })
        }   
    }

    renderTareInput = () => {
        if (this.state.idtruckToSend === ''){
            return(<label className="pt-3 text-right">No truck selected</label>)
        } else if (!this.state.tareSetBySelectedTruck){
            return (
               <input className="form-control form-control-lg text-right bg-secondary p-3" name="tareweight" onBlur={this.updateTareInDatabase} onChange={this.handleChange} type="text" placeholder="0" value={this.state.tareweight} />
            )
        } else {
            return (
                <label className="pt-3 text-right">{this.state.tareweight} lbs</label>
            )
        }
    }
}

function mapStateToProps(state) {
    return {
      tickets: state.tickets,
    };
  }
  
  function mapDispatchToProps(dispatch) {
    return {
        getTickets: (idsite, idgenerator) => dispatch(getActiveTickets(idsite, idgenerator)),
      createTicket: (data) => dispatch(createTicket(data)),
      updateTicketSourceGeo: (data) => dispatch(updateTicketSourceGeo(data)),
      updateTicketSourcePicture: (idticket, picture) => dispatch(updateTicketSourcePicture(idticket, picture)),
      updateTicketArrivalGeo: (data) => dispatch(updateTicketArrivalGeo(data)),
      updateTicketArrivalPicture: (idticket, picture, signature, tons) => dispatch(updateTicketArrivalPicture(idticket, picture, signature, tons)),
      getNextTicketNumber: (id) => dispatch(getNextTicketNumber(id)),
    };
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(ActiveTickets)
