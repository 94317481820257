import React, {Fragment, Component} from 'react';
import jwtDecode from 'jwt-decode';
import SharedSiteButton from './SharedSiteButton';
import ModalEditTeam from '../../DumpProject/ModalEditTeam'

export default function SiteDisplayAgreements ({site, refreshSites, showSharedSite, switchSidePanel}) {

	const link = (pageURL) => {
		localStorage.setItem('site', JSON.stringify(site));
		window.location.href = pageURL;
	}

	const switchRightHandPanel = (displayState, idproject) => {
		switchSidePanel(displayState, {site, idproject});
	}

	const renderSoils = (soils) => {
		if (soils.length == 0) return "No soils yet"
		return <Fragment>
			{soils.map(
				({profile, soildescription}, index, soils) => {
					if (index == soils.length - 1){
						return <span>{profile}</span>
					} else {
						return <span>{profile}, </span>
					}
				}
			)}
		</Fragment>
	}

	const renderPartnerName = (siteName, customername) => {
		if (siteName != null){
			return siteName;
		} else if (customername != ""){
			return customername;
		} else {
			return "Pending";
		}
	}

	const renderAgreementRow = ({idproject, profile, verb, siteName, hauledTodayTickets, tickets, siteShared, partnersite, customername, soils}, key) => {
		return(
			<div className="row border-bottom mb-2 pr-4" key={key}>
				<div className="col-10 text-dark strong">
				  <p className="mb-1">{renderPartnerName(siteName, customername)} <span className="my-1 small text-light text-right">— {renderSoils(soils)}</span></p>
				</div>
				<div className="col-1 small strong pr-0 ml-auto">
				  <a className="text-light" href="#">
				   {siteShared ?  <SharedSiteButton site={partnersite} showSharedSite={showSharedSite} /> : null }
				  </a>
				</div>
				{/*<div className="col-3">
				  <p><a href="truck-requests.html"><span className="badge badge-pale badge-info strong">5 Requested</span></a></p>
				</div>
				<div className="col-3">
				  <p><a href="truck-lineup.html"><span className="badge badge-pale badge-primary strong">4 Committed</span></a></p>
				</div>*/}
				<div className="col-3">
				  <p>
				  	<a href="#" onClick={() => switchRightHandPanel("activeTickets", idproject)}>
				  		<span className={"badge badge-pale strong " + (tickets > 0 ? "badge-success" : "badge-light")}>
				  			{tickets} Active Loads
			  			</span>
			  		</a>
		  		</p>
				</div>
				<div className="col-3">
				  	<p>
					  	<a href="#" onClick={() => switchRightHandPanel("hauledToday", idproject)}>
					  		<span className={"badge badge-pale strong " + (hauledTodayTickets > 0 ? "badge-secondary" : "badge-light")}>
					  			{hauledTodayTickets} Hauled Today
				  			</span>
				  		</a>
		  			</p>
				</div>
			</div>
		)
	}

	return(<Fragment>
			
		{
			site.projectsList.map((agreement, key) => {
				return renderAgreementRow(agreement, key);
			})
		}

		<div className="btn-group dropup float-left">
	    	<span className="dropdown-toggle no-caret small" data-toggle="dropdown"><i className="ti ti-settings px-1" aria-hidden="true"></i>Site Details</span>
			<div className="dropdown-menu">
				<a className="dropdown-item" href="#" onClick={() => link("site-agreements")}>{site.idprofile == 3 || site.idprofile == 4 ? "View" : "Manage" } Agreements</a>
				<a className="dropdown-item" href="#" data-toggle="modal" data-target={"#edit-team-"+site.idsite}>{site.idprofile == 3 || site.idprofile == 4 ? "View" : "Manage" } Site Team</a>
				{site.idprofile != 3 ? <a className="dropdown-item" href="#" onClick={() => switchRightHandPanel("archivedTickets")}>View Archive</a> : null}
				{site.idprofile != 3 && site.idprofile !=4 && site.pendingCount != site.projectsList.length ? <a className="dropdown-item" data-toggle="modal" data-target="#add-material" onClick={() => switchRightHandPanel("same")}>Add Material to an Agreement</a> : null}

			</div>
	    </div>

		<div className="px-0 pb-3 float-right">    
	        <a className="btn btn-xs btn-outline-success mt-1 mr-2 mb-2 py-0" href="#" onClick={() => switchRightHandPanel("activeTickets", 0)}>All Active Loads</a> 
	        <a className="btn btn-xs btn-outline-primary mt-1 mr-2 mb-2 py-0" href="#" onClick={() => switchRightHandPanel("createticket")}>New Ticket</a>
	  	</div>
		
		<ModalEditTeam idsite={site.idsite} name={site.name} refreshSites={refreshSites} />

	</Fragment>)

}