import {
    GET_USER_REQUESTED,
    GET_USER_REJECTED,
    GET_USER_FULFILLED,
} from './types';

import request from 'superagent';
import config from '../constants/config';

export function getUser(id){
    return dispatch => {
        dispatch(getUserRequestedAction());
        request
        .get(`${config.apiUrl}/users/id/${id}`)
        .set('Accept', 'application/json')
        .then(response => {
            dispatch(getUserFulfilledAction(response.body))
        })
        .catch((error) => {
            dispatch(getUserRejectedAction(error));
        });
    }
}

function getUserRequestedAction() {
    return {
        type: GET_USER_REQUESTED,
        inProgress: true
    };
}
  
function getUserRejectedAction(error) {
    return {
        type: GET_USER_REJECTED,
        inProgress: false,
        success: false,
        error
    }
}
  
function getUserFulfilledAction(data) {
    return {
        type: GET_USER_FULFILLED,
        data,
        success: true,
        inProgress: false,
        error: false
    };
}