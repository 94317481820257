import React, {Component} from 'react';

export default class ModalUpdatedUsers extends Component {
    constructor(props){
        super(props);
    }

    render(){
        return (
            <div className="modal modal-top fade" id="modal-update" tabIndex="-1">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">KK River &#8212; Falk</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="alert alert-success mt-2" role="alert">
                                <strong>Today's user access has been updated:</strong><br />
                            </div>
                            <ul className="lead list-unstyled col-lg-9 ml-4">
                                <li className="flexbox mb-2">
                                    <a className="col-lg-4"><i className="ti-check text-success mr-2"></i><strong>caseyh@earth.com</strong></a>
                                    <a>Team Member</a>
                                </li>
                                <li className="flexbox mb-2">
                                    <a className="col-lg-4"><i className="ti-check text-success mr-2"></i><strong>robkubota@another.com</strong></a>
                                    <a>Team Member</a>
                                </li>
                                <li className="flexbox mb-2">
                                    <a className="col-lg-4"><i className="ti-check text-success mr-2"></i><strong>deerej@other.com</strong></a>
                                    <a>View-Only</a>
                                </li>
                                <li className="flexbox mb-2">
                                    <a className="col-lg-4"><i className="ti-check text-success mr-2"></i><strong>jimk@earth.com</strong></a>
                                    <a>Project Leader</a>
                                </li>
                            </ul>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-dismiss="modal">OK</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}