import React, {Fragment} from 'react';
import H from "@here/maps-api-for-javascript";
import polyUtil from 'polyline-encoded';
import poly from '../../constants/polyline'
import Dialog from './Dialog';
import NewSite from './NewSiteDialog';
import './homepage.css'

export default class MapContainer extends React.Component {

  // Create the parameters for the routing request:
  routeRequestParams = {
    routingMode: "fast",
    transportMode: "truck",
    origin: "42.84695192878384,-88.75094261558499",
    destination: "43.04337020492091,-88.18267201228066", // Newport
    return: "polyline,travelSummary",
    units: "imperial",
    spans: "truckAttributes",
  };
  routes = new H.map.Group();

  constructor(props) {
    super(props);
    // the reference to the container
    this.ref = React.createRef();
    // reference to the map
    this.map = null;
    this.ui = null;
    this.sharedSiteMarker = null;

     const defaultCenter = {
        lat: 43.038902, lng: -87.906471,
      }

    this.state = {
        tempMarker: null,
        showNewSiteModal: false,
        showInfoWindow: false,
        zoomAmount: 13,

        selectedSite: null,
        activeMarker: null,
        location: JSON.parse(JSON.stringify(defaultCenter)),
        defaultCenter,
        clickingOnMarker: false,

        mapInstance: null,
        mapMarkers: [],
        activeBubble: null,

        groupSitesMarkerIcon: '<svg width="24" height="24" ' +
          'xmlns="http://www.w3.org/2000/svg">' +
          '<rect stroke="white" fill="#1b468d" x="1" y="1" width="22" ' +
          'height="22" /><text x="12" y="18" font-size="12pt" ' +
          'font-family="Arial" font-weight="bold" text-anchor="middle" ' +
          'fill="white">G</text></svg>',

        regularSiteMarkerIcon: '<svg width="24" height="24" ' +
          'xmlns="http://www.w3.org/2000/svg">' +
          '<rect stroke="white" fill="#1b468d" x="1" y="1" width="22" ' +
          'height="22" /><text x="12" y="18" font-size="12pt" ' +
          'font-family="Arial" font-weight="bold" text-anchor="middle" ' +
          'fill="white"></text></svg>',

        sharedSiteMarkerIcon: '<svg width="36" height="36" xmlns="http://www.w3.org/2000/svg">'+
        '<rect stroke="white" fill="#025e25" x="18" y="-12" width="22" height="22" transform="rotate(45)"/></svg>',
    }
  }

  calculateRoute = (router, params, style, routes, map) => {
    var style2 = {
      strokeColor: "rgba(128, 255, 255, 0.7)",
      lineWidth: 8,
    }

    router.calculateRoute(
      params,
      function (result) {
        console.log("Router calculate route result:", result);
        //addRouteShapeToMap(style, result.routes[0]);

        let totalDuration = 0;
        let totalLength = 0;
        let totalBase = 0;

        result.routes[0].sections.forEach((section) => {

          totalDuration += section.travelSummary.duration;
          totalLength += section.travelSummary.length;
          totalBase += section.travelSummary.baseDuration;

          // decode LineString from the flexible polyline
          let linestring = H.geo.LineString.fromFlexiblePolyline(section.polyline);
          // Create a polyline to display the route:
          let polyline = new H.map.Polyline(linestring, {
            style: style,
          });
          let polyencoded = 'BGw-p6xC5pq6nFE1M8yE4DwqBUnB3vEwCrjIU7zBUrxBUzwCUrlCUrqCUrdUjhBAvgBoB7gDoBjlE8B_pFoB7gDAnVU7kBoVAwRAsJUsOUkNA0hC8B8kBoBsOUwlBU84BoB4XU0eUkrBoBkwBoB0KA4DAoaAofUkrBoB4SgFkNUokBwCgKUoQU0FA4SUokBoBwMA0PU0eoBwvBoB8GAsiBU8QUsiBoB4IA4hBU4uC8B0yBUgKA8foBkXUoVU4XAoLAkpC8B0FA4SUopBU0ZU8VUosC8BsdoBsYUoQUoQU8uBUsxBoB8uBoB8VUg3BoBoVUsOU0FAkhBU4cUwbU8sC8BopBU8LAgKAkXUsYUsTU8VUo2CoBoLAofUoaU4XUosCoB0UA4SA0KA8LA8VU4wBAofUsYAs-CUkhBU0KUgZoBkNU8QUsYU8kBoBokBoB0PUopBU8kBU0UUsTUwWUkSAkSU8aU0ZoBgtBoBwRAkDAsTUsTAgFA4DA0ZU4SAsJAwgBU8aAouBU0ZA8GAgFAoVU4SU0PA0UUgFAwCAwMAoQUgKAwWAkIA4DAgeUkIAA_EArJAzeAzKA_JA3IAjSA_JA_EAzFAvHA3IA7QAvHAvMA_OA_OAjNAjhBA7VArJArOA7QTv-BAjIU3mBA_nBAzPAjcTvMT3STnVAjNArEA7L4DnLwC3IwCjIwCrEwC7B4DjDsdUoGAoGAs2B8BsnBU4NUwMT0UUAnLUriBUn2CAnQUnaoBzyBAriBnB7pBvCzUzFjS7BvRjD_iB_TvqGjD_iBrE7uBjDr2BoB_iBoB3SoB7LgFvgB4I3mBsJ_d8Lze0PzoB8LzeoQ_nB8L3cwM7asOzZkNrT4NjSoQrT4SjSsTnQkSjNoQ_J8Q3IwR7G4NrEoazF0ZvCokBoB4rBoBkSA41BUkSU4uCgFwgB8BofU8f7BwgBrEsYzF4mBzKkwBzPwWvHkXvH4XnG8a3DwgBnB4zCwCwbUsxB8BoQU0FUgKA4cU8iCoBwvBUgUUstD4DwqB8BkrBgF8pBwHkcoG8pB0K8V0FgKkD4mB0Kwb0Fkc4D8LU8aoBsqCoB4IAwlBU4yF4DsOA0eAkcoBwlBnBssB7BoBAgZvCgKnB0jBzF0K7Bwb3D4XjD8Q7B46BrE4mBA89BgF8GUsO4D8aoGs2B8L8zBwM4I8BoLkDk1B8LsJwCgeoGw-BsOoL8BkrB0KkmB4IsYwH0KkDgKoB0ekDkc8BopB0F8pB8BwgB0FoaA8sCAoxCUsiBnBkNA0e7B8pBT4nDvC4XAokBoBgUToLAsOA84BjDgPUwWnB8QT8f8BgZkDsiB0F4SgF0UoG0U8G8QgF0ZgK4coLgtBoQkDoBoG8BgK4DsEoBwgB4NgKkDwMsEopBsOkS8GokBoLopBkNwbwHkhBkIwWsEsJoBkckDkuCoLkN8B0UkDwWkDkS8BgP8B8LoBgKoBoawC4hBgFghCkI8pB0F8LoBkIoB8LoBsJUgP8BsToB4NUsOTgZjDkcrE4c7G4IvCoLrEwHjD0ezPkX7L0UvMoazPwMrJwHzF8V_TwbzZ8QnQsOrO0KrJkNvM0KzKwHvHkpCrlCsJ3IsY_T4c3XoQvM4S3NkczUkIzF0KjI8uBriB0PvM8QnLgFjDwM3I0FrE0F3DsJ7GsTjNgyB_iBwb_TwW7QkXvWwWzZ0U3c4Sze0PnfsTvvB0FvRoB_EwCjIwM7QkInQ0K_Y0KrYgFjI0F3IwH_JkIjIgKjI0KnG8L_EsJnB8QvCof_E8VA4cTkSAgZToVT4rBnBsJAvCzPjDvR_EvbzFvgBrEzZ7B3IT3NA7GA7VAnGA3IAvHA3DU7QU_OU3D8BzUA7B8B_J8B3N4DvbgFzZ4DjS8BvMU3DsE3mBU_EoBzPoBjhBU_OTjcAnQTrdT7zBTvbTzrCTzZTnkBA3XAjNT3rBA_dnB7nCArT-XT';
          // Add the route polyline and the two markers to the map:
          let decodedPL = poly.decode(polyencoded);
          console.log(decodedPL)
          var lineString2 = new H.geo.LineString();
          decodedPL.polyline.forEach((geo) => {
            lineString2.pushPoint({lat:geo[0], lng:geo[1]});
          });
          let polyline2 = new H.map.Polyline(lineString2, { style: { lineWidth: 10 }});
          console.log(polyline2)
          // let linestring2 = H.geo.LineString.fromFlexiblePolyline(decodedPL.polyline);
          // console.log(linestring2)
          //let polyline2 = new H.map.Polyline(decodedPL.polyline, { style: { lineWidth: 10 }});
          //console.log(polyline2)
          //map.addObjects([polyline]);
          map.addObjects([polyline2]);

          // Set the map's viewport to make the whole route visible:
          //map.getViewModel().setLookAtData({bounds: polyline.getBoundingBox()});
        });

        console.log("Total base duration:", totalBase)
        console.log("Total calculated duration:", totalDuration);
        console.log("Total calculated distance:", totalLength);

      },
      console.error
    );
  }

  componentDidMount() {
    let {zoomAmount, defaultCenter} = this.state;

    if (!this.map) {
      // instantiate a platform, default layers and a map as usual
      const platform = new H.service.Platform({
        apikey: 'yDjdRgZxJsqz5cQZnYB_QDWIgiTcq5gL4MtZKl4R6jE'
      });

      console.log("H.service:", H.service);

      const layers = platform.createDefaultLayers();
      const map = new H.Map(
        this.ref.current,
        layers.vector.normal.map,
        {
          pixelRatio: window.devicePixelRatio,
          center: defaultCenter,
          zoom: zoomAmount,
        },
      );
      const ui = H.ui.UI.createDefault(map, layers);
      //const router = platform.getRoutingService(null, 8);

      var mapEvents = new H.mapevents.MapEvents(map);

      // Add event listeners:
      map.addEventListener('tap', this.onClickMap);
      map.addEventListener('mapviewchange', this.onZoomMap);
      const markers = new H.map.Group();

      // add the interactive behaviour to the map
      new H.mapevents.Behavior(mapEvents);
      // The blue route showing a simple truck route
     /* this.calculateRoute(router, this.routeRequestParams, {
        strokeColor: "rgba(0, 128, 255, 0.7)",
        lineWidth: 4,
      }, this.routes, map);*/
     

      this.map = map;
      this.ui = ui;
      this.markers = markers;

      this.map.addObject(this.markers);

      console.log("UI:", ui);
      console.log("this.UI:", this.ui);
    }
  }

  async componentWillReceiveProps(newProps) {

    let {mapMarkers} = this.state;
    let totalSites = newProps.mySitesList.length + newProps.hcGroupSites.length;

      console.log("New call to componentWillReceiveProps independent of goToAdSharedSite, shared site is ",newProps.adSharedSite);

      if (this.props.adSharedSite == null && newProps.adSharedSite == null) {
          console.log("Returning from componentWillRecieveProps without calling goToAdSharedSite")
      } else if (this.props.adSharedSite == null && newProps.adSharedSite != null){
          console.log("Passed a shared site when previous shared site was null; calling goToAdSharedSite");
          this.goToAdSharedSite(newProps.adSharedSite);
          totalSites++;

      } else if (this.props.adSharedSite != null && newProps.adSharedSite != null){
          if (this.props.adSharedSite.location_x != newProps.adSharedSite.location_x || this.props.adSharedSite.location_y != newProps.adSharedSite.location_y){
              console.log("New shared site different from old shared site; calling goToAdSharedSite");
              this.goToAdSharedSite(newProps.adSharedSite)
              totalSites++;

          } else {
              console.log("Passed same prop as last time; not calling goToAdSharedSite");
              console.log("shared newProps",newProps.adSharedSite);
              console.log("shared old props", this.props.adSharedSite)
          }
     
      } else {
          console.log("Something else happened with the shared site. Here are the params:");
          console.log("shared newProps",newProps.adSharedSite);
          console.log("shared old props", this.props.adSharedSite)
      }

    if ((JSON.stringify(this.props.mySitesList) != JSON.stringify(newProps.mySitesList)) || (mapMarkers.length < totalSites)){
      await this.renderMySitesMarker(newProps.mySitesList);
    }

    if (JSON.stringify(this.props.hcGroupSites) != JSON.stringify(newProps.hcGroupSites) || (mapMarkers.length < totalSites)){
      this.renderHCGroupSitesMarker(newProps.hcGroupSites);
    }

     let boundingBox = this.markers.getBoundingBox();
    if (boundingBox !== null) this.map.setCenter(boundingBox.getCenter())
  }

  async goToAdSharedSite(site){

    if (this.sharedSiteMarker){
      this.markers.removeObject(this.sharedSiteMarker);
      this.sharedSiteMarker = null;
    }

    this.sharedSiteMarker = await this.addMarkerToMap(site, "sharedSiteMarkerIcon");

    let location = {
      lat: site.location_x,
      lng: site.location_y
    };

    setTimeout(() => {
      this.map.setCenter(location);
    }, 300);
  }

  onClickMap = (evt) => {
   
    console.log("onClickMap")

    let {locked} = this.props;
    let {clickingOnMarker, tempMarker} = this.state;
    console.log("onClickMap", "Map locked?", locked);
    if (!locked && !clickingOnMarker && !tempMarker){

      if (evt.currentPointer){
        let location = this.map.screenToGeo(evt.currentPointer.viewportX, evt.currentPointer.viewportY);
        console.log("Calculated location:", location);

        let tempMarker = this.addMarkerToMap({name: "New Site", idsite: 0, location_x: location.lat, location_y: location.lng}, "regularSiteMarkerIcon", true)

        this.setState({tempMarker, location}, () => {
          setTimeout(() => {
              this.setState({showNewSiteModal: true});
          }, 500)
        })
      }
    }
  }

  onZoomMap = async (evt) => {
    let newZoom = null;
    let oldZoom = null;

    if (evt.newValue && evt.newValue.lookAt){
      newZoom = (evt.newValue.lookAt.zoom).toFixed(1);
      oldZoom = (evt.oldValue.lookAt.zoom).toFixed(1);

      if (newZoom != oldZoom && oldZoom != 0){
        console.log("Map zoomed with evt",evt);
        console.log("Map was zoomed", "newValue: ", newZoom, "oldValue: ", oldZoom);

        this.removeMapMarkers();

        let markerSize1 = this.markerSize(24, 24, newZoom);
        let markerSize2 = this.markerSize(22, 22, newZoom);
        let markerSize3 = this.markerSize(36, 36, newZoom)
        let textLocation = this.markerSize(-12, 18, newZoom);

        let groupSitesMarkerIcon = '<svg width="'+markerSize1.width+'" height="'+markerSize1.height+'" ' +
          'xmlns="http://www.w3.org/2000/svg">' +
          '<rect stroke="white" fill="#1b468d" x="1" y="1" width="'+markerSize2.width+'" ' +
          'height="'+markerSize2.height+'" /><text x="'+markerSize2.width/2+'" y="'+((markerSize2.height/2)*1.5) +'" font-size="'+markerSize1/2+'pt" ' +
          'font-family="Arial" font-weight="bold" text-anchor="middle" ' +
          'fill="white">G</text></svg>'

        let regularSiteMarkerIcon = '<svg width="'+markerSize1.width+'" height="'+markerSize1.height+'" ' +
          'xmlns="http://www.w3.org/2000/svg">' +
          '<rect stroke="white" fill="#1b468d" x="1" y="1" width="'+markerSize2.width+'" ' +
          'height="'+markerSize2.height+'" /><text x="12" y="18" font-size="12pt" ' +
          'font-family="Arial" font-weight="bold" text-anchor="middle" ' +
          'fill="white"></text></svg>'

        let sharedSiteMarkerIcon = '<svg width="'+markerSize3.width+'" height="'+markerSize3.height+'" xmlns="http://www.w3.org/2000/svg">'+
        '<rect stroke="white" fill="#025e25" x="'+textLocation.height+'" y="'+textLocation.width+'" width="'+markerSize2.width+'" height="'+markerSize2.height+'" transform="rotate(45)"/></svg>';

        this.setState({groupSitesMarkerIcon, regularSiteMarkerIcon, sharedSiteMarkerIcon})

        await this.renderMySitesMarker();
        this.renderHCGroupSitesMarker();

        if (this.props.adSharedSite){
          this.sharedSiteMarker = this.addMarkerToMap(this.props.adSharedSite, "sharedSiteMarkerIcon");
        }
      }
    }
  }

  markerSize = (baseWidth, baseHeight, zoom) => {

    if (zoom >= 12.8 && zoom <= 13.2) {
        return {width: baseWidth, height: baseHeight};
    } else {
        let factor = 1;
        if (zoom > 13){
          factor = (zoom - 13) * 1.1;
        } else if (zoom < 13){
          factor = (1 / ((13 - zoom) * 2));
        }

        let width = baseWidth * factor;
        let height = baseHeight * factor;

        if (width > (baseWidth * 1.5)) 
            width = baseWidth * 1.5;
        if (height > (baseHeight * 1.5))
            height = baseHeight * 1.5;

        return { width, height }
    }    
  }

  renderMySitesMarker = async (sitesList) => {
    if (!sitesList){
      sitesList = this.props.mySitesList;
    }

    for (var i = 0; i < sitesList.length; i++){
      let site = sitesList[i];
      await this.addMarkerToMap(site, "regularSiteMarkerIcon", false)    
    }


    return;
  }

  renderHCGroupSitesMarker = (sitesList) => {
    if (!sitesList){
      sitesList = this.props.hcGroupSites;
    }

    for (var i = 0; i < sitesList.length; i++){
      let site = sitesList[i];
      this.addMarkerToMap(site, "groupSitesMarkerIcon");
    }
  }

  componentWillUnmount() {
    console.log("Component unmounting");
    if (this.map) {
      this.map.removeEventListener('tap', this.onClickMap);
      this.map.removeEventListener('mapviewchange', this.onZoomMap);
      this.removeMapMarkers();
    }
  }

  removeMapMarkers() {
    this.ui.getBubbles().forEach(bub => this.ui.removeBubble(bub))

    if (this.markers) {
      //console.log("Ghostly Overhang Debug", "Calling markers.removeAll");
      this.markers.removeAll();
    }
  }

  deleteTempMarker = () => {
    let {tempMarker, tempMarkerBubble} = this.state;
    if (tempMarker){
      this.markers.removeObject(tempMarker);
    }

    if (tempMarkerBubble){
      tempMarkerBubble.close();
      tempMarkerBubble.removeEventListener('statechange', (evt) => this.pinBubbleStateChange(evt));
      this.ui.removeBubble(tempMarkerBubble);
    }

    this.setState({tempMarker: null, tempMarkerBubble: null});
  }

  addMarkerToMap = async ({name, location_x, location_y, idsite}, markerType, dontAddToList) => {
    let markerIcon = this.state[markerType];
    console.log("dontAddToList in addMarkerToMap:", dontAddToList);

    // Create an icon, an object holding the latitude and longitude, and a marker:
     var icon = new H.map.Icon(markerIcon),
    coords = { lat: location_x, lng: location_y },
    marker = new H.map.Marker(coords, { icon: icon });

    if (!dontAddToList){
      marker.addEventListener('tap', (evt) => this.addMarkerBubble(evt, this.ui, name));
    }

    // Add the marker to the map and center the map at the location of the marker:
    this.markers.addObject(marker);

    if (!dontAddToList){
      let {mapMarkers} = this.state;
      mapMarkers.push(marker);
      await this.promisedSetState({mapMarkers});
      //this.markers.addObject(marker);
      console.log("Adding marker to mapMarkers and markers obj");
    }

    return marker;
  }

  promisedSetState = (newState) => new Promise(resolve => this.setState(newState, resolve));

  addMarkerBubble = (evt, ui, name) => {
    console.log("Calling addMarkerBubble");
    this.setState({clickingOnMarker: true});

    let {activeBubble} = this.state;
    if (activeBubble != null){
      activeBubble.close();
      this.setState({activeBubble: null});
    }

    console.log("results of evt.target.getGeometry()", evt.target.getGeometry());
    // event target is the marker itself, group is a parent event target
    // for all objects that it contains
    var bubble =  new H.ui.InfoBubble(evt.target.getGeometry(), {
      // read custom data
      content: name
    });

    bubble.addEventListener('statechange', (evt) => this.bubbleStateChange(evt))

    // show info bubble
    ui.addBubble(bubble);

    this.setState({activeBubble: bubble, clickingOnMarker: false})
  }

  bubbleStateChange = (evt) => {
   this.setState({activeBubble: null})
  }


  render() {

    let {location} = this.state;
    let {username, refreshSites, backendURL, setLocked} = this.props

    return (<Fragment>
      <div
        style={{ width: '100%', height:'100%' }}
        ref={this.ref}
      />

      <Dialog
        openDialog={() => this.setState({showNewSiteModal: true})}
        onCloseDialog={() => this.setState({showNewSiteModal: false}, this.deleteTempMarker)}
        isOpen={this.state.showNewSiteModal}
        modalTitle={'New Site'}
        width={'sm'}
        buttonText={'Close'}
        >
        <div style={{width: '100%'}}>
          <NewSite
            location={location}
            username={username}
            onCloseDialog={() => this.setState({showNewSiteModal: false})}
            refreshSites={refreshSites}
            backendURL={backendURL}
            closeModal={(newSite) => {
              if (setLocked){
                setLocked(true);
              }
              let tempMarker = this.state.tempMarker;
              this.setState({tempMarker: null});

              tempMarker.addEventListener('tap', (evt) => this.addMarkerBubble(evt, this.ui, newSite.projectName));
              tempMarker.dispatchEvent('tap');

              this.setState({
                showNewSiteModal: false, 
                selectedSite: {
                  name: newSite.projectName,
                  location_y: newSite.newSite.longitud,
                  location_x: newSite.newSite.latitud,
                  haulSiteId: newSite.haulSiteId,
                  idsite: newSite.assuredSiteId,

                  haulSetup: false,
                  idprofile: 1,
                  tickets: 0,
                  tons_dumped: 0,
                  projects: 0,
                },
                showInfoWindow: true
              })
            }}
          />
        </div>
      </Dialog>
    </Fragment>)
  }
}
