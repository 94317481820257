import React, {Component} from 'react';
import ajax from 'superagent';
import request from 'superagent';
import config from '../../../../constants/config';
import SuccessPopup from '../../../Popups';
import Compress from 'compress.js';


export default class ModalTruckID extends Component{

	constructor(props){
        super(props);

        console.log("Setting site from modal truck ID except not doing that");

        this.state = {trucksList: [], statesList: [], idsite: props.idsite};
        this.setDefaultState();
          
    }

    componentWillMount = () => {
        ajax.get(`${config.apiUrl}/states`)
            .end((error, response) => {
                if (!error && response) {
                    this.setState({statesList: response.body.statesList})
                } else {
                    console.log('There was an error fetching', error);
                }
            }
        );
        ajax.get(`${config.apiUrl}/trucks/get/distinct/plates`)
            .end((error, response) => {
                if (!error && response) {
                    //console.log("response.body.trucksList:");
                    //console.log(response.body.trucksList);
                    this.setState({trucksList: response.body.trucksList});
                    //console.log(this.state.truckSearchResults);
                } else {
                    this.setState({trucksList: []})
                    console.log('There was an error fetching', error);
                }
            }
        );

        if (this.props.id){
            this.setDefaultState();
        }

    }

    componentWillReceiveProps = (newProps) => {
        /*console.log("ModalTruckId ==================================================================")
        console.log("ModalTruckId", "Component receiving props at " + new Date());
        console.log("ModalTruckId newProps idsite", (newProps.site ? newProps.site.idsite : "no site"));
        console.log("ModalTruckId current state idsite", this.state.idsite);
        console.log("ModalTruckId check1 site exists", !!newProps.site)
        console.log("ModalTruckId check2 props don't match", (newProps.site ? this.state.idsite != newProps.site.idsite : "n/a"))
        console.log("ModalTruckId check3 new site id isn't 0", (newProps.site ? newProps.site.idsite != 0 : "n/a"))

        console.log("ModalTruckId final check", newProps.site && this.state.idsite != newProps.site.idsite && newProps.site.idsite != 0)*/
        //if (JSON.stringify(newProps) !== JSON.stringify(this.props)){
        if (newProps.site && this.state.idsite != newProps.site.idsite && newProps.site.idsite != 0){
            //console.log("ModalTruckId Calling setDefaultState from componentWillReceiveProps");
            this.setDefaultState();
        }

        //if (newProps.clearState && !this.props.clearState) this.setDefaultState();
    }


    setDefaultState = async () => {

        let site = null;

        if (this.props.site){
            site = this.props.site;
        } else if (this.props.id){
            site = localStorage.getItem('site');
            console.log("setDefaultState localStorage with props ", site);
            if (site != undefined){
                site = JSON.parse(site);
            }
        }

        console.log("Calling setDefaultState with idsite " + site.idsite);

        this.setState({
            driver: null,
            fleet: null,
            tare: null,
            owner: null,
            hasInvalidInput: false,
            hasInvalidPlate: false,
            hasInvalidState: false,
            idsite: site.idsite,
            idtruck: null,
            image: null,
            plate: null,
            selectedState: null,
            sitename: site.name,
            uploadPopupTrigger: false,
            setTruckPopupTrigger: false,
            errorSettingTruckPopupTrigger: false,
            errorUploadingPhotoPopupTrigger: false,
            identifiedTruckPopupTrigger: false,
            imgLoading: false,
        });

        return;
    }

    getTruck = (plate) => {

        let apiRoute = "/trucks/get/by/plates/";
        let data = plate;
        if (!plate &&  this.props.id){
            apiRoute = "/trucks/"
            data = this.props.id;
        } 

        console.log("apiRoute: " + apiRoute + data);

        ajax.get(config.apiUrl + apiRoute + data)
            .end((error, response) => {
                if (!error && response) {
                   // console.log("Respone body for getting truck by plates:");
                    //console.log(response.body);
                    let truckResult = response.body.trucksList;
                    if (truckResult.length == 1){
                        this.setState({'hasInvalidState': false, 'needStateForPlate': false})
                        this.setTruck(truckResult[0]);
                        //this.validateInput();
                    } else if (response.body.trucksList.length > 1){
                        if (!this.state.selectedState){
                            this.setState({'hasInvalidState': true, 'needStateForPlate': true})
                        } else {
                            for (var i = 0; i < truckResult.length; i++){
                                console.log(truckResult[i]);
                                console.log(this.state.selectedState);
                                if (truckResult[i].idstate == this.state.selectedState){
                                    this.setTruck(truckResult[i]);
                                    //this.validateInput();
                                }
                            }
                        }
                    } else {
                        this.setState({'hasInvalidState': false, 'needStateForPlate': false});
                    }
                } else {
                    console.log('There was an error fetching', error);
                }
            }
        );
    }

    setTruck = (truck) => {

        this.setState({
            idtruck: truck.idtruck,
            selectedState: truck.idstate,
            driver: truck.driver,
            fleet: truck.fleetnum,
            image: truck.photo,
            plate: truck.plate,
            tare: truck.tareweight,
            owner: truck.owner
        })
    }

    renderStatesOptions = () => {
        return this.state.statesList.map((state, index) => {
            return <option value={state.idstate} key={index}>{state.name}</option>
        })
    }

    renderTrucksOptions = () => {
        return this.state.trucksList.map((truck, index) => {
            return <option value={truck.plate} key={index} >{truck.plate}</option>
        })
    }

    allowSubmit = () => {
        if (!this.state.plate) return false;
        if (!this.state.selectedState) return false;
        if (!this.state.image) return false;
        if (this.state.hasInvalidInput) return false;
        return true;
    }


    validateInput = () => {

        console.log("Calling validateInput");

        let validPlate = true;
        let validState = true;
        let validImage = true;
        let allInputValid = true;

        //Form validation
        if (!this.state.plate){
           validPlate = false;
        } 

        if (!this.state.selectedState) { 
           validState = false;
        }

        if (!this.state.image) {
            validImage = false;
        }

        if (validPlate === false || validState === false || validImage === false){
            allInputValid = false;
        }

        this.setState({hasInvalidPlate: !validPlate, hasInvalidState: !validState, hasInvalidImage: !validImage, hasInvalidInput: !allInputValid});   
        return !allInputValid;

    }

    submitData = () => {

        //alert("submitData is being called!!");

        let inputInvalid = this.validateInput();
        if (inputInvalid) return;

        let closeModalId = "close-modal-truck-id" + (this.props.id? this.props.id : "");

        let data = {
            driver: this.state.driver,
            fleet: this.state.fleet,
            idsite: (this.props.site ? this.props.site.idsite : this.state.idsite),
            idtruck: this.state.idtruck,
            image: this.state.image,
            plate: this.state.plate,
            idstate: this.state.selectedState,
            tare: parseInt((this.state.tare + "").replace(/,/g, "")),
            owner: this.state.owner,
            tempidtruck: (!this.props.id ? null : this.props.id)
        }

        //console.log(data);
        //document.getElementById('close-modal-truck-id').click();

        ajax.post(`${config.apiUrl}/trucks/add/to/todaysTrucks`)
            .send(data)
            .then(res => {
                if (this.props.updateTruckList){
                    this.props.updateTruckList();

                    ajax.get(`${config.apiUrl}/trucks/${res.body.truck.idtruck}`)
                        .end((error, response) => {
                            if (!error && response) {
                                this.props.setActiveTruck(response.body.truckData[0]); 
                                document.getElementById(closeModalId).click(); 
                                this.setDefaultState();
                            } else {
                                console.log('There was an error fetching', error);
                                document.getElementById(closeModalId).click(); 
                            }
                        }
                    );
                } else {
                    this.setState({identifiedTruckPopupTrigger: true});
                    document.getElementById(closeModalId).click(); 
                    /*setTimeout(function() {
                        window.location.reload();
                    }, 1250)*/
                }
            }).catch(e =>{
                console.log(e);
                this.setState({errorSettingTruckPopupTrigger: true});
                document.getElementById(closeModalId).click(); 
            });
    }

    handleFileUpload = (file) => {
        this.setState({imgLoading: true});

        let savedState = {
            driver: this.state.driver,
            fleet: this.state.fleet,
            tare: this.state.tare,
            owner: this.state.owner,
            hasInvalidInput: this.state.hasInvalidInput,
            hasInvalidPlate: this.state.hasInvalidPlate,
            hasInvalidState: this.state.hasInvalidState,
            idsite: this.state.idsite,
            idtruck: this.state.idtruck,
            plate: this.state.plate,
            selectedState: this.state.selectedState,
            sitename: this.state.sitename
        }

        let compress = new Compress();

        compress.compress(new Array(file), {
            size: 4, // the max size in MB, defaults to 2MB
            quality: 1, // the quality of the image, max is 1,
            maxWidth: 720, // the max width of the output image, defaults to 1920px
            maxHeight: 720, // the max height of the output image, defaults to 1920px
            resize: true, // defaults to true, set false if you do not want to resize the image width and height
        }).then((data) => {
            //alert("handleTempFileUpload called");

            let img1 = data[0]
            let base64str = img1.data
            let imgExt = img1.ext
            let file = Compress.convertBase64ToFile(base64str, imgExt)

            this.setState({imgLoading: true}, () => {
                 request
                    .post(`${config.apiUrl}/trucks/updateTruckPhoto`)
                    .attach('picture', file)
                    .set('Accept', 'application/json')
                    .then(response => {
                       // this.setState({uploadPopupTrigger: true});
                        console.log("img upload response with idsite "+savedState.idsite+":");
                        console.log(response);
                        this.setState({image: response.body.filename, imgLoading: false, hasValidImage: true,
                            driver: savedState.driver,
                            fleet: savedState.fleet,
                            tare: savedState.tare,
                            owner: savedState.owner,
                            hasInvalidInput: savedState.hasInvalidInput,
                            hasInvalidPlate: savedState.hasInvalidPlate,
                            hasInvalidState: savedState.hasInvalidState,
                            idtruck: savedState.idtruck,
                            plate: savedState.plate,
                            selectedState: savedState.selectedState,
                            sitename: savedState.sitename})
                    })
                    .catch((error) => {
                        console.log(error)
                        this.setState({imgLoading: false, errorUploadingPhotoPopupTrigger: true, hasValidImage: false});                    
                    });
                }
            );  
        });     
    
    }

    closeModal = () => {
        console.log("Closing truckID modal");
        document.getElementById("close-modal-truck-id" + (this.props.id? this.props.id : "")).click();
    }

    handlePlateChange = async (e) => {
        if (this.state.idtruck && !this.props.id){
            await this.setDefaultState();
        }
        if (e.target)
        this.setState({plate: e.target.value});
    }

	render(){

        //console.log("ModalTruckId id prop:");
        //console.log(this.props.id);

        if (this.props.id && !this.state.plate && !this.state.idtruck){
            console.log("Calling getTruck from render");
            this.getTruck(null);
        }

        return(<React.Fragment>

            <div>
            <div class="modal modal-idtruck fade" style={{zIndex: '2000', backgroundColor: "rgba(84,84,84,0.3)"}} id={"modal-addtruck" + (this.props.id ? this.props.id : "")} tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document" onClick={null}>
                    <div class="modal-content">
                        <div class="alert alert-primary" role="alert">
                            <strong>Add or Edit a Truck</strong>
                            <button type="button" class="close" data-dismiss="modal" id={"close-modal-truck-id" + (this.props.id? this.props.id : "")} aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div class="modal-body pt-0">

                            <p class="pt-0">Enter a license plate # to add or edit a truck.</p>

                            <form class="input-line contrast mx-2">

                                <div class="form-group input-group">
                                    <div class="input-group-append"><span class="form-control">* Truck Plate #: </span></div>
                                    <input className={"form-control pb-0 pl-2" + (this.state.hasInvalidPlate ? " is-invalid" : "")}
                                    type="text" 
                                    onChange={this.handlePlateChange}
                                    onBlur={e => this.getTruck(e.target.value)}
                                    value={this.state.plate || ""}
                                    list={"truckSearchResultsList" + (this.props.id ? this.props.id : "")} />
                                    <datalist id={"truckSearchResultsList" + (this.props.id ? this.props.id : "")} >
                                        {this.renderTrucksOptions()}
                                    </datalist>
                                </div>
                                <div class="form-group">
                                    <select className={"form-control" + (this.state.hasInvalidState ? " is-invalid" : "")} 
                                        value={this.state.selectedState || 0} 
                                        onChange={e => { this.setState({selectedState: e.target.value}); }}>
                                        <option>* State</option>
                                        { this.renderStatesOptions() }
                                    </select>
                                    <span className="text-danger" style={this.state.needStateForPlate ? {display: "inline-block"} : {display: "none"}}>Enter Your State To Use Plate Autocomplete</span> 
                                </div>
                                <div class="form-group input-group">
                                    <div class="input-group-append"><span class="form-control">Truck Owner: </span></div>
                                    <input className="form-control pb-0 pl-2" 
                                    type="text"
                                    onChange={(e) => this.setState({owner: e.target.value})}
                                    value={this.state.owner || ""} />
                                </div>
                                <div class="form-group input-group">
                                    <div class="input-group-append"><span class="form-control">Driver: </span></div>
                                    <input className="form-control pb-0 pl-2"
                                    type="text" 
                                    onChange={(e) => this.setState({driver: e.target.value})}
                                    value={this.state.driver || ""} />
                                </div>
                                <div class="form-group input-group">
                                    <div class="input-group-append"><span class="form-control">Truck Fleet #: </span></div>
                                    <input className="form-control pb-0 pl-2" type="text"
                                    onChange={(e) => this.setState({fleet: e.target.value})}
                                    value={this.state.fleet || ""} />
                                </div>
                                <div class="form-group input-group">
                                    <div class="input-group-append"><span class="form-control">Empty Weight (lbs): </span></div>
                                    <input className="form-control pb-0 pl-2" type="text"
                                    onChange={(e) => this.setState({tare: e.target.value})}
                                    value={this.state.tare || ""} />
                                </div>
                                
                                <div class="custom-file col-4 mt-4 float-left">*
                                    <input type="file" name="image" className="custom-file-input ml-2" id={"customFileTruck" + (this.props.id ? this.props.id : "")} accept=".png, .jpg, .jpeg" capture="environment"
                                    onChange={(e) => {
                                        this.handleFileUpload(e.target.files[0]);
                                        /*this.setState({image: e.target.files[0]});*/
                                        }}
                                    />
                                    <label className={"custom-file-label2 btn btn-circle p-4 mb-0 ml-6" + (this.state.hasInvalidImage ? " text-danger" : "")} 
                                    for={"customFileTruck" + (this.props.id ? this.props.id : "")}>
                                        <i className="lead fa fa-camera"></i>
                                    </label>
                                </div> 
                                {this.state.image ? 
                                    <div class="float-right mr-4">
                                        <img class="trkimg" src={config.apiUrl + "/images/trucks/" + this.state.image } />
                                    </div>
                                : null}
                                {this.state.imgLoading ? <label class="small fw-100 float-left mt-2 pt-2 ml-2">Photo loading...</label> : null}
                            </form>                          

                        </div>
                        <span className="text-danger pl-6" style={this.state.hasInvalidInput ? {display: "inline-block"} : {display: "none"}}>Enter All Required Fields</span>
                        <button type="button" class="btn btn-md btn-primary" onClick={this.submitData} >Confirm Settings & Select Truck</button>
                    </div>
                </div>
            </div>

            <SuccessPopup
                popupId="truckIdErrorSettingTruck"
                title="Oh no!"
                message="Error setting truck!"
                trigger={this.state.errorSettingTruckPopupTrigger}
                useButton={true}
                style="warning"
                callback={() => this.setState({errorSettingTruckPopupTrigger: false})}
                 />

             <SuccessPopup
                popupId="truckIdErrorSettingPhoto"
                title="Oh no!"
                message="Error uploading photo!"
                trigger={this.state.errorUploadingPhotoPopupTrigger}
                useButton={true}
                style="warning"
                callback={() =>  this.setState({errorUploadingPhotoPopupTrigger: false})}
                 />

            <SuccessPopup
                popupId="truckIdUpdateSuccess"
                title="Success!"
                message="Truck Added To Today's Trucks!"
                trigger={this.state.setTruckPopupTrigger}
                callback={() =>  {this.closeModal(); this.setDefaultState(); this.setState({setTruckPopupTrigger: false})}}
                 />

            <SuccessPopup
                popupId="truckIdPhotoUploadSuccess"
                title="Success!"
                message="Truck Photo Uploaded!"
                trigger={this.state.uploadPopupTrigger}
                callback={() =>  {this.setState({uploadPopupTrigger: false})}}
                 />

             <SuccessPopup
                popupId="truckIdTempUpdateSuccess"
                title="Success!"
                message="Truck Identified!"
                trigger={this.state.identifiedTruckPopupTrigger}
                callback={() =>  {this.closeModal(); this.setState({identifiedTruckPopupTrigger: false})}}
                 />

        </div>
        </React.Fragment>)


	}

}