import React, {Fragment, Component} from 'react';

export default function UnlockMapButton ({setMapLocked, className, children, mapLocked, callback}) {

	return(<Fragment>

		{mapLocked ? 

			<div class="col-12 border-primary px-4 pt-2 pb-2 mx-auto mt-4">
              	<div class="col-12 mr-auto alert-light p-1">
	                <p class="mb-0"><i class="fa fa-lock text-brand mr-2"></i>Unlock the map to add a site.
	                  	<button class="btn btn-xs btn-primary px-2 py-0 mt-1 float-right" onClick={() => {
							setMapLocked(false);
							if (callback) callback();
						}}>Add a Site</button>
	                </p>
                </div>
          	</div>

		: 

		 	<div class="col-12 border-primary px-4 pt-2 pb-2 mx-auto mt-4">
              	<div class="col-12 mr-auto alert-light p-1">
	                <p class="mb-0"><i class="fa fa-unlock-alt text-brand fw-300 mr-2"></i>Map is unlocked for adding a site.
	                  	<button class="btn btn-xs btn-outline-secondary px-3 py-0 mt-1 float-right" onClick={() => setMapLocked(true)}>Cancel</button>
	                </p>
                </div>
          	</div>

		}
		
	</Fragment>)

}