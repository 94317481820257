/* Depreciated */

import React, {Component} from 'react';
import ajax from 'superagent';
import config from '../../../constants/config';
import _ from 'lodash';

export default class ModalProjectTeam extends Component {
    constructor(props){
        super(props);
        let user = localStorage.getItem('user');
        let userId = user ? JSON.parse(user).id : null;
        let idsite = JSON.parse(localStorage.getItem('site')).idsite;
        let siteName = JSON.parse(localStorage.getItem('site')).name;
        this.state = {
            agreementSelected: 0,
            userId,
            idsite,
            users: [],
            usersToAdd: [{idprofile: 0, idsitetype: 0}],
            profilesList: [],
            usersObject: {},
            listOfProjects: [],
            siteName,
            idsitetype: 0,
        }
        this.updateProfile = this.updateProfile.bind(this);
        this.renderAddUsers = this.renderAddUsers.bind(this);
    }

    componentWillMount(){
        ajax.get(`${config.apiUrl}/users/teammembersbysiteanduser/${this.state.idsite}/${this.state.userId}`)
            .end((error, response) => {
                if (!error && response) {
                    let usersObject = {};
                    let listOfProjects = [];
                    response.body.usersList.forEach(user => {
                        let name = `${user.source} to ${user.dump}`;
                        if(usersObject[name]){
                            usersObject[name].push(user);
                        }else{
                            usersObject[name] = [user]
                        }
                        if(listOfProjects.indexOf(user.idproject) < 0){
                            listOfProjects.push(user.idproject);
                        }
                    });
                    this.setState({users: response.body.usersList, usersObject, listOfProjects})
                    if(response.body.usersList.length > 0){
                        const u = response.body.usersList[0];
                        if(u.dump === this.state.siteName){
                            this.setState({idsitetype: 2})
                        }else if(u.source === this.state.siteName){
                            this.setState({idsitetype: 1})
                        }
                    }
                } else {
                    console.log('There was an error fetching', error);
                }
            }
        );
        ajax.get(`${config.apiUrl}/profiles`)
            .end((error, response) => {
                if (!error && response) {
                    //console.log(response.body)
                    const validProfiles = response.body.profilesList.filter(item => {
                        return item.profile !== "Project Leader"
                    })
                    this.setState({profilesList: validProfiles})
                } else {
                    console.log('There was an error fetching', error);
                }
            }
        );
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.users != this.props.users){
            this.setState({users: nextProps.users});
        }
    }

    renderUserGroup(group){
        return (
            group.map(item => {
                return (
                    <li className="flexbox mb-2">
                        <a className="col-lg-4"><i className="ti-check text-success mr-2"></i><strong>{item.email}</strong></a>
                        <a className="small">{item.profile}</a>
                        <a className="small">{item.userLocation}</a>
                    </li>
                )
            })
        )
    }

    renderUsers(){
        const users = [];
        const options = this.state.usersObject !== {} ? Object.entries(this.state.usersObject).map(entry => {
            return <option value={entry[0]}>{entry[0]}</option>;
        }) : [];
        const selection = (
            <select value={this.state.agreementSelected} onChange={e => {
                this.setState({//cambio prueba
                    agreementSelected: e.target.value,
                    usersToAdd: [{idprofile: 0, idsitetype: 1}],
                })
            }}>
                <option value={0} disabled={true}>Select Agreement</option>
                {options}
            </select>
        )
        return (
            <div>
                {selection}
                <ul className="lead col-lg-11 mt-2 ml-4">
                    {this.state.agreementSelected ? this.renderUserGroup(this.state.usersObject[this.state.agreementSelected]) : <span>No Team Members</span>}
                </ul>
            </div>
        )
        /* for(const site in this.state.usersObject){
            users.push(
                <div>
                    {selection}
                    <label className="ml-3"><strong>{site}</strong></label>
                    <ul className="lead col-lg-11 mt-2 ml-4">
                        {this.renderUserGroup(this.state.usersObject[site])}
                    </ul>
                </div>
            )
        }
        return users; */
    }

    removeUser(user){
        ajax.post(`${config.apiUrl}/projects/${user.idproject}/${user.iduser}`)
            .then(res => {
                this.setState({usersToAdd: [{idprofile: 0}]});
                ajax.get(`${config.apiUrl}/api/getUsersByProjectId/${this.props.project.idproject}`)
                    .end((error, response) => {
                        if (!error && response) {
                            console.log(response.body)
                            this.setState({users: response.body.userList})
                        } else {
                            console.log('There was an error fetching', error);
                        }
                    }
                );
                alert('Users removed!');
                //this.props.history.push(`/dump-project/`);
            });
    }

    renderOptions() {
        return this.state.profilesList.map(r => {
            return <option value={r.idprofile} key={r.idprofile}>{r.profile}</option>
        })
    }

    updateProfile(key, value) {
        this.setState(state => {
            const usersToAdd = state.usersToAdd.map((item, j) => {
                if(j == key){
                    return Object.assign({}, item, {idprofile: value});
                }else{
                    return item;
                }
            });
            return {
                usersToAdd
            }
        })
    }
    updateLocation(key, value) {
        this.setState(state => {
            const usersToAdd = state.usersToAdd.map((item, j) => {
                if(j == key){
                    return Object.assign({}, item, {idsitetype: value});
                }else{
                    return item;
                }
            });
            return {
                usersToAdd
            }
        })
    }
    
    updateEmail(key, value) {
        this.setState(state => {
            const usersToAdd = state.usersToAdd.map((item, j) => {
                if(j == key){
                    return Object.assign({}, item, {email: value});
                }else{
                    return item;
                }
            });
            return {
                usersToAdd
            }
        })
    }

    renderAddUserRow(key){
        return (
            <div className="form-group row gap-y" key={key}>
                <div className="col-lg-5">
                    <div className="input-group">
                        <input className="form-control form-control-sm" type="text"  placeholder="name@company.com" value={this.state.usersToAdd[key].email || ""} onChange={(e) => {
                            this.updateEmail(key, e.target.value)
                        }} />
                    </div>
                </div>
                <div className="col-lg-5">
                    <select className="form-control form-control-sm" value={this.state.usersToAdd[key].idprofile || 0} onChange={e => {
                        this.updateProfile(key, ~~e.target.value);
                    }}>
                        <option value={0} disabled={true}>Select one</option>
                        {this.renderOptions()}
                    </select>
                </div>
            </div>
        )
    }

    renderAddUsers() {
        return this.state.usersToAdd.map((item, index) => {
            return this.renderAddUserRow(index)
        })
    }

    addNewUserRow(){
        const users = this.state.usersToAdd;
        const last = users.length - 1;
        if(users[last].email != "" && users[last].idprofile != 0){
            this.setState(state => {
                const usersToAdd = [...state.usersToAdd, {idprofile: 0}];
                return {usersToAdd}
            })
        }
    }

    
    addUser(){
        const data = {
            iduser:         this.state.userId,
            projectUsers:   this.state.usersToAdd.map(item => {
                return Object.assign({}, item, {idsitetype: this.state.idsitetype});
            }),
            idproject:      this.state.usersObject[this.state.agreementSelected][0].idproject,
            listOfProjects: this.state.listOfProjects,
        };
        ajax.post(`${config.apiUrl}/api/addUserProject`)
            .send(data)
            .then(res => {
                this.setState({usersToAdd: [{idprofile: 0}]});
                ajax.get(`${config.apiUrl}/api/getUsersByProjectId/${this.props.project.idproject}`)
                    .end((error, response) => {
                        ajax.get(`${config.apiUrl}/users/teammembersbysiteanduser/${this.state.idsite}/${this.state.userId}`)
                        .end((error, response) => {
                            if (!error && response) {
                                let usersObject = {};
                                response.body.usersList.forEach(user => {
                                    let name = `${user.source} to ${user.dump}`;
                                    if(usersObject[name]){
                                        usersObject[name].push(user);
                                    }else{
                                        usersObject[name] = [user]
                                    }
                                });
                                this.setState({users: response.body.usersList, usersObject})
                            } else {
                                console.log('There was an error fetching', error);
                            }
                        });
                    }
                );
                alert('Users added!');
                //this.props.history.push(`/dump-project/`);
            });
    }

    render(){
        return (
            <div className="modal modal-top fade" id="modal-users" tabIndex="-1" >
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">{this.state.siteName}</h5>
                        </div>
                        <div className="modal-body">
                            <div className="alert alert-primary mt-2" role="alert">
                                <strong>Current Users:</strong><br />
                            </div>
                            {this.renderUsers()}
                            <hr className="mb-3" />
                            <div className="col-lg-12">
                                <div className="form-group row gap-y">
                                    <div className="col-lg-5">
                                        <label>Add User(s)</label>
                                    </div>
                                    <div className="col-lg-5">
                                        <label>Grant Access</label>
                                    </div>
                                </div>
                                {!_.isEmpty(this.state.usersObject)? this.renderAddUsers() : null}
                                <button type="button" className="btn btn-sm btn-outline-secondary mt-4" onClick={this.addNewUserRow.bind(this)} disabled={_.isEmpty(this.state.usersObject)}><i className="fa fa-plus fw-400"></i></button>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">ACCEPT</button>
                            <button type="button" className="btn btn-primary" data-toggle="modal" data-dismiss="modal" onClick={this.addUser.bind(this)} disabled={_.isEmpty(this.state.usersObject)}>Update</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
