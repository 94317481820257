import {
    SET_VALUES_FOR_PDF,
} from './types';

export function setValuesForPDF(items, filter) {
    return {
        type: SET_VALUES_FOR_PDF,
        items,
        filter,
    };
}