import React, {Fragment, Component} from 'react';
import ajax from 'superagent';
import config from '../../constants/config';
import MenuAppBar from '../NavBar';
import Footer from '../Footer';
import Partner from './Partner';

export default class MyPartners extends Component {

    constructor(props){
        super(props);
        let user = localStorage.getItem('user');
        let iduser = JSON.parse(user).id;

        this.state = {
            iduser,
            partners: [],
        }
    }

    componentWillMount = () => {
    	let {iduser} = this.state;

    	ajax.get(`${config.apiUrl}/sites/mypartners/` + this.state.iduser)
            .end((error, response) => {
                if (!error && response) {
                	console.log("MyPartners response: ", response);
                    let {partners} = response.body;
                    this.setState({partners});
                } else {
                    console.log('There was an error fetching', error);
                }
            }
        );
    }

    render = () => {

    	let {partners} = this.state;

        return(<Fragment>
	    	<MenuAppBar showSandbox={true} />
	        <main class="main-content">

                <header class="section-header mt-7 mb-1 mx-lg-12">
                    <h2><strong>My Partners</strong></h2>
                </header>

                <div class="section pt-7">
                    <div class="container">


                        <div class="row">
                            <div class="col-12 col-lg-11 mx-auto px-0">

								{
									partners.map((partner, key) => {
										return <Partner 
											partner={partner}
											key={key}
										 />
									})
								}

							</div>
						</div>
					</div>
				</div>
			</main>

	     	<Footer />
        </Fragment>)
    }
}