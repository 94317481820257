import React, {Fragment, Component} from 'react';
//import ajax from 'superagent';
import config from '../../../../constants/config';
import CCForm from '../CCForm'


export default class OptInModal extends Component {

	constructor(props){
		super(props);

		this.state={
			info: {}
		};

	}

	setPaymentInfo = (info) => {
		this.setState({info});
		//this.props.returnValidPaymentInfo(info);
	}

	closeModal = () => {
		let {idproject} = this.props;
		document.getElementById('close-modal-'+idproject).click();
	}

	render(){

		let {idproject, recipient, idsite, newSite, callback} = this.props;
		let {info} = this.state;

		return(<Fragment>

            <div style={{display: "none"}}><button id={"open-premium-"+idproject} data-toggle="modal" data-target={"#premium-"+idproject}/></div>


			<div class="modal fade" id={"premium-"+idproject} tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
				<div class="modal-dialog">
					<div class="modal-content">
						<div class="modal-header">
							<h4 class="modal-title">Premium Opt-In</h4>
							<button type="button" id={"close-modal-"+idproject} class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
						</div>
						<div class="modal-body">
							<div class="row">
								<div class="col-lg-12 mx-auto mx-md-0 mb-6 flexbox">
									<div class="">
										
										<CCForm 
	                                        recipient={recipient} 
	                                        returnValidPaymentInfo={this.setPaymentInfo} 
	                                        idsite={idsite} 
	                                        newSite={newSite} 
	                                        showOptIn={true}
                                        />

									</div>
								</div>
							</div>
						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-default" onClick={this.closeModal}>Close</button>
							<button type="button" class="btn btn-primary" onClick={() => {
								this.closeModal();
								callback(info)
							}}>Save changes</button>
						</div>
					</div>
				</div>
			</div>


		</Fragment>)
	}

}