import {
    GET_PARTNER_SITES_REQUESTED,
    GET_PARTNER_SITES_REJECTED,
    GET_PARTNER_SITES_FULFILLED,
} from './types';

import request from 'superagent';
import config from '../constants/config';

export function getPartnerSites(idsite, idgenerator){
    return dispatch => {
        dispatch(getPartnerSitesRequestedAction());
        request
        .get(`${config.apiUrl}/sites/partners/${idgenerator}/${idsite}`)
        .set('Accept', 'application/json')
        .then(response => {
            dispatch(getPartnerSitesFulfilledAction(response.body))
        })
        .catch((error) => {
            dispatch(getPartnerSitesRejectedAction(error));
        });
    }
}

function getPartnerSitesRequestedAction() {
    return {
        type: GET_PARTNER_SITES_REQUESTED,
        inProgress: true
    };
}
  
function getPartnerSitesRejectedAction(error) {
    return {
        type: GET_PARTNER_SITES_REJECTED,
        inProgress: false,
        success: false,
        error
    }
}
  
function getPartnerSitesFulfilledAction(data) {
    return {
        type: GET_PARTNER_SITES_FULFILLED,
        data,
        success: true,
        inProgress: false,
        error: false
    };
}