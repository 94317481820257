import React, {Fragment, Component} from 'react';
import jwtDecode from 'jwt-decode';
import ModalEditTeam from '../../DumpProject/ModalEditTeam'

export default function SiteDisplayEmpty ({site, refreshSites}) {

	const link = (pageURL) => {
		localStorage.setItem('site', JSON.stringify(site));
		window.location.href = pageURL;
	}


	return(<Fragment>
		
		<div className="card-body">

			{site.idprofile == 1 || site.idprofile == 2 ?
				<div className="col-12 text-center">     
					<a className="btn btn-primary mt-4" href="#" onClick={() => link("disposal-agreement-setup-1")}>+ Add a Disposal Agreement</a>
				</div>
			: null }

			{site.team_size == 1 ?
				<div className="col-12 text-center">
					<button className="btn btn-outline-primary mt-4 " data-toggle="modal" data-target={"#edit-team-"+site.idsite}>Start your Site Team</button>
				</div>
			:
				<div className="col-12 pt-6 px-0 clearfix">     
                  <a className="link small strong text-primary cursor float-right" data-toggle="modal" data-target={"#edit-team-"+site.idsite}>
						<u>{site.idprofile == 3 || site.idprofile == 4 ? "View" : "Manage" } Site Team</u>
					</a>
                </div>  }

		</div>

		<ModalEditTeam idsite={site.idsite} name={site.name} refreshSites={refreshSites} />

	</Fragment>)

}