import React, {Fragment, Component} from 'react';
import moment from 'moment';

export default class Agreement extends Component {

    constructor(props){
        super(props);

        this.state = {
            
        }
    }

    formatDate = (date) => {
    	if (date){
    		return "Last ticket closed "+ moment(date).add(5, 'hours').format('M-D-YY')
    	} else {
    		return "No tickets closed yet";
    	}
    }

    goToArchive = () => {
    	let {idprojectsoil, idmine, mysite} = this.props.agreement;
        let site = {idsite: idmine, name: mysite};

        let displayState = "archivedTickets";

        let panelProps = {
            site,
            idprojectsoil,
        }

        let loadParams = {panelProps, displayState}

    	localStorage.setItem('loadParams', JSON.stringify(loadParams));
    	window.location.href = "/my-sites";
    }


    render = () => {

    	let {agreement} = this.props;

        return(<Fragment>
        
            <div class="row border-bottom mb-4">
                <div class="col-5 col-md-3 twonhalf-col strong">
                    <p class="mb-0">{agreement.mysite}</p>
                </div>
                <div class="col-2 col-md-1">
                    <p class="mb-0">{agreement.preposition}</p>
                </div>
                <div class="col-5 col-md-5 twonhalf-col ml-md-8 ml-lg-4 strong">
                    <p class="mb-0 text-right">{agreement.theirsite}</p>
                </div>
                <div class="col-3 onenhalf-col">
                    <p>{agreement.profile}</p>
                </div>
                <div class="col-9 col-md-8 col-lg-4 mb-lg-0 ml-0 ml-lg-4">
                    <p><span class="badge badge-pale badge-dark mr-6 mr-md-8 mr-lg-4">{agreement.tonsTD} Tons TD</span>
                    <a class="small-3 fw-400 text-dark" href="#" onClick={this.goToArchive}>{this.formatDate(agreement.lastClosedTicket)}<i class="ti ti-angle-right fs-9 ml-2" aria-hidden="true"></i></a></p>
                </div>
            </div>

        </Fragment>)
    }
}

