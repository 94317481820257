import React from 'react';
import StripeForm from '../StripeComponents/StripeForm'
import {Elements, StripeProvider} from 'react-stripe-elements';

export default function BillingInfo({stripeKey}) {

    return(
        <StripeProvider apiKey={stripeKey}>
          <Elements>
            <StripeForm getStripePaymentMethod={() => {return true}} />
          </Elements>
        </StripeProvider>
    )

}