import React, {
    Component,
    Fragment
} from 'react';
import ajax from 'superagent';
import {
    connect
} from 'react-redux';
import DatePicker from "react-datepicker";
import ReactTable from "react-table-6";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import "react-table-6/react-table.css";
import "react-datepicker/dist/react-datepicker.css";
import PDFdiv from '../../PDFdiv';
import "./style.css";
import TempTruckID from '../TempTruckID';
import Manifest from '../../Manifest';
import ModalClosedTickets from '../ModalClosedTickets';
import pageUrls from '../../../constants/pageUrls';
import config from '../../../constants/config';
import moment from 'moment';

import {
    getClosedTickets,
} from '../../../actions/tickets';
import {
    setValuesForPDF,
} from '../../../actions/pdf';

class Archive extends Component {
    constructor(props, context){
        super(props);
        let user = localStorage.getItem('user');
        let userId = user ? JSON.parse(user).id : null;
        let idgenerator = JSON.parse(localStorage.getItem('user')).id;
        let idsite = props.site.idsite;
        let sitename = props.site.name;

        //let path = props.location.pathname;
        //path = path.split('/');
        //console.log("Archive path: ", path);
        //let idprojectsoil = (path[2] ? path[2] : null);
        let idprojectsoil = props.idprojectsoil;

        this.state = {
            userId,
            filter: false,
            ticketList: [],
            idsite,
            idgenerator,
            sitename,
            filtersForPDF: {},
            itemsForPDF: [1,2,3,4,5,6],
            wellsByTransactionId: {},
            needsToggle: false,
            tonnage: 0,
            loads: 0,
            idprojectsoil,
            loadedFilters: {sourcename: "", dumpname: "", profile: ""},
            loadsToDate: 0,
            tonsToDate: 0,
            filteredTickets: [],
        }
    }
    componentWillMount() {
        this.props.getClosedTickets(this.state.idsite, this.state.idgenerator);

        let {idprojectsoil} = this.state;

        if (idprojectsoil != null){

            ajax.get(`${config.apiUrl}/soilprofiles/partnerAndProfile/${idprojectsoil}`)
                .end((error, response) => {
                    if (!error && response) {
                        console.log("Archive response:", response);
                        if (response.body.success){
                            let loadedFilters = response.body.data[0];
                            this.setState({filter: true, loadedFilters});
                        }else 
                            this.setState({emptyProfileList: true})
                    } else {
                        console.log('There was an error fetching', error);
                    }
                }
            );
        }

    }

    componentDidMount(){

        this.intervalId = setInterval(() => { 
            this.props.getClosedTickets(this.state.idsite, this.state.idgenerator);
        }, 60000);

    }

    componentWillUnmount = () => {
        clearInterval(this.intervalId);
    }

    componentWillReceiveProps(newProps){

        ajax.get(config.apiUrl + '/sites/permissions/' + this.state.userId + '/' + this.state.idsite)
            .end((error, response) => {
                if (!error && response) {

                    console.log("response.body:",response.body);
                    if (response.body.permissions.canViewArchiveTab === false){
                        //window.location.href = pageUrls.closedTickets;
                        this.props.switchSidePanel("hauledToday")
                    }
                } else {
                    console.log('There was an error fetching', error);
                }
            }
        );

        this.getTickets(newProps);
        
    }

    getTickets = (newProps) => {
         if(newProps.tickets.closedTickets.inProgress !== this.props.tickets.closedTickets.inProgress && newProps.tickets.closedTickets.success){
            let temp = newProps.tickets.closedTickets.data.slice();
            for(let i = 0; i < temp.length; ++i){
                for(let j = temp.length - 1; j > i; --j){
                    if(temp[i].idticket == temp[j].idticket){
                        temp[i].idprofile = temp[i].idprofile <= temp[j].idprofile ? temp[i].idprofile : temp[j].idprofile
                        temp.splice(j, 1)
                    }
                }
            }
            this.setState({ticketList: temp, filteredTickets: temp}, () => { this.calcTonnage(); this.calcLoads(); });
        }
    }


    //filter functions
    filterTextMethod(filter, rows, column){
        const val = filter.value ? filter.value.toString().toLowerCase() : '';
        const vals = val.split(',').map(i => i.trim());
        const result = rows.filter(r => {
            for(var i = 0; i < vals.length; ++i){
                if(filter.id && r[filter.id] && r[filter.id].toString().toLowerCase().search(vals[i]) >= 0){
                    return true
                }
            }
            return false;
            //return r[filter.id] ? r[filter.id].toString().toLowerCase().search(val) >= 0 : false
        })
        return result;
    }

    setTicketAsInvalid = (ticket) => {
        ticket.invalidreason = true;
        this.calcLoads(); this.calcTonnage();
        this.setState({needsToggle: !this.state.needsToggle});
    }

    setTicketAsRecovered = (ticket) => {
        ticket.recoverreason = true;
        this.calcLoads(); this.calcTonnage();
        this.setState({needsToggle: !this.state.needsToggle});
    }

    FilterText(header){
        return (
        <input
            id={header.column.id}
            placeholder='Search'
            value={header.filter ? header.filter.value || "" : ""}
            onChange={e => {
                header.onChange(e.target.value);
            }}
        />
        );
    }
    filterNumberMethod(filter, rows, column){
        const max = filter.value ? filter.value.max : "";
        const min = filter.value ? filter.value.min : "";
        const result = rows.filter(r => {return r[filter.id] ? r[filter.id] >= min &&  r[filter.id] <= max : false})
        const ids = result.map(r => r.wellId)
        const wells = this.state.wellsByTransactionId.data;
        const newWells = wells.map(r => {
            if(ids.indexOf(r.wellId) >= 0){
                return Object.assign({}, r, { filtered: true });
            }else{
                return Object.assign({}, r, { filtered: false })
            }
        })
        this.state.wellsByTransactionId.data = newWells;
        return rows;
    }

    FilterNumber(header){
        let min = header.filter ? header.filter.value.min || "" : "";
        let max = header.filter ? header.filter.value.max || "" : "";
        return (
        <div style={{width: "100%"}}>
            <input
            type="number"
            style={{width: "45%"}}
            placeholder='Min'
            value={header.filter ? header.filter.value.min || "" : ""}
            onChange={e => {
                if(e.target.value == ""){
                   header.onChange({min: min, max: max});
                } else {
                    header.onChange({min: e.target.value, max: max});
                }
            }}
            />
            -
            <input
            type="number"
            style={{width: "45%"}}
            placeholder='Max'
            value={header.filter ? header.filter.value.max || "" : ""}
            onChange={e => {
                if(e.target.value == ""){
                   header.onChange({min: min, max: max});
                } else {
                    header.onChange({min: min, max: e.target.value});
                }
            }}
            />
        </div>
        );
    }

    filterDatesMethod(filter, rows, column){

        const max = filter.value.max ? moment(filter.value.max).hour(23).minute(59).second(59) : moment().add(5, "years");
        const min = filter.value.min ? moment(filter.value.min).hour(0).minute(0).second(0) : moment("2000-01-01");

        console.log("Filterdates values: min", min, "max", max);

        const result = rows.filter(r => {

            console.log("filterdates data for ", r);
            console.log("filterdates data for "+ r.ticket);

            if (r[filter.id]){
                let rowDate = moment(r[filter.id]).add(5, "hours");
                console.log("filterdates rowDate", rowDate)
                let greaterThanMin = (rowDate.isSameOrAfter(min));
                console.log("filterdates greaterThanMin?", greaterThanMin);
                let lessThanMax = (rowDate.isSameOrBefore(max));
                console.log("filterdates lessThanMax?", lessThanMax);
                return greaterThanMin && lessThanMax;

            } else {
                return false;
            }
        });
        
        return result;
    }

    FilterDates(header){

        let min = header.filter && header.filter.value && header.filter.value.min;
        let max = header.filter && header.filter.value && header.filter.value.max;

        return (
        <div style={{width: "100%", height: "120px"}}>
            Min Date:
            <div  style={{display: "block"}}>
                <DatePicker
                selected={min}
                onChange={date => {
                    header.onChange({min: date, max: max});
                }}
                popperPlacement="bottom-start"
            />
            </div>
            Max Date:
            <div style={{display: "block"}}>
                <DatePicker
                selected={max}
                onChange={date => {
                    header.onChange({max: date, min: min});
                }}
                popperPlacement="bottom-start"
                />
            </div>
        </div>
        );
    }

    renderTime = (time) => {
        let date = new Date(time);
        const hour = ('00' + (date.getHours() % 12 || 12)).substr(-2) + ':' + ('00' + (date.getMinutes())).substr(-2);
        return <span>{hour + (date.getHours() > 11 ? 'PM' : 'AM')}</span>
    }

    calcTonnage = () => {
        let tonsToDate = this.state.filteredTickets.reduce((total, item) => {
            if (item.invalidreason !== null && item.recoverreason === null ) 
                return total;
            else return total + item.dumped;
        }, 0);

        this.setState({tonsToDate});
    }

    calcLoads = () => {

        let loadsToDate = this.state.filteredTickets.reduce((total, item) => {
            if (item.invalidreason !== null && item.recoverreason === null ) 
                return total;
            else return total + 1;
        }, 0);

        this.setState({loadsToDate});

    }

    updateFilteredTickets = (sortedData) => {
        let {filteredTickets} = this.state;

        let newFilteredTickets = sortedData.map(({_original}) => {return _original.original});

        if (filteredTickets.toString() != newFilteredTickets.toString()){
            this.setState({filteredTickets: newFilteredTickets}, () => { this.calcTonnage(); this.calcLoads(); });
        }
    }

    render() {
        const {ticketList} = this.state;
        const tickets = ticketList.sort((a,b) => {
            return (new Date(b.datedisposed)).getTime() - (new Date(a.datedisposed)).getTime()
        }).map(r => {
            return {
                date: moment(r.datedisposed).subtract(5, "hours"),
                ticket: r.internalnumber,
                loaded: r.sourcetimestamp || r.datecreated,
                unloaded: (
                    (r.invalidreason && !r.recoverreason) ? 
                         <label className="small-6 text-danger fw-700">INVALID</label>
                    : r.recoverreason ? 
                         <label className="small-6 text-info fw-700">RECOVERED</label> 
                    : r.dumptimestamp ?
                        this.renderTime(r.dumptimestamp)
                    : r.datedisposed ? 
                        this.renderTime(r.datedisposed) : null),
                truckLicense: r.licenseplate || <TempTruckID
                        number={r.trucktempnum || 0}
                        idtruck={r.truck}
                        idElement="label"
                        idClasses = "small-4 fw-600"
                        showIcon = {true}
                        iconClasses = "small fa fa-exclamation-triangle text-warning mr-1 fw-100"
                        showLink = {false}
                        />,
                soilProfile: r.profile,
                original: r,
                source_site: r.source_site,
                dump_site: r.dump_site,
                tonnage: r.dumped,
            }
        })
        const columns = [
            {
                Header: 'Date',
                accessor: 'date',
                Cell: row => {
                    return <span>{(new Date(row.row.date)).toISOString().substr(0,10)}</span>
                },
                style: {textAlign: 'center'},
                filterable: this.state.filter,
                filterAll: true,
                width: 230,
                filterMethod: this.filterDatesMethod.bind(this),
                Filter: this.FilterDates.bind(this),
            },
            {
                Header: 'Ticket #',
                accessor: 'ticket',
                Cell: row => {
                    return <span>#{row.row.ticket}</span>
                },
                style: {textAlign: 'center'}
            },
            {
                Header: 'Export Site',
                accessor: 'source_site',
                Cell: row => {
                    return <span>{row.row.source_site}</span>
                },
                style: {textAlign: 'center'},
                filterable: this.state.filter,
                filterAll: true,
                filterMethod: this.filterTextMethod.bind(this),
                Filter: this.FilterText.bind(this),
            },
            {
                Header: 'Loaded',
                accessor: 'loaded',
                Cell: row => {
                    let date = new Date(row.row.loaded);
                    const hour = ('00' + (date.getHours() % 12 || 12)).substr(-2) + ':' + ('00' + (date.getMinutes())).substr(-2);
                    return <span>{hour + (date.getHours() > 11 ? 'PM' : 'AM')}</span>
                },
                style: {textAlign: 'center'}
            },
            {
                Header: 'Import Site',
                accessor: 'dump_site',
                Cell: row => {
                    return <span>{row.row.dump_site}</span>
                },
                style: {textAlign: 'center'},
                filterable: this.state.filter,
                filterAll: true,
                filterMethod: this.filterTextMethod.bind(this),
                Filter: this.FilterText.bind(this),
            },
            {
                Header: 'Unloaded',
                accessor: 'unloaded',
                Cell: row => {
                    return <span>{row.row.unloaded}</span>
                },
                style: {textAlign: 'center'}
            },
            {
                Header: 'Truck #',
                accessor: 'truckLicense',
                filterable: this.state.filter,
                filterAll: true,
                filterMethod: this.filterTextMethod.bind(this),
                Filter: this.FilterText.bind(this),
            },
            {
                Header: 'Soil Profile',
                accessor: 'soilProfile',
                filterable: this.state.filter,
                filterAll: true,
                filterMethod: this.filterTextMethod.bind(this),
                Filter: this.FilterText.bind(this),
            },
            {
                Header: 'Tonnage',
                accessor: 'tonnage',
            },
            {
                Cell: (row) => {
                    return <a className="btn btn-xs btn-outline-secondary" data-toggle="modal" data-target="#modal-closed" onClick={() => this.setState({closedTicket: row.original.original})}>VIEW TICKET</a>
                }
            }
        ];
        return (<Fragment>
             <div className="row mb-2">
                <div className="fullwidth col-12 mx-auto">
                    <div className="clearfix">  
                        <h3 className="ml-2 float-left">Archive</h3>
                        <p className="small-1 strong border-bottom text-right pt-4"><a className="text-dark" href="#" onClick={() => this.props.switchSidePanel("hauledToday")}><i className="fa fa-caret-left mr-2" aria-hidden="true"></i>Tickets Hauled Today</a></p>
                    </div>
                    <label className="small-1 mb-4 ml-2 fw-500">{this.state.sitename}</label>
                </div>

                <div className="col-12 mb-4">
                    <p className="small-1 strong mb-0">Total Loads Prior To Today: {this.state.loadsToDate}</p>
                    <p className="small-1 strong mb-0">Total Tons Prior To Today:  {this.state.tonsToDate}</p>
                </div>

                <div className="col-12 pr-0 pl-0 table-responsive">
                    <div className="flexbox mt-0 mb-4">
                        <a className="btn btn-outline-secondary" style={this.state.filter ? {backgroundColor: 'deepskyblue', color: 'black'} : {}} onClick={() => {this.setState({filter: !this.state.filter})}}><i className="fa fa-filter" aria-hidden="true"></i>&nbsp;FILTER</a>
                        <button className="btn btn-outline-secondary" disabled={false} title="Coming Soon" onClick={() => print()}><i className="fa fa-download" aria-hidden="true"></i>&nbsp;TONNAGE REPORT</button>
                    </div>
                    <ReactTable
                        data={tickets}
                        columns={columns}
                        defaultPageSize={10}
                        minRows={1}
                        className="-striped -highlight table-responsive"
                        resizable={true}
                        getTheadFilterThProps={() => { return { style: { position: "inherit", overflow: "inherit" } } }}
                        style={{overflowY: 'auto', overflowX: 'visible', zIndex: 0, minHeight: '600px' }}
                        defaultFiltered={[
                            {
                                id: 'source_site',
                                value: this.state.loadedFilters.sourcename,
                            },
                            {
                                id: 'dump_site',
                                value: this.state.loadedFilters.dumpname,
                            },
                            {
                                id: 'soilProfile',
                                value: this.state.loadedFilters.profile,
                            },
                        ]}
                    >
                        {
                            (state, makeTable, instance) => {
                                //console.log("Table state", state);
                                //console.log('*********************', state.filtered, state.pageRows, '**********************')
                                this.props.setValuesForPDF(state.sortedData, state.filtered);
                                this.updateFilteredTickets(state.sortedData);

                                if (this.reactTable === null) {
                                    this.reactTable = instance
                                }
                                return (makeTable())
                            }
                        }
                    </ReactTable>
                    <PDFdiv items={this.state.itemsForPDF} filters={this.state.filtersForPDF} />
                    <ModalClosedTickets ticket={this.state.closedTicket} setTicketAsInvalid={() => this.setTicketAsInvalid(this.state.closedTicket)} setTicketAsRecovered={() => this.setTicketAsRecovered(this.state.closedTicket)} project={this.state.project}/>
                </div>
            </div>
        </Fragment>)
    }
}


function print(quality = 1) {
    console.log("JSPDF calling Print")

    let siteName = JSON.parse(localStorage.getItem('site')).name;
    const date = new Date().toISOString().substring(0, 10);

    const filename  = siteName + '_' + date + '_ticketDataExport.pdf';
    let pdf = new jsPDF({
        orientation: 'p',
        format: 'a4',
        unit: 'pt',
    });;
    //pdf.addFileToVFS('OpenSans-normal.ttf', OpenSans);
    //pdf.addFont('OpenSans-normal.ttf', 'OpenSans', 'normal');
    //pdf.addFileToVFS('Oswald-normal.ttf', Oswald);
    //pdf.addFont('Oswald-normal.ttf', 'Oswald', 'normal');

    console.log("JSPDF siteName", siteName, "date", date, "filename", filename, "pdf", pdf)
    
    var pageSize = pdf.internal.pageSize
    var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()

    pdf.html(document.getElementById('pdfHeading'), {
        callback: (newpdf) => {

            newpdf.autoTable({
                'html': '#pdfTable', 
                startY: (575),
                styles: {
                    lineColor: "#f1f2f3",
                    lineWidth: "1px",
                    fontFamily: "'Open Sans', sans-serif",
                    fontStyle: "normal",
                    fontSize:"9",
                    fontWeight: 300,
                    //minCellHeight: "10"
                    //text-align: left;
                },
                headStyles: {
                    fontSize: "10",
                    fontStyle:"bold",
                    textColor: "black",
                    padding: {
                        right: "0.8em",
                        left: "0.4em",
                    },
                    fillColor: false,
                },
                //bodyStyles: {},
                //footStyles: {},
                alternateRowStyles: {fillColor: "#eee"},
            });
            console.log("jspdf pdf after autotable:", pdf)
            newpdf.save(filename);
            console.log("jspdf pdf after save:", pdf)


        }


    })



    /*
     body {background-color: FFF;color: #333;font-family: 'Open Sans',sans-serif;font-size:.95rem; font-weight: 300;}
        h1   {font-family: 'Open Sans', sans-serif;font-size:2.6em;font-weight: 400;margin:0 0 1.25em 0;}
        h2   {font-family: 'Oswald', sans-serif;font-weight: 400;margin-bottom:.5em;}
        h3   {font-family: 'Open Sans', sans-serif;font-weight: 400;margin-top:1.5em;margin-bottom:0.2em;}
        h4   {font-weight: 600;margin-top:2em;}
        p    {font-weight: 100;margin-top:0em;}
        small, .small {font-size: .8em;font-weight: 400;}
        table   {border: 1px solid #f1f2f3;line-height: 2;text-align: left;}
        th   {font-size:smaller;font-weight:600;padding-right:0.8em;padding-left:.4em;}
        td   {border-top: 1px solid #f1f2f3;padding-right:1em;padding-left:1em;font-size: small;}
        ul   {font-size: small;}
​
        .light {font-weight:100;color: gray}  
        .list-unstyled {margin-top:0.5em;padding-left: 0.5em;list-style: none;}
        .center {text-align: center;}
        .fas {font-size: .85em; margin:4px;}
        .filters{padding:1em;}
        .filtered {font-weight:400;}
        .nofilter {font-weight:300;color:#888;}        
        .table-striped tbody tr:nth-of-type(odd) {background-color: #eee;}
​
        #container  {height:1056px;width:816px;}
        #pdfTable {padding:1em;}
    */
   
}

function mapStateToProps(state) {
    return {
        tickets: state.tickets,
    };
  }
  
  function mapDispatchToProps(dispatch) {
    return {
        setValuesForPDF: (items, filter) => dispatch(setValuesForPDF(items, filter)),
        getClosedTickets: (idsite, idgenerator) => dispatch(getClosedTickets(idsite, idgenerator)),
    };
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(Archive)