import config from '../constants/config';

export const stripeService = {
    charge,
};

function charge (chargeInfo) {
    const requestOptions = {
        method:     'POST',
        headers:    { 'Content-Type': 'application/json' },
        body:       JSON.stringify(chargeInfo)
    };
    return fetch(`${config.apiUrl}/stripe/charge/`, requestOptions);
}
