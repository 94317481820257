import React, {Component} from 'react';
import ajax from 'superagent';
import config from '../../../../constants/config';
import moment from 'moment';
import SuccessPopup from '../../../Popups';
import OptInModal from '../OptInModal';

export default class OptButton extends Component {

	constructor(props){
		super(props);

		let paymentPeriods = props.agreement.paymentPeriods;

		this.state = {
			site: props.site,
			idsite: props.site.idsite,
			user: JSON.parse(localStorage.getItem('user')),
			
			optData: null,
			paymentPeriods,

			initiateOptTitle: "Default ME",
			initiateOptMessage: "Default ME",
			showInitiateOptPopup: false,

			completeOptTitle: "Default ME",
			completeOptMessage: "Default ME",
			showCompleteOptPopup: false,

			checked: false,
		}

	}

	agreementIsPromoForThisSite = () => {
		//console.log("Calling agreementIsPromoForThisSite");
		let {agreement} = this.props;

		if (!agreement) return false;

		let {agreement_type, iddump, idsource} = agreement;
		let {idsite} = this.state;

		if (agreement_type == "normal"){
			//console.log("agreementIsPromoForThisSite Agreement type is normal, returning false");
			return false;
		}
		else if (agreement_type == "free"){
			//console.log("agreementIsPromoForThisSite Agreement is free, returning true");
			return true;
		}
		else if (agreement_type == "promo_for_exporter" && idsource == idsite){
			//console.log("agreementIsPromoForThisSite Agreement is promotional for me, the exporter, returning true");
			return true;
		} 
		else if (agreement_type == "promo_for_importer" && iddump == idsite){
			//console.log("agreementIsPromoForThisSite Agreement is promotional for me, the importer, returning true");
			return true;
		} 

		//console.log("agreementIsPromoForThisSite None of the cases fit");
		
		return false
	}

	initiateOpt = (e) => {
		e.preventDefault();

		console.log(this.agreementIsPromoForThisSite());

		let {agreement, canChangePaymentOptions} = this.props;
		let {idsite, checked} = this.state;

		if (agreement){
			if (!canChangePaymentOptions) return;
			else if (this.agreementIsPromoForThisSite()) return;
			else {
				ajax.get(config.apiUrl + '/projects/togglePaymentData/' + agreement.idproject + '/' + idsite)
				.then((res)=> {
					console.log("togglePaymentData res", res.body.data);
					let {data} = res.body;
					console.log("Calling togglePayment Data");
					console.log(data);
					this.setState({
						initiateOptTitle: data.title,
						initiateOptMessage: data.msg,
						optData: data
					});

					setTimeout(() => { this.setState({showInitiateOptPopup: true})}, 0)
				})
			}
		} else {
			if (!checked) {
				this.openOptInModal();
			}
		}

	}

	openOptInModal = () => {
		console.log("OptButton", "openOptInModal is being called");
		this.setState({showInitiateOptPopup: false})
		let {agreement} = this.props;
		let idproject = 0;
		if (agreement) idproject = agreement.idproject;
		document.getElementById('open-premium-'+idproject).click();
	}

	completeOpt = (info) => {
		let {agreement, setPaymentInfo} = this.props;
		let {checked} = this.state;
		console.log("OptButton", "completeOpt is being called");
		this.setState({showInitiateOptPopup: false});

		if (info){
			let {validPaymentInfo} = info;
			if (setPaymentInfo) setPaymentInfo(info);
			if (!validPaymentInfo || validPaymentInfo === {}) return;
		}

		if (agreement){
			let data = this.state.optData || {};
			data.idsite = this.state.idsite;
			data.idproject = agreement.idproject;

			ajax.post(config.apiUrl + '/projects/togglePayment/')
			.send(data)
			.then((res)=> {
				let {paymentPeriods, data} = res.body;
				console.log(paymentPeriods);
				this.setState({
					completeOptTitle: data.title,
					completeOptMessage: data.msg,
					paymentPeriods
				})

				setTimeout(() => {this.setState({showCompleteOptPopup: true})}, 0)
			})
		} else {
			console.log("OptButton", "completeOpt branch to set checked to true is being called.");
			this.setState({checked: !checked});
		}
	}

	hasActiveToggle = () => {
		let {agreement} = this.props;
		let {idsite} = this.state;

		if (!agreement) {
			return true;
		}

		else {
			return (
				(idsite == agreement.idsource && agreement.initiator == "dump_site") ||
				(idsite == agreement.iddump && agreement.initiator == "source_site") 
			);
		}

	}

	isChecked = () => {
		console.log("OptButton isChecked", "calling is checked");
		let {paymentPeriods, checked, validPaymentInfo} = this.state;
		let {agreement} = this.props;

		let ret = false;

		if (!agreement){
			ret = checked;
		}
		else {
			ret = (paymentPeriods.length > 0 && paymentPeriods[0].end_date == null);
		}

		console.log("OptButton isChecked", "Return value:", ret);
		return ret;
	}

	render(){

		if ( this.hasActiveToggle() ){
			return this.renderActiveToggle()
		} else {
			return this.renderStaticToggle();
		}

	}

	renderActiveToggle = () => {
		let {agreement, canChangePaymentOptions, label, recipient, newSite, idsite} = this.props;
		let {initiateOptTitle, initiateOptMessage, showInitiateOptPopup, 
			completeOptTitle, completeOptMessage, showCompleteOptPopup,
			paymentPeriods} = this.state;

		return(<React.Fragment>

			<div className="switch" onClick={(e) => this.initiateOpt(e)}>
                <input type="checkbox" 
                  name="optingIn" 
                  id="optingIn" 
                  className="switch-input" 
                  checked={this.isChecked()}
                  disabled={!canChangePaymentOptions || this.agreementIsPromoForThisSite()}
                />
                <label className="switch-label">{label ? label : null}</label>
            </div>


			<SuccessPopup
                popupId={"initiateOpt"+agreement.idproject}
                title={initiateOptTitle}
                message={initiateOptMessage}
                trigger={showInitiateOptPopup}
                callback={() => {
                	if (!this.isChecked()){
                		this.openOptInModal()
                	}
                	else {
                		this.completeOpt();
                	}
                }}
                closeCallback = {() => {
                	this.setState({showInitiateOptPopup: false})
                }}
                useButton = {true}
                style="primary"
                buttonLabel = "Confirm"
                />
            <SuccessPopup
                popupId={"completeOpt"+agreement.idproject}
                title={completeOptTitle}
                message={completeOptMessage}
                trigger={showCompleteOptPopup}
                callback={() => this.setState({showCompleteOptPopup: false})}
                useButton = {false}
                style="success"
                buttonLabel = "Yes I'm Sure"
                />

            <OptInModal 
            	callback={this.completeOpt} 
            	idproject={agreement ? agreement.idproject : 0}
            	idsite={idsite}
            	newSite={!!newSite}
            	recipient={!!recipient}
        	/>
		</React.Fragment>
		)

	}

	renderStaticToggle = () => {
		return(
			<div className="switch">
		        <input type="checkbox" 
		          name="optingIn" 
		          id="optingIn" 
		          className="switch-input" 
		          checked={true}
		          disabled={true}
		        />
		        <label className="switch-label"></label>
		    </div>
	    )
	}

}