import {
    GET_USER_REQUESTED,
    GET_USER_REJECTED,
    GET_USER_FULFILLED,
} from '../actions/types';

const initialState = {
    user: {
        inProgress: false,
        success: '',
        error: '',
        data: null,
    },
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_USER_REQUESTED: {
            const { inProgress } = action;
            return Object.assign({}, state, {
                user: {
                    inProgress
                }
            });
        }
        case GET_USER_REJECTED: {
            const { inProgress, success, error } = action;
            return Object.assign({}, state, {
                user: {
                    inProgress,
                    success,
                    error
                }
            });
        }
        case GET_USER_FULFILLED: {
            const { inProgress, success, error, data } = action;
            if(data.success){
                return Object.assign({}, state, {
                    user: {
                        inProgress,
                        success,
                        error,
                        data: data.user[0]
                    }
                });
            }else{
                return Object.assign({}, state, {
                    user: {
                        inProgress,
                        success: false,
                        error: true,
                        data: null
                    }
                });
            }
        }
        
        default:
            return state;
    }
  };
  