import React, {Component, Fragment} from 'react';
import ajax, {Request} from 'superagent';
import MenuAppBar from '../NavBar';
import Footer from '../Footer';
import config from '../../constants/config';
import pageUrls from '../../constants/pageUrls';
import _ from 'lodash';
import Combobox from '../Combobox';
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react'
import { connect } from 'react-redux';
import { getUser } from '../../actions/user';
import { getStripePaymentMethod, getStripeCustomer } from '../../actions/stripe';
import { withRouter } from "react-router-dom";
import {apiService} from '../../services';
import SuccessPopup from '../Popups';
import CCForm from '../StripeComponents/OptInToPayment/CCForm';
import OptButton from '../StripeComponents/OptInToPayment/OptButton';
import jwtDecode from 'jwt-decode';


class NewProject extends Component {
    constructor(props){
        super(props);
        let user = localStorage.getItem('user');
        let userId = user ? JSON.parse(user).id : null;

        let site = {idsite:null, name: ""}
        if (!props.recipient){
            let localSite = JSON.parse(localStorage.getItem('site'))
            site = ((localSite && localSite.idsite && localSite.name) ? localSite : {idsite: null, name: ""});
        }

        let token = jwtDecode(localStorage.getItem('token'));

        let projectdata = null;
        let encryptedProjectdata = null;
        if (this.props.match && this.props.match.params.projectdata != undefined){
            encryptedProjectdata = this.props.match.params.projectdata;
            projectdata = JSON.parse(window.atob(encryptedProjectdata));
        }

        this.state = {
            adminBothSides: false,
            customer: '',
            dumpSite: '',
            dumpSitesList: [],
            emptyProfileList: null,
            //generator: '',
            isDisabled: false,
            markerPosition: {lat: 43.03501925228261, lng: -87.90833392173009},
            memberEmail: '',
            memberRole: '',
            newSiteName: null,
            partnersList: [],
            profilesList: [],
            profilesToSend: [{idsoilprofile: 0}],
            projectLeaderSite: 1,
            proposedPartner: '',
            newPartnerEmail: null,
            soilCategory: '',
            soilDescription: '',
            soilProfilesList: [],
            sourceSite: '',
            sourceSitesList: [],
            sitesList: [],
            site: {idsite: parseInt(site.idsite), name: site.name},
            teamMembersToSend: [{idprofile: 1, email: 'InviteNewPartner', idsite: "2"}],
            token,
            tripCycle: 5,
            //transporter: '',
            //transportersList: [],
            //transportersToSend: [''],
            userId,
            stripePayment: null,
            projectdata,
                encryptedProjectdata,
            partner: null,
            successPopupTriggerAdminAgreementCreated: false,
            successPopupTriggerCollabAgreementCreated: false,
            successPopupTriggerCollabAgreementCompleted: false,
            validPaymentInfo: false,
            optingInToPayment: !this.props.recipient,
            shareSite: null,
                alreadyShared: false,
            submitClicked: false,
            price: "",
        }

        this.renderSoilProfilesOptions = this.renderSoilProfilesOptions.bind(this);
        this.renderSitesOptions = this.renderSitesOptions.bind(this);
        this.renderSitesCombobox = this.renderSitesCombobox.bind(this);
        this.submitIsDisabled = this.submitIsDisabled.bind(this);
        this.makeName = this.makeName.bind(this);
        this.sendInformation = this.sendInformation.bind(this);
        this.goBack = this.goBack.bind(this);
        //this.renderTransportersCombobox = this.renderTransportersCombobox.bind(this)
    }

    async componentWillMount(){

        if (this.state.projectdata){
            console.log("Projectdata is valid");

            this.setState({site: {idsite: null, name: ''}});

            ajax.get(`${config.apiUrl}/projects/initiatorInfo/` + this.state.encryptedProjectdata)
                .end((error, response) => {
                    if (!error && response) {
                        let {partner} = response.body;
                        this.setState({partner});
                    } else {
                        console.log('There was an error fetching', error);
                    }
                }
            );

            ajax.get(`${config.apiUrl}/sites/getUserFromSiteUser/` + this.state.projectdata.idsiteuser)
                .end((error, response) => {
                    if (!error && response) {
                        let user = response.body.user[0];

                        if (user.iduser != this.state.userId){
                            this.goBack();
                        }
                    } else {
                        console.log('There was an error fetching', error);
                    }
                }
            );

            ajax.get(`${config.apiUrl}/projects/` + this.state.projectdata.idproject)
                .end((error, response) => {
                    if (!error && response) {
                        let project = response.body.projectsList[0];

                        if (project.iddump && project.idsource) this.goBack();

                        this.setState({project, tripCycle: project.tripCycleLength}, console.log(this.state.project));

                        let getSite = project.idsource;
                        let verb = "export";
                        console.log("GET SITE:");
                        console.log(getSite);
                        console.log(project.iddump)
                        if (getSite == null){
                            console.log("getSite was null")
                            getSite = project.iddump;
                            verb = "import";
                        }

                        ajax.get(`${config.apiUrl}/sites/` + getSite)
                            .end((error, response) => {
                                if (!error && response) {
                                    let otherSiteName = response.body.sitesList[0].name;

                                    this.setState({otherSiteName, verb}/*, alert("State is set")*/);
                                } else {
                                    console.log('There was an error fetching', error);
                                }
                            }
                        );
                    } else {
                        console.log('There was an error fetching', error);
                    }
                }
            );

            ajax.get(`${config.apiUrl}/soilprofiles/projectid/` + this.state.projectdata.idproject)
                .end((error, response) => {
                    if (!error && response) {
                        console.log("Soil response:");
                        console.log(response.body);
                        if (response.body.success)
                            this.setState({profilesToSend: response.body.soilProfiles})
                        else
                            this.setState({emptyProfileList: true})
                    } else {
                        console.log('There was an error fetching', error);
                    }
                }
            );

        }

        console.log("Debug BUG-863", "readSiteAndProjectDataByUserId full URL: ", `${config.apiUrl}/sites/readSiteAndProjectDataByUserId/${this.state.userId}`)
        ajax.get(`${config.apiUrl}/sites/readSiteAndProjectDataByUserId/${this.state.userId}`)
            .end((error, response) => {
                if (!error && response) {
                    console.log("Got site list");
                    let sitesList = response.body.sitesList;
                    console.log("Debug BUG-863", "The sites list endpoint returned with the following response:", response);
                    this.setState({sitesList: response.body.sitesList})
                } else {
                    console.log("Debug BUG-863", "The sites list endpoint returned with an error:", error);
                    console.log('There was an error fetching', error);
                }
            }
        );
        ajax.get(`${config.apiUrl}/soilprofiles`)
            .end((error, response) => {
                if (!error && response) {
                    this.setState({soilProfilesList: response.body.soilProfilesList})
                } else {
                    console.log('There was an error fetching', error);
                }
            }
        );
      
        ajax.get(`${config.apiUrl}/users/id/` + this.state.userId)
            .end((error, response) => {
                if (!error && response) {
                    this.setState({userEmail: response.body.user[0].email})
                } else {
                    console.log('There was an error fetching', error);
                }
            }
        );

       /* ajax.get(config.apiUrl + '/sites/permissions/' + this.state.userId + '/' + this.state.site.idsite)
            .end((error, response) => {
                if (!error && response) {
                    console.log(response.body);
                    if (response.body.permissions.canAddAgreements === false) this.goBack();
                } else {
                    console.log('There was an error fetching', error);
                }
            }
        );*/

        console.log("Debug BUG-863", "disposalpartnerleadersbyuser full URL: ", config.apiUrl + '/users/disposalpartnerleadersbyuser/' + this.state.userId)
        ajax.get(config.apiUrl + '/users/disposalpartnerleadersbyuser/' + this.state.userId)
            .end((error, response) => {
                if (!error && response) {
                    let {partnersList} = response.body;
                    console.log("Debug BUG-863", "The partners list endpoint returned with the following response:", response);
                    this.setState({partnersList: response.body.partnersList});
                } else {
                     console.log("Debug BUG-863", "The partners list endpoint returned with an error:", error);
                    console.log('There was an error fetching', error);
                }
            }
        );

        if (document.referrer.indexOf("my-partners") != -1){
            let partner = JSON.parse(localStorage.getItem('partner'));
            this.setState({
                site: {idsite: null, name: ""},
                teamMembersToSend: [{email: partner.email, idprofile: 1, idsite: "1"}]
            })

        }

        let price = await apiService.getADPrice();
        this.setState({price});

        //this.props.getUser(this.state.userId);
    }

    getSiteShared = (passedEmail) => {

        console.log("getSiteShared called");

        if (this.state.newSiteName || this.state.site.idsite == null){
            this.setState({alreadyShared: false});
            console.log("getSiteShared early out due to no site or new site")
            return;
        }

        let idsite = this.state.site.idsite;
        let idproject = 0;
        let email = "none";

        if (this.state.projectdata){
            idproject = this.state.projectdata.idproject || 0;
        } else {
            email = this.state.teamMembersToSend[0].email || passedEmail ;
            console.log("getSiteShared email", email);

            if (email == "InviteNewPartner"){
                email = this.state.newPartnerEmail;
            }

            let emailRegexp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
            let validEmail = emailRegexp.test(email);

            if (!validEmail) email = "none";
        }

        console.log("getSiteShared params: ", idsite, email, idproject);

        ajax.get(`${config.apiUrl}/projects/siteHasBeenShared/${idsite}/${email}/${idproject}`)
            .end((error, response) => {
                if (!error && response) {
                    console.log(response.body);
                    if (response.body.success){
                        let {siteHasBeenShared} = response.body;
                        console.log("getSiteShared response body", response.body);
                        if (siteHasBeenShared){
                            this.setState({shareSite: true, alreadyShared: true})
                        } else {
                            this.setState({shareSite: this.state.shareSite, alreadyShared: false})
                        }
                    }
                } else {
                    console.log('There was an error fetching', error);
                }
            }
        );
    }

    componentWillReceiveProps(newProps){
        if(newProps.user.inProgress != this.props.user.inProgress){
            if(newProps.user.success && newProps.user.data.stripe_token){
                this.props.getStripePaymentMethod(newProps.user.data.stripe_token);
            }
        }
        if(newProps.stripe.paymentMethod.inProgress != this.props.stripe.paymentMethod.inProgress){
            if(newProps.stripe.paymentMethod.success){
                this.setState({stripePayment: newProps.stripe.paymentMethod.data});
            }
        }
    }

    renderLeftColumn = () => {
        return(<div class="col-12 col-lg-5 ml-auto mr-2 pb-0">
            <p class="lead border-bottom bg-pale-primary mb-0 pl-3"><strong>
                {this.props.recipient ? "My Partner" : "My Site"}
            </strong></p>
            <div class="p-4">
                {this.renderImportExport()}
                {this.renderTripCycle()}
                {!this.props.recipient ? this.renderShareSites() : null}

                {this.props.recipient && this.state.project && this.state.project.siteShared != "neither" ?
                    <div class="mb-6">
                        <p class="text-dark"><strong>Your partner has shared their site location with you.</strong><br />It will now appear in your sandbox map.</p>
                    </div>
                : null }

            </div>
        </div>)
    }

    renderImportExport = () => {

        if (!this.props.recipient){
            return(<Fragment>

                <div class="mb-6">
                    <label class="border-bottom mb-0">Select one of your sites</label>
                    <span>
                        <select class="form-control form-control-sm text-right p-0"
                            value={JSON.stringify(this.state.site)}
                            onChange={(e) => {
                                this.setState({site: JSON.parse(e.target.value)}, () => {
                                    this.getSiteShared();
                                });
                            }}>

                                <option value={JSON.stringify({idsite: null, name: ""})}></option>
                                {this.state.sitesList.map((site, key) => {
                                    let {idsite, name} = site;
                                    return <option value={JSON.stringify({idsite, name})} key={key}>{site.name}</option>
                                })}

                        </select>
                    </span>
                </div>

                <div class="mb-6">
                    <label class="border-bottom">Which end of the agreement is your site?</label>
                    <span>
                        <select class="form-control form-control-sm text-right pl-0"
                            value={this.state.teamMembersToSend[0].idsite}
                            onChange={e => {
                            this.updateUserSite(0, e.target.value);}}>
                                <option value={2}>Export Site</option>
                                <option value={1}>Import Site</option>
                        </select>
                    </span>
                </div>

            </Fragment>);

        } else {

            return(<React.Fragment>

                {this.state.partner ?
                <div class="mb-6">
                    <label class="col-12 border-bottom pl-0 small">Your Partner has initiated a new agreement:</label>
                    <p class="text-dark"><strong>{this.state.partner.name}</strong> at <strong>{this.state.partner.companyname}</strong> would like to connect.</p>
                </div>  : null}
                <div class="mb-6">
                    <label class="col-12 border-bottom pl-0 small">They have set it up so that...</label>
                    <p class="text-dark"><strong>{this.state.otherSiteName} </strong>will be the <strong>{this.state.verb} site</strong>.</p>
                </div>

            </React.Fragment>)

        };
    }

    renderTripCycle = () => {

        if (!this.props.recipient){
            return(
                <div class="mb-6" style={this.state.newSiteName ? {'paddingTop': '40px'} : null}>
                    <label class="border-bottom">What is the approximate travel time to the other site?</label>
                    <span>
                        <select class="form-control form-control-sm text-right pl-0"
                            value={this.state.tripCycle}
                            name="tripCycle"
                            onChange={this.handleChange}>
                                <option value={5}>5 Minutes</option>
                                <option value={10}>10 Minutes</option>
                                <option value={15}>15 Minutes</option>
                                <option value={20}>20 Minutes</option>
                                <option value={25}>25 Minutes</option>
                                <option value={30}>30 Minutes</option>
                                <option value={35}>35 Minutes</option>
                                <option value={40}>40 Minutes</option>
                                <option value={45}>45 Minutes</option>
                                <option value={50}>50 Minutes</option>
                                <option value={55}>55 Minutes</option>
                                <option value={60}>1 Hour</option>
                                <option value={65}>1 Hour 5 Minutes</option>
                                <option value={70}>1 Hour 10 Minutes</option>
                                <option value={75}>1 Hour 15 Minutes</option>
                                <option value={80}>1 Hour 20 Minutes</option>
                                <option value={85}>1 Hour 25 Minutes</option>
                                <option value={90}>1 Hour 30 Minutes</option>
                        </select>
                    </span>
                </div>
            )
        } else {
            let tripCycleLength = 90;
            if (this.state.project) {
                tripCycleLength = this.state.project.tripCycleLength;
            }
            if (tripCycleLength > 60){
                let hours = Math.floor(tripCycleLength / 60);
                let minutes = tripCycleLength % 60;
                tripCycleLength = hours + "hours, " + minutes;
            }
            return (
                <div class="mb-6">
                    <label class="col-12 border-bottom pl-0 small">The approximate one-way travel time is...</label>
                    <p class="text-dark">{tripCycleLength} minutes</p>
                </div>
            )
        }

    }

    renderRightColumn = () => {
       return(<div class="col-12 col-lg-5 mr-auto ml-lg-2 mb-lg-2 pb-0">
            <p class="lead border-bottom bg-pale-primary mb-0 pl-3"><strong>
                {this.props.recipient ? "My Site" : "My Partner"}
            </strong></p>
            <div class="p-4">
                {this.props.recipient ? this.renderProposedPartner() : null}
                {this.renderAgreementPartner()}
                {!this.props.recipient ? this.renderProposedPartner() : null}
                {this.props.recipient ? this.renderShareSites() : null}
            </div>
        </div>)
    }


    renderAgreementPartner = () => {

        if (!this.props.recipient) {

            return(<Fragment>
                <div class="mb-2">
                    <label class="border-bottom">Select or invite your partner</label>
                    <span>
                        <select class="form-control form-control-sm text-right pl-0"
                            value={this.state.teamMembersToSend[0].email || ""}
                            onChange={(e) => {this.updateEmail(0, e.target.value)}}>
                            <option value={"InviteNewPartner"} selected>New Partner</option>
                            {
                                this.state.partnersList.map((partner, key) => {
                                    return <option value={partner.email} key={key}>{partner.name}</option>
                                })
                            }
                        }
                        </select>
                    </span>
                </div>

                {this.state.teamMembersToSend[0].email == "InviteNewPartner" ?
                    <div class="mb-6">
                        <input class="form-control form-control-sm pl-0"
                            placeholder="Partner's Email Address"
                            type="email"
                            value={this.state.newPartnerEmail}
                            name="newPartnerEmail"
                            onChange={this.handleChange}
                        />
                    </div>
                :null}

           </Fragment> )
        } else if (this.props.recipient) {
            return (<Fragment>
                <label class="border-bottom mb-0">Select or add the site you want to connect</label>
                {this.renderSitesCombobox()}
            </Fragment>)
        }
    }

    renderProposedPartner = () => {

        if (this.props.recipient == false){
            return(
                <div class="mb-6">
                    <label class="border-bottom">Which site of theirs are you referring to?</label>
                    <input class="form-control form-control-sm pl-0"
                        type="text" placeholder="Location or Name"
                        value={this.state.proposedPartner}
                        name="proposedPartner"
                        onChange={this.handleChange} />
                </div>
            )
        } else {
            if (this.state.project && this.state.project.proposedpartner){
                return (
                    <p class="text-dark">They are requesting to connect with <strong>{this.state.project.proposedpartner}</strong>.</p>
                )
            } else return null;
        }
    }

    renderSitesOptions(){
        return this.state.sitesList.map(r => {
            return <option key={r.idsite} value={r.idsite}>{r.name}</option>
        })
    }

    renderSitesCombobox(){

        const sourceSite = this.state.site ? _.find(this.state.sitesList, (o) => { return o.idsite === this.state.site; }) : null;
        return (
            <div class="contrast-div">
            <Combobox
                items={this.state.sitesList}
                value={this.state.site ? this.state.site.name : ''}
                identifier="idsite"
                onSelect={item => {
                    this.setState({
                        site: item
                    }, () => {
                        this.getSiteShared()
                    })
                }}
                className="contrast-div form-control pl-0"
                renderItem={item => item.name}
                normalStyle={{backgroundColor: 'white'}}
                hoverStyle={{backgroundColor: '#ddd'}}
                filterOptions={(site, val, other) => {
                    return site.name ? site.name.toLowerCase().startsWith(val.toLowerCase()) : false;
                }}
                disabled={this.state.isDisabled}
                showAddOption={true}
                onAddElement={(value) => {
                    this.setState({newSiteName: value}, () => {
                        this.getSiteShared()
                    });
                }}
            />
            {this.state.newSiteName !== null ?
                <div style={{height: '400px'}}>
                    <Map
                        google={this.props.google}
                        style={{ height: "100%", maxHeight: '300px' }}
                        id="mapId" zoom={14}
                        initialCenter={{
                          lat: 43.03501925228261,
                          lng: -87.90833392173009
                        }}

                        onClick={this.mapClicked}>
                            <Marker
                                title={'Here'}
                                position={this.state.markerPosition}
                            />
                    </Map>
                </div>
            : null }
            </div>
        )
    }

    mapClicked = (mapProps, map, clickEvent) => {
        const { latLng } = clickEvent;
        const lat = latLng.lat();
        const lng = latLng.lng();
        this.setState({markerPosition: { lat, lng }});
    }

    renderSoilProfilesOptions(){
        return this.state.soilProfilesList.map(r => {
            return <option key={r.idsoilprofile} value={r.idsoilprofile}>{r.profile}</option>
        })
    }

    updateUserProfile(key, value) {
        this.setState(state => {
            const teamMembersToSend = state.teamMembersToSend.map((item, j) => {
                if(j == key){
                    return Object.assign({}, item, {idprofile: value});
                }else{
                    return item;
                }
            });
            return {
                teamMembersToSend
            }
        })
    }

    updateUserSite(key, value) {
        this.setState(state => {
            const teamMembersToSend = state.teamMembersToSend.map((item, j) => {
                if(j == key){
                    return Object.assign({}, item, {idsite: value});
                }else{
                    return item;
                }
            });
            return {
                teamMembersToSend
            }
        })
    }

    updateEmail(key, value) {
        let {teamMembersToSend} = this.state;
        teamMembersToSend[0].email = value;
        this.setState({teamMembersToSend}, () => {
            this.getSiteShared(value)
        });
    }

    updateSoilProfile(key, value) {
        console.log("Calling updateSoilProfile")
        this.setState(state => {
            const profilesToSend = state.profilesToSend.map((item, j) => {
                if(j == key){
                    return Object.assign({}, item, {idsoilprofile: value});
                }else{
                    return item;
                }
            });
            return {
                profilesToSend
            }
        })
    }

    updateSoilDescription(key, value) {
        this.setState(state => {
            const profilesToSend = state.profilesToSend.map((item, j) => {
                if(j == key){
                    return Object.assign({}, item, {soildescription: value});
                }else{
                    return item;
                }
            });
            return {
                profilesToSend
            }
        })
    }

    renderMaterials = () => {
        return(
            <div class="col-12 col-lg-10 mx-lg-auto py-0 pl-lg-1">

                <p class="lead border-bottom bg-pale-primary mb-2 pl-3"><strong>Materials</strong></p>
                <label class="col-12 border-bottom pb-0 mb-0">What materials will be hauled?</label>
                {!this.props.recipient ? <em class="small-3 col-12 pb-3 pl-4">You may leave this for your partner to fill in.</em> : null}
                <div class="px-4">

                    <div class="form-group mt-1 pb-0">

                        {this.renderAddProfiles()}

                        <div class="col-12 row gap-y my-4">
                             <button type="button"
                                class="btn btn-outline-dark px-4 mr-3"
                                onClick={this.addNewProfileRow.bind(this)}>
                                    <i class="fa fa-plus"></i>
                            </button>
                        </div>

                    </div>


                </div>

            </div>
        );
    }

    renderAddProfileRow (key){
        return (<Fragment>
            <div class="col-12 row gap-y my-4">
                <select class="form-control form-control-sm col-lg-3 py-0 mr-5 mb-2 pl-0"
                    value={this.state.profilesToSend[key].idsoilprofile || 0}
                    onChange={e => {this.updateSoilProfile(key, ~~e.target.value); }}>
                        <option value={0} disabled={true}>Material Type</option>
                        {this.renderSoilProfilesOptions()}
                </select>
                <input class="form-control form-control-sm col-lg-5 mr-5 mb-2 pl-0" type="text" placeholder="Description"
                    value={this.state.profilesToSend[key].soildescription || ""}
                    onChange={(e) => {this.updateSoilDescription(key, e.target.value)}} />
            </div>

        </Fragment> );
    }

    renderAddProfiles() {
        return this.state.profilesToSend.map((item, index) => {
            return this.renderAddProfileRow(index)
        })
    }

    renderShareSites() {

        if (this.state.alreadyShared){
            return(
                <div class="mb-6">
                    <p class="text-dark">You have already shared this site with your partner.</p>
                </div>
            )
        }

        else {
            return (
                <div class="mb-6">
                    <label class="border-bottom">Would you like to share your site's location with your partner?</label>
                    <div class="custom-control custom-radio custom-control-inline" onClick={() => this.setState({shareSite: true})}>
                        <input
                            type="radio"
                            id="customRadioInline1"
                            name="customRadioInline1"
                            class="custom-control-input"
                            checked={this.state.shareSite === true}
                         />
                        <label class="custom-control-label" for="customRadioInline1">Yes, it will help them find a route to us</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline"  onClick={() => this.setState({shareSite: false})}>
                        <input
                            type="radio"
                            id="customRadioInline2"
                            name="customRadioInline1"
                            class="custom-control-input"
                            checked={this.state.shareSite === false} />
                        <label class="custom-control-label" for="customRadioInline2">No thanks</label>
                    </div>
                </div>
            )
        }

    }

    addNewProfileRow(){
        console.log("Calling addNewProfileRow");
        const profiles = this.state.profilesToSend;
        const last = profiles.length - 1;
        if(profiles[last].soildescription != "" && profiles[last].idsoilprofile != 0){
            this.setState(state => {
                const profilesToSend = [...state.profilesToSend, {idsoilprofile: 0}];
                return {profilesToSend}
            }, console.log(this.state.profilesToSend))
        }
    }

    handleChange = (e) =>{
        if (e.target){
            let {name, value} = e.target;
            this.setState({
                [name]: value,
            }, () => {
                if (name == "newPartnerEmail"){
                    this.getSiteShared();
                }
            })
        }
    }

    makeName(){

        let sourceSiteName = "[Source Site TBD]";
        let dumpSiteName = "[Dump Site TBD]";
        let idsource = null;
        let iddump = null;

        if (!this.props.recipient){

            if (this.state.teamMembersToSend[0].idsite === "2"){
                sourceSiteName = this.state.site.name;
                idsource = this.state.site.idsite;
            }

            else if (this.state.teamMembersToSend[0].idsite === "1"){
                dumpSiteName = this.state.site.name;
                iddump = this.state.site.idsite;
            }

            if (this.state.adminBothSides){
                if (sourceSiteName === "[Source Site TBD]")
                    sourceSiteName = this.state.customer;
                else
                    dumpSiteName = this.state.customer;
            }
        }

        else {
            console.log("makeName detecting this is the recipient");
            sourceSiteName = this.state.otherSiteName;
            dumpSiteName = this.state.site.name;

            if (this.state.verb === "import from"){
                sourceSiteName = this.state.site.name;
                dumpSiteName = this.state.otherSiteName;
            }
        }

        let data = {
            name: sourceSiteName + " - " + dumpSiteName,
            idsource, iddump
        }

        return data;
    }

    sendInformation(e) {
        e.preventDefault();
        let {name, idsource, iddump} = this.makeName();
        this.setState({submitIsDisabled: true});

        const data = {name, idsource, iddump,
            webUrl: config.webUrl,
            projectSoils: this.state.profilesToSend,
            tripCycle: this.state.tripCycle,
            shareSite: this.state.shareSite,
        };

        console.log(data);


        if (!this.props.recipient){

            let user = localStorage.getItem('user');
            let userId = user ? JSON.parse(user).id : null;

            data.datecreated = ((new Date()).toISOString().substr(0,10))
            data.customername= this.state.customer;
            data.idgenerator= userId;
            data.generatorname= null;
            data.users= this.state.teamMembersToSend.concat([{idprofile: 1, iduser: this.state.userId, idsite: this.state.projectLeaderSite}]);
            data.idsite = this.state.site.idsite;
            data.inviterSiteName = this.state.site.name;
            data.isAdminProject = this.state.adminBothSides;
            data.optingInToPayment = this.state.optingInToPayment;
            data.proposedpartner = this.state.proposedPartner;
            data.newPartnerEmail = this.state.newPartnerEmail;

            console.log(data);

            ajax.post(`${config.apiUrl}/projects`)
            .send(data)
            .then(res => {
                if (this.state.adminBothSides){
                    this.setState({successPopupTriggerAdminAgreementCreated: true});
                } else {
                    this.setState({successPopupTriggerCollabAgreementCreated: true});
                }
            }).catch(res =>{
                alert('Error creating project!');
            });
        }

        else {

            data.idproject = this.state.projectdata.idproject;
            data.idsite = this.state.site.idsite;
            data.sitename = this.state.newSiteName;
            data.sitecoords = this.state.markerPosition;
            data.idsiteuser = this.state.projectdata.idsiteuser;
            data.optingInToPayment = this.state.optingInToPayment;
            data.iduser = this.state.userId;

            console.log(data);

            if (data.sitename !== null){
                this.submitNewSite(data);
            } else {
                this.updateAgreement(data);
            }

        }
    }

    submitNewSite = (data) => {
        ajax.post(config.eiBackend + "/sites/add")
            .send({
                formValues: {
                    nameProject: data.sitename
                },
                geometry: data.sitecoords,
                userId: this.state.token['cognito:username']
            }).then(res => {
                data.idsite = res.body.assuredSiteId;
                this.updateAgreement(data);
            }).catch(error => {
                alert("Error creating agreement!")
                console.log(error);
            });
    }


    updateAgreement = (data) => {
         ajax.post(`${config.apiUrl}/projects/updateSiteUserAfterAgreement`)
            .send(data)
            .then(res => {
                this.setState({successPopupTriggerCollabAgreementCompleted: true});
            }).catch(error =>{
                alert('Error creating agreement!');
                console.log(error);
            });
    }

    goBack(e) {
        if (document.referrer.indexOf(config.webUrl) == -1){
            window.location.href = config.webUrl;
        } else {
            this.props.history.goBack();
        }
    }

    submitIsDisabled() {
        if (this.state.submitClicked == true) return true;
        else if (this.state.shareSite == null) return true;
        else if (this.props.recipient){
            return !(((this.state.site.idsite || (this.state.newSiteName && this.state.markerPosition)) || this.state.dumpSite || this.state.sourceSite) && this.state.profilesToSend[0].idsoilprofile !== 0 && (this.state.validPaymentInfo || !this.state.optingInToPayment))
        } else if (this.state.adminBothSides) {
            return !(this.state.customer && this.state.profilesToSend[0].idsoilprofile !== 0 && this.state.profilesToSend[0].soildescription !== undefined && this.state.profilesToSend[0].soildescription !== "" && this.state.validPaymentInfo);
        } else {
            let validEmail = true;
            let email = this.state.teamMembersToSend[0].email;
            if (email == "InviteNewPartner"){
                validEmail = !!(this.state.newPartnerEmail)
            } else {
                validEmail = !!(email);
            }
            return !(
                validEmail
                && this.state.site.idsite
                && this.state.teamMembersToSend[0].idsite !== "0"
                && this.state.validPaymentInfo
            )
        }
    }

    setPaymentInfo = ({optingInToPayment, validPaymentInfo}) => {
        this.setState({optingInToPayment, validPaymentInfo});
    }


    render(){
        return(<React.Fragment>
            <MenuAppBar showSandbox={true} />
            <main class="main-content">

                <header class="section-header mt-7 mb-6">
                    <h2><strong>
                        {this.props.recipient ? "Delivery Agreement Setup"  : "New Delivery Agreement"}
                    </strong></h2>
                </header>
                <div class="section pt-5">
                    <div class="container">

                        <div class="row gap-y contrast">
                            <p class="lead p-5 ml-lg-8 pb-0">
                                {this.props.recipient ? "You've been invited to complete the setup process for a new Delivery Agreement." : "Start the setup process and invite your partner to connect their site."}
                            </p>
                        </div>


                        <div class="row gap-y contrast mb-6">
                            {this.renderLeftColumn()}
                            {this.renderRightColumn()}
                        </div>

                        <div class="row gap-y contrast mb-6">
                            {this.renderMaterials()}
                        </div>

                        <div class="row">


                            {this.props.recipient ?

                                <div class="col-lg-10 border-primary mx-auto mb-7 flexbox pb-4 pl-5">
                                    <div class="mt-4">
                                        <p class="lead mb-2 border-bottom">Get Premium Features</p>
                                        <p class="small mt-2 mb-4">As an invited partner, you will have access to basic features for free.</p>
                                        <p class="small mt-2 mb-4">If you would like to view the ticket archive and generate tonnage reports for this agreement,
                                        <br />opt in for these premium features and you will be charged {this.state.price} per valid ticket that is closed on this agreement.</p>

                                        <OptButton
                                            label="Opt-In to Premium Features"
                                            agreement={false}
                                            canChangePaymentOptions={true}
                                            setPaymentInfo = {this.setPaymentInfo}
                                            newSite={!!this.state.newSiteName}
                                            recipient={true}
                                            site={this.state.site}
                                            idsite={this.state.site.idsite}
                                        />

                                    </div>
                                </div>

                            :
                                <div class="col-lg-10 border-primary mx-auto mb-7 flexbox pb-4 pl-5">
                                    <div class="mt-4">
                                        <p class="lead mb-2 border-bottom">Premium Features</p>
                                        <p class="small mt-2 mb-4">As an initiating partner, you will automatically receive Premium Features.</p>
                                        <p class="small mt-2 mb-4">This gives you access to view the ticket archive and generate tonnage reports for this agreement.
                                        <br />You will be charged {this.state.price} per valid ticket that is closed on this agreement.</p>

                                        <CCForm
                                            recipient={this.props.recipient}
                                            returnValidPaymentInfo={this.setPaymentInfo}
                                            idsite={this.state.site.idsite}
                                            newSite={!!this.state.newSiteName}
                                            showOptIn={false}
                                            showSwitch={false}
                                            showHeading={false}
                                        />
                                    </div>
                                </div>
                            }
                        </div>

                        <hr class="col-10" />


                        <div class="row">
                            <div class="col-lg-10 mx-auto mb-6 flexbox pt-4">
                                <a class="btn btn-outline-secondary" href="#" onClick={this.goBack}>Cancel</a>
                                <button class="btn btn-primary" onClick={this.sendInformation}
                                    disabled={this.submitIsDisabled()}
                                    title={this.submitIsDisabled() ? 'Please complete all required fields' : null}>
                                    {(this.props.recipient || this.state.adminBothSides) ? "Complete Setup" : "Submit"}</button>
                            </div>

                        </div>

                    </div>
                </div>
            </main>
            <SuccessPopup
                popupId="collabAgreementCreatedPopup"
                title="Success!"
                message="Invitation to collaborate has been sent!"
                trigger={this.state.successPopupTriggerCollabAgreementCreated}
                callback={() =>  {this.goBack();}}
                useButton = {true}
                disallowClose = {true}
                />

            <SuccessPopup
                popupId="adminAgreementCreatedPopup"
                title="Success!"
                message="Agreement has been created!"
                trigger={this.state.successPopupTriggerAdminAgreementCreated}
                callback={() =>  {this.goBack();}}
                useButton = {true}
                disallowClose = {true}
                />

            <SuccessPopup
                popupId="collabAgreementAcceptedPopup"
                title="Success!"
                message="Agreement is now complete!"
                trigger={this.state.successPopupTriggerCollabAgreementCompleted}
                callback={() =>  {this.goBack();}}
                useButton = {true}
                disallowClose = {true}
                />

            <Footer />
        </React.Fragment>)
    }
}


//export default connect(mapStateToProps, mapDispatchToProps)(NewProject)
export default GoogleApiWrapper({
  apiKey: 'AIzaSyCRTgaqtoxDv1Uj23wnjgFG249oqCJ4uw4'
})(NewProject)
