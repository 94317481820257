import React, {Fragment, Component} from 'react';
import Agreement from './Agreement';
import pageUrls from '../../constants/pageUrls';

export default class Partner extends Component {

    constructor(props){
        super(props);

        this.state = {
            
        }
    }

    addAgreement = () => {
    	let {partner} = this.props;
    	localStorage.setItem('partner', JSON.stringify(partner));
    	localStorage.setItem('site', JSON.stringify({idsite: 0, sitename: "", name: ""}));
    	window.location.href = pageUrls.newDisposalAgreement;
    }

    render = () => {

    	let {partner} = this.props;

        return(<Fragment>

        	<div class="accordion" id={"accordion-"+partner.iduser}>

				<div class="card">
					<p class="card-title lead fw-700">
						<a class="collapsed" data-toggle="collapse" href={"#collapse-"+partner.iduser}>{partner.name}</a>
					</p>

					<div id={"collapse-"+partner.iduser} class="collapse" data-parent={"#accordion-"+partner.iduser}>
						<div class="card-body pt-0">

							<div class="row mb-4 pl-0 pt-1 border-bottom border-top flexbox">
								<label class="ml-4">Our Agreements</label>
								<a class="small link text-primary strong text-right pb-1 mr-4" href={pageUrls.newDisposalAgreement} onClick={this.addAgreement}>+ Add an Agreement</a>
							</div>
		    	

							{
								partner.projects.sort((a,b) => {
									if (a.lastClosedTicket == null && b.lastClosedTicket != null) return -1;
									else if (a.lastClosedTicket == null && b.lastClosedTicket == null) return 0;
									else if (a.lastClosedTicket != null && b.lastClosedTicket == null) return 1;
					                else return (new Date(b.lastClosedTicket)).getTime() - (new Date(a.lastClosedTicket)).getTime();
						        }).map((agreement, key) => {
									return <Agreement 
										agreement={agreement}
										key={key}
									 />
								})
							}

						</div>
					</div>
				</div>
			</div>
        </Fragment>)
    }
}