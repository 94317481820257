import React, { Component } from 'react';
import MenuAppBar from '../NavBar';

export default class ProjectDash extends Component {
    constructor(props) {
        super(props);
        let user = localStorage.getItem('user');
        let userId = user ? JSON.parse(user).id : null;
        this.state = {
            userId,
        }
    }

    render() {
        return (
            <div>
                <MenuAppBar />
                <main className="main-content">
                    <header className="section-header mt-5 mb-1">
                        <h2><strong>KK River</strong></h2>
                    </header>
                    <div className="section">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-7">
                                    <figure className="figure img-thumbnail shadow-4">
                                        <img src="../img/ec-maps/gmap1-street-marker.jpg" className="figure-img img-fluid" alt="..." />
                                    </figure>
                                </div>
        
                                <div className="col-lg-5">
                                    <figure className="figure img-thumbnail shadow-4">
                                        <img src="../img/ec-maps/0.jpg" className="figure-img img-fluid" alt="..." />
                                    </figure>
                                </div>
                            </div>

                            <h3 className="mb-5">Highlights</h3>
                            <div className="row">

                                <div className="card border col-lg-5 shadow-4 mr-6 mb-6">
                                    <div className="card-body">
                                        <h6>Earth Ideal</h6>
                                        <p>Important status information will be displayed here.</p>
                                    </div>
                                </div>

                                <div className="card border col-lg-5 shadow-4 mr-6 mb-6">
                                    <div className="card-body">
                                        <h6>Earth Tight</h6>
                                        <p>Important status information will be displayed here.</p>
                                    </div>
                                </div>

                                <div className="card border col-lg-5 shadow-4 mr-6 mb-6">
                                    <div className="card-body">
                                        <h6>Assured Disposal</h6>
                                        <p>Important status information will be displayed here.</p>
                                    </div>
                                </div>

                                <div className="card border col-lg-5 shadow-4 mr-6 mb-6">
                                    <div className="card-body">
                                        <h6>Safety NetWork</h6>
                                        <p>Important status information will be displayed here.</p>
                                    </div>
                                </div>

                                <div className="card border col-lg-5 shadow-4 mr-6 mb-6">
                                    <div className="card-body">
                                        <h6>Haul Tracks</h6>
                                        <p>Important status information will be displayed here.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}