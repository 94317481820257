import React, { Component, Fragment } from 'react';
import ajax from 'superagent';
import config from '../../../constants/config';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';

import {
	isMobileOnly,
	isMacOs,
} from "react-device-detect";

class Contact extends Component {

	constructor(props){
		super(props);

		let user = localStorage.getItem('user');
        let loggedInUser = user ? JSON.parse(user).id : null;

		this.state = {
			canContactUser: false,
			canUseSms: false,
			loggedInUser,
			iduser: null,
			idticket: null,

			cardShowing: false,

			email: null,
			phonenumber: null,
			companyname: null,
			name: null,

		}
	}

	componentWillMount = () => {
		let {mode, idticket, iduser} = this.props;

		console.log("ContactInfo contact component mounted for mode " + this.props.mode);
		if (this.props.mode == "closer"){
			console.log("ContactInfo props for newly mounted closer: ", this.props)
		}

		if (idticket && iduser && (mode == "closer" || mode == "creator")){
			this.getContactInfoTicket(this.props)
		} 
	}

	componentWillReceiveProps = (newProps) => {

		console.log("ContactInfo recieving props for mode " + this.props.mode);

		let {idticket, iduser, mode} = newProps;
		let {loggedInUser} = this.state;
		
		//If the endpoint to determine if the logged in user needs to be called, call it.
		//Otherwise, don't.
		if (
			( //For the <Contact> components in ModalClosedTickets
				(mode == "creator" || mode == "closer") &&
				((idticket !== null && idticket !== this.state.idticket) ||
				(iduser !== null && iduser !== this.state.iduser))
			)
		){
			this.setState({idticket, iduser});
			this.getContactInfoTicket(newProps);			

		} else {
			console.log("Determined that I should not be getting ContactInfo for mode " + mode)
		}
	}

	getContactInfoTicket(props){
		let {idticket, iduser, mode} = props;
		let {loggedInUser} = this.state;

		console.log("Getting ContactInfo for mode " + mode);

		ajax.get(`${config.apiUrl}/users/contactinfo/${iduser}/${loggedInUser}/${mode}/${idticket}`)
        .then(res => {
           let {contactInfo} = res.body;
           let {email, phonenumber, companyname, name, canContactUser} = contactInfo;
           console.log("ContactInfo","Data returned successfully for " + mode, contactInfo);
           this.setState({email, companyname, name, phonenumber, canContactUser});
           this.detectCanUseSms();
        }).catch(res =>{
            alert('Error creating project!');
        });
	}

	clickListener = () => {
		console.log("Calling clickListener");
		let {canContactUser} = this.state;

		if (!canContactUser) return;
		else if (this.detectCanUseSms()) this.sendSMS();
		else this.showBusinessCard();
	}

	detectCanUseSms = () => {
		console.log("Calling detectCanUseSms");
		let {canContactUser} = this.state;

		if (!canContactUser) return;

		let canUseSms = false;
		
		if (isMobileOnly || isMacOs) canUseSms = true;
		this.setState({canUseSms});
		return canUseSms;
	}


	sendSMS = () => {
		console.log("Calling sendSMS");

		let {canContactUser, phonenumber} = this.state;

		if (!canContactUser) return;

		try {
			window.location = "sms:"+phonenumber.replace(/[^+\d]+/g, "");;
		} catch(e) {
			console.log(e);
			this.showBusinessCard();
		}
	}

	showBusinessCard = () => {
		console.log("Calling showBusinessCard");
		let {canContactUser, cardShowing} = this.state;
		let {mode} = this.props;
		if (!canContactUser) return;

		this.setState({cardShowing: !cardShowing});

	}

	render(){
		let {children, mode} = this.props;
		let {canContactUser, name, email, phonenumber, companyname, cardShowing, canUseSms} = this.state;

		return(<Fragment>
			<div onClick={this.clickListener} style={{display: 'inline'}}>
				{canContactUser ?  <Fragment>
					
						
		            {!canUseSms ? <Popover placement="right" isOpen={cardShowing} target={"Popover-"+mode} toggle={this.showBusinessCard} >
		                <PopoverHeader><strong>{name}</strong></PopoverHeader>
		                <PopoverBody>
		                    <div> {companyname}</div> 
		                    <div> {email}</div>
		                    <div> {phonenumber}</div>
		                </PopoverBody>
		              </Popover> : null }
					<a href="#" id={"Popover-"+mode}>{children}</a>
					
				</Fragment>:<Fragment>
					{children}
				</Fragment>}
			</div>

		</Fragment>)
	}

}

export default Contact;