const config = {
    //apiUrl: "http://localhost:2998",
    //webUrl: "http://localhost:3000",
    apiUrl: "https://dev.assureddisposal.com:2997",
    webUrl: "https://dev.assureddisposal.com",
    stripe_key: "pk_test_WFDEFX0s2MaQcbDoemtS4GMt00iNNZKz6F",  //test key
    adFrontPage: "https://dev.assureddisposal.com",
    hcFrontPage: "https://haulcheck-dev.earthideal.com",
    eiFrontPage: "https://dev.earthideal.com",
    //eiBackend: "http://localhost:2997",
    eiBackend: "https://dev.earthideal.com:2999"
}

export default config;
