import config from '../constants/config'
import JwtDecode from 'jwt-decode'

export const apiService = {
  getADPrice
}

async function getADPrice(){
  const resp = await fetch(`${config.eiBackend}/api/getADPrice`).then(handleResponse)
  return resp.adPrice + "¢"
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
