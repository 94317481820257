import React, {Component, Fragment} from 'react';

import ajax, {Request} from 'superagent';
import request from 'superagent';
import { connect } from 'react-redux';
import pageUrls from '../../../constants/pageUrls';
import config from '../../../constants/config';
import "./style.css";

import {
    getActiveTickets,
} from '../../../actions/tickets';

import AgreementFilter from '../AgreementFilter'

import Ticket from '../Ticket';
import ModalConfirmDisposedPhoto from '../ModalConfirmDisposedPhoto';

class ActiveTickets extends Component {
    constructor(props){
        super(props);

        let site = props.site;
        let idsite = props.site.idsite;
        let sitename = props.site.name;
        let idgenerator = JSON.parse(localStorage.getItem('user')).id;

        let filterList = [];
        if (props.defaultFilter) filterList.push(props.defaultFilter)

        this.state = {
        	//General config
        	idsite,
        	sitename,
        	idgenerator,
        	site,

        	//Permissions
        	canEditTickets: false,
        	unconditionalTicketAccess: false,
        	canCloseExportTickets: false,
        	canModifyMixedTickets: false,

        	//The tickets themselves
        	ticketList: [],

            //Filter list
            filterList,
        }
    }

    componentWillMount = () => {
		ajax.get(config.apiUrl + '/sites/permissions/' + this.state.idgenerator + '/' + this.state.idsite)
		    .end((error, response) => {
		        if (!error && response) {
		            let {canEditTickets, unconditionalTicketAccess, canCloseExportTickets, canModifyMixedTickets} = response.body.permissions;
		            this.setState({canEditTickets, unconditionalTicketAccess, canCloseExportTickets, canModifyMixedTickets})
		        } else {
		            console.log('There was an error fetching', error);
		        }
		    }
		);
    }

    componentDidMount = () => {
    	this.fetchTickets();
    	this.intervalId = setInterval(() => { 
            this.fetchTickets();
        }, 60000);
    }

    fetchTickets = () => {
        let {getTickets, refreshSidebarTickets} = this.props;
        let getTicketsResult = getTickets(this.state.idsite, this.state.idgenerator)
        console.log(getTicketsResult); //This line needs to be here for the ticket list to refresh properly. I don't actually know why.
        if (refreshSidebarTickets) refreshSidebarTickets();
    }

    componentWillUnmount = () => {
        clearInterval(this.intervalId);
    }

    componentWillReceiveProps = (newProps) => {

        if ((JSON.stringify(newProps.site) != JSON.stringify(this.state.site)) && newProps.site.idsite != 0){
            this.setState({site: newProps.site, sitename: newProps.site.name, idsite: newProps.site.idsite}, () => {
                this.fetchTickets()}
            );
        }

        console.log("newProps.defaultFilter", newProps.defaultFilter);

        if (newProps.defaultFilter === 0){
            console.log("Updating defaultFilter to blank");
            this.updateFilter([]);
        } else if (newProps.defaultFilter){
            console.log("Updating defaultFilter to value");
            this.updateFilter([newProps.defaultFilter]);
        }

        if(newProps.tickets.activeTickets.inProgress !== this.props.tickets.activeTickets.inProgress && newProps.tickets.activeTickets.success){
            let temp = newProps.tickets.activeTickets.data.slice();
            for(let i = 0; i < temp.length; ++i){
                for(let j = temp.length - 1; j > i; --j){
                    if(temp[i].idticket == temp[j].idticket){
                        temp[i].idprofile = temp[i].idprofile <= temp[j].idprofile ? temp[i].idprofile : temp[j].idprofile
                        temp.splice(j, 1)
                    }
                }
            }
            this.setState({ticketList: temp},   
                () => {      
                    /*console.log("Referrer:")
                    console.log(document.referrer);
                    console.log(document.referrer === undefined);
                    console.log(document.referrer === null);
                    alert("Referrer:");*/

                    if (temp.length > 0 && document.referrer === ""){
                        let ticket = this.state.ticketList[0];
                        //console.log("Ticket:");

                        //this.setState({disposalPartnerSelected: ticket.idproject, soilProfileToSend: ticket.idprojectsoil});
                        //this.props.getNextTicketNumber(ticket.idproject);
                    }
                }
            );
        }
    }

    refreshWindow = () => {
        window.location.reload(); 
    }

    updateFilter = (filterList) => {
        this.setState({filterList});
    }

    render = () => {
    	return(<Fragment>
			<div className="row mb-2">
				<div className="fullwidth col-12 mx-auto">
					<div className="clearfix">  
					<h3 className="ml-2 float-left">Active Loads</h3>
					<p className="small-1 strong text-dark border-bottom text-right pt-4"><a className="text-dark" href="#" onClick={() => this.props.switchSidePanel("hauledToday")}>Tickets Hauled Today<i className="fa fa-caret-right ml-2" aria-hidden="true"></i></a></p>
					</div>
					<AgreementFilter idsite={this.state.idsite} callback={this.updateFilter} filterList={this.state.filterList} sitename={this.state.sitename} />
				</div>

				<div className="fullwidth col-12 mx-auto clearfix">  
                    <a className="btn btn-xs btn-outline-primary mt-1 mr-2 mb-2 py-0" href="#" onClick={() => this.props.switchSidePanel("createticket")}>New Ticket</a>

		    		{(this.state.canEditTickets === false && this.state.ticketList.length === 0) ? <span>No Tickets to Display</span> : this.renderTicketList()}
	    		</div>
    		</div>

		    <ModalConfirmDisposedPhoto ticket={this.state.ticketSelected} project={this.props.project} fetchTickets={this.fetchTickets} 
		        canCloseTickets={this.state.unconditionalTicketAccess || this.state.canEditTickets} /* offlineData={this.state.offlineData}*/ />

		</Fragment>)
    }

    renderNoTicketsCard = () => {
        return(
            <div className="col-12 col-sm-8 col-md-6 col-lg-5 ml-md-9">
				<div className="card d-block border">
					<div className="card-body">
						<h5 className="card-title" data-font="Permanent Marker:400">No active tickets</h5>
						<p className="mb-0">There are no loaded trucks at this time.</p>
					</div>
				</div>
			</div>
        )
    }

    countFilteredTickets = () => {
        let {ticketList, filterList} = this.state;
        if (filterList.length == 0) return ticketList.length;
        else if (ticketList.length == 0) return 0;
        else {
            let total = 0;
            for (var i = 0; i < ticketList.length; i++){
                let {idproject} = ticketList[i];
                if (filterList.indexOf(idproject) !== -1){
                    total++;
                }
            }

            return total;
        }
    }

    renderTicketList(){
        if (this.state.ticketList.length == 0 || this.countFilteredTickets() == 0) return this.renderNoTicketsCard();
        return this.state.ticketList.sort((a,b) => {
            //if (this.state.siteType == "export")
                return (new Date(b.datecreated)).getTime() - (new Date(a.datecreated)).getTime()
            //else
               // return (new Date(a.datecreated)).getTime() - (new Date(b.datecreated)).getTime()
        }).map(r => {

            /*if (this.state.siteType === "export" && r.source_site == this.state.sitename ||
                this.state.siteType === "import" && r.dump_site == this.state.sitename){*/

            let {filterList} = this.state;
            if (filterList.length == 0 || (filterList.length > 0 && filterList.indexOf(r.idproject) !== -1)){

                    return <Ticket 
                        ticket={r} 
                        key={r.idticket}
                        onClick={ticket => {
                            this.setState({ticketSelected: ticket})
                        }}
                    />
            }
            /*} else {
                return null;
            }*/

        })
    }
}

function mapStateToProps(state) {
    return {
      tickets: state.tickets,
    };
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      getTickets: (idsite, idgenerator) => dispatch(getActiveTickets(idsite, idgenerator)),
    };
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(ActiveTickets)
