import React, {Component, Fragment}  from 'react';
import config from '../../../constants/config';
import ajax from 'superagent'

export default class AgreementFilter extends Component {

  constructor(props){
    super(props);

    let {filterList} = props;

    this.state = {
      showDropdown: false,
      partnersList: [],
      filterList,
    }
  }

  componentWillReceiveProps = (newProps) => {
    if (this.state.filterList.toString() !== newProps.filterList.toString()){
      this.setState({filterList: newProps.filterList});
    }
  }

  toggleShowDropdown = () => {
    this.setState({showDropdown: !this.state.showDropdown})
  }

  componentWillMount = () => {
    let {defaultFilter, idsite} = this.props;

    ajax.get(`${config.apiUrl}/projects/siteTypeAndPartners/${idsite}`)
    .end((error, response) => {
      if (!error && response) {
        let {fullPartnersList} = response.body;

        if (defaultFilter){
          for (var i = 0; i < fullPartnersList.length; i++){
            if (fullPartnersList[i].idproject == defaultFilter) fullPartnersList[i].selected = true;
          }
        }

        this.setState({partnersList: fullPartnersList});
      } else {
        console.log('There was an error', error);
      }
    });
  }

  toggleAgreement = (idproject) => {
    let {partnersList, filterList} = this.state;

    let selected = (filterList.indexOf(idproject) !== -1);
    console.log("Calling toggleAgreement, selected is " + selected);

    if (!selected){
      filterList.push(idproject);
    } else {
      let idindex = filterList.indexOf(idproject);
      filterList.splice(idindex, 1);
    }

    this.setState({filterList});
    this.props.callback(filterList);
  }

  clearFilters = () => {
    this.setState({filterList: []})
    this.props.callback([]);
  }

  render = () => {
    let {showDropdown, partnersList, filterList} = this.state;

    return(<Fragment>
      <label className="small-1 mb-2 ml-2 fw-500">{this.props.sitename} - {this.renderFilteredPartners()}</label>

      <div className="dropdown mt-3 float-right hover">
        <span className="dropdown-toggle btn btn-xs btn-round btn-outline-secondary text-light cursor px-3 py-0" 
          onClick={this.toggleShowDropdown}>
            Filter by Agreement
        </span>

        <div className={"dropdown-menu dropdown-menu-right custom-controls-stacked " + (showDropdown ? "show" : "")} tabIndex="0" onBlur={this.closeDropdown}>
          {partnersList && partnersList.length > 0? partnersList.map(({name, idproject}, index) => {
            let {filterList} = this.state;
            return(<div className="dropdown-item hover" onClick={() => this.toggleAgreement(idproject)}>
              <div className="custom-control custom-checkbox hover">
                <input type="checkbox" 
                  className="custom-control-input hover"
                  checked={filterList.indexOf(idproject) !== -1} />
                <label className="custom-control-label hover">{name}</label>
              </div>
            </div>)
          }) : null}
          <div className="dropdown-divider"></div>
          <div className="dropdown-item small" onClick={this.clearFilters}>Clear Filters</div>
        </div>
      </div>

    </Fragment>)
  }

  renderFilteredPartners = () => {
    let {filterList, partnersList} = this.state
    let partnersString = "";

    for (var i = 0; i < partnersList.length; i++){
      let {name, idproject} = partnersList[i];

      if (filterList.indexOf(idproject) != -1){
        partnersString += name + ", "
      }
    }

    if (partnersString.length > 0)
      return partnersString.substring(0, partnersString.length-2);
    else return "All Partners";
  }

  closeDropdown = () => {
    console.log("Closing dropdown due to blur");
    this.setState({showDropdown: false});
  }
}

