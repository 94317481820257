import React, { Component } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import ajax from 'superagent';
import './styles.css';
import MenuAppBar from '../NavBar';
import TabContent from './TabContent';
import ModalProjectTeam from './ModalProjectTeam';
import ModalUpdatedUsers from './ModalUpdatedUsers';
import ModalDownloadTicket from './ModalDownloadTicket';
import ModalEditTeam from '../DumpProject/ModalEditTeam';
import Footer from '../Footer';

import config from '../../constants/config';
import pageUrls from '../../constants/pageUrls';


import '../../assets/css/page.min.css';
import '../../assets/css/style.min.css';
import '../../assets/css/ei-style.css';

export default class Manifest extends Component{
    constructor(props) {
        super(props);
        let user = localStorage.getItem('user');
        let userId = user ? JSON.parse(user).id : null;
        let activePage = props.activePage;
        this.state = {
            activePage,
            userId,
            //closedTicket: null,
            site: {},
            userId,
            loadingMessage: "Loading...",
            canAddAgreements: false,
            canEditTeam: false,
            canEditTickets: false,
            canViewArchiveTab: false,
        }
    }

    componentWillMount(){
        let site = JSON.parse(localStorage.getItem('site'));
        if(site){
            this.setState({site})
            ajax.get(`${config.apiUrl}/projects/bysiteid/${site.idsite}`)
                .end((error, response) => {
                    if (!error && response) {
                        /*console.log("Value of projectsList:");
                        console.log(response.body.projectsList);
                        console.log("Value of idsite: " + site.idsite)*/
                        /*const project = response.body.projectsList.find(r => {
                            return r.idsource == site.idsite || r.iddump == site.idsite;
                        });*/
                        let project = response.body.projectsList;
                        /*console.log("Value of project:");
                        console.log(project);*/
                        if (project == undefined){
                            this.setState({loadingMessage: this.renderNoAgreementsCard()});
                        }
                        this.setState({project})
                    } else {
                        console.log('There was an error', error);
                    }
                }
            );
            ajax.get(config.apiUrl + '/sites/permissions/' + this.state.userId + '/' + site.idsite)
                .end((error, response) => {
                    if (!error && response) {
                        let {canEditTickets, canAddAgreements, canEditTeam, canViewArchiveTab} = response.body.permissions;
                        this.setState({canEditTickets, canAddAgreements, canEditTeam, canViewArchiveTab});
                    } else {
                        console.log('There was an error fetching', error);
                    }
                }
            );
        }
    }

    /* componentDidMount(){
        let source = localStorage.getItem('source');
        if(source){
            this.setState({source})
        }
    } */

    renderNoAgreementsCard = () => {
        return(
            <div class="col-12 col-sm-8 col-md-6 col-lg-5 mx-auto ml-md-9">
              <div class="card d-block shadow-1 hover-shadow-3">
                <div class="card-body">
                  <h5 class="card-title" data-font="Permanent Marker:400">No agreements yet</h5>
                  <p class="mb-0">Start or complete an agreement to be able to create tickets.</p>
                </div>
                <div class="card-footer  text-left">
                  <a class="fs-12 fw-600" href={pageUrls.agreementsList}>View Site Agreements</a>
                </div>
              </div>
            </div> );
    }

    renderSupportLink = () => {
        /*if (this.state.activePage == "active"){
            return(
                <a class="lead cursor float-right mr-2 mr-lg-10 mt-0" 
                  data-toggle="offcanvas" data-target="#offcanvas-tickets-1" href="#">
                    <span data-toggle="tooltip" data-placement="left" title="Working with Tickets">
                        <i class="fa fa-question-circle text-light fs-18 pt-1 pr-2" aria-hidden="true"></i>
                    </span>
                </a>
            )
        } else {
            return(
                <a class="lead cursor float-right mr-2 mr-lg-10 mt-0" 
                  data-toggle="offcanvas" data-target="#offcanvas-tonnage-1" href="#">
                    <span data-toggle="tooltip" data-placement="left" title="Tonnage Logs">
                        <i class="fa fa-question-circle text-light fs-18 pt-1 pr-2" aria-hidden="true"></i>
                    </span>
                </a>
            )
        }*/
        return null;
    }

    render(){
        return (
            <div>
                <MenuAppBar showSandbox={true} showSites={true} />
                <main className="main-content">
                    {/*this.renderSupportLink()*/}
                    <header className="section-header mt-5 mb-0">
                        <h2><strong>{this.state.site.name}</strong></h2>
                    </header>
                    <div className="section pt-6">
                        <div className="container pl-0 pr-0">
                            <div className="row mr-0 ml-0">
                                <div className="col-lg-9 mx-auto mb-6 p-0">  
                                    <ul className="nav nav-tabs" role="tablist">
                                        <li className="nav-item">
                                            <a className={"nav-link" + (this.state.activePage == "active" ? " active" : "")} href={pageUrls.activeTickets}>Active Tickets</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={"nav-link" + (this.state.activePage == "closed" ? " active" : "")} href={pageUrls.closedTickets}>Hauled Today</a>
                                        </li>
                                        {this.state.canViewArchiveTab ? 
                                            <li className="nav-item">
                                                <a className={"nav-link" + (this.state.activePage == "archive" ? " active" : "")} href={pageUrls.archivedTickets}>Archive</a>
                                            </li>
                                        : null }
                                        <li class="nav-item ml-auto">
                                            <div class="dropdown mt-3 mr-4">
                                                <span class="dropdown-toggle no-caret" data-toggle="dropdown"><i class="ti ti-settings text-lighter" aria-hidden="true"></i></span>
                                                <div class="dropdown-menu dropdown-menu-right">
                                                    <a class="dropdown-item cursor" 
                                                        style={ this.state.canAddAgreements ? { 'display' : 'block' } : { 'display' : 'none'}} 
                                                        href={pageUrls.newDisposalAgreement}>Add Agreement</a>
                                                    <a class="dropdown-item cursor" 
                                                        data-toggle="modal" data-target={"#edit-team-"+this.state.site.idsite}>{ this.state.canEditTeam ? "Edit" : "View"} AD Site Team</a>
                                                </div>
                                            </div>
                                      </li>
                                    </ul>
                                </div>
                                <div className="col-12 pr-0 pl-0">
                                    <div className="tab-content">
                                        {this.props.children}
                                    </div>
                                </div>
                                {this.state.project ? <ModalProjectTeam project={this.state.project} /> : null}
                                <ModalUpdatedUsers />
                                <ModalDownloadTicket />
                                <ModalEditTeam idsite={this.state.site.idsite} name={this.state.site.name} refreshSites={() => {return true}} />
                            </div>
                        </div>
                    </div>
                </main>
                <Footer />
            </div>
        )
    }
}
