import React, { Component } from 'react';
import LetterAvatar from '../../LetterAvatar';
import config from '../../../constants/config';
import TempTruckID from '../TempTruckID';

import { connect } from 'react-redux';
import { setTicketForModal } from '../../../actions/tickets';

class Ticket extends Component {
    constructor(props){
        super(props)

        this.state = {
            source_site: (props.ticket.source_site ? this.reduceToInitials(props.ticket.source_site) : "ES"),
            dump_site: (props.ticket.dump_site ? this.reduceToInitials(props.ticket.dump_site) : "IS")
        }
    }

    reduceToInitials = (name) => {
        var initials = name.match(/\b\w/g) || [];
        initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
        return initials;
    }

    render() {
        const ticket = this.props.ticket
        const soilProfileString = ticket.profile;
        function convertDate(date){
            var daysOfWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"]
            date = new Date(date);
            return daysOfWeek[date.getDay()] + " " + ((date.getHours() % 12) || "12") + ":" + ('0' + date.getMinutes()).substr(-2) + ((date.getHours() >= 12) ? "PM" : "AM")/*  + " - " + date.toISOString().substr(5,5).replace('-', '/') + "/" + date.getFullYear() */
        }
        function convertTimestamp(timestamp){
            let date = new Date(timestamp);
            return ('0' + (date.getMonth() + 1)).substr(-2)
                + '-' + ('0' + date.getDate()).substr(-2)
                + '-' + date.getFullYear().toString().substr(2,2)
                + " " + ((date.getHours() % 12) || "12") + ":" + ('0' + date.getMinutes()).substr(-2) + ((date.getHours() >= 12) ? "PM" : "AM")
        }
        const timeString = convertDate(ticket.sourcetimestamp);
        const coords = ticket.geo_load ? ticket.geo_load.replace('POINT(', '').replace(')', '').split(' ') : [0,0];
        

        /*return(
            <div className="fullwidth col-lg-9 pr-0 pl-0 mx-auto"  onClick={() => {this.props.onClick({...ticket, timeString})}}>
                <div className="card border hover-shadow-7">
                    <div className="card-body p-0">
                        <div className="row mr-0 ml-0">
                            <div className="sevennhalf-col p-0 pl-3 pr-1">

                                <div className="col-12 p-0 pl-0 pr-2 mt-2 mb-1">
                                    <label className="badge badge-pale badge-info fw-600 fs1m p-1 mb-2 float-right text-right">#{ticket.internalnumber || ""}</label>
                                    <label className="m-0 p-0 lead-1 fw-600 lh-1">{soilProfileString}</label>
                                </div>

                                {ticket.truckowner ?  <label className="col-6 m-0 pl-0 pr-0 pt-0 pb-0 fw-600 float-left"><img className="ml-0 mb-1 mr-2" width="20" height="20" src={LetterAvatar(ticket.truckowner)} />Fleet #{ticket.fleetnum}</label> : null }
                                
                                {ticket.licenseplate ? <label className="col-6 m-0 pl-0 pr-2 pt-0 pb-0 fw-600 float-right text-right">{ticket.licenseplate}</label> 
                                : <TempTruckID
                                    number={ticket.trucktempnum || 0}
                                    idtruck={ticket.truck}
                                    idElement="label"
                                    idClasses = "col-6 m-0 pl-0 pr-0 fw-600 float-left"
                                    showIcon = {true}
                                    iconClasses = "lead fa fa-exclamation-triangle text-warning mr-2 fw-100"
                                    showLink = {true}
                                    linkElement="label"
                                    linkClasses = "col-6 small-3 pt-1 px-0 text-primary text-right cursor float-right"
                                />  }

                                {ticket.truckDriver ? <label className="col-7 m-0 p-0 fw-100 float-left">{ticket.truckdriver}</label> : null }
                                {ticket.truckemptyweight ? <label className="col-4 m-0 p-0 mr-2 fw-100 float-right text-right">E.W. {ticket.truckemptyweight}</label> : null}

                            </div> 

                            <div className="fournhalf-col float-right mx-auto">
                                <img data-toggle="modal" data-target="#modal-disposal" src={config.apiUrl + "/images/trucks/" + ticket.truckphoto} />
                            </div> 

                            <div className="col-12 px-3 pb-1 lh-2 flexbox">
                                <label className="small fw-400 mb-0 text-left"><b>{timeString}</b>&nbsp;-&nbsp;{ticket.source_site}</label>
                                <label className="lead-1 fw-100 mb-0 text-center"><i className="fa fa-long-arrow-right" aria-hidden="true"></i></label>
                                <label className="small fw-400 mb-0 text-right">{ticket.dump_site}</label>
                            </div>

                        </div>
                    </div>
                </div>
            </div>)*/


        return (
            <div class="fullwidth col-lg-7 pr-0 pl-0 mr-auto" onClick={() => {this.props.onClick({...ticket, timeString})}}>
                <div class="card border hover-shadow-7">
                    <div class="card-body p-0">
                        <div class="row mr-0 ml-0">
                            <div class="sevennhalf-col pt-0 pr-1 pl-3">
                                <div class="col-12 pl-0 pr-2 mt-4 pb-0 mb-0 lh-1">
                                    <label class="badge badge-pale badge-info fw-600 fs1m p-1 mt-0 mb-1 float-right text-right">
                                        #{ticket.internalnumber || ""}
                                    </label>
                                    <label class="lead-1 m-0 p-0 fw-600 lh-1">
                                        {soilProfileString}
                                    </label>
                                </div>
                                <div class="col-12 mt-0 pt-1 pl-0 pr-2 lh-1 flexbox">
                                    {ticket.truckowner ?
                                        <label class="col-6 m-0 pl-0 pr-0 fw-600 float-left">
                                            <img class="ml-0 mb-0 mr-2" width="20" height="20" src={LetterAvatar(ticket.truckowner)} />        
                                            {ticket.truckfleetnum ? 
                                                "Fleet #"+ticket.truckfleetnum
                                            : null}
                                        </label>
                                    : null }
                                    { ticket.licenseplate ? 
                                        <label className="col-6 m-0 pl-0 pr-2 pt-0 pb-0 fw-600 float-right text-right">
                                            {ticket.licenseplate}
                                        </label> 
                                        : (ticket.truck ? <TempTruckID
                                            number={ticket.trucktempnum || 0}
                                            idtruck={ticket.truck}
                                            idElement="label"
                                            idClasses = "col-6 m-0 pl-0 pr-0 fw-600 float-left"
                                            showIcon = {true}
                                            iconClasses = "lead fa fa-exclamation-triangle text-warning mr-2 fw-100"
                                            showLink = {true}
                                            linkElement="label"
                                            linkClasses = "col-6 small-3 pt-1 px-0 text-primary text-right float-right"
                                        /> : null )
                                    }
                                </div> 
                                <div class="col-12 pl-0 pr-2 pt-0 mt-5 flexbox">
                                    <label class="small fw-400 mb-0 text-left">{timeString}</label>
                                    <label class="small fw-400 mb-0 text-right">
                                        {this.state.source_site}
                                        <i class="fa fa-long-arrow-right px-1" aria-hidden="true"></i>
                                        {this.state.dump_site}
                                    </label>
                                </div>
                            </div> 
                            <div class="fournhalf-col bg-secondary float-right mx-auto pt-1">
                            <img class="pt-0" data-toggle="modal" data-target="#modal-disposal" style={{width: "100%"}} src={config.apiUrl + "/images/trucks/" + ticket.truckphoto} />
                                <label class="col-6 m-0 p-0 mb-0 pl-2 small fw-100 float-left">
                                    {ticket.truckdriver ? ticket.truckdriver : null}
                                </label>
                                <label class="col-6 m-0 p-0 mb-0 pr-1 small fw-100 float-right text-right">
                                    {ticket.truckemptyweight ? "EW:"+ticket.truckemptyweight : null}
                                </label>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        tickets: state.tickets,
    };
}
  
function mapDispatchToProps(dispatch) {
    return {
        setTicketForModal: (ticket) => dispatch(setTicketForModal(ticket)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Ticket)