import {
    GET_STRIPE_CUSTOMER_REQUESTED,
    GET_STRIPE_CUSTOMER_REJECTED,
    GET_STRIPE_CUSTOMER_FULFILLED,

    GET_STRIPE_PAYMENT_METHOD_REQUESTED,
    GET_STRIPE_PAYMENT_METHOD_REJECTED,
    GET_STRIPE_PAYMENT_METHOD_FULFILLED,
} from '../actions/types';

const initialState = {
    customer: {
        inProgress: false,
        success: '',
        error: '',
        data: null,
    },
    paymentMethod: {
        inProgress: false,
        success: '',
        error: '',
        data: null,
    },
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_STRIPE_CUSTOMER_REQUESTED: {
            const { inProgress } = action;
            return Object.assign({}, state, {
                customer: {
                    inProgress
                }
            });
        }
        case GET_STRIPE_CUSTOMER_REJECTED: {
            const { inProgress, success, error } = action;
            return Object.assign({}, state, {
                customer: {
                    inProgress,
                    success,
                    error
                }
            });
        }
        case GET_STRIPE_CUSTOMER_FULFILLED: {
            const { inProgress, success, error, data } = action;
            return Object.assign({}, state, {
                customer: {
                    inProgress,
                    success,
                    error,
                    data: data.customer
                }
            });
        }
        
        case GET_STRIPE_PAYMENT_METHOD_REQUESTED: {
            const { inProgress } = action;
            return Object.assign({}, state, {
                paymentMethod: {
                    inProgress
                }
            });
        }
        case GET_STRIPE_PAYMENT_METHOD_REJECTED: {
            const { inProgress, success, error } = action;
            return Object.assign({}, state, {
                paymentMethod: {
                    inProgress,
                    success,
                    error
                }
            });
        }
        case GET_STRIPE_PAYMENT_METHOD_FULFILLED: {
            const { inProgress, success, error, data } = action;
            return Object.assign({}, state, {
                paymentMethod: {
                    inProgress,
                    success,
                    error,
                    data: data.payment
                }
            });
        }
        default:
            return state;
    }
  };
  