import React, {Fragment, Component} from 'react';
import ajax from 'superagent';
import config from '../../../constants/config';
import SuccessPopup from '../../Popups';

//import _ from 'lodash';

export default class ModalNewSite extends Component {

	constructor(props){
		super(props);
        let user = localStorage.getItem('user');
        let userId = user ? JSON.parse(user).id : null;

		this.state = {
			profilesList: [],
            userId

		}

    	this.renderAddUsers = this.renderAddUsers.bind(this);
    	this.setDefaultState = this.setDefaultState.bind(this);
    	this.updateEmail = this.updateEmail.bind(this);
    	this.updateProfile = this.updateProfile.bind(this);
    	this.setDefaultState = this.setDefaultState.bind(this);
    	this.verifyUsers = this.verifyUsers.bind(this);
        this.verifyIndividualUser = this.verifyIndividualUser.bind(this);
    	this.sendData = this.sendData.bind(this);
	}

	setDefaultState(){
		this.setState({
            hasInvalidUsers: false,
			siteName: "",
            usersToAdd: [{idprofile: 0}, {idprofile: 0}],
            successPopupTriggerUsers: false,
            successPopupTriggerNoUsers: false,
            successMessage: "Site created and users added!"
		});

	}

	componentWillMount(){
		this.setDefaultState();
		ajax.get(`${config.apiUrl}/profiles`)
            .end((error, response) => {
                if (!error && response) {
                    const validProfiles = response.body.profilesList.filter(item => {
                        return item.profile !== "Project Leader"
                    })
                    this.setState({profilesList: validProfiles})
                } else {
                    console.log('There was an error fetching', error);
                }
            }
        );

        ajax.get(`${config.apiUrl}/users/id/` + this.state.userId)
            .end((error, response) => {
                if (!error && response) {
                    if (response.body.user[0])
                        this.setState({userEmail: response.body.user[0].email})
                } else {
                    console.log('There was an error fetching', error);
                }
            }
        );
	}

	updateSiteName(e){
		e.preventDefault();
		this.setState({
			siteName: e.target.value
		})
	}

	updateEmail(key, value) {
        this.setState(state => {
            const usersToAdd = state.usersToAdd.map((item, j) => {
                if(j == key){
                    return Object.assign({}, item, {email: value});
                }else{
                    return item;
                }
            });
            return {
                usersToAdd
            }
        })
    }

    updateProfile(key, value) {
        this.setState(state => {
            const usersToAdd = state.usersToAdd.map((item, j) => {
                if(j == key){
                    return Object.assign({}, item, {idprofile: value});
                }else{
                    return item;
                }
            });
            return {
                usersToAdd
            }
        })
    }

    renderOptions() {
        return this.state.profilesList.map(r => {
            return <option value={r.idprofile} key={r.idprofile}>{r.profile}</option>
        })
    }

	renderAddUserRow(key){
        return (
        	<React.Fragment key={key}>
                <div className="col-lg-7 p-0 pl-lg-4">
                    <div className="contrast">
                        <input className={"form-control" + (this.state.usersToAdd[key].invalidEmail ? " is-invalid" : "")} type="email"  
                        placeholder="name@company.com"
                        value={this.state.usersToAdd[key].email || ""} 
                        onChange={(e) => { this.updateEmail(key, e.target.value); }} />
                    </div>
                </div>
                <div className="col-12 col-lg-5 pt-2 px-0 pt-lg-0 pl-lg-3 contrast mb-4">
                    <select className={"form-control" + (this.state.usersToAdd[key].invalidProfile ? " is-invalid" : "")}  value={this.state.usersToAdd[key].idprofile || 0} onChange={e => {
                        this.updateProfile(key, ~~e.target.value);
                        }}>
                            <option value={0} disabled={true}>Select one</option>
                            {this.renderOptions()}
                    </select>
                </div>
			</React.Fragment>
        )
    }

    renderAddUsers() {
        return this.state.usersToAdd.map((item, index) => {
            return this.renderAddUserRow(index)
        })
    }

    addNewUserRow(){
        this.setState(state => {
            const usersToAdd = [...state.usersToAdd, {idprofile: 0}];
            return {usersToAdd}
        })
    }

    verifyUsers(){
    	let validUsersList = [];
        let hasInvalidUsers = false;

    	for (var i = 0; i < this.state.usersToAdd.length; i++){

            let userIsValid = this.verifyIndividualUser(i);
            if (userIsValid === true){
                validUsersList.push(this.state.usersToAdd[i]);
            } else if (userIsValid === false) {
                hasInvalidUsers = true;
            }
    	}

        if (hasInvalidUsers){
            this.setState({hasInvalidUsers: true});
            return false;
        } else { 
            this.setState({hasInvalidUsers: false});
            return validUsersList;
        }
    }

    verifyIndividualUser(i){
        let currentuser = this.state.usersToAdd[i];
        let validUser = true;

        if (currentuser.email && !currentuser.idprofile){
            validUser = false;
            this.state.usersToAdd[i].invalidEmail = false;
            this.state.usersToAdd[i].invalidProfile = true;
        }

        else if (!currentuser.email && currentuser.idprofile){
            validUser = false;
            this.state.usersToAdd[i].invalidProfile = false;
            this.state.usersToAdd[i].invalidEmail = true;
        }

        else if ((currentuser.email === undefined) && currentuser.idprofile === 0){
            validUser = null;
            this.state.usersToAdd[i].invalidProfile = false;
            this.state.usersToAdd[i].invalidEmail = false;
        }

        else if (currentuser.email && currentuser.idprofile){
            validUser = true;
            this.state.usersToAdd[i].invalidProfile = false;
            this.state.usersToAdd[i].invalidEmail = false;
        }

        return validUser;
    }

    async sendData(){
    	//Sanity check
    	if (!this.state.siteName) return;

        //Validate user entry
        let verifiedUsers = this.verifyUsers();
        if (verifiedUsers === false) return;

        verifiedUsers.push({email: this.state.userEmail, idprofile: 1, iduser: this.state.userId});

    	let data = {
    		sitedata: {
    			name: this.state.siteName,
    			address: null,
    			city: null,
    			zip: null,
    			idstate: null,
    			location: null,
    			idsitetype: 4
    		},
    		userdata: verifiedUsers,
            idleader: this.state.userId,
            webUrl: config.webUrl
    	}

        let popupToUse = "successPopupTriggerUsers";
        if (data.userdata.length == 1){
            popupToUse = "successPopupTriggerNoUsers";
        }

        ajax.post(`${config.apiUrl}/sites`)
            .send(data.sitedata)
            .then(res => {
                data.idsite = res.body.site.idsite;
                ajax.post(`${config.apiUrl}/sites/addSiteUsers`)
                .send(data)
                .then(res => {
                    this.props.refresh();
                    this.setState({[popupToUse]: true});
                });

            }
        );
    }

	render(){
		/*return(<React.Fragment>
			
            <div className="modal" id="add-site" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
				<div className="modal-dialog modal-lg" role="document">
					<div className="modal-content">

    					<div className="alert alert-primary" role="alert">
    						<strong>New Site</strong>
    						<button type="button" className="close" data-dismiss="modal" aria-label="Close">
    							<span aria-hidden="true">&times;</span>
    						</button>
    					</div>

    					<div className="modal-body pt-0">
    						<div className="row m-0 mb-5">
    							<div className="col-lg-12">
    								<div className="form-group row">
    									<div className="col-lg-7 mb-6">
    										<label>Site Name</label>
    										<div className="input-group">
    											<input className="form-control form-control" type="text" onChange={this.updateSiteName.bind(this)} value={this.state.siteName}/>
    										</div>
    									</div>

    									<div className="col-lg-12">
    										<label>Site Team</label>
                                            <label className="float-right text-danger" style={this.state.hasInvalidUsers ? {display: "inline-block"} : {display: "none"}}>Please enter highlighted fields</label>
    									</div>
    									{this.renderAddUsers()}

    								</div>
    								<button type="button" className="btn btn-sm btn-outline-secondary" onClick={this.addNewUserRow.bind(this)}><i className="fa fa-plus fw-400"></i></button>
    							</div>

    						</div>

    					</div>
    					<button type="button" className="btn btn-lg btn-primary" /*data-toggle="modal" data-target="#modal-closed" data-dismiss="modal"*//* >Create Site</button>
					</div>
				</div>
			</div>

           

		</React.Fragment>)*/

        return(<React.Fragment>
            
            <div className="modal" id="add-site" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">

                    <div className="alert alert-primary" role="alert">
                        <strong>New Site</strong>
                        <button type="button" id="close-the-new-site-modal"  className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div className="modal-body pt-0">
                        <div className="row m-0 mb-5">
                            <div className="col-lg-12">
                                <div className="form-group row">
                                    <div className="col-12 col-lg-11 px-0 mb-5 mx-auto">
                                        <label className="lead text-dark fw-700">Site Name</label>
                                        <div className="contrast">
                                            <input className="form-control" type="text" onChange={this.updateSiteName.bind(this)} value={this.state.siteName}/>
                                        </div>
                                    </div>

                                    <div className="col-12 col-lg-11 mt-6 mx-lg-auto">
                                        <div className="form-group row gap-y">
                                            <div className="col-lg-12 mx-lg-auto px-0">
                                                <label className="lead text-dark fw-400">Add to Site Team</label>
                                                <label className="float-right text-danger" style={this.state.hasInvalidUsers ? {display: "inline-block"} : {display: "none"}}>Please enter highlighted fields</label>
                                                {this.renderAddUsers()}
                                                <button className="btn btn-xs btn-outline-dark float-right" style={{display: 'none'}}>Import Contacts</button>
                                            </div> 
                                            
                                            <button type="button" className="btn btn-outline-secondary mb-5 mb-lg-0 ml-lg-4 px-4" onClick={this.addNewUserRow.bind(this)}><i className="fa fa-plus fw-400"></i></button>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                    <button type="button" className="btn btn-lg btn-primary" onClick={this.sendData.bind(this)} disabled={!this.state.siteName} >Create Site</button>
                    </div>
                </div>
            </div>

             <SuccessPopup
                popupId="newSiteSuccessPopupUsers"
                title="Success!"
                message="Site created and users added!"
                trigger={this.state.successPopupTriggerUsers}
                callback={() =>  {document.getElementById("close-the-new-site-modal").click(); this.setDefaultState();}}
                 />
            <SuccessPopup
                popupId="newSiteSuccessPopupNoUsers"
                title="Success!"
                message="Site created!"
                trigger={this.state.successPopupTriggerNoUsers}
                callback={() =>  {document.getElementById("close-the-new-site-modal").click(); this.setDefaultState();}}
                 />

        </React.Fragment>)
	}


}
