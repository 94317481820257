/****
*
*  This is a "core" file for the EI Platform.
*  Please keep this file identical accross all platforms on which it appears.
*  If you must implement app-specific functionality, implement that in a wrapper component.
*
*/

import React from 'react';
import '../Login/styles.css';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import MuiPhoneNumber from 'material-ui-phone-number'
import { SignUp } from "aws-amplify-react";
import { Auth } from 'aws-amplify';
import { withRouter } from 'react-router-dom';

class RegisterCore extends SignUp {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      name: '',
      email: '',
      phone: '',
      company: '',
      isPrivate: false,
      password: '',
      isOpen: false,
      message: '',
      err_company: false,
      err_email: false
    };

    this._validAuthStates = ["signIn", "signedIn", "signedOut", "signedUp"];
    Auth.currentSession()
    //.then(data => this.props.history.push("/haul-checks"))
    .then(data => window.location.href = this.props.home)
    .catch(err => console.log(err));
  }

  handleChange = (event) => {
    this.setState({[event.target.name]: event.target.value});
  }

  handleCheckChange = (event) => {
    this.setState({[event.target.name]: event.target.checked });
  };

  handleClose = (event) => {
    this.setState({message: '', isOpen: false});
  }

  handleStateChanged(authState) {
    console.log("On State changed");
    const {redirect} = this.state;
    if (authState==='signedIn' && !redirect) {
      //this.props._authState = 'signedIn';
      this.setState({redirect: true});
      console.log(redirect);
      //window.location.href = this.props.home;
    } else if (authState==='signedOut') {
      //this.props._authState = 'signedIn';
      this.setState({redirect: false});
      console.log("From SignedOut || SignIn" + redirect);
    }
  }

  keyPress = (e) =>{
      if(e.keyCode == 13){
         this.handleSignUp()
      }
   }

  async handleSignUp() {
    let {username, password, email, phone, name, company, isPrivate} = this.state;

    try {
      if (username==='' || password==='' || email === '' || name === '' || phone === '' || company === '' ) {
        this.setState({message: 'Missing required fields', isOpen: true});
        return;
      }
      else if (!this.validateCompany() || !this.validateEmail()) return;
      await this.cleanCompany();
      const user = await Auth.signUp({
        username: username,
        password: password,
        attributes: {
          email: email,
          'custom:phone': phone,
          'custom:company': company,
          'custom:private': "0",
          name: name
        }
      });
      console.log(user);
      this.setState({message: 'User created successfully', isOpen: true});
      //this.props.history.push("/");
      localStorage.setItem('tempUsername', username);
      window.location.href = `${this.props.home}/account-confirmation`;
    } catch (error) {
        this.setState({message: 'Error creating account: ' + error.message, isOpen: true});
    }
    //super.signIn().then(() => {
        // window.location.href = `${this.props.home}`;
    // });
  }

  validateCompany = () => {
    let urlTest = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    return this.validateField("company", RegExp(urlTest), "Company website format is invalid");
  }

  cleanCompany = async () => {
    let {company} = this.state;
    company = company.replace(/^\/\/|^.*?:(\/\/)?/, '');
    await this.setState({company});
  }

  validateEmail = () => {
    let emailTest = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    return this.validateField("email", RegExp(emailTest), "Email format is invalid");
  }


  validateField = (val, regex, message) => {
    let formVal = this.state[val];
    let errVal = "err_"+val;
    if (!formVal.match(regex) || formVal == ''){
      this.setState({[errVal]: true, message: message, isOpen: true });
      return false;
    } else {
      this.setState({[errVal]: false, message: '', isOpen: false});
      return true;
    }
  }

    render() {
        return (
            <div className="Login" style={{backgroundImage: `url(/img/bg/4.jpg)` }}>
            <Snackbar open={this.state.isOpen}
              autoHideDuration={2500}
              onClose={() => this.handleClose()}
              message = {this.state.message}
            >
            </Snackbar>
                <div className="Login-content">
                    <div className="background">
                        <Container component="main" maxWidth="xs">
                            <CssBaseline />
                            <div>
                                <img src={this.props.logo} alt="logo" width="45%" className="mb-6"/>
                                <h5 className="login-title">Create an Account</h5>

                                <form name="form">
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="username"
                                        label="Username"
                                        name="username"
                                        value={this.state.username} onChange={this.handleChange}
                                        onKeyUp={this.keyPress}
                                        autoFocus
                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type="password"
                                        id="password"
                                        value={this.state.password} onChange={this.handleChange}
                                        onKeyUp={this.keyPress}
                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="name"
                                        label="Your Name"
                                        name="name"
                                        value={this.state.name} onChange={this.handleChange}
                                        onKeyUp={this.keyPress}
                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="email"
                                        label="Work Email Address"
                                        name="email"
                                        error={this.state.err_email}
                                        value={this.state.email} onChange={this.handleChange}
                                        onKeyUp={this.keyPress}                                        
                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="company"
                                        label="Company Website"
                                        name="company"
                                        error={this.state.err_company}
                                        value={this.state.company} onChange={this.handleChange}
                                        onKeyUp={this.keyPress}
                                    />
                                    <MuiPhoneNumber 
                                      onlyCountries={['us']}
                                      defaultCountry='us'
                                      variant="outlined"
                                      margin="normal"
                                      required
                                      fullWidth
                                      id="phone"
                                      label="Mobile Phone Number"
                                      name="phone"
                                      value={this.state.phone} 
                                      onChange={(phone) => {
                                        this.setState({phone});
                                      }}
                                      onKeyUp={this.keyPress}
                                      />
                                   {/* <p class="small">Your contact info will only be shared with users you are connected with or users you choose to share it with. You can change your privacy settings at any time.</p>
                                    <FormControlLabel
                                      control={<Checkbox checked={this.state.isPrivate} onChange={this.handleCheckChange} name="isPrivate" />}
                                      label="Keep my contact info private"
                                    />*/}
                                    <Button
                                        type="button"
                                        onClick={() => {this.handleSignUp()} }
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                    >
                                        Create Account
                                    </Button>
                                    {/*<p style={{textAlign: 'center', marginTop: '1.5em'}}>Already have an account? <Link variant="body2" tabIndex="0" onClick={() => window.location.href = `${url.frontend}/register`}>Resend your activation code</Link>
                                    </p>*/}
                                </form>
                            </div>
                        </Container>
                    </div>
                </div>

            </div>
        );
    }
}

export default withRouter(RegisterCore);
