import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getTimeAmPm, getLocalDate } from '../../helpers/dateHelpers';
import moment from 'moment';

//Defining the variables for the styles up here because,
//even though it's against ReactJS conventions, its just a lot
//cleaner and more convenient than doing things the "right" way
//for this very specific and rare situation

/*let headingStyle = null;
let h1Style = null;
let h2Style = null;
let h3Style = null;
let h4Style = null;
let pStyle = null;
let filtersStyle = null;
let ulStyle = null;
let smallStyle = null;*/

let headingStyle = {
    backgroundColor: "#FFF",
    color: "#333",
    fontFamily: "'Open Sans', sans-serif",
    fontSize:"12pt",
    fontWeight: "300",
    marginLeft: "16pt",

}

let h1Style = {
    fontFamily: "'Open Sans', sans-serif",
    fontSize:"32pt",
    fontWeight: "400",
    marginLeft: "16pt",

}

let h2Style = {
    fontFamily: "'Oswald', sans-serif",
    fontWeight: "400",
    marginBottom: "8pt",
    marginLeft: "16pt",
    marginTop: "16pt",
}

let h3Style = {
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: "400",
    marginTop:"4pt",
    marginBottom:"4pt",
    marginLeft: "16pt"

}

let h4Style = {
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: "600",
    marginTop: "8pt",
    marginLeft: "16pt",
    marginBottom: "0pt",    
}

let pStyle = {
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: "100",
    marginTop: "0pt",
    marginLeft: "16pt",
    marginBottom: "0pt",
}

let filtersStyle = {
    fontFamily: "'Open Sans', sans-serif",
    padding: "16pt",
    marginLeft: "16pt",
    marginTop: "0pt",
    marginBottom: "0pt",
}

let ulStyle = {
    fontFamily: "'Open Sans', sans-serif",
    marginTop: "8pt",
    marginLeft: "20pt",
    marginBottom: "0pt",
    listStyle: "none"
}

let liStyle = {
    fontFamily: "'Open Sans', sans-serif",
    marginTop: "8pt",
    marginLeft: "24pt",
    paddingLeft: "16pt",
    marginBottom: "2pt",
}

let smallStyle = {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: "10pt",
    fontWeight: "400",
    marginLeft: "16pt"
}



class PDFdiv extends Component {

    constructor(props){
        super(props);
        this.state = {
            filters: props.filters,
            items: props.items,
            totalTonnage: props.items.reduce((a, c) => a + c.tonnage || 0, 0),
            siteName: JSON.parse(localStorage.getItem('site')).name
        }
    }


    calcTonnage = () => {
        let totalTonnage = this.state.items.reduce((total, ticket) => {
            let item = ticket._original.original;
            if (item.invalidreason !== null && item.recoverreason === null) 
                return total;
            else return total + item.dumped;
        }, 0);

        this.setState({totalTonnage});
    }

    calcLoads = () => {

        let totalLoads = this.state.items.reduce((total, ticket, i) => {
            let item = ticket._original.original;
            if (item.invalidreason !== null && item.recoverreason === null) {
                return total;
            }
            else return total + 1;
        }, 0);

        this.setState({totalLoads});

    }

    componentWillReceiveProps(newProps){
        console.log("newProps.valuesForPdf", newProps.valuesForPDF)
        let filters = {};
        for(var i = 0; i < newProps.valuesForPDF.filter.length; ++i){
            switch(newProps.valuesForPDF.filter[i].id){
                case 'source_site':
                    filters = Object.assign(filters, {exportSite: newProps.valuesForPDF.filter[i].value})
                break;
                case 'dump_site':
                    filters = Object.assign(filters, {importSite: newProps.valuesForPDF.filter[i].value})
                break;
                case 'truckLicense':
                    filters = Object.assign(filters, {truckId: newProps.valuesForPDF.filter[i].value})
                break;
                case 'soilProfile':
                    filters = Object.assign(filters, {soilProfile: newProps.valuesForPDF.filter[i].value})
                break;
                case 'date':
                    filters = Object.assign(filters, {date: newProps.valuesForPDF.filter[i].value})
                break;
            }
        }

        this.setState({filters, items: newProps.valuesForPDF.items}, () => {this.calcTonnage(); this.calcLoads(); })
    }

    renderDateFilter = (datesObj) => {
        if (!datesObj) return '(no filter)';

        else {
            let ret = "";
            let min = "Beginning of records";
            let max = "Now";

            if (datesObj.max !== undefined){
                max = moment(datesObj.max).format("MM/DD/YYYY");
            }

            if (datesObj.min !== undefined){
                min = moment(datesObj.min).format("MM/DD/YYYY");
            }

            return min + " to " + max;

        }
    }


    render(){
        const items = this.state.items.map((r, i) => {
            console.log(r)
            return (
                <tr>
                    <td>{getLocalDate(r.date)}</td>
                    <td>{r.ticket}</td>
                    <td>{r.source_site == this.state.siteName ? r.dump_site : r.source_site}</td>
                    <td>{getTimeAmPm(r.loaded)}</td>
                    <td>{r.unloaded}</td>
                    <td>{r.truckLicense}</td>
                    <td>{r.soilProfile}</td>
                    <td>{r.tonnage}</td>
                </tr>
            )
        })
        return (
            <div style={{visibility: "hidden", position: "absolute", bottom: "0"}}>
                <div id="pdfHeading" style={headingStyle}>
                    <h2 style={h2Style}>Assured Disposal</h2>
                    <h1 style={h1Style}>Tonnage Report</h1>
                    <h3 style={h3Style}>{this.state.siteName}</h3>
                    <p style={pStyle}>Generated: {(new Date()).toLocaleDateString()}</p>
                    <small style={filtersStyle}>Filters Applied:</small>
                    <p style={liStyle}>
                        Dates: {this.renderDateFilter(this.state.filters.date)}
                    </p>
                    <p style={liStyle}>
                        Export Site: {this.state.filters.exportSite || '(no filter)'}
                    </p>
                    <p style={liStyle}>
                        Import Site:  {this.state.filters.importSite || '(no filter)'}
                    </p>
                    <p style={liStyle}>
                        Truck #:  {this.state.filters.truckId || '(no filter)'}
                    </p>
                    <p style={liStyle}>
                        Soil Profile: {this.state.filters.soilProfile || '(no filter)'}
                    </p>               
                    <h4 style={h4Style}>Total Loads: &nbsp; {this.state.totalLoads}</h4>
                    <h4 style={h4Style}>Total Tonnage: &nbsp; {this.state.totalTonnage.toFixed(2)}</h4>
                </div>
                <table id="pdfTable">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Ticket</th>
                            <th>Partner Site</th>
                            <th>Load Time</th>
                            <th>Dump Time</th>
                            <th>Truck Plate #</th>
                            <th>Soil Profile</th>
                            <th>Tonnage</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items}                          
                    </tbody>
                </table>
            </div>

        )
    }
}

function mapStateToProps(state) {
    return {
        valuesForPDF: state.pdf.valuesForPDF,
    };
  }
  
  function mapDispatchToProps(dispatch) {
    return {

    };
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(PDFdiv)