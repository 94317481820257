export function convertDateToShortString(date){
    var daysOfWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"]
    date = new Date(date);
    return daysOfWeek[date.getDay()] + " " + ((date.getHours() % 12) || "12") + ":" + ('0' + date.getMinutes()).substr(-2) + ((date.getHours() >= 12) ? "PM" : "AM")/*  + " - " + date.toISOString().substr(5,5).replace('-', '/') + "/" + date.getFullYear() */
}

export function getTimeAmPm(date){
    date = new Date(date);
    const hour = date.getHours() % 12 || 12;
    const minutes = date.getMinutes();
    const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
    return hour + ':' + minutes + ampm;
}

export function getLocalDate(date){
    date = new Date(date);
    const day = ('0' + date.getDate()).substr(-2)
    const month = ('0' + (date.getMonth() + 1)).substr(-2)
    const year = date.getFullYear();
    return month + '/' + day + '/' + year;
}