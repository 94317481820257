import React, {Component} from 'react';
import ajax, {Request} from 'superagent';
import config from '../../../constants/config';
import LetterAvatar from '../../LetterAvatar';
import TempTruckID from '../TempTruckID';


export default class TruckProfile extends Component {
    constructor(props){
    	super(props);

        let photo = this.props.photo;

    	this.state = {
    		idtruck: this.props.idtruck,
    		truckData: {
    			owner: "NA",
    			plate: "NA",
    			shortcode: "NA",
                number: "NA",
    			photo
    		}
    	}

    	this.refreshTruckData(this.state.idtruck)
    }


    componentWillReceiveProps = (newProps) => {

    	if (newProps.idtruck != this.state.idtruck){
    		this.refreshTruckData(newProps.idtruck);
    	}

    }


	 refreshTruckData = (idtruck) => {
		ajax.get(`${config.apiUrl}/trucks/${idtruck}`)
        .end((error, response) => {
            if (!error && response) {
                if (response.body.success && response.body.truckData !== undefined){
                    this.setState({truckData: response.body.truckData[0]})
                    //console.log(this.state.truckData);
                }
            } else {
                console.log('There was an error fetching', error);
            }
        });
	}


	render(){
		//return null;
		if (this.state.truckData == undefined){
			return null;
		}

        let truckData = this.state.truckData;
		return(
			<div class="row p-1 mt-6 mx-0 bg-gray">
				<div class="col-12 p-0">

					{truckData.owner ? <label class="col-6 m-0 pl-1 pt-1 fw-600 "><img class="m-1 mb-1 mr-1" width="20" height="20" src={LetterAvatar(truckData.owner)} />{truckData.owner}</label> : null }
					{truckData.plate ? <label class="col-6 m-0 pr-2 pt-1 fw-600 float-right text-right">{truckData.plate}</label> : null }                                                

                    {truckData.number && !truckData.plate ? <TempTruckID
                        number={truckData.number || 0}
                        idtruck={truckData.idtruck}
                        idElement="label"
                        idClasses = "col-6 m-0 pl-0 pr-0 fw-600 float-left"
                        showIcon = {true}
                        iconClasses = "lead fa fa-exclamation-triangle text-warning mr-2 fw-100"
                        showLink = {true}
                        linkElement="label"
                        linkClasses = "col-6 small-3 pt-1 px-0 text-primary text-right cursor float-right"
                    /> : null}
					{truckData.fleetnum ? <label class="col-6 m-0 pl-2 fw-200 float-left">Fleet #{truckData.fleetnum}</label> : null}
					{truckData.shortcode ? <label class="col-6 m-0 pr-2 fw-200 float-right text-right">{truckData.shortcode}</label> : null}

					<img class="py-1" src={config.apiUrl + "/images/trucks/" + truckData.photo} />

				</div>
			</div>
		)

	}





}
