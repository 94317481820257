import React, {Component} from 'react';
import ajax from 'superagent';
import MenuAppBar from '../NavBar';
import Footer from '../Footer';
import config from '../../constants/config';
import ModalEditTeam from './ModalEditTeam';
import ModalNewSite from './ModalNewSite';
import pageUrls from '../../constants/pageUrls'

export default class DumpProject extends Component {
    constructor(props){
        super(props);
        let user = localStorage.getItem('user');
        let userId = user ? JSON.parse(user).id : null;
        this.state = {
            sources: {},
            activeSite: undefined,
            userId,
            sites: []
        }

        this.getSites = this.getSites.bind(this);
        this.renderNoSites = this.renderNoSites.bind(this);

    }

    componentWillMount() {
        if(this.state.userId){
            this.getSites();
        }

        this.getGeoLocation();
    }

    componentDidMount() {
        document.title = 'Assured Disposal Sites';
    }

    getGeoLocation = (event, cb) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
               // cb(null, position.coords)
            }, error => {
               // cb(error);
            });
        } else {
            //cb({type: 'no geolocation'})
        }
    }

    getSites(){
        ajax.get(`${config.apiUrl}/sites/readSiteAndProjectDataByUserId/${this.state.userId}`)
            .end((error, response) => {
                if (!error && response) {
                    let sites = [];
                    if (response.body.sitesList){
                        console.log("sitesList", response.body.sitesList);
                        sites = response.body.sitesList.map(r => {
                            return {
                                idsite: r.idsite,
                                name: r.name,
                                numberOfProjects: r.projects,
                                activeTickets: r.tickets,
                                soilProfiles: r.soilProfiles,
                                tonsToDate: r.tons_dumped,
                                profile: r.idprofile,
                                notification: r.notifications,
                            }
                        });
                        console.log('====================', sites)
                    }
                    this.setState({sites})
                } else {
                    console.log('There was an error fetching from GitHub', error);
                }
            }
        );
    }
    
    siteCard(site, index){
        console.log("site", site)
        let activeTickets = site.activeTickets || 0;
        let profile = site.profile;
        
        let showBadge = false;
        let badgeClear = false;

        if (site.notification !== undefined){
            showBadge = true;
            badgeClear = site.notification.dateread !== null;
        }

        let canAddAgreement = (profile === 1 || profile === 2)
        let canEditTeam = (profile === 1 || profile === 2 )

        return (
            <div className="col-lg-8 mt-1 mb-4"  key={index}>
                <div className="card border hover-shadow-4">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-6">
                                <div className={"badge-primary mr-3" + (badgeClear ? " badge-ring" : " badge-dot")} 
                                    style={{display: (showBadge ? 'inline-block' : 'none')}}></div>
                                <label className="lead mb-2 fw-500"><strong>{site.name}</strong></label>
                            </div>
                            <div className="col-6">
                                <a className={"badge badge-pale " + (activeTickets > 0 ? "badge-success" : "badge-secondary") + " mt-1 fs-14 float-right"} 
                                    style={{cursor: 'pointer'}}
                                    onClick={() => this.setSite(site.idsite, site.name, "storage", pageUrls.activeTickets)}>
                                    <strong>{activeTickets} &nbsp;Active Ticket{activeTickets == 1 ? null : 's'}</strong>
                                </a>
                            </div>
                            <hr className="col-10 col-sm-11 bg-secondary mt-2 mb-2 mx-auto" />
                            <div className="col-1">
                                <div className="dropdown mt-2 pr-lg-4 pb-2">
                                    <span className="dropdown-toggle no-caret" data-toggle="dropdown"><i className="ti ti-settings text-lighter" fs-12 fw-500 p-1 aria-hidden="true"></i></span>
                                    <div className="dropdown-menu">
                                        <a className="dropdown-item cursor"data-toggle="modal" data-target="#edit-team" 
                                            onClick={() => this.setSite(site.idsite, site.name, "state")}>
                                            { canEditTeam ? "Edit" : "View"} AD Site Team</a>
                                        <a className="dropdown-item cursor"
                                            style={ canAddAgreement ? { 'display' : 'block' } : { 'display' : 'none'}} 
                                            onClick={() => this.setSite(site.idsite, site.name, "storage", pageUrls.newDisposalAgreement)} >
                                        Add Agreement</a>
                                    </div>  
                                </div>
                            </div>
                            <div className="col-5 mt-1 pl-3 pl-lg-0 pt-1">
                                <a href="#" onClick={() => this.setSite(site.idsite, site.name, "storage", pageUrls.agreementsList)}>
                                    <span className="link"><u>{site.numberOfProjects} &nbsp;Agreement{site.numberOfProjects == 1 ? null : 's'}</u></span>
                                </a>
                            </div>
                            <div className="col-5 col-md-5 pt-1 pr-4 ml-auto">
                                <label className="fs1m mt-1 fw-300 float-right">{site.tonsToDate} Ton{site.tonsToDate == 1 ? null : 's'} TD</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>);
    }

    renderNoSites() {
        return(
           
            <div className="col-12 col-sm-8 col-md-6 col-lg-5 mx-auto ml-md-0">
                <div className="card d-block shadow-1 hover-shadow-3">
                    <div className="card-body ">
                        <h5 className="card-title empty-card-heading">No sites yet</h5>
                        <p className="mb-0">To get started, add a new site and invite your team.</p>
                    </div>
                    <div className="card-footer  text-left">
                        <a className="fs-12 fw-600" href={config.eiFrontPage}><span className="text-primary cursor">Return to Sandbox Map to create a new Site</span></a>
                    </div>
                </div>
            </div>
        );
    }

   setSite(idsite, name, mode, navTarget){
        let siteobj = {name, idsite};
        
        if (mode === "storage") localStorage.setItem('site', JSON.stringify(siteobj));
        else if (mode === "state") this.setState({activeSite : siteobj});
        else alert("Mode in setSite not set! This is a critical error");


        if (navTarget) this.props.history.push(navTarget);
   }

    renderSources(){
        const sites = [];
        for(let i in this.state.sites){
            sites.push(i);
        }
        if (sites.length == 0){
            return this.renderNoSites();
        } else {
            return sites.sort().map((r, i) => {
                return this.siteCard(this.state.sites[i], i);
            });
        }
    }

    renderSupportLink = () => {
        return(
            <a class="lead cursor float-right mr-2 mr-lg-10 mt-0" 
              data-toggle="offcanvas" data-target="#offcanvas-setup-1" href="#">
                <span data-toggle="tooltip" data-placement="left" title="Getting Set Up">
                    <i class="fa fa-question-circle text-light fs-18 pt-1 pr-2" aria-hidden="true"></i>
                </span>
            </a>
        )
    }

    render(){
        return(<React.Fragment>
            <MenuAppBar showSandbox={true}/>
            <main className="main-content">
                {this.renderSupportLink()}
                <header className="section-header mt-7 mb-1">
                    <h2><strong>Assured Disposal Sites</strong></h2>
                </header>
                <div className="section pt-3">
                    <div className="container">
                        {/*<div className="col-12 flexbox">
                            <a></a>
                                <a className="btn btn-primary" data-toggle="modal" data-target="#add-site" style={{color: 'white'}}>+ NEW Assured Disposal Site</a>
                            <a></a>
                        </div>*/}

                            <label className="lead mt-5 mb-4 mr-4 ml-7 fw-500">MY SITES</label>
                            <div className="row ml-3">
                                {this.renderSources()}
                               
                            </div>  
                            <div className="row ml-3">
                                <label><em className="col-12 small-5 lh-1 my-0 py-0 ml-2">Click on "Active Tickets" to create and manage tickets.</em></label>   
                            </div>

                    </div>
                </div>
            </main>
            <Footer />
            <ModalEditTeam site={this.state.activeSite}  />
            <ModalNewSite refresh={this.getSites} />
        </React.Fragment>);
    }
}
