import React, {Component, Fragment} from 'react';
import config from '../../constants/config';
import pageUrls from '../../constants/pageUrls';
import ajax from 'superagent';
import { userService } from '../../services';
import SandboxCore from './SandboxCore';
import jwtDecode from 'jwt-decode';

export default class SandboxMap extends Component {

    constructor(props){
        super(props);
        const userDecoded = jwtDecode(localStorage.getItem('token'));

        let username = userDecoded['cognito:username'];

        this.state = {
            username
        }
    }


    render = () => {
        return(
            <Fragment>
                <SandboxCore
                    backendURL={config.eiBackend}
                    app={"AssuredDeliveries"}
                    username={this.state.username}
                    locked = {this.props.locked}
                    setLocked={this.props.setMapLocked}
                    refreshSites = {this.props.getSiteList}
                    mySitesList = {this.props.sitesList || []}
                    adSharedSite = {this.props.sharedSite}
                    hcGroupSites = {[]}
                />
            </Fragment>
        )
    }
}
