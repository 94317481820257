import React, {Component} from 'react';
import DatePicker from 'react-datepicker';
import './style.css';
import { getUserById } from '../../../services/userService';
import LetterAvatar from '../../LetterAvatar';
import config from '../../../constants/config';
import TonnageCalculator from '../../TonnageCalculator';
import TruckProfile from '../TruckProfile';
import SuccessPopup from '../../Popups'
import { stripeService } from '../../../services';
import ajax from 'superagent';
import Contact from '../Contact';


import { connect } from 'react-redux';
import { setTicketForModal,
    getActiveTickets,
    createTicket,
    updateTicketSourceGeo,
    updateTicketSourcePicture,
    updateTicketArrivalGeo,
markTicketAsInProgress,
 updateTicketArrivalPicture } from '../../../actions/tickets';

/*import {
    getActiveTickets,
    createTicket,
    updateTicketSourceGeo,
    updateTicketSourcePicture,
    updateTicketArrivalGeo,
    updateTicketArrivalPicture,
    ,
} from '../../../actions/tickets';*/


class ModalConfirmDisposedPhoto extends Component {
    constructor(props){
        super(props);
        let userId = JSON.parse(localStorage.getItem('user')).id;
        let idsite = JSON.parse(localStorage.getItem('site')).idsite;

        this.state = {
            showModal: false,
            imageToSend: null,
            imageSelected: null,
            tonnage: 20,
            workingOffline: false,
            offlineConfirmation: false,
            offlineTimestamp: "",
            offlineSignature: "",
            cameraList: [],
            cameraSelected: 0,
            loadingLocalImage: false,
            ticketCompletionPopupTrigger: false,
            ticketInvalidPopupTrigger: false,
            recoverPopup: false,

            canEditTickets: false,

            invalidreason: null,
            showInvalid: false,

            recoverreason: null,
            showRecoverInput: false,


            profile: props.ticket && props.ticket.profile,
            idproject: props.ticket && props.ticket.idproject,
            internalnumber: props.ticket && props.ticket.internalnumber,
            transporter: props.ticket && props.ticket.transporter,
            truck: props.ticket && props.ticket.truck,
            truckphoto: props.ticket && props.ticket.truckphoto,
            licenseplate: props.ticket && props.ticket.licenseplate,
            source_site: props.ticket && props.ticket.source_site,
            dump_site: props.ticket && props.ticket.dump_site,
            generatorname: props.ticket && props.ticket.generatorname,
            timeString: props.ticket && props.ticket.timeString,
            picture_load: props.ticket && props.ticket.picture_load,
            creatorName: props.ticket && props.ticket.creatorname,
            idcreator: props.ticket && props.ticket.idcreator,
            idticket: props.ticket && props.ticke.idticket,
            sourcetimestamp: props.ticket && props.ticket.sourcetimestamp,
            sourcesignature: props.ticket && props.ticket.sourcesignature,
            userId,
            idsite

        }

        this.getGeoLocation = this.getGeoLocation.bind(this);

        this.getGeoLocation("dumpsite", (error, coords) => {
            if(!error){
                this.setState({dumpCoords : {lat: coords.latitude, long: coords.longitude}});
            }
        })
        let userResponse = getUserById(userId);
        userResponse.then((response) => {
            this.setState({
                chargeinfo: {
                    amount:         80,
                    description:    `Assured Disposal Charge to ${response.user[0].email}`,
                    currency:       'usd',
                    receipt_email:  response.user[0].email,
                    confirm:        true,
                    customer:       response.user[0].stripe_cus_id,
                    payment_method: response.user[0].stripe_token,
                },
                closerName: response.user[0].username,
            })
        });

    }

    componentWillReceiveProps(newProps){
        if(newProps.ticket !== this.props.ticket){
            if(newProps.ticket.dumped){
                this.setState({
                    tonnage: newProps.ticket.dumped,
                })
            }
            this.setState({
                profile: newProps.ticket.profile,
                idproject: newProps.ticket.idproject,
                internalnumber: newProps.ticket.internalnumber,
                transporter: newProps.ticket.transporter,
                truck: newProps.ticket.truck,
                truckphoto: newProps.ticket.truckphoto,
                licenseplate: newProps.ticket.licenseplate,
                source_site: newProps.ticket.source_site,
                dump_site: newProps.ticket.dump_site,
                generatorname: newProps.ticket.generatorname,
                timeString: newProps.ticket.timeString,
                invalidreason: newProps.ticket.invalidreason,
                picture_load: newProps.ticket.picture_load,
                creatorName: newProps.ticket.creatorname,
                idcreator: newProps.ticket.idcreator,
                idticket: newProps.ticket.idticket,
                sourcetimestamp: newProps.ticket.sourcetimestamp,
                sourcesignature: newProps.ticket.sourcesignature,

                showInvalid: false, invalid: false
            }, () =>{ 
                let invalidText = document.getElementById('invalid-reason-textarea');
                if (invalidText)
                    invalidText.value = ""
            });
        }

        

        if(newProps.tickets.updateTicketArrivalPicture.inProgress !== this.props.tickets.updateTicketArrivalPicture.inProgress){
            if(newProps.tickets.updateTicketArrivalPicture.success){
                this.setState({imageSelected: null, imageToSend: null});
                this.props.fetchTickets();
                const { chargeinfo } = this.state;
                //stripeService.charge(chargeinfo);
            }
        }
    }

    /*componentWillMount = () => {
        ajax.get(config.apiUrl + '/sites/permissions/' + this.state.userId + '/' + this.state.idsite)
            .end((error, response) => {
                if (!error && response) {
                    this.setState({canEditTickets: response.body.permissions.canEditTickets})
                } else {
                    console.log('There was an error fetching', error);
                }
            }
        );
    }*/

    getGeoLocation(event, cb){
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                cb(null, position.coords)
            }, error => {
                cb(error);
            });
        } else {
            let currentPosition = {
                status: 'error',
                position: null,
            }
            this.setState({[event]: currentPosition.position});
        }
    }

    handleChange(e) {
        /*if(e.target.name == "tonnage"){
            let vals = e.target.value.split('.');
            e.target.value = vals[1] && vals[1].length > 2 ? vals[0] + '.' + vals[1].substr(0,2) : e.target.value;
        }*/
        this.setState({[e.target.name]: e.target.value})
    }

    handleOfflineToggle(e){
        this.setState({workingOffline: !this.state.workingOffline})
    }

    handleOfflineConfirmation(e){
        this.setState({workingOffline: !this.state.workingOffline});
    }


    setupVideo(){
        const video = document.getElementById('cameraInputDisposed');
        const canvas = window.canvas = document.getElementById('takenPictureDisposed');
        canvas.width = 480;
        canvas.height = 360;

        const button = document.getElementById('takePictureDisposed');
        button.onclick = () => {
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
            canvas.toBlob(blob => {
                this.setState({imageToSend: blob})
            })
        };

        const changeCameraDisposed = document.getElementById('changeCameraDisposed');
        changeCameraDisposed.onclick = () => {
            this.setState({cameraSelected: this.state.cameraSelected + 1});
            start.bind(this)();
        }

        function handleError(error) {
            console.log('navigator.MediaDevices.getUserMedia error: ', error.message, error.name);
        }

        var gotDevices = (deviceInfos) => {
            // Handles being called several times to update labels. Preserve values.
            this.setState({cameraList : []});
            let newCameraList = [];
            for (let i = 0; i !== deviceInfos.length; ++i) {
                const deviceInfo = deviceInfos[i];
                if (deviceInfo.kind === 'videoinput') {
                    newCameraList = [...newCameraList, deviceInfo.deviceId];
                    //this.setState({cameraList: [...this.state.cameraList, deviceInfo.deviceId]})
                }
            }
            newCameraList.reverse();
            this.setState({cameraList: newCameraList})
        }
        
        function gotStream(stream) {
            window.stream = stream; // make stream available to console
            video.srcObject = stream;
            // Refresh button list in case labels have become available
            return navigator.mediaDevices.enumerateDevices();
        }

        function start() {
            if (window.stream) {
              window.stream.getTracks().forEach(track => {
                track.stop();
              });
            }
            canvas.getContext('2d').clearRect (0, 0, canvas.width, canvas.height);
            const videoSource = this.state.cameraList[this.state.cameraSelected % this.state.cameraList.length];
            const constraints = {
              audio: false,
              video: {deviceId: videoSource ? {exact: videoSource} : undefined}
            };
            navigator.mediaDevices.getUserMedia(constraints).then(gotStream).then(gotDevices).catch(handleError);
        }
        navigator.mediaDevices.enumerateDevices().then(gotDevices).then(start.bind(this)).catch(handleError);
        document.getElementById('modal-disposal').addEventListener('blur', this.stopVideo);
    }

    stopVideo(){
        if (window.stream) {
            window.stream.getTracks().forEach(track => {
                track.stop();
            });
        }
    }


     handleUpdate = () =>{


        this.stopVideo();
        const offlineData = {
            tons: this.state.tonnage,
            workingOffline: this.state.workingOffline,
            offlineConfirmation: this.state.offlineConfirmation,
            offlineTimestamp: new Date(this.state.offlineTimestamp),
            offlineSignature: this.state.offlineSignature,
        }
        this.setState({imageSelected: null, imageToSend: null, showCameraDisposed: false, showModal: false, offlineSignature: '', offlineTimestamp: null}, () => {
            if(this.state.workingOffline){
                document.getElementById('workingOffLineCheckbox').click()
            }
        });

        this.props.markTicketAsInProgress(this.props.ticket.idticket)
        const today = new Date();
        function get24hours(date){
            date = new Date(date);
            let hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
            let minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
            let seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
            return "" + hours + ":" + minutes + ":" + seconds
        }
        this.getGeoLocation("dumpSite", (error, coords) => {
            let geo_load = null;
            if(!error){
                geo_load = `POINT(${coords.longitude} ${coords.latitude})`;
            }

            const data = {
                geo_load,
                id: this.props.ticket.idticket,
                timestamp: today.toISOString().split('T')[0]+ ' ' + get24hours(today),
                idcloser: JSON.parse(localStorage.getItem('user')).id,
                invalidreason: this.state.invalidreason
            }
            this.props.updateTicketArrivalGeo(data);
        });

        this.props.updateTicketArrivalPicture(
            this.props.ticket.idticket, 
            //this.props.dumpImage, 
            offlineData.workingOffline ? offlineData.offlineSignature : '', 
            offlineData.tons,
            offlineData.workingOffline ? offlineData.offlineTimestamp.toISOString() : today.toISOString(),
            today.toISOString(),
            this.showPopUp,
        );
    }

    sendInvalidData = () => {
        const today = new Date();
        function get24hours(date){
            date = new Date(date);
            let hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
            let minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
            let seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
            return "" + hours + ":" + minutes + ":" + seconds
        }

        this.getGeoLocation("dumpSite", (error, coords) => {
            let geo_invalid = null;
            if(!error){
                geo_invalid = `POINT(${coords.longitude} ${coords.latitude})`;
            }

           const data = {
                geo_invalid,
                id: this.props.ticket.idticket,
                timestamp: today.toISOString().split('T')[0]+ ' ' + get24hours(today),
                idcloser: JSON.parse(localStorage.getItem('user')).id,
                invalidreason: this.state.invalidreason
            }

            ajax.post(`${config.apiUrl}/tickets/trackarriving/geo`)
            .send(data)
            .then(res => {

                const offlineData = {
                    tons: this.state.tonnage,
                    workingOffline: this.state.workingOffline,
                    offlineConfirmation: this.state.offlineConfirmation,
                    offlineTimestamp: new Date(this.state.offlineTimestamp),
                    offlineSignature: this.state.offlineSignature,
                }
                this.props.updateTicketArrivalPicture(
                    this.props.ticket.idticket, 
                    //this.props.dumpImage, 
                    offlineData.workingOffline ? offlineData.offlineSignature : '', 
                    offlineData.tons,
                    offlineData.workingOffline ? offlineData.offlineTimestamp.toISOString() : today.toISOString(),
                    today.toISOString(),
                    this.showPopUp
                );
                this.setState({invalid: true});
                this.setState({
                    invalidmarkername: res.body.data[0].invalidmarkername,
                    invalidtimestamp: res.body.data[0].invalidtimestamp,
                    invalidCoords: res.body.data[0].geo_invalid,
                    invalidreason: res.body.data[0].invalidreason,
                    invalid: true,
                    showInvalidInput: false,
                });
            }).catch(e =>{
                //alert('Error creating project!');
                throw e;
            });    
            
        });
    }

    sendRecoverData = () => {
        this.getGeoLocation("recoverCoords", (error, coords) => {
            let geo_recover = null;
            if(!error){
                geo_recover = `POINT(${coords.longitude} ${coords.latitude})`;
            }

            let user = localStorage.getItem('user');
            let userId = user ? JSON.parse(user).id : null;

            const data = {
               idrecovermarker: userId,
               geo_recover,
               recoverreason: this.state.recoverreason,
               idticket: this.props.ticket.idticket
            }

            ajax.post(`${config.apiUrl}/tickets/trackinvalid/recover`)
            .send(data)
            .then(res => {
                //this.setState({recoverPopup: true});
                console.log("ticket successfully marked as recovered");
                console.log("Res:");
                console.log(res.body);
                this.setState({
                    recovermarkername: res.body.data[0].recovermarkername,
                    recovertimestamp: res.body.data[0].recovertimestamp,
                    recoverCoords: res.body.data[0].geo_recover,
                    recoverreason: res.body.data[0].recoverreason,
                    recovered: true,
                    showRecoverInput: false,
                    recoverPopup: true
                });
            }).catch(e =>{
                //alert('Error creating project!');
                throw e;
            });    
            
        });
    }

    showPopUp = () => {
        let popupToUse = (

            this.state.showInvalid ? 
            "ticketInvalidPopupTrigger" :
            "ticketCompletionPopupTrigger");

        if (!this.state[popupToUse]) 
            this.setState({[popupToUse]: true});
    }
    
    render(){
        const coords = this.props.ticket && this.props.ticket.geo_load ? this.props.ticket.geo_load.replace('POINT(', '').replace(')', '').split(' ') : [0,0];
        function convertTimestamp(timestamp){
            let date = new Date(timestamp);
            return ('0' + (date.getMonth() + 1)).substr(-2)
                + '-' + ('0' + date.getDate()).substr(-2)
                + '-' + date.getFullYear().toString().substr(2,2)
                + " " + ((date.getHours() % 12) || "12") + ":" + ('0' + date.getMinutes()).substr(-2) + ((date.getHours() >= 12) ? "PM" : "AM")
        }
        function dateFromTimestamp(timestamp){
            let date = new Date(timestamp);
             return ((''+(date.getMonth() + 1)).substr(-2)
                + '/' + (''+ date.getDate()).substr(-2)
                + '/' + date.getFullYear().toString().substr(2,2));
        }
        let daysOfWeek=["SUN","MON","TUES","WED","THU","FRI","SAT"];
        function dayAndTimeFromTimestamp(timestamp){
            let date = new Date(timestamp);
             return (daysOfWeek[date.getDay()]
                + " " + ((date.getHours() % 12) || "12") + ":" + ('0' + date.getMinutes()).substr(-2) + ((date.getHours() >= 12) ? "PM" : "AM"));
        }
        return (<React.Fragment>
            <div className="modal" id="modal-disposal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-long" role="document">
                    <div className="modal-content">


                        {!this.state.invalid && !this.state.recovered ? 
                            <div className="alert alert-primary" role="alert">
                                <strong>Are you ready to confirm this dump?</strong>
                                <button type="button" id="close-confirm-disposed-modal" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        : null }

                        {this.state.invalid && !this.state.recovered ?
                            <div class="alert alert-dark" role="alert">
                                <strong>Invalid Ticket</strong>
                                <button type="button" id="close-confirm-disposed-modal" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        : null }

                        {this.state.recovered ? 
                            <div class="alert alert-info" role="alert">
                                <strong>Recovered Ticket</strong>
                                <button type="button" id="close-confirm-disposed-modal" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        : null}


                        <div className="modal-body pt-0 pb-3">
                            <div className="row m-0 mb-4">
                                <div className="col-12 p-0 mt-4 mb-1 border-bottom">
                                    <label className="badge badge-pale badge-info fw-600 fs1m p-1 mb-2 float-right text-right">#{this.state.internalnumber}</label>
                                    <label className="col-9 m-0 p-0 lead-2 fw-600 lh-1">{this.state.profile}</label>

                                </div>
                                <div className="col-12 p-0 px-0 flexbox">
                                    <label className="lead-1 fw-400 mb-0 text-left">{this.state.source_site}</label>
                                    <label className="lead-1 fw-100 mb-0 text-center"><i className="fa fa-long-arrow-right" aria-hidden="true"></i></label>
                                    <label className="lead-1 fw-400 mb-0 text-right">{this.state.dump_site}</label>
                                </div>
                            </div>
                                            
                           
                            <TruckProfile idtruck={this.state.truck} photo={this.state.truckphoto} />

                            <div className="row p-1 mt-4 mx-0 bg-pale-success">
                                <div className="col-12 p-2">

                                    <label className="col-6 m-0 p-0 fw-700 float-left"><i className="ti ti-check text-success mr-1" aria-hidden="true"></i>TRUCK LOADED</label>
                                    <label className="col-5 m-0 p-0 fw-600 float-right text-right"><img className="round m-1 mb-1 mr-1" width="20" height="20" src={LetterAvatar(this.state.creatorName)} />
                                        <Contact iduser={this.state.idcreator} idticket={this.state.idticket} mode={"creator"}> 
                                            {this.state.creatorName}
                                        </Contact>
                                        {coords ? <a className="col-4 m-0 p-0" href={`https://www.google.com/maps/dir/${coords[1]},${coords[0]}//@${coords[1]},${coords[0]},15z`} target="window">
                                            <label className="m-0 mr-1">&nbsp;<i className="fa fa-location-arrow" aria-hidden="true"></i></label> 
                                        </a>: null}
                                    </label>

                                    <label className="m-0 ml-4 p-0 fw-400 float-left">{dayAndTimeFromTimestamp(this.state.sourcetimestamp)}</label>
                                    <label className="col-5 m-0 mr-2 p-0 fw-400 float-right text-right">{dateFromTimestamp(this.state.sourcetimestamp)}</label>
                                </div>
                            </div> 
                            <div className="col-12 mt-5 p-0">
                                <label className="col-6 fw-400 fs2m pl-2">Tonnage</label>
                                <label className="btn-link fw-400 fs2m float-right pr-4" data-toggle="collapse" href="#open-calculator-modal-confirm-disposed"><u>{this.state.tonnage}</u></label>
                            </div>  
                            
                            <TonnageCalculator id="modal-confirm-disposed" tonnage={this.state.tonnage} idtruck={this.state.truck} onChange={newValue => this.handleChange({target: {name: "tonnage", value: newValue}}) }/>                           

                             {this.props.canCloseTickets ? 
                                <div className="custom-control custom-checkbox pb-3" onClick={() => this.setState({showInvalid: !this.state.showInvalid, invalidreason: null})}>
                                    <input type="checkbox" className="custom-control-input mt-2 mb-2"
                                    checked={this.state.showInvalid} />
                                    <label className="custom-control-label mb-0"><em>This is an invalid ticket.</em></label> 
                                </div>
                             : null }

                        {this.props.canCloseTickets ? <React.Fragment>
                            {this.state.invalid ? <React.Fragment>
                                <div class="row p-1 mt-4 mx-0 bg-light">
                                    <div class="col-12 p-2">
                                        <label class="col-6 m-0 p-0 fw-700 float-left">
                                            <i class="ti ti-close text-danger mr-1" aria-hidden="true"></i>INVALID TICKET
                                        </label>
                                        <label class="col-5 m-0 p-0 fw-600 float-right text-right"><img class="round m-1 mb-1 mr-1" width="20" height="20" src={LetterAvatar(this.state.invalidmarkername)} />{this.state.invalidmarkername}
                                            {this.state.invalidCoords ? <a class="col-4 m-0 p-0" href={`https://www.google.com/maps/dir/${this.state.invalidCoords.lat},${this.state.invalidCoords.long}//@${this.state.invalidCoords.lat},${this.state.invalidCoords.long},15z`} target="window">
                                                <label class="m-0 mr-1">&nbsp;<i class="fa fa-location-arrow" aria-hidden="true"></i></label>
                                            </a> : null}
                                        </label>
                                        <label class="m-0 ml-4 p-0 fw-400 float-left">{dayAndTimeFromTimestamp(this.state.invalidtimestamp)}</label>
                                        <label class="col-5 m-0 mr-2 p-0 fw-400 float-right text-right">{dateFromTimestamp(this.state.invalidtimestamp)}</label>
                                        <p class="float-left clearfix mx-4 mt-2 lh-2">
                                            <i class="fa fa-quote-left" aria-hidden="true"></i>
                                            <em>{this.state.invalidreason}</em>
                                            <i class="fa fa-quote-right float-right" aria-hidden="true"></i>
                                        </p>
                                        {!this.state.recovered ? 
                                        <a class="btn btn-xs btn-outline-info float-left px-2 py-0 ml-4 mb-2"
                                            onClick={() => this.setState({showRecoverInput: !this.state.showRecoverInput})}>Recover Ticket</a>
                                        : null }
                                    </div>
                                </div> 
                                {! this.state.recovered ? 
                                    <div class="accordion mt-3" id="recover-invalid">
                                        <div class="card noborder">
                                            <div id="open-recover" className={"collapse bg-gray" + (this.state.showRecoverInput ? " show" : "" )}>
                                                <div class="card-body border-primary p-2 mb-2">
                                                    <label class="col-6 m-0 p-0 fw-700 float-left">
                                                        <i class="ti ti-pulse text-info mr-1" aria-hidden="true"></i>RECOVER TICKET
                                                    </label>                                                            
                                                    <div class="contrast">
                                                        <textarea class="form-control" 
                                                            rows="4" 
                                                            id="recover-reason-textarea"
                                                            onChange={(e) => this.setState({recoverreason: e.target.value})}
                                                            value={this.state.recoverreason}
                                                            name="recoverreason"
                                                            placeholder="Why is this ticket being recovered?">
                                                        </textarea>
                                                    </div>
                                                    <a class="btn btn-xs btn-outline-info text-info float-left px-2 py-0 mt-3 ml-4 mb-2" onClick={this.sendRecoverData}>Save & Recover</a> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                : <div class="row p-1 mt-4 mx-0 bg-pale-info">
                                    <div class="col-12 p-2">
                                        <label class="col-6 m-0 p-0 fw-700 float-left">
                                            <i class="ti ti-pulse text-info mr-1" aria-hidden="true"></i>RECOVERED TICKET
                                        </label>
                                        <label class="col-5 m-0 p-0 fw-600 float-right text-right"><img class="round m-1 mb-1 mr-1" width="20" height="20" src={LetterAvatar(this.state.recovermarkername)} />{this.state.recovermarkername}
                                            {this.state.recoverCoords ? <a class="col-4 m-0 p-0" href={`https://www.google.com/maps/dir/${this.state.recoverCoords.lat},${this.state.recoverCoords.long}//@${this.state.recoverCoords.lat},${this.state.recoverCoords.long},15z`} target="window">
                                                <label class="m-0 mr-1">&nbsp;<i class="fa fa-location-arrow" aria-hidden="true"></i></label>
                                            </a> : null}
                                        </label>
                                        <label class="m-0 ml-4 p-0 fw-400 float-left">{dayAndTimeFromTimestamp(this.state.recovertimestamp)}</label>
                                        <label class="col-5 m-0 mr-2 p-0 fw-400 float-right text-right">{dateFromTimestamp(this.state.recovertimestamp)}</label>
                                        <p class="float-left clearfix mx-4 mt-2 lh-2">
                                            <i class="fa fa-quote-left" aria-hidden="true"></i>
                                            <em>{this.state.recoverreason}</em>
                                            <i class="fa fa-quote-right float-right" aria-hidden="true"></i>
                                        </p>
                                    </div>
                                </div>    }

                            </React.Fragment>
                            : <div className="accordion" id="invalid-ticket">
                                <div className="card noborder">
                                    <div id="open-invalid" className={"collapse bg-gray" + (this.state.showInvalid ? " show" : "") } data-parent="#invalid-ticket">
                                        <div className="card-body border-primary p-2 mb-2">
                                            <label className="col-6 m-0 p-0 fw-700 float-left">
                                                <i className="ti ti-close text-danger mr-1" aria-hidden="true"></i>
                                                INVALID TICKET
                                            </label>
                                            <div className="contrast">
                                                <textarea className="form-control"
                                                id="invalid-reason-textarea"
                                                rows="4" 
                                                onChange={this.handleChange.bind(this)}
                                                value={this.state.invalidreason}
                                                name="invalidreason"
                                                placeholder="Why is this ticket invalid?">
                                                </textarea>
                                                <button class="btn btn-xs btn-outline-dark text-dark float-left px-2 py-0 mt-3 ml-4 mb-2" disabled={this.state.invalidreason === null} onClick={this.sendInvalidData}>Mark as Invalid</button> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> }
                        </React.Fragment> : null }
                        </div>
                        {this.props.canCloseTickets && !this.state.invalid ? <button
                            type="button"
                            disabled={this.state.showInvalid}
                            className="btn btn-lg btn-primary"
                            onClick={() => { this.handleUpdate();  }}
                        >Complete Ticket</button> : 
                            <button type="button" className="btn btn-lg btn-primary" data-toggle="modal" data-dismiss="modal" >DONE</button>
                        }
                    </div>
                </div>
            </div>
             <SuccessPopup
                popupId="recoverPopupInvalidTicket"
                title="Recovered"
                message="Ticket successfully recovered!"
                trigger={this.state.recoverPopup}
                style="info"
                callback={() => {
                    this.setState({recoverPopup: false}); 
                }}
                />
            <SuccessPopup
                popupId="ticketCompletionSuccess"
                title="Dump Confirmed"
                message="Ticket has been moved to Hauled Today."
                trigger={this.state.ticketCompletionPopupTrigger}
                callback={() =>  {this.setState({ticketCompletionPopupTrigger: false}); document.getElementById('close-confirm-disposed-modal').click();}}
                 />
            <SuccessPopup
                popupId="ticketInvalidSuccess"
                title="Ticket Marked As Invalid"
                style="dark"
                message="Ticket has been moved to Hauled Today."
                trigger={this.state.ticketInvalidPopupTrigger}
                callback={() =>  {this.setState({ticketInvalidPopupTrigger: false});}}
                 />
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        tickets: state.tickets,
    };
}
  
function mapDispatchToProps(dispatch) {
    return {
        setTicketForModal: (ticket) => dispatch(setTicketForModal(ticket)),
          getTickets: (id) => dispatch(getActiveTickets(id)),
          createTicket: (data) => dispatch(createTicket(data)),
          updateTicketSourceGeo: (data) => dispatch(updateTicketSourceGeo(data)),
          updateTicketSourcePicture: (idticket, picture) => dispatch(updateTicketSourcePicture(idticket, picture)),
          updateTicketArrivalGeo: (data) => dispatch(updateTicketArrivalGeo(data)),
          updateTicketArrivalPicture: (idticket, picture, signature, tons, timestamp, datedisposed) => dispatch(updateTicketArrivalPicture(idticket, picture, signature, tons, timestamp, datedisposed)),
          markTicketAsInProgress: (id) => dispatch(markTicketAsInProgress(id)),

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalConfirmDisposedPhoto)