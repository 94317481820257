import React, {Component, Fragment} from 'react';
import config from '../../constants/config';
import pageUrls from '../../constants/pageUrls';
import ajax from 'superagent';
import { userService } from '../../services';
import NotificationItem from './NotificationItem';
import SupportGuide from './SupportGuide';
import NavbarCore from './NavbarCore';
import jwtDecode from 'jwt-decode';

export default class MenuAppBar extends Component {

    constructor(props){
        super(props);
        const userJWT = localStorage.getItem('token');
        const userDecoded = jwtDecode(userJWT);

        let user = localStorage.getItem('user');
        let username = (JSON.parse(user).username);
        let iduser = JSON.parse(user).id;
        //username = username.split(':')[0];

        this.state = {
            username,
            userDecoded,
            iduser
        }
    }

    render = () => {
        return(
            <Fragment>
                <NavbarCore
                    haulcheckFrontendURL={config.hcFrontPage}
                    assuredFrontendURL={config.webUrl}
                    assuredAPIURL={config.apiUrl}
                    eiFrontendUrl={config.eiFrontPage}
                    assuredPageUrls={pageUrls}
                    myFrontendUrl={config.webUrl}
                    app={"AssuredDisposal"}
                    iduser={this.state.iduser}
                    username={this.state.username}
                    showSandbox={this.props.showSandbox}
                    showBetaBadge={true}
                    logo="../img/logo/ad-logo.png"
                >
                    
                    <div class="nav-divider"></div>
                    
                    <li class="nav-item">
                        <a class="nav-link" href="#">I am a Site Leader</a>
                        <ul class="nav">
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="modal" data-target="#getting-set-up-guide-modal">Getting Set Up</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="modal" data-target="#site-based-field-use-guide-modal">Site-based Field Use</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="modal" data-target="#working-with-tickets-guide-modal">Working with Tickets</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="modal" data-target="#tracking-trucks-guide-modal">Tracking Trucks</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="modal" data-target="#tracking-and-reports-guide-modal">Tracking & Reports</a>
                            </li>
                        </ul>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" href="#">I've been invited to an Agreement</a>
                        <ul class="nav">
                            <li class="nav-item">
                                <a class="nav-link" href="#" data-toggle="modal" data-target="#completing-setup-getting-started-guide-modal">Completing Setup / Getting Started</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="modal" data-target="#site-based-field-use-guide-modal">Site-based Field Use</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="modal" data-target="#working-with-tickets-guide-modal">Working with Tickets</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="modal" data-target="#tracking-trucks-guide-modal">Tracking Trucks</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="modal" data-target="#tracking-and-reports-guide-modal">Tracking & Reports</a>
                            </li>
                        </ul>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" href="#">I've been invited as a Co-Leader</a>
                        <ul class="nav">
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="modal" data-target="#how-it-works-guide-modal">How It Works</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="modal" data-target="#site-based-field-use-guide-modal">Site-based Field Use</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="modal" data-target="#working-with-tickets-guide-modal">Working with Tickets</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="modal" data-target="#tracking-trucks-guide-modal">Tracking Trucks</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="modal" data-target="#tracking-and-reports-guide-modal">Tracking & Reports</a>
                            </li>
                        </ul>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" href="#">I've been invited as a Team Member</a>
                        <ul class="nav">
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="modal" data-target="#how-it-works-guide-modal">How It Works</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="modal" data-target="#site-based-field-use-team-member-guide-modal">Site-based Field Use</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="modal" data-target="#working-with-tickets-guide-modal">Working with Tickets</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="modal" data-target="#tracking-trucks-guide-modal">Tracking Trucks</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="modal" data-target="#tracking-hauls-guide-modal">Tracking Hauls</a>
                            </li>
                        </ul>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" href="#">I've been invited as a Guest</a>
                        <ul class="nav">
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="modal" data-target="#how-it-works-guide-modal">How It Works</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="modal" data-target="#site-based-field-use-guest-guide-modal">Site-based Ticket Tracking</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="modal" data-target="#trucks-tracking-and-reports-guide-modal">Trucks, Tracking, & Reports</a>
                            </li>
                        </ul>
                    </li>

                </NavbarCore>

                <SupportGuide />

            </Fragment>
        )
        //return null;
    }
}
