import React, {Fragment, Component} from 'react';
import _ from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ajax from 'superagent';
import moment from 'moment';
import jwtDecode from 'jwt-decode';

export default function NewSite ({backendURL, refreshSites, location, username, closeModal}) {

  const formik = useFormik({
    initialValues: {
      nameProject: '',
    },
    validationSchema: Yup.object({
      nameProject: Yup.string()
        //.max(25, 'Must be 25 characters or less')
        .required("Name of project can't be empty"),
    }),
    onSubmit: async (values) => {
      //alert(JSON.stringify(values, null, 2));
      await updateSiteHandler(values)
    },
  });


 const updateSiteHandler = async (formValues) => {

  let data = {
    formValues,
    userId: username,
    geometry: location
  }

  ajax.post(`${backendURL}/sites/add`)
  .send(data)
  .end(async (error, response) => {
    if (!error && response) {
      refreshSites();
      closeModal(response.body);
    }
  });
}

/*const validateTime = (time) =>{
  moment(time, [
    "Hmm", "HHmm",
    "h:mm a", "h:mm A", "h:mma", "h:mmA",
    "h:m a", "h:m A", "h:ma", "h:mA",
    "hh:mm a", "hh:mm A", "hh:mma", "hh:mmA",
    "hh:mm a", "hh:mm A", "hh:mma", "hh:mmA", 
    
  ])
}*/


  return (

    <form onSubmit={formik.handleSubmit}>
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-body pt-0">
            <div className="row m-0 mb-5">
              <div className="col-lg-12">
                <div className="form-group row">
                  <div className="col-12 px-0 mb-2 mx-auto">
                    <label className="lead text-dark fw-600">Site Name</label>
                      <div className="contrast">
                        <input
                        className="form-control"
                        id="nameProject"
                        name="nameProject"
                        type="text"
                        placeholder="Name of Project"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.nameProject}
                        />
                        {formik.touched.nameProject && formik.errors.nameProject ? (
                        <div className="error-message">{formik.errors.nameProject}</div>
                        ) : null}
                        </div>
                        <button type="submit" className="btn btn-lg btn-primary mt-1" >Create Site</button>
                      </div>
                  </div>
                </div>
            </div>
          </div>

        </div>
      </div>
    </form>
  );
};
