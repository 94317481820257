import { combineReducers } from 'redux';
import ticketsReducer from './ticketsReducer';
import usersReducer from './usersReducer';
import stripeReducer from './stripeReducer';
import sitesReducer from './sitesReducer';
import pdfReducer from './pdfReducer';

export default combineReducers({
    tickets: ticketsReducer,
    users: usersReducer,
    stripe: stripeReducer,
    sites: sitesReducer,
    pdf: pdfReducer,
});