import React, {Component} from 'react';
//import { Spinner } from 'react-bootstrap';
import ajax, {Request} from 'superagent';
import {Elements, StripeProvider, injectStripe} from 'react-stripe-elements';
import config from '../../../constants/config';
import jwtDecode from 'jwt-decode';
import ShowCardForm from './ShowCardForm';
import CardEntryForm from './CardEntryForm';
import "./style.css";

class StripeForm extends Component {

	constructor(props){
        super(props);

        let user = JSON.parse(localStorage.getItem('user'));

        this.state = {
            userId: user.id,
            userEmail: user.email,
           	stripeToken: user.stripe_token,
           	customerId: user.stripe_cus_id,
           	showLoadingWidget: false,
        }

    }

    componentWillMount = () => {
        this.setState({showLoadingWidget: true});
    	ajax.get(`${config.apiUrl}/stripe/paymentByUserId/${this.state.userId}`)
        .then(res => {
            let paymentMethod = res.body.payment;
            this.setState({paymentMethod, showLoadingWidget: false});
            if (this.props.getStripePaymentMethod) this.props.getStripePaymentMethod(paymentMethod);
        });
    }

    getStripePaymentMethod = (idpayment) => {
    	this.setState({showLoadingWidget: true});
    	ajax.get(`${config.apiUrl}/stripe/payment/${idpayment}`)
		.then(res => {
			console.log("getStripePaymentMethod res:", res);
			let paymentMethod = res.body.payment;
			this.setState({paymentMethod, showLoadingWidget: false});
			if (this.props.getStripePaymentMethod) this.props.getStripePaymentMethod(paymentMethod);
		});
    }

    resetCard = () => {
    	this.setState({
    		paymentMethod: null
    	})
    }

    displayLoadingWidget = () => {
    	this.setState({showLoadingWidget: true});
    }

    hideLoadingWidget = () => {
    	this.setState({showLoadingWidget: false});
    }

    render() {
    	if (this.state.showLoadingWidget){
            return(<div className="loader">Loading...</div>)
    	} else if (!this.state.paymentMethod){
			return( <CardEntryForm getStripePaymentMethod={this.getStripePaymentMethod} 
				displayLoadingWidget={this.displayLoadingWidget}
				hideLoadingWidget={this.hideLoadingWidget} /> )
		} else {
			return(<ShowCardForm resetCard={this.resetCard} stripePayment={this.state.paymentMethod} />)
		}
    }
    

}

export default injectStripe(StripeForm);
