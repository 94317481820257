import {
    SET_VALUES_FOR_PDF,
} from '../actions/types';

const initialState = {
    valuesForPDF: {
        items: [],
        filter: {},
    }
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_VALUES_FOR_PDF: {
            const { items, filter } = action;
            return Object.assign({}, state, {
                valuesForPDF: {
                    items,
                    filter,
                },
            });
        }
        default:
            return state;
    }
  };
  