import React, {Fragment, Component} from 'react';
import ModalTruckID from '../ActiveTickets/ModalTruckID';

export default class TempTruckID extends Component{

    constructor(props){
        super(props);
    }

    renderTempId = (idClasses, IdElement, showIcon, iconClasses) => {
    	return(<Fragment>
    		<IdElement className={idClasses}>
    			{showIcon ? <i className={iconClasses} aria-hidden="true"></i> : null }
    			Temp ID #{this.props.number}
			</IdElement>
			</Fragment>
		)
    }

    renderLink = (linkClasses, LinkElement) => {
    	return(
    		<Fragment>
	    		<LinkElement className={linkClasses} data-toggle="modal" data-target={"#modal-addtruck"+this.props.idtruck}>
	    			Identify Truck
				</LinkElement>
				<ModalTruckID id={this.props.idtruck} />
			</Fragment>
    	)
    }


	render(){
	let props = this.props;
	  return(<Fragment>
		
	    {this.renderTempId(props.idClasses, props.idElement, props.showIcon, props.iconClasses)}
	    {this.props.showLink ? this.renderLink(props.linkClasses, props.linkElement) : null}

	    </Fragment>)
	}
}