import React, {Component} from 'react';
import { userService } from '../../services'; 
import { userHelper } from '../../helpers';
import ajax from 'superagent';
import eiLogo from '../../assets/img/logo/ei-logo.png'
import '../../assets/css/page.min.css';
import '../../assets/css/style.min.css';
import config from '../../constants/config';
import pageUrls from '../../constants/pageUrls';
import './style.css';

class InviteeRegister extends Component {
    
    constructor(props) {
        super(props);

        let { email } = this.props.match.params;
        let error = 0;
        let errorMessage = '';

        let projectdata = null;
        let rawprojectdata = null;
        if (this.props.match.params.projectdata != undefined){
            rawprojectdata = this.props.match.params.projectdata;
            projectdata = JSON.parse(window.atob(this.props.match.params.projectdata))
        }

        try {
            let validation = window.atob(email).split(":")[0];
            email = window.atob(email).split(":")[1];

            if (validation !== 'assuredVerify') {
                throw {status: 403, message: "Wrong key string."};
            }
        } catch(err) {
            if (err.status === 403) {
                error = err.status;
                errorMessage= err.message;
            } else {
                error = 500;
                errorMessage = "Key string is not correctly encoded";
            }
            console.log('Error:', err);
        } finally {
            this.state = {
                user: {
                    username:   '',
                    password:   '',
                    password2:  '',
                    fullname:   '',
                    email:      email,
                },
                passwordMatch:  false,
                submitted:      false,
                loading:        false,
                tosAccepted:    false,
                error:          error,
                errorMessage:   errorMessage,
                projectdata,
                rawprojectdata
            };
        }


        this.handleChange   = this.handleChange.bind(this);
        this.handleSubmit   = this.handleSubmit.bind(this);
        this.handleTOS      = this.handleTOS.bind(this);
        
    }

    componentWillMount() {
        if (this.state.projectdata){

            ajax.get(`${config.apiUrl}/users/email/${this.state.user.email}`)
            .end((error, response) => {
                if (!error && response) {
                    console.log("Email response:");
                    console.log(response);
                    if (response.body.user[0].username){
                        console.log("User exists!");
                        window.location = config.webUrl + pageUrls.loginFromInvite + this.state.rawprojectdata;
                    }
                } else {
                    console.log('There was an error fetching from GitHub', error);
                }
            }
        );

        }
    }

    handleChange(event) {
        const { name, value } = event.target;
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                submitted: false,
                [name]: value
            }
        });
    }

    handleTOS(event) {
        this.setState({
            tosAccepted: event.target.checked
        });
        console.log(event.target.checked);
    }

    handleSubmit(event) {

        const { user } = this.state;

        this.setState({ 
            submitted:      true,
            loading:        true,
            passwordMatch:  userHelper.passwordMatch(user.password, user.password2)
        });

        event.preventDefault();
    }

    componentDidUpdate() {
        const { user, passwordMatch, submitted, loading } = this.state;

        if (submitted && loading && user.fullname && user.email && user.username && user.password && passwordMatch) {
            userService.update(user)
                .then((value) => {
                    this.setState({
                        error:          value.errorType,
                        errorMessage:   value.message,
                        loading:        false
                    });
                    console.log('Error:', value.errorType);
                    if (value.errorType === 0) {
                         if (this.state.projectdata){
                            window.location.href=config.webUrl + pageUrls.loginFromInvite + this.props.match.params.projectdata;
                        } else {
                            window.location.href=config.webUrl + pageUrls.login;
                        }
                    }
                });
        }
    }
    
    
    render() {
        //const { registering  } = this.props;
        const { user, submitted, passwordMatch, error, errorMessage, tosAccepted } = this.state;
        return (
            <div className="layout-centered bg-img bg-4 register-component vcenter">
                <main className="main-content">
                    { (error === 403 || error === 500) &&
                    <div className="center bg-white rounded shadow-7 w-600 mw-100 p-6">
                        <h1>We are sorry</h1>
                        <h3>{ errorMessage }</h3>
                    </div>
                    }
                    { error !== 403 && error !== 500 &&
                    <div className="bg-white rounded shadow-7 w-400 mw-100 p-6">
                        <img src={ eiLogo } width="45%" className="mb-6" alt=""></img>
                        <h5 className="mb-4">Welcome to Assured Disposal</h5>
                        <span>Add a password and log in to continue.</span>
                        <form name="form" onSubmit={ this.handleSubmit }>
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                name="username"
                                value={ user.username }
                                onChange={ this.handleChange }
                                placeholder="Username" />
                            { submitted && !user.username &&
                                <div className="help-block">Username is required</div>
                            }
                            { submitted && error === 1 &&
                                <div className="help-block">{errorMessage}</div>
                            }
                        </div>
                
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                name="fullname"
                                checked={ user.fullname }
                                onChange={ this.handleChange }
                                placeholder="Your Name" />
                            { submitted && !user.fullname &&
                                <div className="help-block">Fullname is required</div>
                            }
                        </div>
                
                        <div className="form-group">
                            <input 
                                type="email"
                                className="form-control"
                                name="email"
                                value={ user.email }
                                onChange={ this.handleChange }
                                placeholder="Work Email Address"
                                disabled/>
                            { submitted && !user.email &&
                                <div className="help-block">Email is required</div>
                            }
                            { submitted && error === 2 &&
                                <div className="help-block">{errorMessage}</div>
                            }
                        </div>
                
                        <div className="form-group">
                            <input 
                                type="password" 
                                className="form-control" 
                                name="password"
                                value={ user.password }
                                onChange={ this.handleChange }
                                placeholder="Password"/>
                            { submitted && !user.password &&
                                <div className="help-block">Password is required</div>
                            }
                        </div>
                
                        <div className="form-group">
                            <input
                                type="password"
                                className="form-control"
                                name="password2"
                                value={ user.password2 }
                                onChange={ this.handleChange }
                                placeholder="Password (confirm)"/>
                            { submitted && !passwordMatch &&
                                <div className="help-block">Passwords do not match</div>
                            }
                        </div>
                
                        <div className="form-group py-3">
                            <div className="custom-control custom-checkbox">
                            <label className="" htmlFor="tosAccepted">                                
                            <input 
                                type="checkbox"
                                className="custom-control-input checkbox"
                                id="tosAccepted"
                                name="tosAccepted"
                                checked={ tosAccepted }
                                onChange={ this.handleTOS }
                                />
                                I agree to the <a className="ml-1" href="../web/terms.html">terms of service</a>
                            </label>
                            </div>
                        </div>
                
                        <div className="form-group">
                            <button className="btn btn-block btn-primary" disabled={ !tosAccepted }>Sign Up</button>
                        </div>
                        </form>
                
                        <div className="divider">Already a member? &nbsp;<a href="login">Login here</a></div>
                    </div>
                    }
                </main>
            </div>
        );
    }
}

export default InviteeRegister;
