import React, {Component} from 'react';
//import { Spinner } from 'react-bootstrap';

export default class ShowCardForm extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }
       
    render(){
       return(<React.Fragment>
            <p className="text-center mb-5 mt-3">You have registered a card with this information. </p>
            <div className="form-group row gap-y ml-3">
                <label className="col-12 pb-1">Card type: {this.props.stripePayment.card.brand}</label>
                <label className="col-12 pb-1">Card number: **{this.props.stripePayment.card.last4}</label>
            </div>
            <div className="form-group row gap-y mt-4">
                <div className="col-md-6">
                    <br />
                    <button id="change-card" type="button" className="btn btn-primary" onClick={() => {this.props.resetCard()}}>Change Card</button>
                </div>
            </div>
       </React.Fragment>)
    }
}