import React, { Component } from 'react';
import { resetPassword } from '../../services/userService';
import config from '../../constants/config';

export default class Register extends Component {
    constructor() {
        super();

        this.state = {
            iduser:     '',
            submitted:  false,
            success:    false,
            message:    ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        const { value } = event.target;
        
        this.setState({
            submitted: false,
            iduser: value
        });
        console.log(value);
    }

    handleSubmit(event) {
        event.preventDefault();
        const email = this.state.iduser;
        console.log(this.state.iduser);
        resetPassword(email)
            .then((value) => {
                this.setState({
                    submitted:  true,
                    success: value.success,
                    message: value.message
                });
                console.log('Response:', value);
                if(value.success) {
                    window.alert('Your password has been reset successfully. In a moment you will receive an email with your new password.')
                    window.location.href=config.webUrl + "/login/";
                } else {
                    window.alert(value.message);
                }
            });
    }

    render() {
        const { email } = this.state;
        return (
            <div className="Login" style={{backgroundImage: `url(/img/bg/4.jpg)` }}>
                <div className="Login-content">
                    <main className="main-content">

                        <div className="bg-white rounded shadow-7 w-400 mw-100 p-6">
                            <img src="../img/logo/ei-logo.png" width="45%" className="mb-6" alt=""/>
                            <h5 className="mb-4">Recover your password</h5>

                            <form className="input-line1" form="form" onSubmit={ this.handleSubmit }>
                                <div className="form-group">
                                    <input 
                                        type="email" 
                                        className="form-control" 
                                        name="email" 
                                        placeholder="Email Address"
                                        value={ email }
                                        onChange={ this.handleChange }/>
                                </div>

                                <button className="btn btn-lg btn-block btn-primary">Reset Password</button>
                            </form>

                        </div>

                    </main>
                </div>
            </div>
        )
    }
}
