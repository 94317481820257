import config from '../constants/config';
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from '../aws-exports';
import jwtDecode from 'jwt-decode';

export const updateUserService = {
    updatePhoneNumber,
    updateCompanyName,
    updateName,
    refreshJWTToken
}

const earthidealAPIURL = config.eiBackend;

async function updatePhoneNumber (data) {
    const requestOptions = {
        method:     'POST',
        headers:    { 'Content-Type': 'application/json' },
        body:       JSON.stringify(data),
    };
    return fetch(`${earthidealAPIURL}/users/update/phonenumber/`, requestOptions).then(handleResponse);
}

async function updateCompanyName (data) {
    const requestOptions = {
        method:     'POST',
        headers:    { 'Content-Type': 'application/json' },
        body:       JSON.stringify(data),
    };
    return fetch(`${earthidealAPIURL}/users/update/companyname/`, requestOptions).then(handleResponse);
}


async function updateName (data) {
    const requestOptions = {
        method:     'POST',
        headers:    { 'Content-Type': 'application/json' },
        body:       JSON.stringify(data),
    };
    return fetch(`${earthidealAPIURL}/users/update/name/`, requestOptions).then(handleResponse);
}

async function refreshJWTToken() {
	Amplify.configure(awsconfig);

	try {
		const cognitoUser = await Auth.currentAuthenticatedUser();
		let {refreshToken} = await Auth.currentSession();


	    cognitoUser.refreshSession(refreshToken, (err, session) => {

			if (session !== null){
				const { idToken, refreshToken, accessToken } = session;
				localStorage.setItem('token', idToken.jwtToken)
			}
		});

	} catch (e) {
		console.log('Unable to refresh Token', e);
	}
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                localStorage.removeItem('user');
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
