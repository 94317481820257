import React, {Fragment, Component} from 'react';

export default class BaseGuideModal extends Component {

  constructor(props){

    super(props)

    this.state={
      currentPage: 0
    }
  }

  componentDidMount = () => {
    let {id} = this.props;
    if (!this.props.suppressOpen) document.getElementById('open-'+id+'-guide-modal').click();
    if (this.props.markAsSeen) this.props.markAsSeen();
  }

  nextPage = () => {
    let {currentPage} = this.state;
    let {pages} = this.props;
    if (currentPage < pages.length){
      currentPage++;
      this.setState({currentPage});
    }
  }

  prevPage = () => {
    let {currentPage} = this.state;
    let {pages} = this.props;
    if (currentPage > 0){
      currentPage--;
      this.setState({currentPage});
    }
  }

  setPage = (pageNum) => {
    //console.log("SetupGuideModal calling setPage with page num " + pageNum);
    this.setState({currentPage: pageNum});
  }

  render(){

    let {currentPage} = this.state;
    let {pages, id} = this.props;
    let activePage = pages[currentPage];

   // console.log(currentPage + " is page "+pages.length+" of TicketGuideModal: ",currentPage == pages.length)

    return(<Fragment>
      <div style={{display: "none"}}>
        <button 
          id={"open-"+id+"-guide-modal"} 
          data-toggle="modal" 
          data-target={"#"+id+"-guide-modal"} 
        />
      </div>

      <div className="modal" id={id+"-guide-modal"} tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-long" role="document" style={{marginTop: "100px"}}>
          <div class="modal-content">

            <div class="slider-arrows-circle-dark slick-slider px-8" >
              {currentPage != 0 ?
                <button 
                  class="slick-prev slick-arrow" 
                  disabled={currentPage == 0} 
                  onClick={this.prevPage}>
                </button>
              : null }

              <div class="slick-list" style={{marginTop: "-5rem", marginLeft: "-5rem", marginRight: "-5rem"}}>
                <img class="card-img-top" src={activePage.img} alt="Card image cap"/>
              </div>
              
              {currentPage != (pages.length - 1) ?
                <button 
                  class="slick-next slick-arrow" 
                  disabled={currentPage == pages.length - 1}
                  onClick={this.nextPage}>
                </button>
              : null }
            </div>

            <div class="modal-body py-0">

              <div class="row">
                <div class="col-12">
                  <div class="card d-block">

                    <div class="card-body">
                      <p class="text-center">{activePage.title}</p>
                      <p class="text-center pb-0">{activePage.text}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal-footer" style={{display: 'block'}}>
              <div class="slider-dots slider-dots-fill slick-slider slick-dotted">
                <ul class="slick-dots">
                  {
                    pages.map((page, key)=>{
                      return <li key={key} 
                        className={ currentPage == key ? "slick-active" : ""}
                        onClick={() => this.setPage(key)}  >
                          <button 
                            type="button"
                            >
                          </button>

                        </li>
                    })
                  }
                </ul>
              </div>

              <a class="nav-link cursor pull-right" data-dismiss="modal" style={{paddingTop: "-2.1rem"}}>Dismiss</a>

            </div>
          </div>
        </div>
      </div>

    </Fragment>)
  }

}